import { Box, BoxProps } from '@chakra-ui/react'

const StyledBox = ({
  insideFolder,
  ...rest
}: BoxProps & { insideFolder?: boolean }) => (
  <Box
    borderLeft={insideFolder ? '0' : '5px solid var(--colors-hr)'}
    color="var(--colors-typography-primary)"
    padding="20px"
    fontSize="14px"
    fontStyle="italic"
    fontWeight={400}
    lineHeight="14px"
    margin="0"
    {...rest}
  />
)

export const EmptyState = ({
  children,
  ...props
}: BoxProps & { insideFolder?: boolean }) => (
  <StyledBox as="blockquote" {...props}>
    {children}
  </StyledBox>
)
EmptyState.displayName = 'EmptyState'
