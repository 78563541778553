import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const BluetoothNo = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M10.5857864,12 L1.29289322,2.70710678 L2.70710678,1.29289322 L22.7071068,21.2928932 L21.2928932,22.7071068 L16,17.4142136 L11,22.4142136 L11,14.4142136 L7.70710678,17.7071068 L6.29289322,16.2928932 L10.5857864,12 Z M13,14.4142136 L13,17.5857864 L14.5857864,16 L13,14.4142136 Z M11,6.78578649 L11,1.58578644 L17.4142136,8 L14.8142135,10.6 L13.4,9.18578646 L14.5857864,8 L13,6.41421356 L13,8.78578649 L11,6.78578649 Z"
    />
  </BaseIcon>
)
