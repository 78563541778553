import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const AlarmNo = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M4.25705467,5.67126824 L3.75,5.16421356 L2.70710678,6.20710678 L1.29289322,4.79289322 L2.33578644,3.75 L1.29289322,2.70710678 L2.70710678,1.29289322 L22.7071068,21.2928932 L21.2928932,22.7071068 L18.3287318,19.7429453 C16.6048612,21.1536347 14.4012702,22 12,22 C6.4771525,22 2,17.5228475 2,12 C2,9.5987298 2.84636529,7.39513881 4.25705467,5.67126824 Z M16.9056439,18.3198574 L5.68014258,7.09435615 C4.62708161,8.44903985 4,10.1512885 4,12 C4,16.418278 7.581722,20 12,20 C13.8487115,20 15.5509601,19.3729184 16.9056439,18.3198574 Z M7.35578008,3.14156656 C8.74352684,2.41251181 10.3235898,2 12,2 C17.5228475,2 22,6.4771525 22,12 C22,13.6764102 21.5874882,15.2564732 20.8584334,16.6442199 L19.3584437,15.1442302 C19.7713927,14.1790579 20,13.116226 20,12 C20,7.581722 16.418278,4 12,4 C10.883774,4 9.82094209,4.22860729 8.85576982,4.64155631 L7.35578008,3.14156656 Z M17.7928932,2.70710678 L19.2071068,1.29289322 L22.7071068,4.79289322 L21.2928932,6.20710678 L17.7928932,2.70710678 Z"
    />
  </BaseIcon>
)
