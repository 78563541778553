import styled from '@emotion/styled'
import { HTMLAttributes } from 'react'
import { ChevronDown } from '.'
import { Box, BoxProps } from '@chakra-ui/react'
import { boxProps } from './Box'

const DetailsHOC = ({ children, ...props }: BoxProps) => (
  <Box as="details" {...props}>
    {children}
  </Box>
)

/**
 * The wrapper to create a toggle
 */
export const Details = styled(DetailsHOC)<{ open: boolean } & BoxProps>`
  display: ${(p) => (p.display ? p.display : 'flex')};
  align-items: center;
`

export const StyledSummary = styled.summary<{ open: boolean } & BoxProps>`
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 14px;

  &:focus {
    outline: none;
  }

  &::marker,
  &::-ms-expand,
  &::-webkit-details-marker {
    display: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    content: '';
  }

  ${(props) => boxProps(props)}
`
const ChevronDownButton = styled.button<{ open: boolean }>`
  width: 34px;
  height: 34px;
  border: none;
  background-color: transparent;
  border-radius: 8px;
  margin-right: 14px;
  border: 2px solid transparent;
  &:hover {
    border: 2px solid var(--colors-inputs-text-color);
  }
  &[open] {
    transform: rotate(180deg);
  }
`
/**
 * The trigger of a toggle.
 * Usage:
 * ```
 * <Details open={open}>
 *  <Summary open={open} onClick={toggleOpen}>
 *    Click Me
 *  </Summary>
 *  This content is hidden until marked `open`
 * </Details>
 * ```
 */
export const Summary = ({
  onClick,
  open,
  children,
  ...rest
}: {
  open: boolean
} & BoxProps &
  HTMLAttributes<HTMLElement>) => (
  <StyledSummary
    role="button"
    aria-expanded={open}
    open={open}
    tabIndex={0}
    onClick={(e) => {
      e.preventDefault()
      onClick?.(e)
    }}
    {...rest}
  >
    <ChevronDownButton open={open}>
      <ChevronDown
        width={12}
        height={12}
        fill="var(--colors-inputs-text-color)"
      />{' '}
    </ChevronDownButton>
    {children}
  </StyledSummary>
)
