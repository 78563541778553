import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const CheckMark = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <polygon
      fillRule="evenodd"
      points="9.707 14.293 19 5 20.414 6.414 9.707 17.121 4 11.414 5.414 10"
    />
  </BaseIcon>
)
