import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const GridSlides = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M5,5 L19,5 C20.1045695,5 21,5.8954305 21,7 L21,17 C21,18.1045695 20.1045695,19 19,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,7 C3,5.8954305 3.8954305,5 5,5 Z M5,7 L5,17 L19,17 L19,7 L5,7 Z M20,21 L20,23 L4,23 L4,21 L20,21 Z M20,1 L20,3 L4,3 L4,1 L20,1 Z"
    />
  </BaseIcon>
)
