import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const AppNotification = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M19,12 L21,12 L21,19 C21,20.1045695 20.1045695,21 19,21 L5,21 C3.8954305,21 3,20.1045695 3,19 L3,5 C3,3.8954305 3.8954305,3 5,3 L12,3 L12,5 L5,5 L5,19 L19,19 L19,12 Z M18,11 C15.2385763,11 13,8.76142375 13,6 C13,3.23857625 15.2385763,1 18,1 C20.7614237,1 23,3.23857625 23,6 C23,8.76142375 20.7614237,11 18,11 Z M18,9 C19.6568542,9 21,7.65685425 21,6 C21,4.34314575 19.6568542,3 18,3 C16.3431458,3 15,4.34314575 15,6 C15,7.65685425 16.3431458,9 18,9 Z"
    />
  </BaseIcon>
)
