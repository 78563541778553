import { PropsWithChildren, ReactNode } from 'react'
import { Box, BoxProps } from '@chakra-ui/react'
import { ToolTip } from './ToolTip'
import { RectangleBox } from './RectangleBox'

export const CustomMenu = ({
  onClose,
  placement = 'bottom-start',
  open,
  children,
  icon,
  toggleOpen,
  style,
  ...props
}: PropsWithChildren<
  {
    onClose: VoidFunction
    placement?: string
    open?: boolean
    toggleOpen: VoidFunction
    icon: ReactNode
    style?: object
  } & BoxProps
>) => {
  return (
    <Box {...props} style={style}>
      <ToolTip
        content={
          <Box padding="16px" borderRadius={0}>
            {children}
          </Box>
        }
        placement={placement as any}
        animation="none"
        arrow={false}
        duration={0}
        interactive
        visible={open}
      >
        <RectangleBox
          onClick={(e: any): void => {
            e.preventDefault()
            e.stopPropagation()
            if (open) {
              onClose()
            }
            toggleOpen()
          }}
          style={{
            width: '36px',
            height: '36px',
            padding: '4px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: open ? 'var(--colors-borders)' : 'transparent',
            cursor: 'pointer',
          }}
        >
          {icon}
        </RectangleBox>
      </ToolTip>
    </Box>
  )
}
CustomMenu.displayName = 'CustomMenu'
