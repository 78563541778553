import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const VolumeNo = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M14,15.4142136 L14,20.618034 L6.76393202,17 L3,17 C1.8954305,17 1,16.1045695 1,15 L1,9 C1,7.8954305 1.8954305,7 3,7 L5.58578644,7 L1.29289322,2.70710678 L2.70710678,1.29289322 L22.7071068,21.2928932 L21.2928932,22.7071068 L14,15.4142136 Z M8,9.41421356 L8,15.381966 L12,17.381966 L12,13.4142136 L8,9.41421356 Z M14,9.78578649 L12,7.78578649 L12,6.61803399 L11.2214983,7.00728482 L9.73078635,5.51657284 L14,3.38196601 L14,9.78578649 Z M21.7717346,17.5575211 L20.2542258,16.0400123 C20.7303686,14.8286671 21,13.4577449 21,12 C21,8.98782698 19.8487564,6.34636022 18.1031499,4.71748008 L19.6790988,3.45672099 C21.7136304,5.48074529 23,8.56810385 23,12 C23,14.0209003 22.5539462,15.9223307 21.7717346,17.5575211 Z M18.6653695,14.451156 L16.9616203,12.7474068 C16.9869421,12.5027996 17,12.2533793 17,12 C17,10.02567 16.2071847,8.29170963 14.9972703,7.20218374 L16.5747536,5.94019713 C18.0648988,7.41238271 19,9.58933501 19,12 C19,12.8532984 18.882838,13.6773141 18.6653695,14.451156 Z M6,9 L3,9 L3,15 L6,15 L6,9 Z"
    />
  </BaseIcon>
)
