import styled from '@emotion/styled'
import { P } from './Typography'
import { Box } from '@chakra-ui/react'
import { Menu, MenuItem } from './Menu'
import { format } from 'date-fns'
import { Label } from './Input'
import { mq } from './MediaQuery'

const getDate = (date?: string): string =>
  date ? format(new Date(date), 'MMM dd, YYY') : 'No date'

export interface ProjectProps {
  createdAt: string
  updatedAt: string
  owner: string
  menuItems?: MenuItem[]
  onCloseMenu?: () => void
  isInFolder?: boolean
}

const Wrapper = styled.section<{ paddingLeft?: string; isInFolder: boolean }>`
  display: flex;
  align-items: center;
  position: relative;

  ${({ isInFolder }): string =>
    isInFolder
      ? `
      padding: 4px 23px 4px 0;
      border-radius:0 0 18.5px 18.5px;
      margin-bottom: 0;

      &:hover:before{
        content:'';
        background: var(--colors-inputs-text-color);
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin-left: -24px;
        margin-right: 16px;
      }
    `
      : `
        padding: 8px 23px 8px 0;
      `};

  ${({ paddingLeft }): string =>
    paddingLeft ? `padding-left: ${paddingLeft};` : '20px'};
`

const ProjectName = styled.p`
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  line-height: normal;
  text-decoration: underline;
  letter-spacing: 0.29px;
  color: var(--colors-typography-anchor);
  width: 50%;
  margin: 0;

  & a {
    color: var(--colors-inputs-text-color);
    text-decoration: none;
  }

  @media (max-width: 1200px) {
    margin-bottom: 0px;
  }
`

export const Project = ({
  createdAt,
  updatedAt,
  owner,
  children,
  menuItems,
  isInFolder = false,
  onCloseMenu,
  ...props
}: React.PropsWithChildren<ProjectProps>) => (
  <Wrapper {...props} isInFolder={isInFolder}>
    <Box
      display="flex"
      flexWrap="nowrap"
      width="100%"
      justifyContent="space-between"
      css={[
        ...mq('flexDirection', ['column', 'row']),
        ...mq('alignItems', ['start', 'center']),
      ]}
    >
      <ProjectName>{children}</ProjectName>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="end"
        flexWrap="wrap"
      >
        <Box marginRight="40px">
          <Label variant="alt" marginBottom="4px">
            Last Updated:
          </Label>
          <P fontSize="14px">{getDate(updatedAt)}</P>
        </Box>
        <Box marginRight="40px">
          <Label variant="alt" marginBottom="4px">
            Created:
          </Label>
          <P fontSize="14px">{getDate(createdAt)}</P>
        </Box>
        <Box marginRight={'60px'}>
          <Label variant="alt" marginBottom="4px">
            Owner:
          </Label>
          <P fontSize="14px">{owner ? owner : 'No Owner'}</P>
        </Box>
      </Box>
    </Box>
    {menuItems && (
      <Menu placement="bottom-end" items={menuItems} onClose={onCloseMenu} />
    )}
  </Wrapper>
)
Project.displayName = 'Project'
