import { BaseIcon, SVGProps } from '@aurelius/icons'

export const PinPIcon = ({ ...rest }: SVGProps) => (
  <BaseIcon
    title="Picture-in-Picture"
    fill="currentColor"
    viewBox="0 0 48 48"
    {...rest}
  >
    <path d="M38.5,8h-29C6.467,8,4,10.467,4,13.5v21C4,37.533,6.467,40,9.5,40h29c3.033,0,5.5-2.467,5.5-5.5v-21	C44,10.467,41.533,8,38.5,8z M39,24.5c0,0.829-0.671,1.5-1.5,1.5h-13c-0.829,0-1.5-0.671-1.5-1.5v-10c0-0.829,0.671-1.5,1.5-1.5h13	c0.829,0,1.5,0.671,1.5,1.5V24.5z" />
  </BaseIcon>
)
