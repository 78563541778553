import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const ShieldNo = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M3.07046741,4.48468097 L1.29289322,2.70710678 L2.70710678,1.29289322 L22.7071068,21.2928932 L21.2928932,22.7071068 L18.1151101,19.5293236 C16.6413773,20.6862158 14.6030073,21.8431079 12,23 C6,20.3270758 3,17.6604092 3,15 L3,5 C3,4.79045027 3.02195554,4.62481164 3.07046741,4.48468097 Z M16.6942533,18.1084669 L5,6.41421356 L5,15 C5,16.5084521 7.22911083,18.568744 12.000926,20.8019938 C14.007303,19.8649766 15.5639239,18.957802 16.6942533,18.1084669 Z M6.4876034,2.27338989 C7.83074961,1.70053003 9.90882632,1 12,1 C14.9952495,1 17.5,2 19,3 C20.5,4 21,4 21,5 C21.0264318,5.29131477 21,13.5 21,15 C21,15.4928064 20.8975442,15.9856128 20.6926327,16.4784192 L19.0001224,14.7859089 C19.000354,14.5513041 19.0009174,14.2193362 19.0018395,13.7546873 C19.002442,13.4510385 19.0036756,12.8625961 19.0058778,11.801424 C19.0071585,11.1695166 19.0081782,10.6272502 19.0090335,10.1097828 C19.0127226,7.87763814 19.0117352,5.96181826 19.0095779,5.36928408 C18.6788452,5.1638246 18.1627209,4.84551481 17.8905996,4.66410059 C16.4143231,3.67991623 14.2601784,3 12,3 C10.721267,3 9.21599181,3.36779059 8.00931409,3.79510057 L6.4876034,2.27338989 Z"
    />
  </BaseIcon>
)
