import { ReactNode } from 'react'
import styled from '@emotion/styled'
import { boxProps } from '../Box'
import { BoxProps } from '@chakra-ui/react'
import { pick } from '@styled-system/props'

export const IconButton = styled.div<
  BoxProps & {
    disabled?: boolean
  }
>`
  border-radius: 4px;
  border: solid 1px var(--colors-buttons-alt-text);
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background: var(--colors-buttons-alt-background);
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.49px;
  color: var(--colors-buttons-alt-text);

  &:hover {
    background: ${(props) =>
      !props.backgroundColor ? 'var(--colors-buttons-alt-hover)' : ''};
    border: ${({ border }) =>
      border
        ? 'solid 2px var(--colors-inputs-text-color);;'
        : 'solid 1px var(--colors-buttons-alt-text);'};
  }
  ${(props) => boxProps(props)}
`

export interface DocumentButtonProps {
  onClick: VoidFunction
  text: string
  icon: ReactNode
}

export const DocumentButton = ({
  onClick,
  text,
  icon,
  ...rest
}: {
  color?: string
  backgroundColor?: string
} & DocumentButtonProps &
  BoxProps) => {
  return (
    <>
      <IconButton as="button" onClick={onClick} {...pick(rest)}>
        {icon} {text}
      </IconButton>
    </>
  )
}
