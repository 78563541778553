import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Nut = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M21,6.80615244 L21,17.1984573 L12,22.3946097 L3,17.1984573 L3,6.80615244 L12,1.61000001 L21,6.80615244 Z M12,3.91940109 L5,7.96085298 L5,16.0437567 L12,20.0852086 L19,16.0437567 L19,7.96085298 L12,3.91940109 Z M12,17 C9.23857625,17 7,14.7614237 7,12 C7,9.23857625 9.23857625,7 12,7 C14.7614237,7 17,9.23857625 17,12 C17,14.7614237 14.7614237,17 12,17 Z M12,15 C13.6568542,15 15,13.6568542 15,12 C15,10.3431458 13.6568542,9 12,9 C10.3431458,9 9,10.3431458 9,12 C9,13.6568542 10.3431458,15 12,15 Z"
    />
  </BaseIcon>
)
