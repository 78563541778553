import { useState } from 'react'
import styled from '@emotion/styled'
import { Box, BoxProps } from '@chakra-ui/react'
export interface RadioProps {
  className?: string
  onChange: Function
  name: string
  value: string
  selected: string
}

export interface HiddenRadioProps {
  checked: boolean
  value: string
}

const HiddenRadio = styled.input<HiddenRadioProps>`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`
const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
  position: absolute;
  height: 14px;
`
interface StyledRadioProps {
  checked: boolean
  className?: string
}

const getColor = (checked: boolean): string =>
  checked
    ? 'var(--colors-inputs-radio-background)'
    : 'var(--colors-layout-background)'

const StyledRadio = styled.div<StyledRadioProps>`
  display: inline-block;
  width: 16px;
  height: 16px;
  background: ${(props): string => getColor(props.checked)};
  border: 1px solid var(--colors-inputs-radio-background);
  border-radius: 24px;

  & input:focus + & {
    box-shadow: 0 0 0 3px pink;
  }
  & svg {
    visibility: ${(props): string => (props.checked ? 'visible' : 'hidden')};
  }
`
const RadioContainer = styled.div`
  display: inline-block;
  position: relative;
  vertical-align: middle;
  margin-right: 4px;
  height: 20px;
`
const RadioList = ({
  className,
  onChange,
  selected,
  name,
  value,
  ...props
}: RadioProps) => {
  return (
    <RadioContainer className={className}>
      <HiddenRadio
        type="radio"
        onChange={(e: any): void => {
          e.preventDefault()
          onChange(value)
        }}
        checked={value === selected}
        name={name}
        value={value}
        {...props}
      />
      <StyledRadio checked={value === selected}>
        <Icon viewBox="0 0 24 24">
          <circle cx="50%" cy="50%" r="10" />
        </Icon>
      </StyledRadio>
    </RadioContainer>
  )
}

const StyledLabel = styled.label`
  font-size: 14px;
  font-weight: 400;
  margin-right: 4px;
  cursor: pointer;
  margin-bottom: 24px;
`
const Span = styled.span`
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.19px;
  color: #176971;
`

export const RadioGroup = ({
  options = [],
  className,
  name,
  onSelect,
  defaultValue,
  ...props
}: {
  name: string
  options: Array<{ label: string; value: string; selected?: boolean }>
  className?: string
  onSelect?: (val: string) => void
  defaultValue?: string
} & Omit<BoxProps, 'onChange'>) => {
  const [selected, setSelected] = useState(defaultValue || '')
  const handleSelect = (val: string): void => {
    setSelected(val)
    onSelect?.(val as any)
  }
  return (
    <Box {...props}>
      {options?.map((opt) => (
        <StyledLabel key={opt?.value}>
          <RadioList
            className={className}
            selected={selected}
            onChange={handleSelect}
            name={name}
            value={opt?.value}
            {...props}
          />
          <Span>{opt?.label}</Span>
        </StyledLabel>
      ))}
    </Box>
  )
}
RadioGroup.displayName = 'RadioGroup'
