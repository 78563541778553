import * as yup from 'yup'

export const newGlobalRecommendation = yup.object().shape({
  name: yup.string().required('Name is required.'),
  description: yup.string(),
  type: yup.string().required('Type is required.'),
  status: yup
    .string()
    .required('Status is required.')
    .oneOf(['not_started', 'in_progress', 'completed', 'none']),
  tags: yup.array().of(yup.string().required()).default([]),
  newTags: yup.array().of(yup.string().required()).default([]),
  globalInsights: yup.array().of(yup.string().required()).default([]),
  insights: yup.array().of(yup.string().required()).default([]),
})

export type newGlobalRecommendationType = yup.InferType<
  typeof newGlobalRecommendation
>

export const updateGlobalRecommendation = yup.object().shape({
  name: yup.string(),
  description: yup.string(),
  type: yup.string(),
  status: yup.string(),
})

export type updateGlobalRecommendationType = yup.InferType<
  typeof updateGlobalRecommendation
>

export const updateGlobalRecommendationsTypeField = yup.object().shape({
  ids: yup
    .array()
    .of(yup.string().required())
    .min(1, 'At least one id is required.')
    .required(),
  type: yup.string().required('Type is required.'),
})

export type updateGlobalRecommendationsTypeFieldType = yup.InferType<
  typeof updateGlobalRecommendationsTypeField
>

export const updateGlobalRecommendationsStatusField = yup.object().shape({
  ids: yup
    .array()
    .of(yup.string().required())
    .min(1, 'At least one id is required.')
    .required('At least one id is required.'),
  status: yup
    .string()
    .required('Type is required.')
    .oneOf(['not_started', 'in_progress', 'completed', 'none']),
})

export type updateGlobalRecommendationsStatusType = yup.InferType<
  typeof updateGlobalRecommendationsStatusField
>

export const arrayOfGlobalRecommendations = yup.object().shape({
  globalRecommendations: yup.array().of(yup.string().required()).required(),
})

export type arrayOfGlobalRecommendationsType = yup.InferType<
  typeof arrayOfGlobalRecommendations
>
