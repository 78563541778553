import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const CreditcardFace = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M2.81818182,3 L21.1818182,3 C22.1859723,3 23,3.8954305 23,5 L23,17 C23,18.1045695 22.1859723,19 21.1818182,19 L2.81818182,19 C1.81402773,19 1,18.1045695 1,17 L1,5 C1,3.8954305 1.81402773,3 2.81818182,3 Z M3,5 L3,17 L21,17 L21,5 L3,5 Z M5,15 L5,13 L7,13 L7,15 L5,15 Z M8,15 L8,13 L11,13 L11,15 L8,15 Z M17,15 L17,13 L19,13 L19,15 L17,15 Z M14,15 L14,13 L16,13 L16,15 L14,15 Z M5,8 L8,8 L8,11 L5,11 L5,8 Z"
    />
  </BaseIcon>
)
