import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Cup = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M5.26756439,4 C5.61337381,3.40219863 6.25971764,3 7,3 L17,3 C17.7402824,3 18.3866262,3.40219863 18.7324356,4 L20,4 C21.1045695,4 22,4.8954305 22,6 L22,9 C22,12.2228413 20.4954134,14.5738734 17.7813405,14.9478456 C16.698759,16.5301579 14.9834979,17.645394 13,17.9291111 L13,19 L14,19 C15.1045695,19 16,19.8954305 16,21 L8,21 C8,19.8954305 8.8954305,19 10,19 L11,19 L11,17.9291111 C9.01650213,17.645394 7.30124102,16.5301579 6.21865948,14.9478456 C3.50458657,14.5738734 2,12.2228413 2,9 L2,6 C2,4.8954305 2.8954305,4 4,4 L5.26756439,4 Z M5,6 L4,6 L4,9 C4,10.5312611 4.39027687,11.6746449 5.1278274,12.3384891 C5.04393827,11.9052466 5,11.4577545 5,11 L5,6 Z M19,6 L19,11 C19,11.4577545 18.9560617,11.9052466 18.8721726,12.3384891 C19.6097231,11.6746449 20,10.5312611 20,9 L20,6 L19,6 Z M7,5 L7,11 C7,13.7614237 9.23857625,16 12,16 C14.7614237,16 17,13.7614237 17,11 L17,5 L7,5 Z"
    />
  </BaseIcon>
)
