import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const ChevronLeft = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <polygon
      fillRule="evenodd"
      points="9.414 12 16.707 19.293 15.293 20.707 6.586 12 15.293 3.293 16.707 4.707"
    />
  </BaseIcon>
)
