import { Hashtag } from '@aurelius/models'
import { useMemo, useCallback } from 'react'

type Props = {
  tags?: Hashtag[]
  show: Show
}
export type Show = {
  notes: boolean
  documents: boolean
  insights: boolean
  recommendations: boolean
}

export const useTagsChart = ({
  tags = [],
  show,
}: Props): {
  max: number
  getCount: (id: string) => number
} => {
  const getCount = useCallback(
    (id: string): number => {
      const tag = tags.find((tag) => tag.id === id)
      return (
        (show.notes ? tag?.notes.length ?? 0 : 0) +
        (show.insights ? tag?.insights.length ?? 0 : 0) +
        (show.documents ? tag?.documents.length ?? 0 : 0) +
        (show.recommendations ? tag?.recommendations.length ?? 0 : 0)
      )
    },
    [show.documents, show.insights, show.notes, show.recommendations, tags],
  )
  const max = useMemo(
    () => Math.max(...tags.map((tag) => getCount(tag.id), 1)),
    [getCount, tags],
  )

  return { max, getCount }
}
