import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const PinStart = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M12.6577283,22.7532553 L12,23.3275712 L11.3422717,22.7532553 C5.81130786,17.9237218 3,13.70676 3,10 C3,4.7506636 7.09705254,1 12,1 C16.9029475,1 21,4.7506636 21,10 C21,13.70676 18.1886921,17.9237218 12.6577283,22.7532553 Z M5,10 C5,12.8492324 7.30661202,16.4335466 12,20.6634039 C16.693388,16.4335466 19,12.8492324 19,10 C19,5.8966022 15.8358849,3 12,3 C8.16411512,3 5,5.8966022 5,10 Z M13.5857864,11 L7,11 L7,9 L13.5857864,9 L11.2928932,6.70710678 L12.7071068,5.29289322 L17.4142136,10 L12.7071068,14.7071068 L11.2928932,13.2928932 L13.5857864,11 Z"
    />
  </BaseIcon>
)
