import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const ChevronsTop = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M12,12.4142136 L6.70710678,17.7071068 L5.29289322,16.2928932 L12,9.58578644 L18.7071068,16.2928932 L17.2928932,17.7071068 L12,12.4142136 Z M6.70710678,12.7071068 L5.29289322,11.2928932 L12,4.58578644 L18.7071068,11.2928932 L17.2928932,12.7071068 L12,7.41421356 L6.70710678,12.7071068 Z"
    />
  </BaseIcon>
)
