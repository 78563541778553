import { GlobalInsight } from '@aurelius/models'
import { CardProps } from './Card'
import { InsightCard } from './InsightCard'

export const GlobalInsightCard = ({
  globalInsight,
  ...rest
}: React.PropsWithChildren<
  {
    globalInsight: GlobalInsight
    checked?: boolean
    check?: (v: { id: string; isGlobal: boolean } | null) => void
    withMetadata: boolean
    readOnly?: boolean
    title?: string
    titleLink?: string
  } & CardProps
>) => {
  return <InsightCard insight={globalInsight} {...rest} />
}

GlobalInsightCard.displayName = 'GlobalInsightCard'
