import { BaseIcon, SVGProps } from '@aurelius/icons'

export const LineIcon = ({ fill = '#000', ...rest }: SVGProps) => (
  <BaseIcon title="Line Icon" viewBox="0 0 50 50" {...rest}>
    <path
      fill="none"
      stroke={fill}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="2.613"
      strokeWidth="2"
      d="M47 3L3 47"
    />
  </BaseIcon>
)
