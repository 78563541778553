import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Table = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M9,14 L21,14 L21,11 L9,11 L9,14 Z M7,14 L7,11 L3,11 L3,14 L7,14 Z M9,6 L9,9 L21,9 L21,6 L9,6 Z M7,6 L3,6 L3,9 L7,9 L7,6 Z M9,19 L21,19 L21,16 L9,16 L9,19 Z M7,19 L7,16 L3,16 L3,19 L7,19 Z M3,4 L21,4 C22.1045695,4 23,4.8954305 23,6 L23,19 C23,20.1045695 22.1045695,21 21,21 L3,21 C1.8954305,21 1,20.1045695 1,19 L1,6 C1,4.8954305 1.8954305,4 3,4 Z"
    />
  </BaseIcon>
)
