import * as yup from 'yup'

export type TaggableField =
  | 'insights'
  | 'notes'
  | 'documents'
  | 'collections'
  | 'recommendations'
  | 'globalInsights'
  | 'globalRecommendations'
  | 'clips'
  | 'highlightreels'

export const hashtags = yup.object().shape({
  tags: yup.array().of(yup.string().required()).required(),
})
export type hashtagsType = yup.InferType<typeof hashtags>

export const addHashtags = yup.object().shape({
  ids: yup.array().of(yup.string().required()).default([]),
  tags: yup.array().of(yup.string().required()).default([]),
  newTags: yup.array(yup.string().trim().required()).default([]),
})

export type addHashtagsType = yup.InferType<typeof addHashtags>

export const addHashtagsToMay = yup.object().shape({
  entities: yup
    .array()
    .required()
    .of(
      yup.object().shape({
        id: yup.string().required(),
        tags: yup.array().of(yup.string().required()).required(),
      }),
    ),
  newTags: yup.array(yup.string().required()).required(),
})

export const addHashtagsToTaggable = (field: TaggableField) =>
  yup.object().shape({
    [field]: yup.array().of(yup.string().required()).default([]),
    hashtags: yup.array().of(yup.string().required()).default([]),
    newHashtags: yup.array(yup.string().required()).default([]),
  })

// TODO: rewrite as {
//   hashtags: string[]
//   newHashtags: string[]
//   [Field: TaggableField]?: string[]
// }
// https://github.com/microsoft/TypeScript/pull/26797
export type addHashtagsToTaggableType = {
  hashtags?: string[]
  newHashtags?: string[]
  insights?: string[]
  notes?: string[]
  documents?: string[]
  collections?: string[]
  recommendations?: string[]
  globalInsights?: string[]
  globalRecommendations?: string[]
  clips?: string[]
  highlightreels?: string[]
}

export const removeHashtags = yup.object().shape({
  hashtags: yup.array().of(yup.string().required()).default([]),
})
export type removeHashtagsType = yup.InferType<typeof removeHashtags>

export type addHashtagsToMayType = yup.InferType<typeof addHashtagsToMay>

export const hashtagCriteria = yup.object().shape({
  tags: yup.array().of(yup.string().required()).default([]),
  sortingTags: yup.string(),
  allNotes: yup.boolean(),
  noteAttitude: yup.boolean().nullable(),
  searchTags: yup.string(),
  project: yup.string(),
})

export type hashtagCriteriaType = yup.InferType<typeof hashtagCriteria>

export const hashtagColor = yup.object().shape({
  color: yup
    .string()
    .required()
    .matches(
      /^#[0-9A-F]{6}$/i,
      'Invalid color! Must be a valid 7-character string specifying an RGB color in hexadecimal format.',
    ),
})
export type hashtagColorType = yup.InferType<typeof hashtagColor>
