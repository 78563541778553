import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Discount = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M12,23 C5.92486775,23 1,18.0751322 1,12 C1,5.92486775 5.92486775,1 12,1 C18.0751322,1 23,5.92486775 23,12 C23,18.0751322 18.0751322,23 12,23 Z M12,21 C16.9705627,21 21,16.9705627 21,12 C21,7.02943725 16.9705627,3 12,3 C7.02943725,3 3,7.02943725 3,12 C3,16.9705627 7.02943725,21 12,21 Z M16,18 C14.3431458,18 13,16.6568542 13,15 C13,13.3431458 14.3431458,12 16,12 C17.6568542,12 19,13.3431458 19,15 C19,16.6568542 17.6568542,18 16,18 Z M16,16 C16.5522847,16 17,15.5522847 17,15 C17,14.4477153 16.5522847,14 16,14 C15.4477153,14 15,14.4477153 15,15 C15,15.5522847 15.4477153,16 16,16 Z M8,12 C6.34314575,12 5,10.6568542 5,9 C5,7.34314575 6.34314575,6 8,6 C9.65685425,6 11,7.34314575 11,9 C11,10.6568542 9.65685425,12 8,12 Z M8,10 C8.55228475,10 9,9.55228475 9,9 C9,8.44771525 8.55228475,8 8,8 C7.44771525,8 7,8.44771525 7,9 C7,9.55228475 7.44771525,10 8,10 Z M16.118034,6 L10.118034,18 L7.88196601,18 L13.881966,6 L16.118034,6 Z"
    />
  </BaseIcon>
)
