import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const PlayerPause = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M7,2 L9,2 C10.1045695,2 11,2.8954305 11,4 L11,20 C11,21.1045695 10.1045695,22 9,22 L7,22 C5.8954305,22 5,21.1045695 5,20 L5,4 C5,2.8954305 5.8954305,2 7,2 Z M15,2 L17,2 C18.1045695,2 19,2.8954305 19,4 L19,20 C19,21.1045695 18.1045695,22 17,22 L15,22 C13.8954305,22 13,21.1045695 13,20 L13,4 C13,2.8954305 13.8954305,2 15,2 Z M7,4 L7,20 L9,20 L9,4 L7,4 Z M15,4 L15,20 L17,20 L17,4 L15,4 Z"
    />
  </BaseIcon>
)
