import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const PinQuestion = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M12.6577283,22.7532553 L12,23.3275712 L11.3422717,22.7532553 C5.81130786,17.9237218 3,13.70676 3,10 C3,4.7506636 7.09705254,1 12,1 C16.9029475,1 21,4.7506636 21,10 C21,13.70676 18.1886921,17.9237218 12.6577283,22.7532553 Z M5,10 C5,12.8492324 7.30661202,16.4335466 12,20.6634039 C16.693388,16.4335466 19,12.8492324 19,10 C19,5.8966022 15.8358849,3 12,3 C8.16411512,3 5,5.8966022 5,10 Z M13,13 L11,13 C11,11.2983529 11.6245803,10.5696759 13.0527864,9.85557281 C13.8745803,9.44467588 14,9.29835285 14,8.5 C14,7.556407 13.2771608,7 12,7 C10.8954305,7 10,7.8954305 10,9 L8,9 C8,6.790861 9.790861,5 12,5 C14.2843464,5 16,6.32062807 16,8.5 C16,10.2016471 15.3754197,10.9303241 13.9472136,11.6444272 C13.1254197,12.0553241 13,12.2016471 13,13 Z M12.0003283,15.9983464 C11.4478622,15.9983464 11,15.5506311 11,14.9983464 C11,14.4460616 11.4478622,13.9983464 12.0003283,13.9983464 C12.5527943,13.9983464 13.0006565,14.4460616 13.0006565,14.9983464 C13.0006565,15.5506311 12.5527943,15.9983464 12.0003283,15.9983464 Z"
    />
  </BaseIcon>
)
