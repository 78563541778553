import { Camera } from '@aurelius/icons'
import { DocumentButton } from './DocumentButton'
import { BoxProps } from '@chakra-ui/react'
import { pick } from '@styled-system/props'

export const ImageButton = ({
  onClick,
  ...rest
}: {
  onClick: VoidFunction
} & BoxProps) => {
  return (
    <DocumentButton
      onClick={onClick}
      text="Add Image"
      {...pick(rest)}
      icon={
        <Camera
          fill="var(--colors-inputs-text-color)"
          height={20}
          width={20}
          marginRight="8px"
        />
      }
    />
  )
}
