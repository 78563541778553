import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const CloudConnect = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M13,15 L13,16.1707057 C13.8524134,16.4719906 14.5280094,17.1475866 14.8292943,18 L20,18 L20,20 L14.8292943,20 C14.4174579,21.1651924 13.3062188,22 12,22 C10.6937812,22 9.58254212,21.1651924 9.17070571,20 L4,20 L4,18 L9.17070571,18 C9.47199063,17.1475866 10.1475866,16.4719906 11,16.1707057 L11,15 L7.23529412,15 C4.356297,15 2,12.7746084 2,10 C2,7.65610106 3.69192449,5.67387548 5.99745823,5.14064352 C7.17872493,3.22078655 9.47052169,2 12,2 C14.9806643,2 17.5932355,3.69384878 18.4931469,6.14741698 C20.5171839,6.65541287 22,8.41552881 22,10.5 C22,12.998466 19.8807287,15 17.2941176,15 L13,15 Z M12,20 C12.5522847,20 13,19.5522847 13,19 C13,18.4477153 12.5522847,18 12,18 C11.4477153,18 11,18.4477153 11,19 C11,19.5522847 11.4477153,20 12,20 Z M20,10.5 C20,9.23820794 18.9741498,8.16144293 17.6067405,8.01644954 L16.8981732,7.94131653 L16.7378321,7.24705197 C16.3113916,5.40059763 14.3463808,4 12,4 C10.0135398,4 8.27095055,5.0073429 7.53689203,6.48412476 L7.30012063,6.96046239 L6.772793,7.03034657 C5.18083114,7.24132158 4,8.51380806 4,10 C4,11.6436696 5.43611453,13 7.23529412,13 L17.2941176,13 C18.8009112,13 20,11.8675272 20,10.5 Z"
    />
  </BaseIcon>
)
