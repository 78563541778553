import { memoize } from 'lodash-es'

export const aureliusBreakpoints = [
  '@media (min-width: 320px)',
  '@media (min-width: 640px)',
  '@media (min-width: 832px)',
  '@media (min-width: 1024px)',
  '@media (min-width: 1280px)',
]
const resolver = (property: string, breakpoints: (string | number)[]) =>
  JSON.stringify([property, ...breakpoints])

/**
 * Constructs a style object for the given property.
 * Syntax: `<div css={mq('width', ['100%', '75%', '50%', '25%', '10%']) }`>...</div>
 * Which would return:
 * ```_
 * {
 *   '@media (min-width: 320px)': {
 *     width: '100%',
 *   },
 *   '@media (min-width: 640px)': {
 *     width: '75%',
 *   },
 *   '@media (min-width: 832px)': {
 *     width: '50%',
 *   },
 *   '@media (min-width: 1024px)': {
 *     width: '25%',
 *   },
 *   '@media (min-width: 1280px)': {
 *     width: '10%',
 *    }
 * }
 * ```
 */
export const mq: (
  property: string,
  breakpoints: (string | number)[],
) => { [x: string]: { [x: string]: string | number } }[] = memoize(
  (property, breakpoints) => {
    const mqs = breakpoints.map((style, i) => {
      const mq =
        aureliusBreakpoints[i] ??
        aureliusBreakpoints[aureliusBreakpoints.length - 1]

      return {
        [mq]: {
          [property]: style,
        },
      }
    })
    return mqs
  },
  resolver,
)
