import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const ArrowTop = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <polygon
      fillRule="evenodd"
      points="13 5.414 13 22 11 22 11 5.414 4.707 11.707 3.293 10.293 12 1.586 20.707 10.293 19.293 11.707"
    />
  </BaseIcon>
)
