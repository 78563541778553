import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Screenshot = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M3,21 C1.8954305,21 1,20.1045695 1,19 L1,5 C1,3.8954305 1.8954305,3 3,3 L21,3 C22.1045695,3 23,3.8954305 23,5 L23,19 C23,20.1045695 22.1045695,21 21,21 L3,21 Z M21,5 L3,5 L3,19 L21,19 L21,5 Z M9.1843164,7.78768371 C9.67333621,7.30584332 10.2804658,7 11,7 L13,7 C13.7195342,7 14.3266638,7.30584332 14.8156836,7.78768371 C14.8879867,7.85892532 14.9541108,7.93005667 15.0143026,8 L15.6,8 C16.9414906,8 18,9.13411721 18,10.5 L18,14.5 C18,15.8658828 16.9414906,17 15.6,17 L8.4,17 C7.05850939,17 6,15.8658828 6,14.5 L6,10.5 C6,9.13411721 7.05850939,8 8.4,8 L8.98569742,8 C9.0458892,7.93005667 9.11201328,7.85892532 9.1843164,7.78768371 Z M11,9 C10.8706092,9 10.7333263,9.06915668 10.5880336,9.21231629 C10.4804087,9.31836108 10.405992,9.42597745 10.383646,9.46815566 L10.1018749,10 L8.4,10 C8.19509331,10 8,10.2090285 8,10.5 L8,14.5 C8,14.7909715 8.19509331,15 8.4,15 L15.6,15 C15.8049067,15 16,14.7909715 16,14.5 L16,10.5 C16,10.2090285 15.8049067,10 15.6,10 L13.8981251,10 L13.616354,9.46815566 C13.594008,9.42597745 13.5195913,9.31836108 13.4119664,9.21231629 C13.2666737,9.06915668 13.1293908,9 13,9 L11,9 Z M12,14 C10.8954305,14 10,13.1045695 10,12 C10,10.8954305 10.8954305,10 12,10 C13.1045695,10 14,10.8954305 14,12 C14,13.1045695 13.1045695,14 12,14 Z"
    />
  </BaseIcon>
)
