import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const BlockAlignLeft = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M22,9.85714286 L22,14.1428571 C22,15.2056811 21.0732946,16 20,16 L7,16 C5.92670537,16 5,15.2056811 5,14.1428571 L5,9.85714286 C5,8.79431889 5.92670537,8 7,8 L20,8 C21.0732946,8 22,8.79431889 22,9.85714286 Z M7,10 L7,14 L20,14 L20,10 L7,10 Z M2,2 L4,2 L4,22 L2,22 L2,2 Z"
    />
  </BaseIcon>
)
