import styled from '@emotion/styled'
import dynamic from 'next/dynamic'
import { Spinner } from './Spinner'

const Tippy = dynamic(() => import('@tippyjs/react'), {
  loading: () => <Spinner />,
})

export const ToolTip = styled(Tippy)`
  background: var(--colors-layout-background);
  color: var(--colors-sidenav-text);
  border: solid 1px var(--colors-borders-lists);
  box-shadow: var(--tooltip-shadow);

  &[data-placement^='right'] {
    .tippy-arrow::before {
      border-right-color: var(--colors-sidenav-text);
      left: -8px;
    }
  }
  &[data-placement^='left'] {
    .tippy-arrow::before {
      border-left-color: var(--colors-sidenav-text);
      right: -8px;
    }
  }
  &[data-placement^='top'] {
    .tippy-arrow::before {
      border-top-color: var(--colors-sidenav-text);
      bottom: -8px;
    }
  }
  &[data-placement^='bottom'] {
    .tippy-arrow::before {
      border-bottom-color: var(--colors-sidenav-text);
      top: -8px;
    }
  }
`

export default ToolTip
