import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Gift = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M5.49703927,7 C5.16883489,6.46337553 5,5.80285481 5,5 C5,3.41708537 6.19035171,2 8,2 C9.81199262,2 11.2732672,3.21362693 12,5.11382774 C12.7267328,3.21362693 14.1880074,2 16,2 C17.8096483,2 19,3.41708537 19,5 C19,5.80285481 18.8311651,6.46337553 18.5029607,7 L20,7 C21.1045695,7 22,7.8954305 22,9 L22,11 C22,12.1045695 21.1045695,13 20,13 L20,20 C20,21.1045695 19.1045695,22 18,22 L6,22 C4.8954305,22 4,21.1045695 4,20 L4,13 C2.8954305,13 2,12.1045695 2,11 L2,9 C2,7.8954305 2.8954305,7 4,7 L5.49703927,7 Z M11,9 L4,9 L4,11 L11,11 L11,9 Z M13,11 L20,11 L20,9 L13,9 L13,11 Z M11,20 L11,13 L6,13 L6,20 L11,20 Z M13,20 L18,20 L18,13 L13,13 L13,20 Z M8,4 C7.37964829,4 7,4.45196225 7,5 C7,6.25323143 8.01700141,6.84912049 10.42942,6.97448747 C10.1613026,5.11159947 9.1621754,4 8,4 Z M15.92942,4 C14.7672446,4 13.7681174,5.11159947 13.5,6.97448747 C15.9124186,6.84912049 16.92942,6.25323143 16.92942,5 C16.92942,4.45196225 16.5497717,4 15.92942,4 Z"
    />
  </BaseIcon>
)
