import { useCallback, useMemo } from 'react'
import { useEditor, useNode } from '@craftjs/core'
import ContentEditable from 'react-contenteditable'
import { Box } from '@chakra-ui/react'
export interface TextboxProps {
  text: string
  fontSize: string
  marginTop?: number
  marginRight?: number
  marginBottom?: number
  marginLeft?: number
  color?: string
  fontWeight?: string
}

export const Textbox = ({
  text: initialText,
  fontSize: initialFontSize = '12px',
  marginTop: initialMarginTop = 0,
  marginRight: initialMarginRight = 0,
  marginBottom: initialMarginBottom = 0,
  marginLeft: initialMarginLeft = 0,
  color: initialColor = '#8e9697',
  fontWeight: initialFontWeight = 'normal',
}: TextboxProps) => {
  const {
    connectors: { connect, drag },
    actions: { setProp },
    marginTop = initialMarginTop,
    marginRight = initialMarginRight,
    marginBottom = initialMarginBottom,
    marginLeft = initialMarginLeft,
    paddingTop = 0,
    paddingRight = 0,
    paddingBottom = 0,
    paddingLeft = 0,
    height,
    width,
    color = initialColor,
    backgroundColor,
    borderWidth = 0,
    borderColor = '#024660',
    borderRadius = 0,
    fontWeight = initialFontWeight,
    textAlign,
    text = initialText,
    fontSize = initialFontSize,
  } = useNode((state) => ({
    id: state.id,
    parent: state.data.parent,
    dragged: state.events.dragged,
    isActive: state.events.selected,
    marginTop: state.data.props.marginTop,
    marginRight: state.data.props.marginRight,
    marginBottom: state.data.props.marginBottom,
    marginLeft: state.data.props.marginLeft,
    paddingTop: state.data.props.paddingTop,
    paddingRight: state.data.props.paddingRight,
    paddingBottom: state.data.props.paddingBottom,
    paddingLeft: state.data.props.paddingLeft,
    height: state.data.props.height,
    width: state.data.props.width,
    color: state.data.props.color,
    backgroundColor: state.data.props.backgroundColor,
    fontWeight: state.data.props.fontWeight,
    textAlign: state.data.props.textAlign,
    borderWidth: state.data.props.borderWidth,
    borderRadius: state.data.props.borderRadius,
    borderColor: state.data.props.borderColor,
    text: state.data.props.text,
    fontSize: state.data.props.fontSize,
  }))

  const addPx = useCallback((value?: string) => {
    if (value) {
      return value?.indexOf?.('px') > 0 ? value : `${value}px`
    } else {
      return '0'
    }
  }, [])

  const style = useMemo(
    () => ({
      fontSize: addPx(fontSize),
      marginTop: addPx(marginTop),
      marginRight: addPx(marginRight),
      marginBottom: addPx(marginBottom),
      marginLeft: addPx(marginLeft),
      paddingTop: addPx(paddingTop),
      paddingRight: addPx(paddingRight),
      paddingBottom: addPx(paddingBottom),
      paddingLeft: addPx(paddingLeft),
      width: width ? `${width}%` : 'auto',
      height: height ? `${height}%` : 'auto',
      color,
      backgroundColor,
      borderRadius: `${borderRadius}px`,
      border: `${borderWidth}px solid ${borderColor}`,
      fontWeight,
      textAlign,
    }),
    [
      addPx,
      backgroundColor,
      borderColor,
      borderRadius,
      borderWidth,
      color,
      fontSize,
      fontWeight,
      height,
      marginBottom,
      marginLeft,
      marginRight,
      marginTop,
      paddingBottom,
      paddingLeft,
      paddingRight,
      paddingTop,
      textAlign,
      width,
    ],
  )
  const { enabled } = useEditor((state) => ({ enabled: state.options.enabled }))
  return (
    <Box position="relative">
      {/* eslint-disable-next-line */}
      <div ref={(ref): any => (ref && enabled ? connect(drag(ref)) : ref)}>
        <ContentEditable
          disabled={true}
          html={text}
          style={style} // eslint-disable-line react/forbid-component-props
          onChange={(e): any =>
            setProp(
              (props: Record<string, any>) =>
                (props.text = e.target?.value?.replace?.(
                  /<\/?[^>]+(>|$)/g,
                  '',
                )),
            )
          }
          tagName="p"
        />
      </div>
    </Box>
  )
}
Textbox.displayName = 'Textbox'
//@ts-ignore
Textbox.craft = {
  displayName: 'Textbox',
}
