import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Book = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M21,22 L6,22 C4.34314575,22 3,20.6568542 3,19 L3,5 C3,3.34314575 4.34314575,2 6,2 L21,2 L20.9999995,18 C20.4477153,18 20,18.4477153 20,19 C20,19.5522847 20.4477153,20 21,20 L21,22 Z M18,19 C18,18.6493646 18.0601542,18.3127789 18.1707057,18 L6,18 C5.44771525,18 5,18.4477153 5,19 C5,19.5522847 5.44771525,20 6,20 L18.1707057,20 C18.0601542,19.6872211 18,19.3506354 18,19 Z M19,4 L6,4 C5.44771525,4 5,4.44771525 5,5 L5,16.1707057 C5.31277893,16.0601542 5.64936458,16 6,16 L19,16 L19,4 Z"
    />
  </BaseIcon>
)
