import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const ChevronsLeft = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M12.7071068,17.2928932 L11.2928932,18.7071068 L4.58578644,12 L11.2928932,5.29289322 L12.7071068,6.70710678 L7.41421356,12 L12.7071068,17.2928932 Z M12.4142136,12 L17.7071068,17.2928932 L16.2928932,18.7071068 L9.58578644,12 L16.2928932,5.29289322 L17.7071068,6.70710678 L12.4142136,12 Z"
    />
  </BaseIcon>
)
