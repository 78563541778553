import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Bascket = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M17.118034,7 L20,7 C21.1045695,7 22,7.8954305 22,9 L22,11 C22,11.8400191 21.4821261,12.5590822 20.7482321,12.8553354 C20.7516471,12.9031122 20.7533839,12.9513533 20.7533839,13 C20.7533839,13.1459247 20.7374136,13.2914111 20.7057581,13.4338609 L19.1502025,20.4338609 C18.9468525,21.348936 18.1352257,22 17.1978284,22 L6.80217161,22 C5.86477431,22 5.05314751,21.348936 4.84979749,20.4338609 L3.29424193,13.4338609 C3.25094564,13.2390276 3.23766963,13.0445179 3.25133987,12.8551625 C2.51767261,12.5588026 2,11.8398557 2,11 L2,9 C2,7.8954305 2.8954305,7 4,7 L6.88196601,7 L9.10557281,2.5527864 C9.35256206,2.05880791 9.9532351,1.85858356 10.4472136,2.10557281 C10.9411921,2.35256206 11.1414164,2.9532351 10.8944272,3.4472136 L9.11803399,7 L14.881966,7 L13.1055728,3.4472136 C12.8585836,2.9532351 13.0588079,2.35256206 13.5527864,2.10557281 C14.0467649,1.85858356 14.6474379,2.05880791 14.8944272,2.5527864 L17.118034,7 Z M18.0002188,9 C17.9999331,9.36654113 17.7973775,9.71934526 17.4472136,9.89442719 C16.9532351,10.1414164 16.3525621,9.94119209 16.1055728,9.4472136 L15.881966,9 L8.11803399,9 L7.89442719,9.4472136 C7.64743794,9.94119209 7.0467649,10.1414164 6.5527864,9.89442719 C6.20262255,9.71934526 6.00006692,9.36654113 5.99978125,9 L4,9 L4,11 L20,11 L20,9 L18.0002188,9 Z M5.24661605,13 L6.80217161,20 L17.1978284,20 L18.7533839,13 L5.24661605,13 Z"
    />
  </BaseIcon>
)
