import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const VolumeOff = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M19,10.5857864 L21.2928932,8.29289322 L22.7071068,9.70710678 L20.4142136,12 L22.7071068,14.2928932 L21.2928932,15.7071068 L19,13.4142136 L16.7071068,15.7071068 L15.2928932,14.2928932 L17.5857864,12 L15.2928932,9.70710678 L16.7071068,8.29289322 L19,10.5857864 Z M6.76393202,7 L14,3.38196601 L14,20.618034 L6.76393202,17 L3,17 C1.8954305,17 1,16.1045695 1,15 L1,9 C1,7.8954305 1.8954305,7 3,7 L6.76393202,7 Z M8,8.61803399 L8,15.381966 L12,17.381966 L12,6.61803399 L8,8.61803399 Z M6,9 L3,9 L3,15 L6,15 L6,9 Z"
    />
  </BaseIcon>
)
