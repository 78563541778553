import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Equalizer = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M1,21 L1,19 L7,19 L7,21 L1,21 Z M1,17 L1,15 L7,15 L7,17 L1,17 Z M1,13 L1,11 L7,11 L7,13 L1,13 Z M1,9 L1,7 L7,7 L7,9 L1,9 Z M9,21 L9,19 L15,19 L15,21 L9,21 Z M9,17 L9,15 L15,15 L15,17 L9,17 Z M9,13 L9,11 L15,11 L15,13 L9,13 Z M17,21 L17,19 L23,19 L23,21 L17,21 Z M17,17 L17,15 L23,15 L23,17 L17,17 Z M17,13 L17,11 L23,11 L23,13 L17,13 Z M17,9 L17,7 L23,7 L23,9 L17,9 Z M17,5 L17,3 L23,3 L23,5 L17,5 Z"
    />
  </BaseIcon>
)
