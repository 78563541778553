import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const PinNo = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M4.1256129,5.53982646 L1.29289322,2.70710678 L2.70710678,1.29289322 L22.7071068,21.2928932 L21.2928932,22.7071068 L17.0176826,18.4318962 C15.827983,19.8154213 14.3737525,21.2548553 12.6577283,22.7532553 L12,23.3275712 L11.3422717,22.7532553 C5.81130786,17.9237218 3,13.70676 3,10 C3,8.33957114 3.40992393,6.82908956 4.1256129,5.53982646 Z M15.5989157,17.0131293 L5.6168307,7.03104426 C5.22093939,7.91564345 5,8.91568851 5,10 C5,12.8492324 7.30661202,16.4335466 12,20.6634039 C13.4162267,19.3870473 14.6151311,18.169469 15.5989157,17.0131293 Z M6.80608045,2.59186693 C8.28170338,1.5757725 10.0789944,1 12,1 C16.9029475,1 21,4.7506636 21,10 C21,11.6240403 20.4603489,13.3460175 19.3887548,15.1745413 L17.9201822,13.7059687 C18.6416967,12.3601146 19,11.1229644 19,10 C19,5.8966022 15.8358849,3 12,3 C10.6260988,3 9.33837635,3.37159404 8.25135183,4.03713831 L6.80608045,2.59186693 Z"
    />
  </BaseIcon>
)
