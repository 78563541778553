import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const ArrowBoldRight = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M12,15.8198039 L2,13.8198039 L2,10.1801961 L12,8.1801961 L12,2.79623177 L22.5185923,12 L12,21.2037682 L12,15.8198039 Z M19.4814077,12 L14,7.20376823 L14,9.8198039 L4,11.8198039 L4,12.1801961 L14,14.1801961 L14,16.7962318 L19.4814077,12 Z"
    />
  </BaseIcon>
)
