import * as yup from 'yup'

export const note = yup.object().shape({
  name: yup.string().trim(),
  position: yup.number().moreThan(-1),
  speaker: yup.number(),
})
export type noteType = yup.InferType<typeof note>

export const noteSpeakerTag = yup.object().shape({
  speakerName: yup.string().required().trim(),
})
export type noteSpeakerTagType = yup.InferType<typeof noteSpeakerTag>

export const noteAttitude = yup.object().shape({
  attitude: yup.boolean().nullable(),
})
export type noteAttitudeType = yup.InferType<typeof noteAttitude>

export const bulkNoteIds = yup.object().shape({
  noteIds: yup.array().of(yup.string().required()).required(),
})
export type bulkNoteIdsType = yup.InferType<typeof bulkNoteIds>

export const bulkNoteAttitude = bulkNoteIds.shape({
  attitude: yup.boolean().nullable(),
})
export type bulkAttitudeNotesType = yup.InferType<typeof bulkNoteAttitude>

export const bulkActionNotes = yup.object().shape({
  notes: yup.array().of(yup.string().required()).required(),
})

export type bulkActionNotesType = yup.InferType<typeof bulkActionNotes>

export const bulkTagNotes = bulkNoteIds.shape({
  tagIds: yup.array().of(yup.string().required()).required(),
  names: yup.array().of(yup.string().trim().required()),
})

export type bulkTagNotesType = yup.InferType<typeof bulkTagNotes>

export const bulkNotes = yup.object().shape({
  names: yup.array().of(yup.string().required()).required(),
  position: yup.number().moreThan(-1),
})
export type bulkNotesType = yup.InferType<typeof bulkNotes>
