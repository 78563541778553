import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const CallNumbers = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M5,8 C3.34314575,8 2,6.65685425 2,5 C2,3.34314575 3.34314575,2 5,2 C6.65685425,2 8,3.34314575 8,5 C8,6.65685425 6.65685425,8 5,8 Z M5,6 C5.55228475,6 6,5.55228475 6,5 C6,4.44771525 5.55228475,4 5,4 C4.44771525,4 4,4.44771525 4,5 C4,5.55228475 4.44771525,6 5,6 Z M12,8 C10.3431458,8 9,6.65685425 9,5 C9,3.34314575 10.3431458,2 12,2 C13.6568542,2 15,3.34314575 15,5 C15,6.65685425 13.6568542,8 12,8 Z M12,6 C12.5522847,6 13,5.55228475 13,5 C13,4.44771525 12.5522847,4 12,4 C11.4477153,4 11,4.44771525 11,5 C11,5.55228475 11.4477153,6 12,6 Z M19,8 C17.3431458,8 16,6.65685425 16,5 C16,3.34314575 17.3431458,2 19,2 C20.6568542,2 22,3.34314575 22,5 C22,6.65685425 20.6568542,8 19,8 Z M19,6 C19.5522847,6 20,5.55228475 20,5 C20,4.44771525 19.5522847,4 19,4 C18.4477153,4 18,4.44771525 18,5 C18,5.55228475 18.4477153,6 19,6 Z M5,15 C3.34314575,15 2,13.6568542 2,12 C2,10.3431458 3.34314575,9 5,9 C6.65685425,9 8,10.3431458 8,12 C8,13.6568542 6.65685425,15 5,15 Z M5,13 C5.55228475,13 6,12.5522847 6,12 C6,11.4477153 5.55228475,11 5,11 C4.44771525,11 4,11.4477153 4,12 C4,12.5522847 4.44771525,13 5,13 Z M12,15 C10.3431458,15 9,13.6568542 9,12 C9,10.3431458 10.3431458,9 12,9 C13.6568542,9 15,10.3431458 15,12 C15,13.6568542 13.6568542,15 12,15 Z M12,13 C12.5522847,13 13,12.5522847 13,12 C13,11.4477153 12.5522847,11 12,11 C11.4477153,11 11,11.4477153 11,12 C11,12.5522847 11.4477153,13 12,13 Z M19,15 C17.3431458,15 16,13.6568542 16,12 C16,10.3431458 17.3431458,9 19,9 C20.6568542,9 22,10.3431458 22,12 C22,13.6568542 20.6568542,15 19,15 Z M19,13 C19.5522847,13 20,12.5522847 20,12 C20,11.4477153 19.5522847,11 19,11 C18.4477153,11 18,11.4477153 18,12 C18,12.5522847 18.4477153,13 19,13 Z M12,22 C10.3431458,22 9,20.6568542 9,19 C9,17.3431458 10.3431458,16 12,16 C13.6568542,16 15,17.3431458 15,19 C15,20.6568542 13.6568542,22 12,22 Z M12,20 C12.5522847,20 13,19.5522847 13,19 C13,18.4477153 12.5522847,18 12,18 C11.4477153,18 11,18.4477153 11,19 C11,19.5522847 11.4477153,20 12,20 Z"
    />
  </BaseIcon>
)
