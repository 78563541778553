import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const ChevronBottom = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <polygon
      fillRule="evenodd"
      points="19.293 7.293 20.707 8.707 12 17.414 3.293 8.707 4.707 7.293 12 14.586"
    />
  </BaseIcon>
)
