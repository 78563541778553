import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const ToggleLeft = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M7,5 L17,5 C20.8659932,5 24,8.13400675 24,12 C24,15.8659932 20.8659932,19 17,19 L7,19 C3.13400675,19 0,15.8659932 0,12 C0,8.13400675 3.13400675,5 7,5 Z M7,7 C4.23857625,7 2,9.23857625 2,12 C2,14.7614237 4.23857625,17 7,17 L17,17 C19.7614237,17 22,14.7614237 22,12 C22,9.23857625 19.7614237,7 17,7 L7,7 Z M7,16 C4.790861,16 3,14.209139 3,12 C3,9.790861 4.790861,8 7,8 C9.209139,8 11,9.790861 11,12 C11,14.209139 9.209139,16 7,16 Z M7,14 C8.1045695,14 9,13.1045695 9,12 C9,10.8954305 8.1045695,10 7,10 C5.8954305,10 5,10.8954305 5,12 C5,13.1045695 5.8954305,14 7,14 Z"
    />
  </BaseIcon>
)
