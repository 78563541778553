import * as yup from 'yup'

export const name = yup.object().shape({
  name: yup.string().default('').trim(),
})
export type nameType = yup.InferType<typeof name>

export const nameAndDescription = yup.object().shape({
  name: yup.string().trim().required('Name is required.'),
  description: yup.string().trim(),
})
export type nameAndDescriptionType = yup.InferType<typeof nameAndDescription>
