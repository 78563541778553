import { Children } from 'react'
import styled from '@emotion/styled'
import { Grid } from './Grid'
import isPropValid from '@emotion/is-prop-valid'

export const Table = styled.div`
  width: 100%;
  margin-bottom: 1.5rem;
`
export const TableRow = styled(Grid, {
  shouldForwardProp: (prop) => isPropValid(prop),
})<{
  odd?: boolean
  columnsWidth?: string
  collapseWidth?: number
}>`
  border: 1px solid var(--colors-table-even);
  display: grid;
  grid-gap: 1.5em;
  padding: 1.5em;
  align-items: center;
  grid-template-columns: ${({ columnsWidth, children }) =>
    columnsWidth ??
    Array.from({ length: Children.count(children) }, () => '1fr').join(' ')};

  ${(props) => props.odd && 'background-color: var(--colors-table-odd)'};

  @media (max-width: ${(props) => props.collapseWidth ?? 600}px) {
    grid-template-columns: 1fr;
    grid-gap: 0.5em;
  }
`

export const TableHeader = styled(Grid, {
  shouldForwardProp: (prop) => isPropValid(prop),
})<{
  columnsWidth?: string
  collapseWidth?: number
}>`
  border: 1px solid var(--colors-table-even);
  display: grid;
  grid-gap: 1.5em;
  text-transform: uppercase;
  padding: 2.5em 1.5em;
  font-size: 0.8em;
  align-items: center;
  grid-template-columns: ${({ columnsWidth, children }) =>
    columnsWidth ??
    Array.from({ length: Children.count(children) }, () => '1fr').join(' ')};

  @media (max-width: ${(props) => props.collapseWidth ?? 600}px) {
    display: none;
  }
`

export const TableCell = styled(Grid)``
