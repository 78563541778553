import { Base } from './Base'
import { Company } from './Company'
import { User } from './User'

export enum CustomTypeModel {
  insights = 'KeyInsights',
  recommendations = 'Recommendations',
  globalRecommendations = 'GlobalRecommendations',
}

export const DefaultInsightTypes = [
  'design',
  'feature',
  'product',
  'usability',
  'none',
]

export const DefaultRecommendationTypes = [
  'design',
  'feature',
  'project',
  'usability',
  'none',
]

export interface GenericType extends Base {
  id: string
  name: string
}

export interface CustomType extends Base {
  company: Company
  created_by: User // eslint-disable-line camelcase
  name: string
  typeModel: CustomTypeModel
  default: boolean
}
