import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const MapPinLocation = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M13,11.8999819 L13,19 L11,19 L11,11.8999819 C8.71775968,11.4367116 7,9.41895791 7,7 C7,4.23857625 9.23857625,2 12,2 C14.7614237,2 17,4.23857625 17,7 C17,9.41895791 15.2822403,11.4367116 13,11.8999819 Z M9,14.1573001 L9,16.1843625 C6.0671837,16.5504753 4,17.3867347 4,18 C4,18.8069531 7.5791408,20 12,20 C16.4208592,20 20,18.8069531 20,18 C20,17.3867347 17.9328163,16.5504753 15,16.1843625 L15,14.1573001 C19.0558765,14.601713 22,15.967812 22,18 C22,20.5067554 17.5202663,22 12,22 C6.4797337,22 2,20.5067554 2,18 C2,15.967812 4.94412354,14.601713 9,14.1573001 Z M12,10 C13.6568542,10 15,8.65685425 15,7 C15,5.34314575 13.6568542,4 12,4 C10.3431458,4 9,5.34314575 9,7 C9,8.65685425 10.3431458,10 12,10 Z"
    />
  </BaseIcon>
)
