import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Padding = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M2.81818182,3 L21.1818182,3 C22.1859723,3 23,3.8954305 23,5 L23,19 C23,20.1045695 22.1859723,21 21.1818182,21 L2.81818182,21 C1.81402773,21 1,20.1045695 1,19 L1,5 C1,3.8954305 1.81402773,3 2.81818182,3 Z M3,5 L3,19 L21,19 L21,5 L3,5 Z M11,13 L6,13 L6,14 L4,14 L4,10 L6,10 L6,11 L11,11 L11,8 L10,8 L10,6 L14,6 L14,8 L13,8 L13,11 L18,11 L18,10 L20,10 L20,14 L18,14 L18,13 L13,13 L13,16 L14,16 L14,18 L10,18 L10,16 L11,16 L11,13 Z"
    />
  </BaseIcon>
)
