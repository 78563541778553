import * as yup from 'yup'

export const resubscribe = yup.object().shape({
  billing: yup
    .string()
    .trim()
    .required('Billing Cycle is required.')
    .oneOf(['yearly', 'monthly'], 'Invalid Billing Cycle'),
  coupon: yup.string().trim(),
  email: yup
    .string()
    .email()
    .lowercase()
    .trim()
    .required('A valid email address required.'),
  password: yup
    .string()
    .trim()
    .min(8, 'Password must be at least 8 characters.')
    .required('Password is required.'),
  plan: yup
    .string()
    .trim()
    .required('Plan Name is required.')
    .oneOf(['enterprise', 'premium', 'pro'], 'Invalid Billing Cycle'),
  token: yup
    .object()
    .shape({
      id: yup.string().trim().required('Stripe Token field "id" is required.'),
      card: yup
        .object()
        .shape({
          id: yup
            .string()
            .trim()
            .required('Stripe Token card field "id" is required.'),
          brand: yup
            .string()
            .trim()
            .required('Stripe Token card field "brand" is required.'),
          exp_month: yup
            .number()
            .required('Stripe Token card field "exp_month" is required.'),
          exp_year: yup
            .number()
            .required('Stripe Token card field "exp_year" is required.'),
          last4: yup
            .string()
            .required('Stripe Token field "last4" is required.'),
        })
        .required('Stripe token card is required'),
    })
    .required('Stripe Token is required.'),
})

export type resubscribeType = yup.InferType<typeof resubscribe>
