import { forwardRef, HTMLProps, ReactNode } from 'react'
import styled from '@emotion/styled'
import { ErrorMessage } from './ErrorMessage'
import MaskedInput, { MaskedInputProps, Mask } from 'react-text-mask'
import { BoxProps, Box } from '@chakra-ui/react'
import { boxProps } from './Box'
import { LabelProps } from './Typography'

export interface InputProps {
  type?: 'text' | 'email' | 'number' | 'password' | 'search' | 'tel' | 'url'
  label?: string
  placeholder?: string
  id?: string
  error?: ReactNode | string
  showError?: boolean
  hideLabel?: boolean
  mask?: (value: string) => Mask
}

type Props = BoxProps & HTMLProps<HTMLInputElement>

export const InputStyled = styled.input<BoxProps>`
  appearance: none; // Removes awkward default styles on some inputs for iOS
  color: var(--colors-inputs-text-color);
  height: ${(props) => (props.height ? props.height : '48px')};
  border-radius: 8px;
  border: solid 0.5px var(--colors-inputs-text-border);
  background-color: var(--colors-inputs-text-background);
  font-size: ${(props) => (props.fontSize ? props.fontSize : '16px')};
  font-weight: 400;
  padding: 0 16px;
  letter-spacing: 0.19px;
  transition: border 0.3s;

  &:placeholder {
    color: var(--colors-inputs-placeholder);
  }
  &::-webkit-input-placeholder {
    color: var(--colors-inputs-placeholder);
  }

  &:focus {
    border-color: var(--colors-inputs-text-background-focus);
    outline: none;
  }
  ${(props) => boxProps(props)}
`

type LProps = {
  variant?: 'alt'
} & LabelProps

const Component = ({ children, ...props }: BoxProps) => (
  <Box as="label" {...props}>
    {children}
  </Box>
)

type InputType = HTMLProps<HTMLInputElement>

export const Label = styled(Component)<InputType & LProps>`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  display: block;
  color: ${(props): string =>
    props.variant === 'alt'
      ? 'var(--colors-typography-labels-alt)'
      : 'var(--colors-typography-labels)'};

  &.visuallyHidden {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
`

export const Input = forwardRef<
  InputType,
  | (InputType & InputProps & Props)
  | ((InputType & InputProps & Props) & MaskedInputProps)
>(
  (
    {
      label,
      error,
      showError = true,
      hideLabel = false,
      mask,
      id,
      as, // eslint-disable-line @typescript-eslint/no-unused-vars
      ...rest
    },
    ref,
  ) => {
    return (
      <>
        {label && (
          <Label
            marginBottom="4px"
            className={hideLabel ? 'visuallyHidden' : ''}
            htmlFor={id}
          >
            {label}
          </Label>
        )}
        {mask ? (
          <MaskedInput
            ref={ref as any}
            mask={mask}
            id={id}
            {...rest}
            render={(ref: any, props: any) => (
              <InputStyled ref={ref} {...props} />
            )}
          />
        ) : (
          <InputStyled ref={ref as any} as="input" id={id} {...rest} />
        )}
        {showError && error && (
          <ErrorMessage marginTop="8px">{error}</ErrorMessage>
        )}
      </>
    )
  },
)

Input.displayName = 'Input'
