import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Bluetooth = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M6.29289322,7.70710678 L7.70710678,6.29289322 L11,9.58578644 L11,1.58578644 L17.4142136,8 L13.4142136,12 L17.4142136,16 L11,22.4142136 L11,14.4142136 L7.70710678,17.7071068 L6.29289322,16.2928932 L10.5857864,12 L6.29289322,7.70710678 Z M13,17.5857864 L14.5857864,16 L13,14.4142136 L13,17.5857864 Z M13,9.58578644 L14.5857864,8 L13,6.41421356 L13,9.58578644 Z"
    />
  </BaseIcon>
)
