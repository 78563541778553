import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Contrast = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M20,7.87279794 C19.4940015,6.89397574 18.8136364,6.01990475 18,5.29168048 L18,18.7083195 C18.8136364,17.9800953 19.4940015,17.1060243 20,16.1272021 L20,7.87279794 Z M16,3.93551965 C15.3714959,3.62317975 14.7013005,3.38214996 14,3.22301642 L14,20.7769836 C14.7013005,20.61785 15.3714959,20.3768202 16,20.0644804 L16,3.93551965 Z M12,3 C7.02943725,3 3,7.02943725 3,12 C3,16.9705627 7.02943725,21 12,21 L12,3 Z M12,23 C5.92486775,23 1,18.0751322 1,12 C1,5.92486775 5.92486775,1 12,1 C18.0751322,1 23,5.92486775 23,12 C23,18.0751322 18.0751322,23 12,23 Z"
    />
  </BaseIcon>
)
