import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Strongbox = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M14.0319673,15.4461809 C13.4364541,15.7980706 12.7418086,16 12,16 C11.2581914,16 10.5635459,15.7980706 9.96803265,15.4461809 L7.70710678,17.7071068 L6.29289322,16.2928932 L8.55381909,14.0319673 C8.20192939,13.4364541 8,12.7418086 8,12 C8,11.2581914 8.20192939,10.5635459 8.55381909,9.96803265 L6.29289322,7.70710678 L7.70710678,6.29289322 L9.96803265,8.55381909 C10.5635459,8.20192939 11.2581914,8 12,8 C12.7418086,8 13.4364541,8.20192939 14.0319673,8.55381909 L16.2928932,6.29289322 L17.7071068,7.70710678 L15.4461809,9.96803265 C15.7980706,10.5635459 16,11.2581914 16,12 C16,12.7418086 15.7980706,13.4364541 15.4461809,14.0319673 L17.7071068,16.2928932 L16.2928932,17.7071068 L14.0319673,15.4461809 Z M20,8 L19,8 L19,6 L20,6 L20,4 L4,4 L4,20 L20,20 L20,18 L19,18 L19,16 L20,16 L20,8 Z M5,22 L3.81818182,22 C2.81402773,22 2,21.1045695 2,20 L2,4 C2,2.8954305 2.81402773,2 3.81818182,2 L20.1818182,2 C21.1859723,2 22,2.8954305 22,4 L22,20 C22,21.1045695 21.1859723,22 20.1818182,22 L19,22 L19,23 L17,23 L17,22 L7,22 L7,23 L5,23 L5,22 Z M12,14 C13.1045695,14 14,13.1045695 14,12 C14,10.8954305 13.1045695,10 12,10 C10.8954305,10 10,10.8954305 10,12 C10,13.1045695 10.8954305,14 12,14 Z"
    />
  </BaseIcon>
)
