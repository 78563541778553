import styled from '@emotion/styled'

const RangeInput = styled.input`
  --main-bg-color: #7e99a8;
  --secondary-bg-color: #024660;
  --border-color: #c9cacc;
  --shadow-color: rgba(0, 0, 0, 0.39);

  -webkit-appearance: none;
  margin: 18px 0;
  width: 100%;

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    background-color: var(--main-bg-color);
    border: solid 0.3px var(--border-color);
  }

  &::-webkit-slider-thumb {
    box-shadow: 0 1px 1px 0 var(--shadow-color);
    /* border: 0.3px solid var(--border-color); */
    height: 16px;
    width: 16px;
    background-color: var(--secondary-bg-color);
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -8px;
    border-radius: 50%;
  }

  &::-webkit-slider-runnable-track {
    background: var(--main-bg-color);
  }

  &::-moz-range-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    /* box-shadow: 0 1px 1px 0 var(--shadow-color); */
    background-color: var(--main-bg-color);
    border: 0.3px solid var(--border-color);
  }

  &::-moz-range-thumb {
    /* box-shadow: 0 1px 1px 0 var(--shadow-color); */
    /* border: 0.3px solid var(--border-color); */
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background-color: var(--secondary-bg-color);
    cursor: pointer;
  }

  &::-ms-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    border-width: 16px 0;
    color: transparent;
  }

  &::-ms-fill-lower {
    background-color: var(--main-bg-color);
    border: 0.2px solid var(--border-color);
    box-shadow: 0 1px 1px 0 var(--shadow-color);
  }

  &::-ms-fill-upper {
    background-color: var(--main-bg-color);
    border: 0.2px solid var(--border-color);
    box-shadow: 0 1px 1px 0 var(--shadow-color);
  }

  &::-ms-thumb {
    box-shadow: 0 1px 1px 0 var(--shadow-color);
    border: 0.3px solid var(--border-color);
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background-color: var(--secondary-bg-color);
    cursor: pointer;
  }

  &:focus::-ms-fill-lower {
    background: var(--main-bg-color);
  }

  &:focus::-ms-fill-upper {
    background: var(--main-bg-color);
  }

  &:focus {
    outline: none;
  }
`

export interface RangeSliderProps {
  value: number
  step?: number
  min?: number
  max?: number
  onChange?: (e: any) => void
}

export const RangeSlider = ({
  value,
  step = 1,
  onChange,
  min = 6,
  max = 50,
}: RangeSliderProps) => {
  return (
    <RangeInput
      type="range"
      value={value}
      step={step}
      min={min}
      max={max}
      onChange={onChange}
    />
  )
}
