import * as yup from 'yup'
import { nameAndDescription } from './name'

export const projectGroup = nameAndDescription.shape({
  projects: yup.array().of(yup.string().required()).required(),
})
export type projectGroupType = yup.InferType<typeof projectGroup>

export const projectGroupSortOrder = yup.object().shape({
  sortOrder: yup.string().required(),
})
export type projectGroupSortOrdertype = yup.InferType<
  typeof projectGroupSortOrder
>
