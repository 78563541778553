import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const ArrowsDiagonalsBltr = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <polygon
      fillRule="evenodd"
      points="14 5 14 3 21 3 21 10 19 10 19 6.414 6.414 19 10 19 10 21 3 21 3 14 5 14 5 17.586 17.586 5"
    />
  </BaseIcon>
)
