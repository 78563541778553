import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const CreditcardAdd = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M19,17 L19,15 L21,15 L21,17 L23,17 L23,19 L21,19 L21,21 L19,21 L19,19 L17,19 L17,17 L19,17 Z M3,10 L3,17 L14,17 L14,19 L2.81818182,19 C1.81402773,19 1,18.1045695 1,17 L1,5 C1,3.8954305 1.81402773,3 2.81818182,3 L21.1818182,3 C22.1859723,3 23,3.8954305 23,5 L23,13 L21,13 L21,10 L3,10 Z M3,8 L21,8 L21,5 L3,5 L3,8 Z"
    />
  </BaseIcon>
)
