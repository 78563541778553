import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Segment = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M8.03196735,14.5538191 L14.5538191,8.03196735 C14.2019294,7.43645409 14,6.74180862 14,6 C14,3.790861 15.790861,2 18,2 C20.209139,2 22,3.790861 22,6 C22,8.209139 20.209139,10 18,10 C17.2581914,10 16.5635459,9.79807061 15.9680327,9.44618091 L9.44618091,15.9680327 C9.79807061,16.5635459 10,17.2581914 10,18 C10,20.209139 8.209139,22 6,22 C3.790861,22 2,20.209139 2,18 C2,15.790861 3.790861,14 6,14 C6.74180862,14 7.43645409,14.2019294 8.03196735,14.5538191 Z M6,20 C7.1045695,20 8,19.1045695 8,18 C8,16.8954305 7.1045695,16 6,16 C4.8954305,16 4,16.8954305 4,18 C4,19.1045695 4.8954305,20 6,20 Z M18,8 C19.1045695,8 20,7.1045695 20,6 C20,4.8954305 19.1045695,4 18,4 C16.8954305,4 16,4.8954305 16,6 C16,7.1045695 16.8954305,8 18,8 Z"
    />
  </BaseIcon>
)
