import { Hashtag } from './Hashtag'
import { HashtagGroup } from './HashtagGroup'

export enum SuggestedUses {
  notes = 'notes',
  documents = 'documents',
  clips = 'clips',
  highlightReels = 'highlightReels',
  insights = 'insights',
  hashtagGroups = 'hashtagGroups',
  collections = 'collections',
  recommendations = 'recommendations',
}

export const SuggestedUsesArray: string[] = Object.values(SuggestedUses)

export type SuggestedUseTypes = {
  [K in keyof typeof SuggestedUses]: string
}

export const SuggestedUseDisplay: SuggestedUseTypes = {
  [SuggestedUses.notes]: 'Notes',
  [SuggestedUses.documents]: 'Documents',
  [SuggestedUses.insights]: 'Key Insights',
  [SuggestedUses.clips]: 'Clips',
  [SuggestedUses.highlightReels]: 'Highlight Reels',
  [SuggestedUses.hashtagGroups]: 'Tag Groups',
  [SuggestedUses.collections]: 'Collections',
  [SuggestedUses.recommendations]: 'Recommendations',
}

export type UseCounts = {
  [K in keyof typeof SuggestedUses]: number
}

export type TagCounts = Partial<
  Omit<Hashtag, 'hashtagGroups'> & {
    createdBy?: string
    hashtagGroups: Partial<HashtagGroup>[]
    suggestedUses: SuggestedUses[]
    counts: UseCounts
  }
>

export type PaginatedTags = {
  pageNumber: number
  pageSize: number
  tags: TagCounts[]
}
