import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Command = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M10,8 L14,8 L14,6 C14,3.790861 15.790861,2 18,2 C20.209139,2 22,3.790861 22,6 C22,8.209139 20.209139,10 18,10 L16,10 L16,14 L18,14 C20.209139,14 22,15.790861 22,18 C22,20.209139 20.209139,22 18,22 C15.790861,22 14,20.209139 14,18 L14,16 L10,16 L10,18 C10,20.209139 8.209139,22 6,22 C3.790861,22 2,20.209139 2,18 C2,15.790861 3.790861,14 6,14 L8,14 L8,10 L6,10 C3.790861,10 2,8.209139 2,6 C2,3.790861 3.790861,2 6,2 C8.209139,2 10,3.790861 10,6 L10,8 Z M10,10 L10,14 L14,14 L14,10 L10,10 Z M16,16 L16,18 C16,19.1045695 16.8954305,20 18,20 C19.1045695,20 20,19.1045695 20,18 C20,16.8954305 19.1045695,16 18,16 L16,16 Z M8,16 L6,16 C4.8954305,16 4,16.8954305 4,18 C4,19.1045695 4.8954305,20 6,20 C7.1045695,20 8,19.1045695 8,18 L8,16 Z M16,8 L18,8 C19.1045695,8 20,7.1045695 20,6 C20,4.8954305 19.1045695,4 18,4 C16.8954305,4 16,4.8954305 16,6 L16,8 Z M8,8 L8,6 C8,4.8954305 7.1045695,4 6,4 C4.8954305,4 4,4.8954305 4,6 C4,7.1045695 4.8954305,8 6,8 L8,8 Z"
    />
  </BaseIcon>
)
