import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Stopwatch = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M11,4.05492878 L11,3 L10,3 L10,1 L14,1 L14,3 L13,3 L13,4.05492878 C14.7354987,4.24677842 16.3222702,4.93209826 17.6168928,5.96746637 L19.2913446,4.28565615 L20.7086554,5.69676573 L19.0312678,7.38152468 C20.2632753,8.92131736 21,10.874623 21,13 C21,17.9705627 16.9705627,22 12,22 C7.02943725,22 3,17.9705627 3,13 C3,8.36744635 6.50004954,4.55237307 11,4.05492878 Z M12,20 C15.8659932,20 19,16.8659932 19,13 C19,9.13400675 15.8659932,6 12,6 C8.13400675,6 5,9.13400675 5,13 C5,16.8659932 8.13400675,20 12,20 Z M11,8 L13,8 L13,14 L11,14 L11,8 Z"
    />
  </BaseIcon>
)
