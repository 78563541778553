import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const FolderOpened = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M21,8 C22.1045695,8 23,8.8954305 23,10 L22.9761871,10.2169305 L20.9959782,19.1278704 C20.9300429,20.1728563 20.0616163,21 19,21 L3,21 C1.8954305,21 1,20.1045695 1,19 L1,5 C1,3.8954305 1.8954305,3 3,3 L9,3 C10.1200023,3 10.832939,3.47545118 11.5489764,4.37885309 C11.5967547,4.43913352 11.8100999,4.71588275 11.8624831,4.78081945 C12.019726,4.97574495 12.0517795,4.99972956 12.0017863,5 L19,5 C20.1045695,5 21,5.8954305 21,7 L21,8 Z M19,8 L19,7 L11.994646,6.99998567 C11.2764915,6.99614058 10.8086916,6.65990923 10.3058322,6.03654146 C10.2364281,5.95050497 10.0158737,5.66440398 9.98159778,5.62115916 C9.60702158,5.14856811 9.38424442,5 9,5 L3,5 L3,9.89145869 L3.02912707,9.76040502 C3.31020006,8.62145726 3.83339587,8 5,8 L19,8 Z M3.02439383,19 L19,19 L19.0238129,18.7830695 L20.9756062,10 L5.03630578,10 C5.02068072,10.0495501 4.99991917,10.1212947 4.97618706,10.2169305 L3.02439383,19 Z"
    />
  </BaseIcon>
)
