import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Image = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M4,15.5857864 L8,11.5857864 L11.5,15.0857864 L18,8.58578644 L20,10.5857864 L20,4 L4,4 L4,15.5857864 Z M4,18.4142136 L4,20 L13.5857864,20 L8,14.4142136 L4,18.4142136 Z M16.4142136,20 L20,20 L20,13.4142136 L18,11.4142136 L12.9142136,16.5 L16.4142136,20 Z M4,2 L20,2 C21.1045695,2 22,2.8954305 22,4 L22,20 C22,21.1045695 21.1045695,22 20,22 L4,22 C2.8954305,22 2,21.1045695 2,20 L2,4 C2,2.8954305 2.8954305,2 4,2 Z M11,5 C12.6568542,5 14,6.34314575 14,8 C14,9.65685425 12.6568542,11 11,11 C9.34314575,11 8,9.65685425 8,8 C8,6.34314575 9.34314575,5 11,5 Z M11,7 C10.4477153,7 10,7.44771525 10,8 C10,8.55228475 10.4477153,9 11,9 C11.5522847,9 12,8.55228475 12,8 C12,7.44771525 11.5522847,7 11,7 Z"
    />
  </BaseIcon>
)
