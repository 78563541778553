import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Elements = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M15,13 L20,13 C21.1045695,13 22,13.8954305 22,15 L22,20 C22,21.1045695 21.1045695,22 20,22 L15,22 C13.8954305,22 13,21.1045695 13,20 L13,15 C13,13.8954305 13.8954305,13 15,13 Z M15,15 L15,20 L20,20 L20,15 L15,15 Z M11,4 L11,20 C11,21.1045695 10.1045695,22 9,22 L4,22 C2.8954305,22 2,21.1045695 2,20 L2,4 C2,2.8954305 2.8954305,2 4,2 L9,2 C10.1045695,2 11,2.8954305 11,4 Z M9,4 L4,4 L4,20 L9,20 L9,4 Z M15,2 L20,2 C21.1045695,2 22,2.8954305 22,4 L22,9 C22,10.1045695 21.1045695,11 20,11 L15,11 C13.8954305,11 13,10.1045695 13,9 L13,4 C13,2.8954305 13.8954305,2 15,2 Z M15,4 L15,9 L20,9 L20,4 L15,4 Z"
    />
  </BaseIcon>
)
