import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Monitor = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M9,20 L9,19 L3,19 C1.8954305,19 1,18.1045695 1,17 L1,4 C1,2.8954305 1.8954305,2 3,2 L21,2 C22.1045695,2 23,2.8954305 23,4 L23,17 C23,18.1045695 22.1045695,19 21,19 L15,19 L15,20 L17,20 L17,22 L7,22 L7,20 L9,20 Z M3,17 L21,17 L21,4 L3,4 L3,17 Z"
    />
  </BaseIcon>
)
