import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const FileScan = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M19,12 L19,9 L15,9 C13.8954305,9 13,8.1045695 13,7 L13,3 L5,3 L5,12 L19,12 Z M3,12 L3,3 C3,1.8954305 3.8954305,1 5,1 L15.4142136,1 L21,6.58578644 L21,12 L23,12 L23,14 L1,14 L1,12 L3,12 Z M19,16 L21,16 L21,21 C21,22.1045695 20.1045695,23 19,23 L5,23 C3.8954305,23 3,22.1045695 3,21 L3,16 L5,16 L5,21 L19,21 L19,16 Z M15,3.41421356 L15,7 L18.5857864,7 L15,3.41421356 Z"
    />
  </BaseIcon>
)
