import { Hashtag } from '@aurelius/models'
import { useCallback, useState } from 'react'
import { Pie, Bar } from '@visx/shape'
import { Group } from '@visx/group'
import { ParentSizeModern as ParentSize } from '@visx/responsive'
import { useTagsChart, Show } from '../hooks/useTagsChart'
import { getColorFromName } from '@aurelius/utils'
import { Box } from '@chakra-ui/react'
import { Tag } from '../Tag'
import { localPoint } from '@visx/event'
import { useTooltip, useTooltipInPortal } from '@visx/tooltip'

const getValue = (d: { label: string; value: number; id?: string }) => d.value

export const TagsPieChart = ({
  tags = [],
  show,
  showLabel = false,
}: {
  tags?: Hashtag[]
  show: Show
  showLabel?: boolean
}) => {
  const { getCount } = useTagsChart({
    tags,
    show,
  })
  const [selected, setSelected] = useState<string | null>(null)
  const tagData = tags?.map((tag) => ({
    label: tag.name,
    value: getCount(tag.id),
    id: tag.id,
  }))

  const getColor = useCallback((name: string) => getColorFromName(name), [])

  const innerPieValues = useCallback(
    (id: string) => {
      const tag = tags.find((tag) => tag.id === id)
      return [
        {
          label: 'Notes',
          value: tag?.notes?.length ?? 0,
        },
        {
          label: 'Documents',
          value: tag?.documents?.length ?? 0,
        },
        {
          label: 'Insights',
          value: tag?.insights?.length ?? 0,
        },
        {
          label: 'Recommendations',
          value: tag?.recommendations?.length ?? 0,
        },
      ]
    },
    [tags],
  )

  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip()

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    // use TooltipWithBounds
    detectBounds: true,
    // when tooltip containers are scrolled, this will correctly update the Tooltip position
    scroll: true,
  })

  const handleMouseOver = (event: any, datum: any) => {
    const coords = localPoint(event.target.ownerSVGElement, event)
    showTooltip({
      tooltipLeft: coords?.x ?? 0,
      tooltipTop: coords?.y ?? 0,
      tooltipData: datum,
    })
  }
  return (
    <Box display="flex" justifyContent="center">
      <Box
        display="flex"
        flexShrink={0}
        flexDirection="column"
        alignItems="end"
        paddingRight="8px"
        paddingBottom="8px"
        marginRight="40px"
        justifyContent="center"
      >
        {tags?.map(
          (tag) =>
            tag && (
              <Tag key={tag.id} marginBottom="8px">
                <Box marginRight="8px" display="inline">
                  #{tag.name} ({getCount(tag.id)})
                </Box>
                <svg width="12px" height="12px">
                  <Bar height={12} width={12} fill={getColor(tag.name)} />
                </svg>
              </Tag>
            ),
        )}
      </Box>
      <ParentSize
        parentSizeStyles={{
          height: '650px',
          // this allows it to resize on window resize
          width: '100%',
          maxWidth: '650px',
          overflowX: 'hidden',
        }}
      >
        {({ width, height }) => {
          const maxWidth = Math.min(width, height)
          const radius = maxWidth / 2
          const top = height / 2
          const left = width / 2
          return (
            <svg ref={containerRef} width={width} height={height}>
              <Group top={top} left={left}>
                <Pie data={tagData} pieValue={getValue} outerRadius={radius}>
                  {(pie) => {
                    return pie.arcs.map((arc, index) => {
                      const { label, value, id } = arc.data
                      const [centroidX, centroidY] = pie.path.centroid(arc)
                      const hasSpaceForLabel =
                        arc.endAngle - arc.startAngle >= 0.1
                      const arcPath = pie.path(arc) ?? undefined
                      const arcFill = getColor(label)
                      return (
                        <g key={`arc-${value}-${index}`}>
                          <path
                            d={arcPath}
                            fill={arcFill}
                            onClick={() => setSelected(id)}
                            style={{
                              cursor: 'pointer',
                            }}
                            onMouseOver={(e) => handleMouseOver(e, id)}
                            onMouseOut={hideTooltip}
                          />
                          {showLabel && hasSpaceForLabel && (
                            <text
                              x={centroidX}
                              y={centroidY}
                              dy=".33em"
                              fill="#ffffff"
                              fontSize={22}
                              textAnchor="middle"
                              pointerEvents="none"
                            >
                              {label} ({value})
                            </text>
                          )}
                        </g>
                      )
                    })
                  }}
                </Pie>
                <Pie<{
                  label: string
                  value: number
                }>
                  data={selected ? innerPieValues(selected) : undefined}
                  pieValue={getValue}
                  outerRadius={radius}
                >
                  {(pie) => {
                    return pie.arcs.map((arc, index) => {
                      const { label, value } = arc.data
                      const [centroidX, centroidY] = pie.path.centroid(arc)
                      const hasSpaceForLabel =
                        arc.endAngle - arc.startAngle >= 0.1
                      const arcPath = pie.path(arc) ?? undefined
                      const arcFill = getColor(`${label}-${value}`)
                      return (
                        <g key={`arc-${value}-${index}`}>
                          <path
                            d={arcPath}
                            fill={arcFill}
                            onClick={() => setSelected(null)}
                            style={{
                              cursor: 'pointer',
                            }}
                          />
                          {hasSpaceForLabel && (
                            <text
                              x={centroidX}
                              y={centroidY}
                              dy=".33em"
                              fill="#ffffff"
                              fontSize={16}
                              textAnchor="middle"
                              pointerEvents="none"
                            >
                              {label} ({value})
                            </text>
                          )}
                        </g>
                      )
                    })
                  }}
                </Pie>
              </Group>
            </svg>
          )
        }}
      </ParentSize>
      {tooltipOpen && (
        <TooltipInPortal
          // set this to random so it correctly updates with parent bounds
          key={Math.random()}
          top={tooltipTop}
          left={tooltipLeft}
        >
          {innerPieValues(tooltipData as string).map((d, i) => {
            //@ts-ignore -> We know the values here
            if (show[d.label.toLowerCase()]) {
              return (
                <Box margin="8px" key={`${d.label}-${i}`}>
                  {d.label}: <strong>{d.value}</strong>
                </Box>
              )
            } else {
              return null
            }
          })}
        </TooltipInPortal>
      )}
    </Box>
  )
}
