import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Globe = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M13,19.9506248 L13,21 L15,21 L15,23 L9,23 L9,21 L11,21 L11,19.9504484 C8.71317074,19.7228065 6.59727987,18.7192573 4.97286605,17.1147319 L6.37834222,15.6918346 C7.86658623,17.1618574 9.8657966,18 12,18 C16.418278,18 20,14.418278 20,10 C20,7.89970455 19.1884646,5.92973256 17.7597898,4.44797581 L19.1995571,3.05978628 C20.9841268,4.9106611 22,7.37665553 22,10 C22,15.1853481 18.0533227,19.4489003 13,19.9506248 Z M12,3 C15.8659932,3 19,6.13400675 19,10 C19,13.8659932 15.8659932,17 12,17 C8.13400675,17 5,13.8659932 5,10 C5,6.13400675 8.13400675,3 12,3 Z M12,5 C9.23857625,5 7,7.23857625 7,10 C7,12.7614237 9.23857625,15 12,15 C14.7614237,15 17,12.7614237 17,10 C17,7.23857625 14.7614237,5 12,5 Z"
    />
  </BaseIcon>
)
