import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const ArrowBoldUndo = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M16.4142136,19 L3,19 L3,5.58578644 L7.00303323,9.58881966 C7.49462757,9.10850918 7.79108599,8.84206192 8.15308273,8.55246452 C9.52535784,7.45464443 10.8892911,7 13,7 C16.8865002,7 21,10.1786135 21,14 L21,16.4142136 L19.2928932,14.7071068 C19.2516511,14.6658647 19.155428,14.5775287 19.0108698,14.4578943 C18.761891,14.2518429 18.4769276,14.0445968 18.1634026,13.8516583 C17.2925728,13.3157631 16.384754,13 15.5,13 C13.9392439,13 13.1642337,13.4480392 12.2606895,14.8464759 L16.4142136,19 Z M5,10.4142136 L5,17 L11.5857864,17 L9.752808,15.1670216 L10.1317569,14.5038611 C11.5459265,12.0290643 12.8829751,11 15.5,11 C16.392569,11 17.2596413,11.2038406 18.0903796,11.5618734 C16.9534008,10.0761184 14.9223683,9 13,9 C11.3329311,9 10.4111501,9.30726033 9.40247283,10.1142021 C8.97349368,10.4573855 8.62351658,10.790697 7.70710678,11.7071068 L7,12.4142136 L5,10.4142136 Z"
    />
  </BaseIcon>
)
