import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const CameraNo = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M19.5857864,21 L4,21 C2.34314575,21 1,19.6568542 1,18 L1,9 C1,7.34314575 2.34314575,6 4,6 L4.58578644,6 L1.29289322,2.70710678 L2.70710678,1.29289322 L22.7071068,21.2928932 L21.2928932,22.7071068 L19.5857864,21 Z M6.58578644,8 L4,8 C3.44771525,8 3,8.44771525 3,9 L3,18 C3,18.5522847 3.44771525,19 4,19 L17.5857864,19 L15.3225619,16.7367755 C14.4216357,17.53866 13.248386,18 12,18 C9.23857625,18 7,15.7614237 7,13 C7,11.7508715 7.46196624,10.5776633 8.26319887,9.67741243 L6.58578644,8 Z M9.68140156,11.0956151 C9.24742492,11.6230392 9,12.2904012 9,13 C9,14.6568542 10.3431458,16 12,16 C12.70928,16 13.3765691,15.7529235 13.9043641,15.3185777 L9.68140156,11.0956151 Z M20,6 C21.6568542,6 23,7.34314575 23,9 L23,18 L21,18 L21,9 C21,8.44771525 20.5522847,8 20,8 L17,8 C16.3356579,8 15.8876309,7.6364073 15.4393863,7.01325501 C15.3362526,6.86987789 15.2340812,6.71331789 15.1111283,6.51408981 C15.0490387,6.41348225 14.8408368,6.06908144 14.8118025,6.02158805 C14.359498,5.28172234 14.0867281,5 14,5 L10,5 C10.0385627,5 10.011098,5.01541008 9.88206558,5.13844489 C9.81348437,5.20383835 9.51008898,5.51489419 9.52687791,5.49797195 C9.52238257,5.50250023 9.04549632,5.03660675 8.09621916,4.10029151 C8.0651746,4.13160301 8.40245394,3.78580769 8.50189205,3.6909916 C8.96793929,3.24660692 9.40745588,3 10,3 L14,3 C15.0866052,3 15.6892144,3.62238725 16.5181975,4.97841195 C16.5517302,5.03326382 16.7568785,5.37261346 16.8131052,5.46372088 C16.9134438,5.62630565 16.9921316,5.74688122 17.0629801,5.84537513 C17.1097019,5.91032811 17.1505105,5.96193936 17.1838035,6 L20,6 Z M19,11 C18.4477153,11 18,10.5522847 18,10 C18,9.44771525 18.4477153,9 19,9 C19.5522847,9 20,9.44771525 20,10 C20,10.5522847 19.5522847,11 19,11 Z"
    />
  </BaseIcon>
)
