import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const PlayerListAdd = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M18,17 L16,17 L16,15 L18,15 L18,13 L20,13 L20,15 L22,15 L22,17 L20,17 L20,19 L18,19 L18,17 Z M4,19 L4,17 L14,17 L14,19 L4,19 Z M4,15 L4,13 L14,13 L14,15 L4,15 Z M4,11 L4,9 L19,9 L19,11 L4,11 Z M4,7 L4,5 L19,5 L19,7 L4,7 Z"
    />
  </BaseIcon>
)
