import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const ArrowBoldReplyAll = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M14,15.833805 L14,20.4142136 L5.58578644,12 L14,3.58578644 L14,8.03493325 C19.2610812,8.41289286 22,11.850697 22,18 L22,20.4142136 L20.2928932,18.7071068 C18.1236934,16.5379069 16.0444418,15.6088962 14,15.833805 Z M12,8.41421356 L8.41421356,12 L12,15.5857864 L12,14.2192236 L12.7574644,14.0298575 C15.1527495,13.4310362 17.5261606,14.0017778 19.8395979,15.6824714 C19.2495421,11.8099952 17.0220455,10 13,10 L12,10 L12,8.41421356 Z M10,6.58578644 L4.58578644,12 L10,17.4142136 L10,20.4142136 L1.58578644,12 L10,3.58578644 L10,6.58578644 Z"
    />
  </BaseIcon>
)
