import { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { zxcvbn, zxcvbnOptions } from '@zxcvbn-ts/core'

const scoreWord = (score: number): string => {
  if (score === 4) {
    return 'Fantastic'
  } else if (score === 3) {
    return 'Great'
  } else if (score === 2) {
    return 'Good'
  } else if (score === 1) {
    return 'Weak'
  } else {
    return 'Nothing'
  }
}

const Strength = styled.div`
  margin: 0 auto 1em;
  width: 100%;
  height: 8px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  border: 1px solid var(--au-medium-grey);
  overflow: hidden;
  margin-bottom: 28px;

  & > div {
    border-width: 1px;
    height: 6px;
    width: 0%;
    border-radius: 2px;
    background-color: #ed171d;
    transition: width 0.2s ease-in-out, background-color 0.2s ease-in-out;
  }

  & > div > div {
    visibility: hidden;
  }

  &[data-value='0'] {
    & > div {
      width: 25%;
    }
  }
  &[data-value='1'] {
    & > div {
      width: 25%;
    }
  }
  &[data-value='2'] {
    & > div {
      width: 50%;
      background-color: #ffe21a;
    }
  }
  &[data-value='3'] {
    & > div {
      width: 75%;
      background-color: #ff8500;
    }
  }
  &[data-value='4'] {
    & > div {
      width: 100%;
      background-color: #8cbc1c;
    }
  }
`

const loadOptions = async () => {
  const zxcvbnCommonPackage = await import('@zxcvbn-ts/language-common')
  const zxcvbnEnPackage = await import('@zxcvbn-ts/language-en')

  return {
    dictionary: {
      ...zxcvbnCommonPackage.default.dictionary,
      ...zxcvbnEnPackage.default.dictionary,
    },
    graphs: zxcvbnCommonPackage.default.adjacencyGraphs,
    translations: zxcvbnEnPackage.default.translations,
  }
}

export const PasswordStrengthIndicator = ({
  password = '',
  id = 'password-strength-meter',
  onPasswordChange,
}: {
  password: string
  id?: string
  onPasswordChange?: (num: number) => void
}) => {
  const [score, setScore] = useState(0)

  useEffect(() => {
    async function fetchData() {
      const options = await loadOptions()
      zxcvbnOptions.setOptions(options)
      const score = zxcvbn(password).score
      setScore(score)
      if (onPasswordChange) {
        onPasswordChange(score)
      }
    }
    fetchData()
  }, [onPasswordChange, password])

  return (
    <Strength id={id} data-value={password.length > 0 ? score : null}>
      <div>
        <div>Password Strength:{scoreWord(score)}</div>
      </div>
    </Strength>
  )
}
