import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Dashboard = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M14.5859179,11.4781966 C14.8490434,11.9243482 15,12.4445452 15,13 C15,14.6568542 13.6568542,16 12,16 C10.3431458,16 9,14.6568542 9,13 C9,11.3431458 10.3431458,10 12,10 C12.3685381,10 12.7215551,10.0664537 13.047722,10.1880321 L15.6807681,6.42653766 L17.3192319,7.57346234 L14.5859179,11.4781966 Z M5.39835032,21.7994935 C2.65046282,19.7349529 1,16.5036875 1,13 C1,6.92486775 5.92486775,2 12,2 C18.0751322,2 23,6.92486775 23,13 C23,16.5036875 21.3495372,19.7349529 18.6016497,21.7994935 L18.3347771,22 L5.66522295,22 L5.39835032,21.7994935 Z M17.6574682,20 C19.7530206,18.305007 21,15.7569911 21,13 C21,8.02943725 16.9705627,4 12,4 C7.02943725,4 3,8.02943725 3,13 C3,15.7569911 4.24697938,18.305007 6.34253177,20 L17.6574682,20 Z M12,14 C12.5522847,14 13,13.5522847 13,13 C13,12.4477153 12.5522847,12 12,12 C11.4477153,12 11,12.4477153 11,13 C11,13.5522847 11.4477153,14 12,14 Z"
    />
  </BaseIcon>
)
