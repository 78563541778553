import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const IPhone = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M7,1 L17,1 C18.1045695,1 19,1.8954305 19,3 L19,21 C19,22.1045695 18.1045695,23 17,23 L7,23 C5.8954305,23 5,22.1045695 5,21 L5,3 C5,1.8954305 5.8954305,1 7,1 Z M10,3 L7,3 L7,21 L17,21 L17,3 L14,3 C14,3.55228475 13.5522847,4 13,4 L11,4 C10.4477153,4 10,3.55228475 10,3 Z"
    />
  </BaseIcon>
)
