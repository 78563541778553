import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const ArrowBoldLeft = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M12,15.8198039 L12,21.2037682 L1.48140774,12 L12,2.79623177 L12,8.1801961 L22,10.1801961 L22,13.8198039 L12,15.8198039 Z M4.51859226,12 L10,16.7962318 L10,14.1801961 L20,12.1801961 L20,11.8198039 L10,9.8198039 L10,7.20376823 L4.51859226,12 Z"
    />
  </BaseIcon>
)
