import { BaseIcon, SVGProps } from '@aurelius/icons'

export const CopyIcon = ({ ...rest }: SVGProps) => (
  <BaseIcon
    title="Send All to Theme"
    width={16}
    height={16}
    viewBox="0 0 16 16"
    {...rest}
  >
    <path
      d="M14.4 0c.922 0 1.6.678 1.6 1.6v8c0 .922-.678 1.6-1.6 1.6h-3.2v3.2c0 .922-.678 1.6-1.6 1.6h-8C.678 16 0 15.322 0 14.4v-8c0-.922.678-1.6 1.6-1.6h3.2V1.6C4.8.678 5.478 0 6.4 0h8zM4.8 6.4H1.6v8h8v-3.2H6.4c-.922 0-1.6-.678-1.6-1.6V6.4zm9.6-4.8h-8v8h8v-8z"
      fill="#00678D"
      fillRule="evenodd"
    />
  </BaseIcon>
)
