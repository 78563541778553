import * as yup from 'yup'

export const newCollection = yup.object().shape({
  name: yup.string().trim().required('Name is required.'),
  description: yup.string().trim().nonNullable(),
})
export type newCollectionType = yup.InferType<typeof newCollection>

export const arrayOfCollections = yup.object().shape({
  collections: yup.array().of(yup.string().required()).required(),
})
export type arrayOfCollectionsType = yup.InferType<typeof arrayOfCollections>

export const shareCollection = yup.object().shape({
  emails: yup
    .array()
    .required()
    .of(yup.string().email().lowercase().required()),
})
export type shareCollectionType = yup.InferType<typeof shareCollection>

export const updateCollectionClips = yup.object().shape({
  clips: yup.array().of(yup.string().required()).required(),
})

export type updateCollectionClipsType = yup.InferType<
  typeof updateCollectionClips
>
