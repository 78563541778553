import * as yup from 'yup'

export const newCustomType = yup.object().shape({
  name: yup.string().trim().required('Name is required.'),
  typeModel: yup
    .string()
    .trim()
    .required()
    .oneOf(['KeyInsights', 'Recommendations']),
})

export type newCustomTypeType = yup.InferType<typeof newCustomType>
