import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const PlayerListPlay = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M4,19 L4,17 L16,17 L16,19 L4,19 Z M4,15 L4,13 L16,13 L16,15 L4,15 Z M4,11 L4,9 L19,9 L19,11 L4,11 Z M4,7 L4,5 L19,5 L19,7 L4,7 Z M18,13 L22,16 L18,19 L18,13 Z"
    />
  </BaseIcon>
)
