import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const PlayerFastBack = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M10.3414954,19.7525767 L2.34149539,12.7525767 C1.8861682,12.3541654 1.8861682,11.6458346 2.34149539,11.2474233 L10.3414954,4.24742331 C10.9880783,3.68166327 12,4.14084119 12,5 L12,19 C12,19.8591588 10.9880783,20.3183367 10.3414954,19.7525767 Z M20.3414954,19.7525767 L12.3414954,12.7525767 C11.8861682,12.3541654 11.8861682,11.6458346 12.3414954,11.2474233 L20.3414954,4.24742331 C20.9880783,3.68166327 22,4.14084119 22,5 L22,19 C22,19.8591588 20.9880783,20.3183367 20.3414954,19.7525767 Z M10,7.20376823 L4.51859226,12 L10,16.7962318 L10,7.20376823 Z M20,16.7962318 L20,7.20376823 L14.5185923,12 L20,16.7962318 Z"
    />
  </BaseIcon>
)
