import { Box, BoxProps } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { H1, HProps, P, PProps } from './Typography'
import { Button } from './Button'
import { Checkbox } from './Checkbox'
import { forwardRef } from 'react'

export const RedSpot = styled(Box)`
  display: inline-block;
  width: 12px;
  height: 12px;
  background-color: #dc1f1f;
  border-radius: 50%;
  margin-right: 5px;
`

export const GreenSpot = styled(Box)`
  display: inline-block;
  width: 12px;
  height: 12px;
  background-color: #008000;
  border-radius: 50%;
  margin-right: 5px;
`

export const CloseIcon = styled.img`
  width: 24px;
  cursor: pointer;
  position: absolute;
  right: 40px;
  position: absolute;
  top: 16px;
  right: 16px;
`

export const TitleBox = styled(Box)`
  width: 50%;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

export const OverviewTitle = styled(H1)<HProps>`
  display: flex;
  min-height: 38px;
  font-weight: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.38px;
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : '16px'};
  font-style: normal;
  font-weight: 900;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '32px')};
  color: var(--colors-typography-header);
`

export const OverviewDescription = styled(Box)`
  display: flex;
  flex: 1 1 0;
  flex-direction: row;
  max-width: 750px;
  flex-wrap: wrap;
  background-color: transparent;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.19px;
  color: var(--colors-typography-notes);
  white-space: pre-wrap;
  padding-top: 15px;
  padding-bottom: 15px;
`

export const SectionBox = ({
  children,
  ...props
}: React.PropsWithChildren<BoxProps>) => (
  <Box
    display="flex"
    flexDirection="row"
    flex="1 1"
    flexWrap="wrap"
    justifyContent="flex-start"
    alignItems="flex-start"
    backgroundColor="transparent"
    {...props}
  >
    {children}
  </Box>
)

export const ParagraphLink = styled(P)`
  cursor: pointer;
  text-decoration: none;
  margin-left: 16px;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.19px;
  color: #1d566e;
`

export const SectionTitleP = styled(P)`
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.14px;
  color: var(--colors-typography-titles);
  margin-right: 16px;
  cursor: pointer;
`

export const HeaderTitleP = styled(P)`
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.19px;
  color: var(--colors-typography-titles);
  margin-right: 16px;
`

export const CustomParagraph = forwardRef(
  (
    {
      fontSize = '16px',
      fontWeight = 'normal',
      fontStyle = 'normal',
      lineHeight = 'normal',
      letterSpacing = '0.14px',
      color = 'var(--colors-typography-meta)',
      children,
      ...rest
    }: PProps,
    ref,
  ) => (
    <P
      fontSize={fontSize}
      fontWeight={fontWeight}
      fontStyle={fontStyle}
      lineHeight={lineHeight}
      letterSpacing={letterSpacing}
      color={color}
      ref={ref as any}
      {...rest}
    >
      {children}
    </P>
  ),
)
CustomParagraph.displayName = 'Custom Paragraph'

export const Keyword = styled(P)`
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.49px;
  color: var(--colors-inputs-text-color);
  border-radius: 2px;
  background-color: #d7dddf;
  padding: 8px;
  margin: 4px 0;
  margin-right: 8px;
  cursor: pointer;
`

export const TagNumber = styled(P)`
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.65px;
  color: var(--colors-inputs-text-color);
`

export const CommentButton = styled(Button)`
  height: 30px;
  border-radius: 2px;
  border: solid 1px var(--colors-inputs-text-color);
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.49px;
  color: var(--colors-inputs-text-color);
  padding: 0 8px;
`
export const HoverWrapper = styled.div`
  position: relative;

  &.fade {
    opacity: 0.2;
  }

  /* checkbox gets passed in as children */
  .checkbox {
    position: absolute;
    left: -2px;
    top: 2px;
  }
  .hidden,
  .au-select__menu {
    visibility: hidden !important;
  }
  .visible {
    visibility: visible !important;
  }
  &:hover .hidden,
  & .hidden:hover,
  &:hover .au-select__menu {
    visibility: visible !important;
  }
`

export const CustomCheckbox = styled(Checkbox)`
  div {
    display: inline-block;
    vertical-align: text-top;
    margin-right: 16px;
  }
  span {
    font-size: 16px;
    font-weight: bold;
    line-height: 16px;
    letter-spacing: 0.19px;
    color: #176971;
  }
`
