import { Company } from './Company'
import { Base } from './Base'
import { ObjectId } from 'mongoose'

export enum HookTypes {
  create = 'create',
  update = 'update',
  delete = 'delete',
}

export interface Subscription extends Base {
  target: string
  type: string
  hook: string
  lastTriggered: Date
  company: Company | ObjectId | string
}
