import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Sun = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M7.61130947,22.5952362 L5.92685415,18.0731458 L1.40476381,16.3886905 L3.41127478,12 L1.40476381,7.61130947 L5.92685415,5.92685415 L7.61130947,1.40476381 L12,3.41127478 L16.3886905,1.40476381 L18.0731458,5.92685415 L22.5952362,7.61130947 L20.5887252,12 L22.5952362,16.3886905 L18.0731458,18.0731458 L16.3886905,22.5952362 L12,20.5887252 L7.61130947,22.5952362 Z M12,18.3896052 L15.2649781,19.8823545 L16.5181332,16.5181332 L19.8823545,15.2649781 L18.3896052,12 L19.8823545,8.73502188 L16.5181332,7.48186681 L15.2649781,4.11764554 L12,5.61039477 L8.73502188,4.11764554 L7.48186681,7.48186681 L4.11764554,8.73502188 L5.61039477,12 L4.11764554,15.2649781 L7.48186681,16.5181332 L8.73502188,19.8823545 L12,18.3896052 Z M12,17 C9.23857625,17 7,14.7614237 7,12 C7,9.23857625 9.23857625,7 12,7 C14.7614237,7 17,9.23857625 17,12 C17,14.7614237 14.7614237,17 12,17 Z M12,15 C13.6568542,15 15,13.6568542 15,12 C15,10.3431458 13.6568542,9 12,9 C10.3431458,9 9,10.3431458 9,12 C9,13.6568542 10.3431458,15 12,15 Z"
    />
  </BaseIcon>
)
