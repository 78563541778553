import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Pulse = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M20,11 L20,4 L4,4 L4,11 L7.41421356,11 L9.66268676,13.2484732 L12.7622024,6.01626993 L16.5,11 L20,11 Z M20,13 L15.5,13 L13.2377976,9.98373007 L10.3373132,16.7515268 L6.58578644,13 L4,13 L4,20 L20,20 L20,13 Z M3.81818182,2 L20.1818182,2 C21.1859723,2 22,2.99492278 22,4.22222222 L22,19.7777778 C22,21.0050772 21.1859723,22 20.1818182,22 L3.81818182,22 C2.81402773,22 2,21.0050772 2,19.7777778 L2,4.22222222 C2,2.99492278 2.81402773,2 3.81818182,2 Z"
    />
  </BaseIcon>
)
