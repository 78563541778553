import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const AlarmChecked = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 C17.5228475,2 22,6.4771525 22,12 C22,17.5228475 17.5228475,22 12,22 Z M12,20 C16.418278,20 20,16.418278 20,12 C20,7.581722 16.418278,4 12,4 C7.581722,4 4,7.581722 4,12 C4,16.418278 7.581722,20 12,20 Z M17.7928932,2.70710678 L19.2071068,1.29289322 L22.7071068,4.79289322 L21.2928932,6.20710678 L17.7928932,2.70710678 Z M4.79289322,1.29289322 L6.20710678,2.70710678 L2.70710678,6.20710678 L1.29289322,4.79289322 L4.79289322,1.29289322 Z M11,12.5857864 L15.2928932,8.29289322 L16.7071068,9.70710678 L11,15.4142136 L7.29289322,11.7071068 L8.70710678,10.2928932 L11,12.5857864 Z"
    />
  </BaseIcon>
)
