import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Wallet = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M18,5 L20,5 C21.1045695,5 22,5.8954305 22,7 L22,19 C22,20.1045695 21.1045695,21 20,21 L4,21 C2.8954305,21 2,20.1045695 2,19 L2.02670349,19 C2.00895171,18.8924597 2,18.7835216 2,18.67427 L2,6.75430141 C2,5.86134069 2.59195463,5.07656869 3.45055774,4.83125352 L15.4505577,1.40268209 C16.5126278,1.09923351 17.6195993,1.7142177 17.9230479,2.77628773 C17.9741002,2.95497066 18,3.13989693 18,3.32572998 L18,5 Z M18,7 L18,15.2456986 C18,16.1386593 17.4080454,16.9234313 16.5494423,17.1687465 L10.1400549,19 L20,19 L20,7 L18,7 Z M4,6.75430141 L4,18.67427 L16,15.2456986 L16,3.32572998 L4,6.75430141 Z M18,11 L18,9 L20,9 L20,11 L18,11 Z M13,10 C12.4477153,10 12,9.55228475 12,9 C12,8.44771525 12.4477153,8 13,8 C13.5522847,8 14,8.44771525 14,9 C14,9.55228475 13.5522847,10 13,10 Z"
    />
  </BaseIcon>
)
