import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const AddToLibrary = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M12,12.1404647 L12,7.13148291 L16.5547002,10.1679497 L15.4452998,11.8320503 L14,10.8685171 L14,14.5 C14,15.9534271 12.6045695,17 11,17 C9.3954305,17 8,15.9534271 8,14.5 C8,13.0465729 9.3954305,12 11,12 C11.3471248,12 11.6844618,12.0489806 12,12.1404647 Z M19,21 L17,21 L17,19 L19,19 L19,17 L21,17 L21,19 L23,19 L23,21 L21,21 L21,23 L19,23 L19,21 Z M15,20 L15,22 L4,22 C2.8954305,22 2,21.1045695 2,20 L2,4 C2,2.8954305 2.8954305,2 4,2 L20,2 C21.1045695,2 22,2.8954305 22,4 L22,14 L20,14 L20,4 L4,4 L4,20 L15,20 Z M11,15 C11.6045695,15 12,14.7034271 12,14.5 C12,14.2965729 11.6045695,14 11,14 C10.3954305,14 10,14.2965729 10,14.5 C10,14.7034271 10.3954305,15 11,15 Z"
    />
  </BaseIcon>
)
