import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const ArrowBoldRedo = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M21,5.58578644 L21,19 L7.58578644,19 L11.7393105,14.8464759 C10.8357663,13.4480392 10.0607561,13 8.5,13 C7.61524604,13 6.70742719,13.3157631 5.83659743,13.8516583 C5.52307244,14.0445968 5.23810904,14.2518429 4.98913021,14.4578943 C4.84457198,14.5775287 4.74834888,14.6658647 4.70710678,14.7071068 L3,16.4142136 L3,14 C3,10.1786135 7.1134998,7 11,7 C13.1107089,7 14.4746422,7.45464443 15.8469173,8.55246452 C16.208914,8.84206192 16.5053724,9.10850918 16.9969668,9.58881966 L21,5.58578644 Z M17,12.4142136 L16.2928932,11.7071068 C15.3764834,10.790697 15.0265063,10.4573855 14.5975272,10.1142021 C13.5888499,9.30726033 12.6670689,9 11,9 C9.07763171,9 7.0465992,10.0761184 5.90962044,11.5618734 C6.74035873,11.2038406 7.60743104,11 8.5,11 C11.1170249,11 12.4540735,12.0290643 13.8682431,14.5038611 L14.247192,15.1670216 L12.4142136,17 L19,17 L19,10.4142136 L17,12.4142136 Z"
    />
  </BaseIcon>
)
