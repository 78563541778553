import { ErrorBoundary } from 'react-error-boundary'
import { ErrorFallback } from '../ErrorFallback'
import { MemoFDE } from '../MemoFDE'
import { AudioPreview } from './AudioPreview'
import { FilePreview } from './FilePreview'
import { ImagePreview } from './ImagePreview'
import { VideoAutoPlayPreview } from './VideoAutoPlayPreview'
import { VideoPreview } from './VideoPreview'

export enum DocumentType {
  File = 'FILE',
  Audio = 'AUDIO',
  Image = 'IMAGE',
  Video = 'VIDEO',
  PDF = 'PDF',
  Spreadsheet = 'Spreadsheet',
  Link = 'Link',
  ZoomMeeting = 'ZoomMeeting',
  HighlightReel = 'HighlightReel',
}

export interface DocumentPreviewProps {
  file: any
  thumbnail?: string
  name: string
  type: DocumentType
  preview?: boolean
  onPreviewClick?: VoidFunction
  duration?: number
  size?: number
  couldPIP?: boolean
  playing?: boolean
  autoplay?: boolean
  first?: boolean
  start?: number
}

export const DocumentPreview = MemoFDE(
  ({
    file,
    thumbnail,
    type,
    onPreviewClick,
    name,
    preview = false,
    couldPIP = false,
    playing = false,
    autoplay = false,
    first = false,
    start = 0,
  }: DocumentPreviewProps) => {
    switch (type) {
      case DocumentType.Audio:
        return (
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <AudioPreview file={file} couldPIP={couldPIP} playing={playing} />
          </ErrorBoundary>
        )
      case DocumentType.Image:
        return (
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <ImagePreview
              preview={preview}
              name={name}
              thumbnail={thumbnail}
              file={file}
              onPreviewClick={onPreviewClick}
            />
          </ErrorBoundary>
        )
      case DocumentType.Video:
        return (
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            {!autoplay && <VideoPreview file={file} thumbnail={thumbnail} />}
            {autoplay && (
              <VideoAutoPlayPreview
                file={file}
                first={first}
                playing={playing}
                start={start}
              />
            )}
          </ErrorBoundary>
        )
      case DocumentType.Link:
        return (
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <a href={file} rel="noreferrer" target="_blank">
              {name}
            </a>
          </ErrorBoundary>
        )
      default:
        return (
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <FilePreview name={name} onPreviewClick={onPreviewClick} />
          </ErrorBoundary>
        )
    }
  },
)

DocumentPreview.displayName = 'DocumentPreviewComponent'
