import {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  forwardRef,
  MouseEventHandler,
} from 'react'
import styled from '@emotion/styled'
import { Box, BoxProps } from '@chakra-ui/react'
import isPropValid from '@emotion/is-prop-valid'

export interface ButtonProps {
  type?: 'submit' | 'reset' | 'button'
  onClick?: MouseEventHandler<HTMLButtonElement>
  loading?: boolean
  variant?: 'small' | 'alt' | 'modal'
  breakText?: boolean
  disabled?: boolean
}

const ButtonStyled = styled(Box, {
  shouldForwardProp: (prop) => isPropValid(prop),
})<ButtonProps & BoxProps>`
  appearance: none; // Removes awkward default styles on some inputs for iOS
  background-color: var(--colors-buttons-primary-background);
  border-radius: 8px;
  border: 2px solid transparent;
  box-shadow: none;
  color: var(--colors-buttons-primary-text);
  display: ${(props) => (props.display ? props.display : 'block')};
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  padding: 12px 24px;
  text-align: center;
  transition: var(--transitions-buttons);
  letter-spacing: 0.19px;
  transition: 0.2s filter linear;
  white-space: ${(props) => (props.breakText ? 'break-spaces' : 'nowrap')};

  ${(props) => (props.margin ? `margin: ${props.margin} ` : '')};
  ${(props) => (props.marginTop ? `margin-top: ${props.marginTop} ` : '')};
  ${(props) =>
    props.marginRight ? `margin-right: ${props.marginRight} ` : ''};
  ${(props) =>
    props.marginBottom ? `margin-bottom: ${props.marginBottom} ` : ''};
  ${(props) => (props.marginLeft ? `margin-left: ${props.marginLeft} ` : '')};

  ${(props) => (props.padding ? `padding: ${props.padding} ` : '')};
  ${(props) => (props.paddingTop ? `padding-top: ${props.paddingTop} ` : '')};
  ${(props) =>
    props.paddingRight ? `padding-right: ${props.paddingRight} ` : ''};
  ${(props) =>
    props.paddingBottom ? `padding-bottom: ${props.paddingBottom} ` : ''};
  ${(props) =>
    props.paddingLeft ? `padding-left: ${props.paddingLeft} ` : ''};

  &:hover {
    background-color: var(--colors-buttons-primary-hover);
    border: 2px solid var(--colors-buttons-primary-background);
  }
  &:disabled {
    cursor: not-allowed;
  }

  & a {
    color: inherit;
    text-decoration: none;
  }

  ${(props) =>
    props.variant == 'small' &&
    `
      margin: 0;
      padding: 0 8px;
      min-height: 24px;
      font-size: 12px;
      letter-spacing: 0.49px;
      line-height: 0px;
      
      border-radius: 2px;
      border: solid 1px var(--colors-buttons-alt-text);
      background-color: var(--colors-buttons-alt-background);
      color: var(--colors-buttons-alt-text);

      &:hover {
        background-color: var(--colors-buttons-alt-hover);
      }
      & > .loader {
        border: 2px solid var(--colors-buttons-alt-text);
        border-top-color: transparent;
      }
  `}

  ${(props) =>
    props.variant == 'alt' &&
    `
      border-radius: 2px;
      border: solid 1px var(--colors-buttons-alt-text);
      background-color: var(--colors-buttons-alt-background);
      color: var(--colors-buttons-alt-text);

      &:hover {
        background-color: var(--colors-buttons-alt-hover);
      }

      & > .loader {
        border: 2px solid var(--colors-buttons-alt-text);
        border-top-color: transparent;
      }
  `}

${(props) =>
    props.variant == 'modal' &&
    `
    color: #ffffff;
    margin: 0 24px 24px 0;
    padding: 0;
    border: none;
    border-radius: 0;
    border-bottom: 2px solid #ffffff;
    font-size: 16px;
    font-weight: bold;
    
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.19px;
    background-color: transparent;

    &:hover {
      border: none;
      border-radius: 0;
      border-bottom: 2px solid #ffffff;
      background-color: transparent;
      filter: brightness(0.9);
    }
  `}
`

const ResetStyled = styled(Box, {
  shouldForwardProp: (prop) => isPropValid(prop),
})<{
  breakText?: boolean
  type?: 'submit' | 'reset' | 'button'
  disabled?: boolean
}>`
  appearance: none; // Removes awkward default styles on some inputs for iOS
  color: var(--colors-typography-anchor);
  background-color: transparent;
  border: none;
  display: block;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.19px;
  transition: 0.2s filter linear;
  white-space: ${(props) => (props.breakText ? 'break-spaces' : 'nowrap')};

  ${(props) => (props.margin ? `margin: ${props.margin} ` : '')};
  ${(props) => (props.marginTop ? `margin-top: ${props.marginTop} ` : '')};
  ${(props) =>
    props.marginRight ? `margin-right: ${props.marginRight} ` : ''};
  ${(props) =>
    props.marginBottom ? `margin-bottom: ${props.marginBottom} ` : ''};
  ${(props) => (props.marginLeft ? `margin-left: ${props.marginLeft} ` : '')};

  ${(props) => (props.padding ? `padding: ${props.padding} ` : '')};
  ${(props) => (props.paddingTop ? `padding-top: ${props.paddingTop} ` : '')};
  ${(props) =>
    props.paddingRight ? `padding-right: ${props.paddingRight} ` : ''};
  ${(props) =>
    props.paddingBottom ? `padding-bottom: ${props.paddingBottom} ` : ''};
  ${(props) =>
    props.paddingLeft ? `padding-left: ${props.paddingLeft} ` : ''};

  &:hover {
    filter: brightness(1.2);
  }
  &:disabled {
    cursor: not-allowed;
  }

  &.small {
    font-size: 12px;
    line-height: 0px;
  }

  & a {
    color: inherit;
    text-decoration: none;
  }

  & > .loader {
    border: 2px solid var(--colors-typography-anchor);
    border-top-color: transparent;
  }
`
const Loader = styled.div`
  content: ' ';
  display: inline-block;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  border: 2px solid var(--colors-buttons-primary-text);
  border-top-color: transparent;
  animation: spin 0.5s linear infinite;
  margin-right: 4px;

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`

type ButtonType = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>

export const Button = forwardRef<
  ButtonType,
  ButtonType & ButtonProps & BoxProps
>(({ loading, disabled, children, type, ...rest }, ref) =>
  type == null || type === 'reset' ? (
    <ResetStyled
      as="button"
      ref={ref as any}
      type={type}
      disabled={loading || disabled}
      {...rest}
    >
      {loading ? <Loader className="loader" /> : null}
      {children}
    </ResetStyled>
  ) : (
    <ButtonStyled
      as="button"
      ref={ref as any}
      type={type}
      disabled={loading || disabled}
      {...rest}
    >
      {loading ? <Loader className="loader" /> : null}
      {children}
    </ButtonStyled>
  ),
)

Button.displayName = 'Button'
