import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const ArrowBoldForwardAll = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <defs>
      <path
        id="arrow-bold-forward-all-a"
        d="M10,3.58578644 L18.4142136,12 L10,20.4142136 L10,15.833805 C7.95555821,15.6088962 5.87630664,16.5379069 3.70710678,18.7071068 L2,20.4142136 L2,18 C2,11.850697 4.7389188,8.41289286 10,8.03493325 L10,3.58578644 Z M12,8.41421356 L12,10 L11,10 C6.97795449,10 4.75045793,11.8099952 4.16040212,15.6824714 C6.4738394,14.0017778 8.84725046,13.4310362 11.2425356,14.0298575 L12,14.2192236 L12,15.5857864 L15.5857864,12 L12,8.41421356 Z M14,6.58578644 L14,3.58578644 L22.4142136,12 L14,20.4142136 L14,17.4142136 L19.4142136,12 L14,6.58578644 Z"
      />
    </defs>
    <use fillRule="evenodd" xlinkHref="#arrow-bold-forward-all-a" />
  </BaseIcon>
)
