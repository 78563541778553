import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const CC = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M12,1 C18.0751322,1 23,5.92486775 23,12 C23,18.0751322 18.0751322,23 12,23 C5.92486775,23 1,18.0751322 1,12 C1,5.92486775 5.92486775,1 12,1 Z M12,3 C7.02943725,3 3,7.02943725 3,12 C3,16.9705627 7.02943725,21 12,21 C16.9705627,21 21,16.9705627 21,12 C21,7.02943725 16.9705627,3 12,3 Z M10.207632,13.4797108 L11.5843531,14.9304482 C10.8657197,15.6124171 9.92069871,16 8.91767512,16 C6.74767992,16 5,14.2030077 5,12 C5,9.79699232 6.74767992,8 8.91767512,8 C9.98882628,8 10.9921042,8.44214695 11.7233231,9.2081138 L10.2766769,10.5891332 C9.91759862,10.2129914 9.43429879,10 8.91767512,10 C7.86489537,10 7,10.8892992 7,12 C7,13.1107008 7.86489537,14 8.91767512,14 C9.40134809,14 9.85575815,13.8136321 10.207632,13.4797108 Z M17.207632,13.4797108 L18.5843531,14.9304482 C17.8657197,15.6124171 16.9206987,16 15.9176751,16 C13.7476799,16 12,14.2030077 12,12 C12,9.79699232 13.7476799,8 15.9176751,8 C16.9888263,8 17.9921042,8.44214695 18.7233231,9.2081138 L17.2766769,10.5891332 C16.9175986,10.2129914 16.4342988,10 15.9176751,10 C14.8648954,10 14,10.8892992 14,12 C14,13.1107008 14.8648954,14 15.9176751,14 C16.4013481,14 16.8557581,13.8136321 17.207632,13.4797108 Z"
    />
  </BaseIcon>
)
