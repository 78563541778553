import styled from '@emotion/styled'
import { omit, pick } from '@styled-system/props'
import { Fragment, ReactNode, useRef, useState } from 'react'
import { useClickAway } from 'react-use'
import { Box, BoxProps } from '@chakra-ui/react'
import { Button } from './Button'
import { MemoFDE } from './MemoFDE'
import { ToolTip } from './ToolTip'
import { PProps, Span } from './Typography'

const MenuButton = styled(Box)<BoxProps>`
  background: transparent;
  border-radius: ${(p) => (p.borderRadius ? p.borderRadius : '8px')};
  display: flex;
  border: none;
  padding: 0;
  height: ${(p) => (p.height ? p.height : '40px')};
  width: ${(p) => (p.width ? p.width : '40px')};
  display: flex;
  border: ${(p) =>
    p.borderWidth
      ? `${p.borderWidth} solid transparent`
      : '2px solid transparent'};
  flex-direction: column;

  &:hover {
    border: ${(p) =>
      p.border ? p.border : '2px solid var(--colors-inputs-text-color)'};
  }

  &.open {
    opacity: 1 !important;
    border: ${(p) =>
      p.border ? p.border : '2px solid var(--colors-inputs-text-color)'};
    background-color: var(--colors-buttons-menu-open-background);
  }
`

const Dot = styled(Span)<PProps>`
  height: 4px;
  width: 4px;
  color: var(--colors-inputs-text-color);
  flex-shrink: 0;
  background-color: var(--colors-inputs-text-color);
  border-radius: 50%;
  display: inline-block;
  margin: 0 auto;

  &.open {
    background-color: var(--colors-buttons-menu-open-text);
  }
`

export type MenuItem = {
  name: ReactNode
  action: () => void
  loading?: boolean
  closeOnClick?: boolean
  onClose?: VoidFunction
}

const MenuItemButton = styled(Button)`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: var(--colors-typography-link);
  margin: 8px 0;

  & svg {
    height: 12px;
    width: 12px;
    fill: var(--colors-typography-link);
  }
`

const MenuItems = ({
  items,
  close,
}: {
  items: MenuItem[]
  close: VoidFunction
}) => (
  <Box>
    {items.map((item: MenuItem, i: number) => (
      <Fragment key={i}>
        <MenuItemButton
          loading={item.loading}
          onClick={(e): void => {
            e.preventDefault()
            e.stopPropagation()
            item.action()
            if (item.closeOnClick) {
              close()
            }
          }}
        >
          {item.name}
        </MenuItemButton>
      </Fragment>
    ))}
  </Box>
)

type Props = {
  items?: MenuItem[]
  onClose?: VoidFunction
  placement?: string
  placeholder?: ReactNode
  open?: boolean
  buttonProps?: BoxProps
} & BoxProps

export const Menu = MemoFDE<Props>(
  ({
    border,
    items,
    onClose,
    placement = 'bottom-start',
    placeholder,
    open,
    buttonProps,
    ...props
  }: Props) => {
    const [isOpen, toggleOpen] = useState(open)

    const wrapperRef = useRef(null)

    useClickAway(wrapperRef, () => toggleOpen(false))

    if (!items || items.length === 0) {
      return null
    }

    return (
      <Box ref={wrapperRef} position="relative" {...pick(props)}>
        <ToolTip
          content={
            <MenuItems
              items={items}
              close={(): void => {
                if (isOpen && onClose) {
                  onClose()
                }
                toggleOpen(!isOpen)
              }}
            />
          }
          placement={placement as any}
          animation="none"
          arrow={false}
          offset={[0, 2]}
          duration={0}
          interactive
          visible={isOpen}
        >
          <MenuButton
            as="button"
            aria-label="Edit Menu"
            onClick={(e): void => {
              e.preventDefault()
              e.stopPropagation()
              if (isOpen && onClose) {
                onClose()
              }
              toggleOpen(!isOpen)
            }}
            className={isOpen ? 'open' : ''}
            border={border}
            {...omit(props)}
            {...buttonProps}
          >
            {placeholder || (
              <>
                <Dot
                  marginTop="8px !important"
                  marginBottom="4px !important"
                  className={isOpen ? 'open' : ''}
                />
                <Dot
                  marginBottom="4px !important"
                  className={isOpen ? 'open' : ''}
                />
                <Dot className={isOpen ? 'open' : ''} />
              </>
            )}
          </MenuButton>
        </ToolTip>
      </Box>
    )
  },
)
Menu.displayName = 'Menu'
