import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const TextBold = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M16.4297041,11.3480445 C18.5153999,12.1296132 20,14.1414332 20,16.5 C20,19.5375661 17.5375661,22 14.5,22 L6,22 L6,2 L12.5,2 C15.5375661,2 18,4.46243388 18,7.5 C18,8.9980147 17.4011125,10.3561517 16.4297041,11.3480445 Z M12.5,11 C14.4329966,11 16,9.43299662 16,7.5 C16,5.56700338 14.4329966,4 12.5,4 L8,4 L8,11 L12.5,11 Z M8,13 L8,20 L14.5,20 C16.4329966,20 18,18.4329966 18,16.5 C18,14.5670034 16.4329966,13 14.5,13 L8,13 Z"
    />
  </BaseIcon>
)
