import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Planet = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M16.4341517,5.34027649 C19.5526401,4.82550436 21.9775728,5.30066885 22.7613144,6.98140814 C23.5450164,8.66206251 22.3504571,10.824965 19.951836,12.8828831 C19.5123956,16.8857302 16.1198862,20 12,20 C10.359675,20 8.83465517,19.5063199 7.56541555,18.6594348 C4.44608523,19.1746732 2.02039254,18.6996452 1.23650448,17.0185919 C0.452540248,15.3373751 1.64816139,13.1735876 4.04839101,11.1150512 C4.48877158,7.11319839 7.88082257,4 12,4 C13.6401386,4 15.1649982,4.49356789 16.4341517,5.34027649 Z M18.3526801,7.13687265 C19.0478987,8.04366602 19.553342,9.10361155 19.8096504,10.2573495 C20.759634,9.23569807 21.1931253,8.35081879 20.9486988,7.82664467 C20.7041658,7.30224176 19.7469377,7.06554811 18.3526801,7.13687265 Z M5.64723739,16.8630198 C4.95168113,15.9557542 4.44609504,14.8951833 4.1899293,13.7407574 C3.23872065,14.7632088 2.80454268,15.6488575 3.04912005,16.1733553 C3.29377581,16.6980213 4.25184178,16.9346893 5.64723739,16.8630198 Z M17.998801,11.8788388 C17.9342721,8.62105818 15.2732265,6 12,6 C8.6862915,6 6,8.6862915 6,12 C6,13.8017545 6.79417414,15.4180196 8.05156374,16.5178367 C9.61918795,16.1740501 11.4219878,15.5791568 13.2667642,14.7189234 C15.1123744,13.8583012 16.727532,12.8591526 17.998801,11.8788388 Z M17.3040733,14.8073455 C16.3274273,15.4175193 15.2552552,15.9984306 14.1120008,16.5315389 C12.9695057,17.0642931 11.8360704,17.5119809 10.7415499,17.8678087 C11.1473536,17.9544194 11.5683538,18 12,18 C14.2994717,18 16.2968203,16.7064576 17.3040733,14.8073455 Z"
    />
  </BaseIcon>
)
