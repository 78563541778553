import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Creditcard = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M21,8 L21,5 L3,5 L3,8 L21,8 Z M21,10 L3,10 L3,17 L21,17 L21,10 Z M2.81818182,3 L21.1818182,3 C22.1859723,3 23,3.8954305 23,5 L23,17 C23,18.1045695 22.1859723,19 21.1818182,19 L2.81818182,19 C1.81402773,19 1,18.1045695 1,17 L1,5 C1,3.8954305 1.81402773,3 2.81818182,3 Z"
    />
  </BaseIcon>
)
