import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const InfinitySymbol = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M13.8953301,9.49438552 C13.5245249,8.8053229 13.1631795,8.23232447 12.787113,7.76330109 C12.9146953,7.63245274 13.0230614,7.52301032 13.0881226,7.46104725 C14.1672616,6.43329581 15.2308059,6 17,6 C20.2160271,6 23,8.59837471 23,12 C23,15.4016253 20.2160271,18 17,18 C14.1474839,18 12.9356928,16.5161742 11.0880785,12.4103647 C9.56069278,9.0161742 8.73081718,8 7,8 C4.74909152,8 3,9.86806378 3,12 C3,14.1411158 4.73495862,16 7,16 C8.04331411,16 9.29028192,15.5849395 10.3793264,14.9973112 C10.7662435,15.6627411 11.149328,16.202911 11.5567767,16.631918 C10.1542402,17.4308849 8.51106941,18 7,18 C3.59837471,18 1,15.2160271 1,12 C1,8.79183891 3.61403375,6 7,6 C9.85251615,6 11.0643072,7.4838258 12.9119215,11.5896353 C14.4393072,14.9838258 15.2691828,16 17,16 C19.1411158,16 21,14.2650414 21,12 C21,9.73495862 19.1411158,8 17,8 C15.7489921,8 15.177938,8.23265169 14.467433,8.90932312 C14.3735045,8.99877883 14.1397256,9.24036553 13.8953301,9.49438552 Z"
    />
  </BaseIcon>
)
