import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const PlayerList = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M18,14.1404647 L18,9.13148291 L22.5547002,12.1679497 L21.4452998,13.8320503 L20,12.8685171 L20,16.5 C20,17.9534271 18.6045695,19 17,19 C15.3954305,19 14,17.9534271 14,16.5 C14,15.0465729 15.3954305,14 17,14 C17.3471248,14 17.6844618,14.0489806 18,14.1404647 Z M4,19 L4,17 L12,17 L12,19 L4,19 Z M4,15 L4,13 L12,13 L12,15 L4,15 Z M4,11 L4,9 L16,9 L16,11 L4,11 Z M4,7 L4,5 L19,5 L19,7 L4,7 Z M17,17 C17.6045695,17 18,16.7034271 18,16.5 C18,16.2965729 17.6045695,16 17,16 C16.3954305,16 16,16.2965729 16,16.5 C16,16.7034271 16.3954305,17 17,17 Z"
    />
  </BaseIcon>
)
