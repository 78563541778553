import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const SelectArea = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M13,8 L13,6 L19,6 C20.1045695,6 21,6.8954305 21,8 L21,19 C21,20.1045695 20.1045695,21 19,21 L8,21 C6.8954305,21 6,20.1045695 6,19 L6,13 L8,13 L8,19 L19,19 L19,8 L13,8 Z M6,6 L6,3 L8,3 L8,6 L11,6 L11,8 L8,8 L8,11 L6,11 L6,8 L3,8 L3,6 L6,6 Z"
    />
  </BaseIcon>
)
