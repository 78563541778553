import * as yup from 'yup'

export const documentForm = yup.object().shape({
  name: yup.string().trim().required('Please enter a name for this document.'),
  description: yup.string().trim(),
})
export type documentFormType = yup.InferType<typeof documentForm>

const magicUploadConfig = yup.object().shape({
  createNotes: yup.boolean(),
  category: yup.string(),
})
export const magicUpload = yup.object().shape({
  magicUploadConfig: magicUploadConfig,
})
export type magicUploadConfigType = yup.InferType<typeof magicUploadConfig>
export type magicUploadType = yup.InferType<typeof magicUpload>

export const zoomCloudRecording = yup.object().shape({
  fileId: yup.string().trim().required(),
  meetingId: yup.string().trim().required(),
  meetingUUID: yup.string().trim().required(),
  meetingTopic: yup.string().trim().required(),
  meetingDuration: yup.number(),
  recordingStart: yup.date(),
  recordingEnd: yup.date(),
  fileSize: yup.number(),
  fileType: yup.string().trim().required(),
  playURL: yup.string().trim().required(),
  downloadURL: yup.string().trim().required(),
  recordingType: yup.string(),
  category: yup.string().trim(),
})

export type zoomCloudRecordingType = yup.InferType<typeof zoomCloudRecording>

export const createZoomMeetingAsDocument = yup.object().shape({
  recordings: yup.array().of(zoomCloudRecording),
  categoryId: yup.string(),
})

export type createZoomMeetingAsDocumentType = yup.InferType<
  typeof createZoomMeetingAsDocument
>

export const document = yup.object().shape({
  name: yup.string().trim().required('Name is required.'),
  filename: yup.string().trim().required(),
  fullname: yup.string().required(),
  thumbnail: yup.string(),
  description: yup.string().trim(),
  url: yup.string().trim().url().required(),
  size: yup.number().required(),
  duration: yup.number(),
  mimetype: yup.string().trim().required(),
  category: yup.string(),
})
export type documentType = yup.InferType<typeof document>

export const transcribe = yup.object().shape({
  categoryId: yup.string().required(),
  projectId: yup.string().required(),
  documentId: yup.string(),
  userId: yup.string(),
  companyId: yup.string(),
  language: yup.string().required(),
})
export type transcribeType = yup.InferType<typeof transcribe>

export const textExtraction = yup.object().shape({
  categoryId: yup.string().required(),
  projectId: yup.string().required(),
  documentId: yup.string().notRequired(),
  userId: yup.string().notRequired(),
  companyId: yup.string().notRequired(),
})
export type textExtractionType = yup.InferType<typeof textExtraction>

export type processTranscribeType = {
  categoryId: string
  projectId: string
  documentId: string
  userId: string
  companyId: string
  language: string
  model: 'video' | 'phone_call' | 'default'
  useEnhanced: boolean
  diarization: boolean
  useAAI: boolean
}
