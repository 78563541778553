import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Options = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M9.12601749,6 C9.57006028,4.27477279 11.1361606,3 13,3 C15.209139,3 17,4.790861 17,7 C17,9.209139 15.209139,11 13,11 C11.1361606,11 9.57006028,9.72522721 9.12601749,8 L2,8 L2,6 L9.12601749,6 Z M4.12601749,16 C4.57006028,14.2747728 6.13616057,13 8,13 C10.209139,13 12,14.790861 12,17 C12,19.209139 10.209139,21 8,21 C6.13616057,21 4.57006028,19.7252272 4.12601749,18 L2,18 L2,16 L4.12601749,16 Z M14,18 L14,16 L22,16 L22,18 L14,18 Z M8,19 C9.1045695,19 10,18.1045695 10,17 C10,15.8954305 9.1045695,15 8,15 C6.8954305,15 6,15.8954305 6,17 C6,18.1045695 6.8954305,19 8,19 Z M19,8 L19,6 L22,6 L22,8 L19,8 Z M13,9 C14.1045695,9 15,8.1045695 15,7 C15,5.8954305 14.1045695,5 13,5 C11.8954305,5 11,5.8954305 11,7 C11,8.1045695 11.8954305,9 13,9 Z"
    />
  </BaseIcon>
)
