import { useEffect, useState, ReactNode, forwardRef } from 'react'
import styled from '@emotion/styled'
import { P, PProps } from './Typography'

const ErrorText = styled(P)`
  line-height: 1;
  font-size: 14px;
  min-height: 14px;
  color: var(--colors-typography-success);
  transition: opacity 0.5s;
  opacity: 0;

  &.fadeIn {
    opacity: 1;
  }
`

export const SuccessMessage = forwardRef(
  ({ children, ...props }: PProps, ref) => {
    const [message, setMessage] = useState<ReactNode>()
    useEffect(() => {
      if (children == null) {
        setTimeout(() => {
          setMessage(children)
        }, 500)
      } else {
        setMessage(children)
      }
    }, [children])

    return (
      <ErrorText
        ref={ref as any}
        className={children ? 'fadeIn' : ''}
        {...props}
      >
        {message}
      </ErrorText>
    )
  },
)
SuccessMessage.displayName = 'Success Message'
