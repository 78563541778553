import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const ArrowRightCurved = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M18.5857864,10 L14.2928932,5.70710678 L15.7071068,4.29289322 L22.4142136,11 L15.7071068,17.7071068 L14.2928932,16.2928932 L18.5857864,12 L8,12 C5.790861,12 4,13.790861 4,16 C4,18.209139 5.790861,20 8,20 L10.5,20 L10.5,22 L8,22 C4.6862915,22 2,19.3137085 2,16 C2,12.6862915 4.6862915,10 8,10 L18.5857864,10 Z"
    />
  </BaseIcon>
)
