import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const CallVoicemail = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M10.0003662,15 L13.9996338,15 C13.3719448,14.1643395 13,13.1256059 13,12 C13,9.23857625 15.2385763,7 18,7 C20.7614237,7 23,9.23857625 23,12 C23,14.7614237 20.7614237,17 18,17 L6,17 C3.23857625,17 1,14.7614237 1,12 C1,9.23857625 3.23857625,7 6,7 C8.76142375,7 11,9.23857625 11,12 C11,13.1256059 10.6280552,14.1643395 10.0003662,15 Z M6,15 C7.65685425,15 9,13.6568542 9,12 C9,10.3431458 7.65685425,9 6,9 C4.34314575,9 3,10.3431458 3,12 C3,13.6568542 4.34314575,15 6,15 Z M18,15 C19.6568542,15 21,13.6568542 21,12 C21,10.3431458 19.6568542,9 18,9 C16.3431458,9 15,10.3431458 15,12 C15,13.6568542 16.3431458,15 18,15 Z"
    />
  </BaseIcon>
)
