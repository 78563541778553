import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const TextAlignCenter = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M19,17 L19,19 L5,19 L5,17 L19,17 Z M22,13 L22,15 L2,15 L2,13 L22,13 Z M19,9 L19,11 L5,11 L5,9 L19,9 Z M22,5 L22,7 L2,7 L2,5 L22,5 Z"
    />
  </BaseIcon>
)
