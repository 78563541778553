import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const EditShape = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M21,16.1707057 C22.1651924,16.5825421 23,17.6937812 23,19 C23,20.6568542 21.6568542,22 20,22 C18.6937812,22 17.5825421,21.1651924 17.1707057,20 L6.82929429,20 C6.41745788,21.1651924 5.30621883,22 4,22 C2.34314575,22 1,20.6568542 1,19 C1,17.6937812 1.83480763,16.5825421 3,16.1707057 L3,7.82929429 C1.83480763,7.41745788 1,6.30621883 1,5 C1,3.34314575 2.34314575,2 4,2 C5.30621883,2 6.41745788,2.83480763 6.82929429,4 L17.1707057,4 C17.5825421,2.83480763 18.6937812,2 20,2 C21.6568542,2 23,3.34314575 23,5 C23,6.30621883 22.1651924,7.41745788 21,7.82929429 L21,16.1707057 Z M19,16.1707057 L19,7.82929429 C18.1475866,7.52800937 17.4719906,6.85241345 17.1707057,6 L6.82929429,6 C6.52800937,6.85241345 5.85241345,7.52800937 5,7.82929429 L5,16.1707057 C5.85241345,16.4719906 6.52800937,17.1475866 6.82929429,18 L17.1707057,18 C17.4719906,17.1475866 18.1475866,16.4719906 19,16.1707057 Z M4,6 C4.55228475,6 5,5.55228475 5,5 C5,4.44771525 4.55228475,4 4,4 C3.44771525,4 3,4.44771525 3,5 C3,5.55228475 3.44771525,6 4,6 Z M20,6 C20.5522847,6 21,5.55228475 21,5 C21,4.44771525 20.5522847,4 20,4 C19.4477153,4 19,4.44771525 19,5 C19,5.55228475 19.4477153,6 20,6 Z M4,20 C4.55228475,20 5,19.5522847 5,19 C5,18.4477153 4.55228475,18 4,18 C3.44771525,18 3,18.4477153 3,19 C3,19.5522847 3.44771525,20 4,20 Z M20,20 C20.5522847,20 21,19.5522847 21,19 C21,18.4477153 20.5522847,18 20,18 C19.4477153,18 19,18.4477153 19,19 C19,19.5522847 19.4477153,20 20,20 Z"
    />
  </BaseIcon>
)
