import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const MusicNote = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M20,10.1804604 L10,11.8471271 L10,18.5 C10,20.477434 8.17292083,22 6,22 C3.82707917,22 2,20.477434 2,18.5 C2,16.522566 3.82707917,15 6,15 C6.72317475,15 7.40804269,15.1686455 8.00000003,15.4665774 L8.00000003,4.15287291 L22,1.81953958 L22,17.5 C22,19.477434 20.1729208,21 18,21 C15.8270792,21 14,19.477434 14,17.5 C14,15.522566 15.8270792,14 18,14 C18.7231748,14 19.4080427,14.1686455 20,14.4665774 L20,10.1804604 Z M6,20 C7.14078767,20 8,19.2839897 8,18.5 C8,17.7160103 7.14078767,17 6,17 C4.85921233,17 4,17.7160103 4,18.5 C4,19.2839897 4.85921233,20 6,20 Z M18,19 C19.1407877,19 20,18.2839897 20,17.5 C20,16.7160103 19.1407877,16 18,16 C16.8592123,16 16,16.7160103 16,17.5 C16,18.2839897 16.8592123,19 18,19 Z M10,5.84712709 L10,9.81953958 L20,8.15287291 L20,4.18046042 L10,5.84712709 Z"
    />
  </BaseIcon>
)
