import * as yup from 'yup'

export const removeURLs = (name: string): string => {
  const exp =
    /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi
  return name.replace(exp, '')
}

export const purchase = yup.object().shape({
  acceptedCustomerTOS: yup
    .boolean()
    .required()
    .oneOf([true], 'You must accept the Terms of Service.'),
  billing: yup
    .string()
    .trim()
    .required('Billing Cycle is required.')
    .oneOf(['yearly', 'monthly'], 'Invalid Billing Cycle'),
  company: yup
    .string()
    .trim()
    .required('Company is required.')
    .transform(removeURLs),
  coupon: yup.string().trim(),
  email: yup
    .string()
    .email()
    .lowercase()
    .trim()
    .required('A valid email address required.'),
  firstname: yup
    .string()
    .trim()
    .required('First Name is required.')
    .transform(removeURLs),
  lastname: yup
    .string()
    .trim()
    .required('Last Name is required.')
    .transform(removeURLs),
  password: yup
    .string()
    .trim()
    .min(8)
    .required('Password must be at least 8 characters.'),
  plan: yup
    .string()
    .trim()
    .required('Plan Name is required.')
    .oneOf(['enterprise', 'premium', 'pro'], 'Invalid Billing Cycle'),
  token: yup
    .object()
    .shape({
      id: yup.string().trim().required('Stripe Token field "id" is required.'),
      card: yup
        .object()
        .shape({
          id: yup
            .string()
            .trim()
            .required('Stripe Token card field "id" is required.'),
          brand: yup
            .string()
            .trim()
            .required('Stripe Token card field "brand" is required.'),
          exp_month: yup
            .number()
            .required('Stripe Token card field "exp_month" is required.'),
          exp_year: yup
            .number()
            .required('Stripe Token card field "exp_year" is required.'),
          last4: yup
            .string()
            .required('Stripe Token field "last4" is required.'),
        })
        .required('Stripe token card is required'),
    })
    .required('Stripe Token is required.'),
  au_password: yup.string().notRequired(),
  recaptcha: yup.string().trim().required('reCaptcha token is required.'),
})

export type purchaseType = yup.InferType<typeof purchase>
