import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const ListenLater = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M10,17 L10,19 L3,19 C1.8954305,19 1,18.1045695 1,17 L1,3 C1,1.8954305 1.8954305,1 3,1 L17,1 C18.1045695,1 19,1.8954305 19,3 L19,10 L17,10 L17,3 L3,3 L3,17 L10,17 Z M8,7 L12,10 L8,13 L8,7 Z M17,23 C13.6862915,23 11,20.3137085 11,17 C11,13.6862915 13.6862915,11 17,11 C20.3137085,11 23,13.6862915 23,17 C23,20.3137085 20.3137085,23 17,23 Z M17,21 C19.209139,21 21,19.209139 21,17 C21,14.790861 19.209139,13 17,13 C14.790861,13 13,14.790861 13,17 C13,19.209139 14.790861,21 17,21 Z M16,18 L16,14 L18,14 L18,16 L20,16 L20,18 L16,18 Z"
    />
  </BaseIcon>
)
