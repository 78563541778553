import * as yup from 'yup'
import { SuggestedUsesArray } from '../interfaces/HashtagCounts'

const optionsSchema = yup.array().of(yup.string().oneOf(SuggestedUsesArray))
export const tagSchema = yup.object().shape({
  id: yup.string().optional(),
  name: yup.string().required(),
  description: yup.string().optional(),
  foregroundColor: yup.string().optional(),
  backgroundColor: yup.string().optional(),
  suggestedUses: optionsSchema.optional(),
})
export type tagType = yup.InferType<typeof tagSchema>
