import * as yup from 'yup'

export const newTeamMember = yup.object().shape({
  email: yup
    .string()
    .email()
    .lowercase()
    .trim()
    .required('A valid email address required.'),
  role: yup
    .string()
    .trim()
    .required('Any admin role required.')
    .oneOf(
      ['admin', 'team_member', 'read_only'],
      'Please submit a valid role.',
    ),
})

export type newTeamMemberType = yup.InferType<typeof newTeamMember>
