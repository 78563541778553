import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const ArrowsDiagonalsTlbr = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <polygon
      fillRule="evenodd"
      points="6.414 5 19 17.586 19 14 21 14 21 21 14 21 14 19 17.586 19 5 6.414 5 10 3 10 3 3 10 3 10 5"
    />
  </BaseIcon>
)
