import styled from '@emotion/styled'

export const PlayerContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  margin-top: 16px;
  margin-bottom: 16px;
`

export const PlayerTrackContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  padding-left: 12px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
`

export const PlayerTimer = styled.div`
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.14px;
  color: var(--colors-typography-meta);
  margin-top: 6px;
  margin-left: 6px;
  align-self: flex-end;
`

export const PlayerTrackBackground = styled.div`
  align-self: center;
  height: 4px;
  flex: 1;
  width: 100%;
  background-color: #d7dddf;
`

export const PlayerTrackElapsed = styled.div`
  align-self: center;
  height: 4px;
  flex: 1;
  background-color: var(--colors-inputs-text-color);
  position: absolute;
  top: 0;
  left: 0;
`

export const PlayerTrackPlayButton = styled.div`
  width: 24px;
  height: 24px;
  background-color: #ffffff;
  border-radius: 50%;
  position: absolute;
  top: -12px;
  left: -12px;
`
