import styled from '@emotion/styled'
import { boxProps } from './Box'
import { BoxProps } from '@chakra-ui/react'
import { pick } from '@styled-system/props'

const StyledBox = styled.hr<BoxProps>`
  margin: 0px;
  border: 1px solid var(--colors-hr);
  border-bottom: none;
  height: 1px;

  ${(props) => boxProps(props)};
`
export const HR = ({
  children,
  ...props
}: React.PropsWithChildren<BoxProps>) => (
  <StyledBox {...pick(props)}>{children}</StyledBox>
)
HR.displayName = 'HR'
