import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const BlockAlignBottom = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M14.1428571,19 L9.85714286,19 C8.79431889,19 8,18.0732946 8,17 L8,4 C8,2.92670537 8.79431889,2 9.85714286,2 L14.1428571,2 C15.2056811,2 16,2.92670537 16,4 L16,17 C16,18.0732946 15.2056811,19 14.1428571,19 Z M14,4 L10,4 L10,17 L14,17 L14,4 Z M2,22 L2,20 L22,20 L22,22 L2,22 Z"
    />
  </BaseIcon>
)
