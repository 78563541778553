import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const CheckMarks = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M10.6664523,15.0943204 L12.0462428,13.6932433 L12.5459105,14.1998821 L20.6192054,6 L22,7.40005893 L12.5459105,17 L10.6664523,15.0943204 Z M6.54591047,14.1998821 L14.6192054,6 L16,7.40005893 L6.54591047,17 L1.97366706,12.3500147 L3.35446166,10.9499558 L6.54591047,14.1998821 Z"
    />
  </BaseIcon>
)
