import { forwardRef } from 'react'
import ReactSelect, { Props as SelectProps } from 'react-select'
import styled from '@emotion/styled'
import { Box, BoxProps } from '@chakra-ui/react'
import { pick, omit } from '@styled-system/props'
import { MemoFDE } from './MemoFDE'

const Wrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'backgroundWhite',
})<{
  backgroundWhite?: boolean
}>`
  .au-select__menu {
    margin: 0;
    border: 2px solid var(--colors-inputs-text-border);
    border-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  /* font reset */
  .au-select__menu,
  .au-select__placeholder,
  .au-select__input,
  .au-select__option,
  .au-select__menu-notice {
    font-family: var(--font-roboto);
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: var(--colors-typography-labels);
  }

  .au-select__input,
  .au-select__option,
  .au-select__indicator,
  .au-select__single-value,
  .au-select__indicator:hover {
    color: var(--colors-inputs-text-color);
  }

  .au-select__control,
  .au-select__control:hover {
    background-color: ${({ backgroundWhite }) =>
      backgroundWhite
        ? 'var(--colors-layout-background)'
        : `var(--colors-custom-button-background)`};
    box-shadow: none;
    border-radius: 8px;
    border: 2px solid var(--colors-inputs-text-border);
    min-height: 37px;
    transition-property: none !important;
  }
  .au-select__dropdown-indicator {
    svg {
      display: none;
    }
    &:before {
      content: '';
      cursor: pointer;
      margin-right: 8px;
      width: 0;
      height: 0;
      border-top: 7px solid transparent;
      border-bottom: 7px solid transparent;
      border-left: 7px solid var(--colors-inputs-text-color);
      display: inline-block;
      transition: transform 0.2s;
      transform: rotate(90deg);
    }
  }

  .au-select__menu {
    background-color: var(--colors-dropdowns-open-background);
    color: var(--colors-dropdowns-open-text);
    z-index: 2;
    border-width: 1px;
  }
  .au-select__option--is-selected {
    background-color: var(--colors-dropdowns-open-background);
    color: var(--colors-dropdowns-open-text);
    font-weight: bold;
  }
  .au-select__option--is-focused {
    background-color: var(--colors-dropdowns-open-text);
    color: var(--colors-dropdowns-open-background);
  }

  .au-select__control--menu-is-open {
    .au-select__indicator:before {
      transform: rotate(-90deg);
    }
  }

  .au-select__indicator-separator {
    display: none;
  }

  .au-select__control {
    margin: 0 -1px;
  }

  .au-select__control--menu-is-open,
  .au-select__control--menu-is-focused {
    box-shadow: none;

    &.au-select__control {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border: 2px solid var(--colors-inputs-text-border);
    }
  }
`

export type LabelOf<T> = { label: string; value: T }

type Props = SelectProps<any> &
  Omit<BoxProps, 'onChange' | 'instanceId'> & {
    backgroundWhite?: boolean
  }

const component = forwardRef<SelectProps<any>, Props>(
  ({ onChange, styles, ...props }, ref) => {
    return (
      <Wrapper {...pick(props)} backgroundWhite={props.backgroundWhite}>
        <ReactSelect
          ref={ref as any}
          classNamePrefix="au-select"
          isSearchable={false}
          onChange={onChange}
          value={props.value}
          id="custom-select"
          instanceId="custom-select"
          inputId="custom-select"
          {...omit(props)}
          styles={styles}
        />
      </Wrapper>
    )
  },
)
component.displayName = 'Select'

export const Select = MemoFDE<Props>(component)
