import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const WiFi = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M10.4098082,17.5857864 C11.1908568,16.8047379 12.4571868,16.8047379 13.2382353,17.5857864 L11.8240218,19 L10.4098082,17.5857864 Z M7.58138109,14.7573593 C9.92452684,12.4142136 13.7235167,12.4142136 16.0666625,14.7573593 L14.6524489,16.1715729 C13.0903517,14.6094757 10.5576918,14.6094757 8.99559466,16.1715729 L7.58138109,14.7573593 Z M4.75295397,11.9289322 C8.65819689,8.02368927 14.9898467,8.02368927 18.8950896,11.9289322 L17.480876,13.3431458 C14.3566817,10.2189514 9.29136187,10.2189514 6.16716753,13.3431458 L4.75295397,11.9289322 Z M2,9.17597822 C2.02485621,9.15074942 2.04982752,9.12559148 2.07491394,9.10050506 C7.5171676,3.6582514 16.325289,3.63328009 21.7984307,9.02559113 L20.3841705,10.4398513 C15.692124,5.82856146 8.1503289,5.85351722 3.4891275,10.5147186 C3.4640374,10.5398087 3.43908163,10.5649823 3.4142602,10.5902384 L2,9.17597822 Z"
    />
  </BaseIcon>
)
