import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const ArrowBoldForward = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M12,8.02302014 L12,2.79623177 L22.5185923,12 L12,21.2037682 L12,15.8718462 C8.69020572,15.5189215 5.9539313,16.4602823 3.70710678,18.7071068 L2,20.4142136 L2,18 C2,11.7344566 5.45133717,8.33140969 12,8.02302014 Z M14,7.20376823 L14,10 L13,10 C7.79936011,10 4.94953337,11.8510227 4.20125412,15.7165365 C6.74442804,14.0145027 9.74981728,13.4445091 13.164399,14.0136061 L14,14.1528729 L14,16.7962318 L19.4814077,12 L14,7.20376823 Z"
    />
  </BaseIcon>
)
