import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Egg = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M12,23 C6.97700227,23 3,19.2950831 3,14 C3,7.95683978 7.24064128,1 12,1 C16.7593587,1 21,7.95683978 21,14 C21,19.2950831 17.0229977,23 12,23 Z M12,21 C15.9488564,21 19,18.1575994 19,14 C19,8.91903239 15.3919691,3 12,3 C8.60803093,3 5,8.91903239 5,14 C5,18.1575994 8.05114361,21 12,21 Z"
    />
  </BaseIcon>
)
