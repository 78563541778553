import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const ArrowBottom = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <polygon
      fillRule="evenodd"
      points="11 18.586 11 3 13 3 13 18.586 19.293 12.293 20.707 13.707 12 22.414 3.293 13.707 4.707 12.293"
    />
  </BaseIcon>
)
