import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const ChevronTop = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <polygon
      fillRule="evenodd"
      points="12 9.414 4.707 16.707 3.293 15.293 12 6.586 20.707 15.293 19.293 16.707"
    />
  </BaseIcon>
)
