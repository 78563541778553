import * as yup from 'yup'

export const newClip = yup.object().shape({
  name: yup.string().required('Name is required.'),
  description: yup.string(),
  startTime: yup.string().required(),
  endTime: yup.string().required(),
  startValue: yup.number().required(),
  endValue: yup.number().required(),
  tags: yup.array().of(yup.string().required()),
  newTags: yup.array().of(yup.string().required()),
  documentId: yup.string().required(),
  mimetype: yup.string().trim().required(),
})

export type newClipType = yup.InferType<typeof newClip>

export const arrayOfClips = yup.object().shape({
  clips: yup.array().of(yup.string().required()).required(),
})
export type arrayOfClipsType = yup.InferType<typeof arrayOfClips>

export const editClip = yup.object().shape({
  name: yup.string().required('Name is required.'),
  description: yup.string(),
  attitude: yup.boolean(),
  startTime: yup.string(),
  endTime: yup.string(),
  startValue: yup.number(),
  endValue: yup.number(),
  documentId: yup.string(),
})

export type editClipType = yup.InferType<typeof editClip>
