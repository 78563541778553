import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const StarNo = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M6.85129847,8.26551204 L1.29289322,2.70710678 L2.70710678,1.29289322 L22.7071068,21.2928932 L21.2928932,22.7071068 L18.7302536,20.1444672 L19.2059835,22.9181855 L12,19.1297757 L4.79401645,22.9181855 L6.17023685,14.8942049 L0.340473695,9.21159025 L6.85129847,8.26551204 Z M16.2809639,17.6951775 L8.61589226,10.0301058 L4.63839598,10.6080699 L8.31919799,14.1959651 L7.4502785,19.2621544 L12,16.8702243 L16.5497215,19.2621544 L16.2809639,17.6951775 Z M9.67190261,5.45768909 L12,0.740448538 L15.6029918,8.04090726 L23.6595263,9.21159025 L18.4772625,14.263049 L17.0629334,12.8487199 L19.361604,10.6080699 L14.2748607,9.86892279 L12,5.25955146 L11.165218,6.9510045 L9.67190261,5.45768909 Z"
    />
  </BaseIcon>
)
