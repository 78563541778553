import { BaseIcon, SVGProps } from '@aurelius/icons'

export const RemoveIconSq = ({ ...rest }: SVGProps) => (
  <BaseIcon title="Remove" width={17} height={17} viewBox="0 0 17 17" {...rest}>
    <g fill="none" fillRule="evenodd">
      <path fill="#00678D" d="M12 8v2H5V8z" />
      <path
        d="M14 16H3a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h11a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2z"
        stroke="#00678D"
        strokeWidth="1.5"
      />
    </g>
  </BaseIcon>
)
