import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Shuffle = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M17.5857864,18 L17,18 C15.0670171,17.9820312 13.8323189,17.4178969 12.7754822,16.1071521 C13.1678375,15.5430536 13.5361529,14.9169672 13.8909426,14.2284294 C14.7312903,15.5397262 15.5623613,15.9865721 17.0093097,16.0000433 L17.5858172,16.0000308 L16.2928932,14.7071068 L17.7071068,13.2928932 L21.4142136,17 L17.7071068,20.7071068 L16.2928932,19.2928932 L17.5857864,18 Z M17.5858172,7.99996921 L17.0093097,7.99995666 C14.98914,8.01876471 14.1694753,8.8823321 12.8489735,11.7638223 C12.7005893,12.0884485 12.6216698,12.260889 12.5477909,12.4205132 C10.7759025,16.2488903 8.96370516,17.9389258 5,18 L3,18 L3,16 L4.98456341,16.0001192 C7.99778944,15.9535997 9.24866011,14.7870513 10.7327652,11.5804646 C10.8045503,11.4253643 10.8813338,11.257591 11.0308005,10.9306074 C12.6437651,7.41092901 13.9561072,6.02829571 17,6 L17.5857864,6 L16.2928932,4.70710678 L17.7071068,3.29289322 L21.4142136,7 L17.7071068,10.7071068 L16.2928932,9.29289322 L17.5858172,7.99996921 Z M9.2967591,9.9151484 C8.25385189,8.57493725 7.02213357,8.03133773 4.98456341,7.99988085 L3,8 L3,6 L5,6 C7.39017643,6.03682871 8.99800451,6.66599321 10.2864139,7.99918708 C9.95038887,8.57240941 9.6245452,9.21039491 9.2967591,9.9151484 Z"
    />
  </BaseIcon>
)
