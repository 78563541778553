import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Dribbble = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M6.37024755,19.02223 C7.91177504,20.2596715 9.86943103,21 12,21 C13.213711,21 14.3713086,20.7597497 15.4278302,20.3242117 C15.0688066,18.1639011 14.4112304,15.9334157 13.4539272,13.6332362 C10.7665276,14.4645012 8.41444743,16.2568027 6.37024755,19.02223 Z M4.94537077,17.5891532 C7.15164736,14.6878216 9.72193205,12.7532774 12.6325786,11.7959386 C12.4448702,11.4027705 12.2486695,11.0076333 12.0439709,10.6105294 C11.5271489,10.7485691 10.9913218,10.8677867 10.4372917,10.9690696 C8.20661024,11.3768631 6.27310058,11.4562801 3.13697726,11.4114627 C3.09582824,11.4108746 3.05656018,11.4103145 3.01904425,11.4097809 C3.00641345,11.604892 3,11.801697 3,12 C3,14.1119087 3.72741719,16.0539201 4.94537077,17.5891532 Z M3.37689717,9.41451485 C6.26498429,9.45100021 8.05997751,9.37052393 10.0776299,9.00167452 C10.4129822,8.94036837 10.7396967,8.87208575 11.0576345,8.79662195 C10.1532917,7.21461873 9.11789334,5.60236744 7.95110118,3.96000653 C5.7652218,5.06297111 4.09048941,7.03113827 3.37689717,9.41451485 Z M9.89553348,3.24738101 C11.0661057,4.92295542 12.1063577,6.57259223 13.0159672,8.19642332 C14.9327167,7.45520186 16.4205398,6.35363006 17.4343231,4.82524991 C15.9242974,3.67974306 14.0415299,3 12,3 C11.2753161,3 10.570637,3.08565062 9.89553348,3.24738101 Z M18.9010854,6.22255541 C17.6940228,7.91484328 16.0253598,9.13959252 13.9645035,9.97356535 C14.190438,10.4191954 14.4062909,10.8628397 14.6120555,11.3045008 C16.5981958,10.9638104 18.7255297,11.0357894 20.987591,11.5232865 C20.8825304,9.51014314 20.1159356,7.67213987 18.9010854,6.22255541 Z M20.8677595,13.5457833 C18.934348,13.0965666 17.126601,12.9810399 15.4388151,13.201716 C16.279813,15.2769452 16.8919849,17.3073313 17.2745871,19.2931789 C19.1352596,17.9451571 20.459817,15.9025253 20.8677595,13.5457833 Z M12,23 C5.92486775,23 1,18.0751322 1,12 C1,5.92486775 5.92486775,1 12,1 C18.0751322,1 23,5.92486775 23,12 C23,18.0751322 18.0751322,23 12,23 Z"
    />
  </BaseIcon>
)
