import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const VideoNo = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M5.58578644,7 L3,7 L3,17 L15,17 L15,16.4142136 L5.58578644,7 Z M16.6766123,18.0908259 C16.3197659,18.6381832 15.7021364,19 15,19 L3,19 C1.8954305,19 1,18.1045695 1,17 L1,7 C1,5.8954305 1.8954305,5 3,5 L3.58578644,5 L0.292893219,1.70710678 L1.70710678,0.292893219 L23.7071068,22.2928932 L22.2928932,23.7071068 L16.6766123,18.0908259 Z M17,8.38196601 L23,5.38196601 L23,18.370101 L21,16.370101 L21,8.61803399 L17,10.618034 L17,13.0007865 L15,11.0007865 L15,7 L10.9992135,7 L8.99921354,5 L15,5 C16.1045695,5 17,5.8954305 17,7 L17,8.38196601 Z"
    />
  </BaseIcon>
)
