import * as yup from 'yup'

export const saml = yup.object().shape({
  ssoLoginUrl: yup
    .string()
    .trim()
    .required('SSO Login URL is required.')
    .url('URL is invalid.'),
  ssoLogoutUrl: yup
    .string()
    .trim()
    .required('SSO Logout URL is required.')
    .url('URL is invalid.'),
  audience: yup.string().trim(),
  metadata: yup
    .string()
    .trim()
    .required('SSO Metadata URL is required.')
    .url('URL is invalid.'),
  certificates: yup.array().of(yup.string().trim().required()).required(),
  ssoOnly: yup.boolean().required(),
})
export type samlType = yup.InferType<typeof saml>

export const samlResponse = yup.object().shape({
  SAMLResponse: yup
    .string()
    .trim()
    .required('request must contain a valid Base64 Encoded SAMLResponse.'),
})
export type samlResponseType = yup.InferType<typeof samlResponse>
