import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const BatteryNo = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M4.58578644,6 L1.29289322,2.70710678 L2.70710678,1.29289322 L22.7071068,21.2928932 L21.2928932,22.7071068 L16.5857864,18 L3,18 C1.8954305,18 1,17.1045695 1,16 L1,8 C1,6.8954305 1.8954305,6 3,6 L4.58578644,6 Z M14.5857864,16 L6.58578644,8 L3,8 L3,16 L14.5857864,16 Z M10.2071068,6 L19,6 C20.1045695,6 21,6.8954305 21,8 L21,9 C22.1045695,9 23,9.8954305 23,11 L23,13 C23,14.1045695 22.1045695,15 21,15 L21,16 C21,16.2371836 20.9587128,16.4647241 20.8829334,16.6758266 L19,14.7928932 L19,8 L12.2071068,8 L10.2071068,6 Z"
    />
  </BaseIcon>
)
