import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Hash = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M10.2,10 L9.8,14 L13.8,14 L14.2,10 L10.2,10 Z M10.4,8 L14.4,8 L15,2 L17,2 L16.4,8 L21.0000061,8 L20.8000061,10 L16.2,10 L15.8,14 L20.0000061,14 L19.8000061,16 L15.6,16 L15,22 L13,22 L13.6,16 L9.6,16 L9,22 L7,22 L7.6,16 L3.00000305,16 L3.20000305,14 L7.8,14 L8.2,10 L4.00000305,10 L4.20000305,8 L8.4,8 L9,2 L11,2 L10.4,8 Z"
    />
  </BaseIcon>
)
