import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const PlayerSkipForward = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M4,21 L4,3 C4,2.17595468 4.94076375,1.70557281 5.6,2.2 L17.6,11.2 C18.1333333,11.6 18.1333333,12.4 17.6,12.8 L5.6,21.8 C4.94076375,22.2944272 4,21.8240453 4,21 Z M6,19 L15.3333333,12 L6,5 L6,19 Z M20,22 L18,22 L18,2 L20,2 L20,22 Z"
    />
  </BaseIcon>
)
