import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Panorama = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M9,19 L9,20.8426999 C4.94412354,20.398287 2,19.032188 2,17 L2,7 C2,4.49324457 6.4797337,3 12,3 C17.5202663,3 22,4.49324457 22,7 L22,17 C22,19.032188 19.0558765,20.398287 15,20.8426999 L15,19 L15,15.1843625 C14.073728,15.068733 13.0611102,15 12,15 C10.9388898,15 9.92627201,15.068733 9,15.1843625 L9,19 Z M7,18.4679831 L7,10.5297167 C5.82938849,10.2848065 4.80865266,9.94790763 4,9.52769756 L4,17 C4,17.4615094 5.17069361,18.0493053 7,18.4679831 Z M17,18.4679831 C18.8293064,18.0493053 20,17.4615094 20,17 L20,9.52769756 C19.1913473,9.94790763 18.1706115,10.2848065 17,10.5297167 L17,18.4679831 Z M15,13.1573001 L15,8.81563752 C17.9328163,8.44952469 20,7.61326534 20,7 C20,6.19304693 16.4208592,5 12,5 C7.5791408,5 4,6.19304693 4,7 C4,7.61326534 6.0671837,8.44952469 9,8.81563752 L9,13.1573001 C9.94715003,13.0535184 10.9549285,13 12,13 C13.0450715,13 14.05285,13.0535184 15,13.1573001 Z"
    />
  </BaseIcon>
)
