import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const ThumbsDown = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M6.57086468,14.9173453 C6.38997052,14.9711236 6.19835748,15 6,15 L4,15 C2.8954305,15 2,14.1045695 2,13 L2,4 C2,2.8954305 2.8954305,2 4,2 L6,2 C6.68685475,2 7.29284112,2.34623869 7.65294381,2.87370078 C7.95642355,2.67736738 8.29504785,2.51351337 8.66312366,2.3818197 C9.50544052,2.08044754 10.3408482,1.9879107 10.9989281,1.99971266 C11.0150698,2.00005426 13.3487605,2.00015005 18,2 C20.7551411,2 22,6.05643086 22,12 C22,13.6824804 20.8695981,15 19,15 L15.9269768,15 C15.9359266,15.0245465 15.9451954,15.0497804 15.9547911,15.0757271 C16.007193,15.2174219 16.2391583,15.8256341 16.2970775,15.9810953 C16.4872151,16.4914441 16.6259612,16.9026411 16.7382242,17.3098712 C16.9079007,17.9253665 17,18.4798886 17,19 C17,20.4720069 16.091867,21.3862822 14.748281,21.777514 C14.1422801,21.9539723 13.6147967,22.0000922 13.0076976,22.0000008 L12.5204954,22 L12.2202699,21.6261158 C11.2556275,20.4248034 7.64562469,16.4822788 6.57086468,14.9173453 Z M8,13 C8,13.2401953 8.04443154,13.4528921 8.14171093,13.6800176 C8.40663864,14.2985643 11.914268,18.1371493 13.4599624,19.9848695 C13.7113931,19.9660111 13.9483736,19.9273712 14.1891338,19.8572655 C14.7621788,19.6904035 15,19.4509737 15,19 C15,18.6834183 14.9359344,18.2976852 14.8101464,17.8413935 C14.7161494,17.500423 14.5944107,17.1396301 14.4229225,16.6793379 C14.3689242,16.5344009 14.1358033,15.923159 14.0789589,15.7694517 C13.9489218,15.4178313 13.8632547,15.1589343 13.8046536,14.9236885 C13.5555377,13.9236468 13.8054549,13 15,13 L19,13 C19.6912225,13 20,12.6401099 20,12 C20,7.20925475 19.015127,4 18,4 C15.9322103,4 15.9322103,4 10.9655994,3.99940813 C10.5253746,3.99154158 9.91062406,4.05963657 9.33687634,4.2649175 C8.45196659,4.58152894 8,5.09776992 8,6 L8,13 Z M4,4 L4,13 L6,13 L6,4 L4,4 Z"
    />
  </BaseIcon>
)
