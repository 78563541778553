import { forwardRef } from 'react'
import { Box, BoxProps } from '@chakra-ui/react'
import { P, PProps } from './Typography'

export type CardProps = BoxProps

export const Card = forwardRef(({ ...rest }: BoxProps, ref) => (
  <Box
    background="var(--colors-card-background)"
    border="1px solid var(--colors-card-borders)"
    borderRadius="18.5px"
    ref={ref}
    {...rest}
  />
))

export const CardTitle = forwardRef(({ ...rest }: PProps, ref) => (
  <P
    fontSize="16px"
    color="var(--colors-typography-link)"
    fontWeight="bold"
    lineHeight="1"
    ref={ref as any}
    {...rest}
  />
))

CardTitle.displayName = 'CardTitle'
Card.displayName = 'Card'
export default Card
