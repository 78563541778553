import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const BellChecked = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M16.1623638,6.52190289 C15.2484077,4.90508719 13.6885768,4.01110567 12,4.01110567 C9.33276935,4.01110567 7,6.21768364 7,9.99618774 C7,13.1126682 6.29422173,15.0734373 4.95848591,16.5261413 C4.74843403,16.7545871 4.29607181,17.1742479 4,17.445401 L4,18.0068809 L20,18.0068809 L20,17.4449576 C19.701613,17.1705938 19.2506046,16.7505999 19.0401756,16.5208324 C18.6956998,16.1446992 18.3931009,15.7349284 18.1337183,15.2804953 L19.6209023,13.7933112 C19.8559203,14.3307891 20.1553816,14.7778511 20.5153799,15.1709333 C20.7591805,15.4371392 21.6159553,16.208066 21.7120353,16.3054582 L22,16.597356 L22,20.0060337 L15.8743256,20.0060337 C15.6241439,20.9903926 15.0074832,21.8525384 14.0878016,22.4135273 C12.8058555,23.1954909 11.1941445,23.1954909 9.91219841,22.4135273 C8.87009269,21.777862 8.29383594,21.067621 8.08797645,20.0060337 L2,20.0060337 L2,16.597356 L2.2879647,16.3054582 C2.39205094,16.2006412 3.24384208,15.4366433 3.48595854,15.1733251 C4.46898326,14.1042185 5,12.628966 5,9.99618774 C5,5.9271049 7.23535296,3.11712997 10.1353075,2.27538271 C10.2402118,2.00482729 10.405725,1.75331598 10.6305147,1.54220031 C11.4002733,0.819266563 12.5997267,0.819266563 13.3694853,1.54220031 C13.5947695,1.75378036 13.7605161,2.00593396 13.8653839,2.27716837 C15.3495938,2.71042047 16.6610442,3.66121675 17.5870192,5.03139509 L16.1623638,6.52190289 Z M10.1786171,20.0060337 C10.3199018,20.2687456 10.5607105,20.4671167 10.9540156,20.7070255 C11.596268,21.0987877 12.403732,21.0987877 13.0459844,20.7070255 C13.3433933,20.5256116 13.5757865,20.2855657 13.7367218,20.0060337 L10.1786171,20.0060337 Z M15,11.5857864 L19.2928932,7.29289322 L20.7071068,8.70710678 L15,14.4142136 L11.2928932,10.7071068 L12.7071068,9.29289322 L15,11.5857864 Z"
    />
  </BaseIcon>
)
