import { RectangleBox } from './RectangleBox'
import { Calendar } from '@aurelius/icons'
import { Box } from '@chakra-ui/react'
import { forwardRef } from 'react'
import dynamic from 'next/dynamic'
import { ReactDatePickerProps } from 'react-datepicker'
import { Spinner } from './Spinner'

const DatePicker = dynamic<ReactDatePickerProps<any>>(
  () => import('react-datepicker'),
  {
    loading: () => <Spinner />,
  },
)
export interface DatePickerProps {
  date: Date | undefined
  onChange: (date: Date) => void
  style?: { [key: string]: string }
  name?: string
}

export interface DateInputProps {
  value: string
  onClick: () => void
}

const DatePickerButton = ({ value, onClick }: DateInputProps) => {
  return (
    <RectangleBox
      onClick={onClick}
      style={{
        justifyContent: value ? 'space-between' : 'flex-end',
        width: '100%',
      }}
    >
      {value}{' '}
      <Calendar fill="var(--colors-inputs-text-color)" height={28} width={28} />
    </RectangleBox>
  )
}

export const DatePickerBox = forwardRef(
  ({ date, onChange, style, name }: DatePickerProps, ref) => (
    <Box style={style}>
      <DatePicker
        //@ts-ignore
        ref={ref}
        name={name}
        selected={date}
        onChange={onChange}
        dateFormat="MMM dd, yyyy"
        // @ts-ignore
        customInput={<DatePickerButton />}
        popperPlacement="left"
      />
    </Box>
  ),
)

DatePickerBox.displayName = 'DatePickerBox'
