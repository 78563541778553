import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Key = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M8,9 C8,5.13400675 11.1340068,2 15,2 C18.8659932,2 22,5.13400675 22,9 C22,12.8659932 18.8659932,16 15,16 L13,16 L13,18 L11,18 L11,20 L9,20 L9,22 L2,22 L2,16.5857864 L8.14800517,10.4377813 C8.0499525,9.96846804 8,9.48730703 8,9 Z M11,14 L15,14 C17.7614237,14 20,11.7614237 20,9 C20,6.23857625 17.7614237,4 15,4 C12.2385763,4 10,6.23857625 10,9 C10,9.49863276 10.0726899,9.98638049 10.2140786,10.4528677 L10.3878889,11.0263246 L4,17.4142136 L4,20 L7,20 L7,18 L9,18 L9,16 L11,16 L11,14 Z M15,11 C13.8954305,11 13,10.1045695 13,9 C13,7.8954305 13.8954305,7 15,7 C16.1045695,7 17,7.8954305 17,9 C17,10.1045695 16.1045695,11 15,11 Z"
    />
  </BaseIcon>
)
