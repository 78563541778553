import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Laughing = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M12,23 C5.92486775,23 1,18.0751322 1,12 C1,5.92486775 5.92486775,1 12,1 C18.0751322,1 23,5.92486775 23,12 C23,18.0751322 18.0751322,23 12,23 Z M12,21 C16.9705627,21 21,16.9705627 21,12 C21,7.02943725 16.9705627,3 12,3 C7.02943725,3 3,7.02943725 3,12 C3,16.9705627 7.02943725,21 12,21 Z M12.0320826,19 C8.67459233,19 6.80642529,17.2315963 6.80642529,14 L6.80642529,13 L17.1157851,13 L17.1434468,13.9715395 C17.2357791,17.2144552 15.4003421,19 12.0320826,19 Z M12.0320826,17 C13.9089434,17 14.8526173,16.3954957 15.0875358,15 L8.89676688,15 C9.18179352,16.3902342 10.1562705,17 12.0320826,17 Z M14,8 L17,8 L17,10 L14,10 L14,8 Z M7,8 L10,8 L10,10 L7,10 L7,8 Z"
    />
  </BaseIcon>
)
