import * as yup from 'yup'

export const projectSorting = yup.object().shape({
  sortBy: yup
    .string()
    .default('createdAt')
    .oneOf(['createdAt', 'updatedAt', 'name']),
  direction: yup.number().default(-1).integer().oneOf([-1, 1]),
  cursor: yup.number().default(0).moreThan(-1),
  search: yup.string().default('').trim(),
  paths: yup.string().default('').trim(),
  filters: yup.string().default('').trim(),
})
export type projectSortingType = yup.InferType<typeof projectSorting>
