import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Calculator = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M18,7 L18,3 L6,3 L6,7 L18,7 Z M18,9 L6,9 L6,21 L18,21 L18,9 Z M14,15 L14,14 L16,14 L16,15 L16,19 L14,19 L14,15 Z M6,1 L18,1 C19.1045695,1 20,1.8954305 20,3 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,3 C4,1.8954305 4.8954305,1 6,1 Z M10,19 L8,19 L8,17 L10,17 L10,19 Z M13,19 L11,19 L11,17 L13,17 L13,19 Z M10,16 L8,16 L8,14 L10,14 L10,16 Z M13,16 L11,16 L11,14 L13,14 L13,16 Z M10,13 L8,13 L8,11 L10,11 L10,13 Z M13,13 L11,13 L11,11 L13,11 L13,13 Z M16,13 L14,13 L14,11 L16,11 L16,13 Z"
    />
  </BaseIcon>
)
