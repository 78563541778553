import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const GridDynamic = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M11,4 L4,4 L4,20 L11,20 L11,10 L4,10 L4,8 L11,8 L11,4 Z M13,4 L13,14 L20,14 L20,16 L13,16 L13,20 L20,20 L20,4 L13,4 Z M4,2 L20,2 C21.1045695,2 22,2.8954305 22,4 L22,20 C22,21.1045695 21.1045695,22 20,22 L4,22 C2.8954305,22 2,21.1045695 2,20 L2,4 C2,2.8954305 2.8954305,2 4,2 Z"
    />
  </BaseIcon>
)
