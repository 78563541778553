import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const VerifiedCopy = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#000"
        d="M16,22 L16,20 L20,20 L20,16 L22,16 L22,20 C22,21.1045695 21.1859723,22 20.1818182,22 L16,22 Z M8,22 L3.81818182,22 C2.81402773,22 2,21.1045695 2,20 L2,16 L4,16 L4,20 L8,20 L8,22 Z M16,2 L20.1818182,2 C21.1859723,2 22,2.8954305 22,4 L22,8 L20,8 L20,4 L16,4 L16,2 Z M8,2 L8,4 L4,4 L4,8 L2,8 L2,4 C2,2.8954305 2.81402773,2 3.81818182,2 L8,2 Z"
      />
      <path
        stroke="#000"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M8 14C8 15 9 17 12 17 15 17 16 15 16 14M11.9970456 7C11.9970456 9.66666667 11.9980304 11.3333333 12 12 12 12.5 11.5 13 11 13 10.6666667 13 10.4309644 13 10.2928932 13"
      />
      <rect width="2" height="3" x="15" y="7" fill="#000" />
      <rect width="2" height="3" x="7" y="7" fill="#000" />
    </g>
  </BaseIcon>
)
