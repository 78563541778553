import * as yup from 'yup'
import { sorting } from './sorting'

export const updateJiraIntegration = yup.object().shape({
  email: yup
    .string()
    .trim()
    .email('Email address is not valid.')
    .required('Email is required.'),
  domain: yup.string().trim().required('Domain is required.'),
  api_token: yup
    .string()
    .trim()
    .required(
      'API token is required. Visit https://id.atlassian.com/manage/api-tokens to generate one.',
    ),
})

export type JiraIntegrationType = yup.InferType<typeof updateJiraIntegration>

export const companySorting = sorting.shape({
  paths: yup.string().default('').trim(),
  filter: yup.string().default('').trim(),
  tags: yup.string().default('').trim(),
  onlyGlobal: yup.boolean(),
})

export type CompanySortingType = yup.InferType<typeof companySorting>

export const zoomCode = yup
  .object()
  .shape({
    code: yup.string().trim().required(),
  })
  .required()
export type zoomCodeType = yup.InferType<typeof zoomCode>

export const zoomCloudRecordingsQuery = yup
  .object()
  .shape({
    userId: yup.string().trim().required(),
    from: yup.string().trim(),
    to: yup.string().trim(),
  })
  .required()
export type zoomCloudRecordingsQueryType = yup.InferType<
  typeof zoomCloudRecordingsQuery
>

export type zoomPayload = {
  payload: {
    // eslint-disable-next-line camelcase
    user_data_retention: boolean
    // eslint-disable-next-line camelcase
    user_id: string
  }
}
