import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const ArrowBottomRight = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <polygon
      fillRule="evenodd"
      points="19 17.586 19 10 21 10 21 21 10 21 10 19 17.586 19 4.293 5.707 5.707 4.293"
    />
  </BaseIcon>
)
