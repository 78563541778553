import { BaseIcon, SVGProps } from '@aurelius/icons'

export const SelectAllIcon = ({ ...rest }: SVGProps) => (
  <BaseIcon
    title="Select All"
    width={17}
    height={17}
    viewBox="0 0 17 17"
    {...rest}
  >
    <path
      d="M15.111 2.833c1.043 0 1.889.846 1.889 1.89V15.11A1.889 1.889 0 0 1 15.111 17H4.722a1.889 1.889 0 0 1-1.889-1.889V9.444h1.89v5.667H15.11V4.722H9.444V2.833h5.667zM4.722 0v2.833h2.834v1.89H4.722v2.833H2.833V4.722H0V2.833h2.833V0h1.89z"
      fill="#00678D"
      fillRule="evenodd"
    />
  </BaseIcon>
)
