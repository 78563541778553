import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Behance = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M10.0631951,11.5724638 C11.2238614,12.2726251 12,13.5456741 12,15 C12,17.209139 10.209139,19 8,19 L2,19 L2,11 L2,5 L7,5 C9.209139,5 11,6.790861 11,9 C11,9.9796381 10.6478342,10.8770235 10.0631951,11.5724638 Z M4,13 L4,17 L8,17 C9.1045695,17 10,16.1045695 10,15 C10,13.8954305 9.1045695,13 8,13 L7,13 L4,13 Z M17.6378174,17 C18.6782995,17 19.3176926,16.7872212 19.6590408,16.4669813 C19.8388519,16.2982894 19.9138794,16.1105966 19.9138794,16 L21.9138794,16 C21.9138794,17.6272028 20.4505955,19 17.6378174,19 C14.854599,19 13,17.1958448 13,14 C13,11.0338939 14.5805115,9 17.5,9 C20.4194885,9 22,11.0338939 22,14 L22,15 L15.088601,15 C15.3601187,16.3771121 16.2475191,17 17.6378174,17 Z M19.91463,13 C19.66628,11.690232 18.8733357,11 17.5,11 C16.1266643,11 15.33372,11.690232 15.08537,13 L19.91463,13 Z M14,8 L14,6 L21,6 L21,8 L14,8 Z M4,7 L4,11 L7,11 C8.1045695,11 9,10.1045695 9,9 C9,7.8954305 8.1045695,7 7,7 L4,7 Z"
    />
  </BaseIcon>
)
