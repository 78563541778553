import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const FileShredder = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M19,13 L19,9 L15,9 C13.8954305,9 13,8.1045695 13,7 L13,3 L5,3 L5,13 L3,13 L3,3 C3,1.8954305 3.8954305,1 5,1 L15.4142136,1 L21,6.58578644 L21,13 L19,13 Z M15,3.41421356 L15,7 L18.5857864,7 L15,3.41421356 Z M1,14 L23,14 L23,16 L1,16 L1,14 Z M5,23 L5,17 L7,17 L7,23 L5,23 Z M13,23 L13,17 L15,17 L15,23 L13,23 Z M9,21 L9,17 L11,17 L11,21 L9,21 Z M17,21 L17,17 L19,17 L19,21 L17,21 Z"
    />
  </BaseIcon>
)
