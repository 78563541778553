import styled from '@emotion/styled'
import { useEffect, useState } from 'react'
import useInView from 'react-cool-inview'
import { Box } from '@chakra-ui/react'
import { MemoFDE } from '../MemoFDE'
import { CloseWindow } from '../svg/CloseWindow'
import { PinPIcon } from '../svg/PinPIcon'

import ReactPlayer from 'react-player/lazy'

// import dynamic from 'next/dynamic'
// import { Spinner } from '../Spinner'

// const ReactPlayer = dynamic(() => import('react-player/lazy'), {
//   loading: () => <Spinner />,
// })

const ControlWrapper = styled.span`
  font-weight: bold;
  font-size: 12px;
  color: var(--colors-inputs-text-color);
  padding-left: 20px;
`

const Wrapper = styled(Box)`
  width: 100%;
  max-width: 400px;
  position: relative;

  .react-player-fixed {
    position: fixed;
    z-index: 100;
    bottom: 50px;
    right: 50px;
  }

  .react-player {
    position: relative;
    top: 0;
    left: 0;
  }
`

export interface AudioPreviewProps {
  file: any
  couldPIP?: boolean
  playing?: boolean
}

export const AudioPreview = MemoFDE(
  ({ file, couldPIP = false, playing = false }: AudioPreviewProps) => {
    const [isPlaying, setIsPlaying] = useState(playing)
    const [isToggle, setIsToggle] = useState(false)
    const [showToggle, setShowToggle] = useState(true)
    const [progress, setProgress] = useState(0)
    const [shouldPlay, setShouldPlay] = useState(true)
    const [wasClosed, setWasClosed] = useState(false)

    const { observe: observeTop, inView: inViewTop } = useInView({
      threshold: 0.25,
      onChange: ({ observe }) => {
        observe()
      },
    })

    const { observe: observeBottom, inView: inViewBottom } = useInView({
      threshold: 0.25,
      onChange: ({ observe }) => {
        observe()
      },
    })

    useEffect(() => {
      setIsPlaying(playing)
      if (playing) {
        setWasClosed(false)
      }
    }, [playing])

    useEffect(() => {
      if (isToggle) {
        setShowToggle(true)
        setShouldPlay(false)
      } else {
        if (!inViewTop && !inViewBottom) {
          setIsToggle(false)
          setShowToggle(false)
        } else {
          setShowToggle(true)
          setShouldPlay(true)
        }
      }
    }, [inViewTop, inViewBottom, isToggle])

    const shouldFixed =
      ((progress > 0 && !inViewTop && !inViewBottom && shouldPlay) ||
        isToggle) &&
      !wasClosed

    return (
      <Wrapper>
        <div ref={observeTop} />
        <div
          className={shouldFixed ? 'react-player-fixed' : 'react-player'}
          style={!shouldFixed ? { width: '100%', height: '100%' } : undefined}
        >
          {showToggle && couldPIP && (
            <div>
              <ControlWrapper
                onClick={() => setIsToggle(!isToggle)}
                style={{
                  cursor: 'pointer',
                }}
              >
                <PinPIcon
                  fill="var(--colors-inputs-text-color)"
                  height={16}
                  width={16}
                />{' '}
                Picture-in-Picture
              </ControlWrapper>
            </div>
          )}
          {shouldFixed && !isToggle && (
            <Box
              height="28px"
              width="28px"
              backgroundColor="var(--colors-hint-close-button)"
              borderRadius="50%"
              position="relative"
              top="5px"
              left="-10px"
            >
              <CloseWindow
                style={{
                  cursor: 'pointer',
                  position: 'absolute',
                  top: '2px',
                  right: '2px',
                }}
                fill="var(--colors-buttons-primary-text)"
                onClick={() => {
                  setShouldPlay(false)
                  setIsPlaying(false)
                  setWasClosed(true)
                }}
              />
            </Box>
          )}
          <ReactPlayer
            url={file}
            config={{
              file: {
                forceAudio: true,
              },
            }}
            playing={isPlaying}
            width={shouldFixed ? '400px' : '100%'}
            height={shouldFixed ? '40px' : '40px'}
            controls={true}
            pip={true}
            onPlay={() => {
              setShouldPlay(true)
              setIsPlaying(true)
              setWasClosed(false)
            }}
            onPause={() => setIsPlaying(false)}
            onEnded={() => setIsPlaying(false)}
            onProgress={(prog) => setProgress(prog.playedSeconds)}
            preload="metadata"
          />
        </div>
        <div ref={observeBottom} />
      </Wrapper>
    )
  },
)

AudioPreview.displayName = 'AudioPreview'
