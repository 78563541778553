import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Direction45 = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M1,11.5714286 L21,3 L12.4285714,23 L9.23529412,14.7647059 L1,11.5714286 Z M10.7810216,13.2189784 L12.5211751,17.7067427 L17.1921134,6.80788655 L6.29325726,11.4788249 L10.7810216,13.2189784 Z"
    />
  </BaseIcon>
)
