import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Coffee = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M19.8680879,8.99445216 L17.8672954,23 L6.1327046,23 L4.13192525,8.99454456 C3.09687032,8.91839393 2.28077641,8.05450144 2.28077641,7 C2.28077641,6.83647639 2.3008311,6.67356996 2.34049141,6.51492875 L2.84049141,4.51492875 C3.02818251,3.76416432 3.62642284,3.20235437 4.35963404,3.04466395 L4.82917961,2.10557281 C5.16796364,1.42800475 5.86048987,1 6.61803399,1 L17.381966,1 C18.1395101,1 18.8320364,1.42800475 19.1708204,2.10557281 L19.640366,3.04466395 C20.3735772,3.20235437 20.9718175,3.76416432 21.1595086,4.51492875 L21.6595086,6.51492875 C21.927406,7.58651857 21.2758847,8.67238755 20.2042948,8.940285 C20.093882,8.96788821 19.9814031,8.9859944 19.8680879,8.99445216 Z M6.15300969,9 L7.8672954,21 L16.1327046,21 L17.8469903,9 L6.15300969,9 Z M5.61803399,5 L4.78077641,5 L4.28077641,7 L19.7192236,7 L19.2192236,5 L18.381966,5 L17.381966,3 L6.61803399,3 L5.61803399,5 Z"
    />
  </BaseIcon>
)
