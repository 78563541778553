import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Delivery = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M19.8292943,16 L21,16 L21,12.5351838 L18.2243368,10.6847416 L16.381966,7 L3,7 L3,16 L4.17070571,16 C4.58254212,14.8348076 5.69378117,14 7,14 C8.30621883,14 9.41745788,14.8348076 9.82929429,16 L14.1707057,16 C14.5825421,14.8348076 15.6937812,14 17,14 C18.3062188,14 19.4174579,14.8348076 19.8292943,16 Z M19.8292943,18 C19.4174579,19.1651924 18.3062188,20 17,20 C15.6937812,20 14.5825421,19.1651924 14.1707057,18 L9.82929429,18 C9.41745788,19.1651924 8.30621883,20 7,20 C5.69378117,20 4.58254212,19.1651924 4.17070571,18 L3,18 C1.8954305,18 1,17.1045695 1,16 L1,7 C1,5.8954305 1.8954305,5 3,5 L16.381966,5 C17.1395101,5 17.8320364,5.42800475 18.1708204,6.10557281 L19.7756632,9.31525835 L23,11.4648162 L23,16 C23,17.1045695 22.1045695,18 21,18 L19.8292943,18 Z M7,18 C7.55228475,18 8,17.5522847 8,17 C8,16.4477153 7.55228475,16 7,16 C6.44771525,16 6,16.4477153 6,17 C6,17.5522847 6.44771525,18 7,18 Z M17,18 C17.5522847,18 18,17.5522847 18,17 C18,16.4477153 17.5522847,16 17,16 C16.4477153,16 16,16.4477153 16,17 C16,17.5522847 16.4477153,18 17,18 Z"
    />
  </BaseIcon>
)
