import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const PlayerPlayCircle = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M12,23 C5.92486775,23 1,18.0751322 1,12 C1,5.92486775 5.92486775,1 12,1 C18.0751322,1 23,5.92486775 23,12 C23,18.0751322 18.0751322,23 12,23 Z M12,21 C16.9705627,21 21,16.9705627 21,12 C21,7.02943725 16.9705627,3 12,3 C7.02943725,3 3,7.02943725 3,12 C3,16.9705627 7.02943725,21 12,21 Z M8,17 L8,7 C8,6.21456446 8.86395093,5.73572169 9.52999894,6.1520017 L17.5299989,11.1520017 C18.156667,11.5436692 18.156667,12.4563308 17.5299989,12.8479983 L9.52999894,17.8479983 C8.86395093,18.2642783 8,17.7854355 8,17 Z M15.1132038,12 L10,8.80424764 L10,15.1957524 L15.1132038,12 Z"
    />
  </BaseIcon>
)
