import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const ToggleRight = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M7,5 L17,5 C20.8659932,5 24,8.13400675 24,12 C24,15.8659932 20.8659932,19 17,19 L7,19 C3.13400675,19 0,15.8659932 0,12 C0,8.13400675 3.13400675,5 7,5 Z M7,7 C4.23857625,7 2,9.23857625 2,12 C2,14.7614237 4.23857625,17 7,17 L17,17 C19.7614237,17 22,14.7614237 22,12 C22,9.23857625 19.7614237,7 17,7 L7,7 Z M17,16 C14.790861,16 13,14.209139 13,12 C13,9.790861 14.790861,8 17,8 C19.209139,8 21,9.790861 21,12 C21,14.209139 19.209139,16 17,16 Z M17,14 C18.1045695,14 19,13.1045695 19,12 C19,10.8954305 18.1045695,10 17,10 C15.8954305,10 15,10.8954305 15,12 C15,13.1045695 15.8954305,14 17,14 Z"
    />
  </BaseIcon>
)
