import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const LockCircle = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M11,9 L13,9 L13,7 C13,6.44771525 12.5522847,6 12,6 C11.4477153,6 11,6.44771525 11,7 L11,9 Z M9,9 L9,7 C9,5.34314575 10.3431458,4 12,4 C13.6568542,4 15,5.34314575 15,7 L15,9 C16.1045695,9 17,9.8954305 17,11 L17,15 C17,16.1045695 16.1045695,17 15,17 L9,17 C7.8954305,17 7,16.1045695 7,15 L7,11 C7,9.8954305 7.8954305,9 9,9 Z M12,22 C5.92486775,22 1,17.0751322 1,11 C1,4.92486775 5.92486775,0 12,0 C18.0751322,0 23,4.92486775 23,11 C23,17.0751322 18.0751322,22 12,22 Z M12,20 C16.9705627,20 21,15.9705627 21,11 C21,6.02943725 16.9705627,2 12,2 C7.02943725,2 3,6.02943725 3,11 C3,15.9705627 7.02943725,20 12,20 Z M9,11 L9,15 L15,15 L15,11 L9,11 Z M12,14 C11.4477153,14 11,13.5522847 11,13 C11,12.4477153 11.4477153,12 12,12 C12.5522847,12 13,12.4477153 13,13 C13,13.5522847 12.5522847,14 12,14 Z"
    />
  </BaseIcon>
)
