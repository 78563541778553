import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const MicNo = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M14.0319673,15.4461809 C13.4364541,15.7980706 12.7418086,16 12,16 C9.790861,16 8,14.209139 8,12 L8,9.41421356 L1.29289322,2.70710678 L2.70710678,1.29289322 L22.7071068,21.2928932 L21.2928932,22.7071068 L16.9056439,18.3198574 C15.7991209,19.1800111 14.4607085,19.7559585 13,19.9381062 L13,21 L16,21 L16,23 L8,23 L8,21 L11,21 L11,19.9381062 C7.05368842,19.4460082 4,16.0796177 4,12 L4,10 L6,10 L6,12 C6,15.3137085 8.6862915,18 12,18 C13.2958304,18 14.4957155,17.589209 15.4765344,16.8907479 L14.0319673,15.4461809 Z M10,11.4142136 L10,12 C10,13.1045695 10.8954305,14 12,14 C12.1791593,14 12.3528166,13.9764427 12.5180432,13.9322568 L10,11.4142136 Z M16,11.7857865 L14,9.78578649 L14,5 C14,3.8954305 13.1045695,3 12,3 C10.8954305,3 10,3.8954305 10,5 L10,5.78578649 L8.14460779,3.93039427 C8.61238846,2.24059489 10.161316,1 12,1 C14.209139,1 16,2.790861 16,5 L16,11.7857865 Z M17.7907353,13.5765218 C17.9271822,13.0741479 18,12.5455777 18,12 L18,10 L20,10 L20,12 C20,13.116226 19.7713927,14.1790579 19.3584437,15.1442302 L17.7907353,13.5765218 Z"
    />
  </BaseIcon>
)
