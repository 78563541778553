import { Box } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { MouseEvent, useRef, useState } from 'react'
import { HexColorPicker } from 'react-colorful'
import { useClickAway } from 'react-use'

const ColorButton = styled(Box)`
  border: 0;
  height: 24px;
  width: 24px;
`

const ClearButton = styled(ColorButton)`
  position: relative;
  overflow: clip;
  border: 1px solid var(--colors-borders);

  /* remove "icon" */
  &::after {
    content: '';
    position: absolute;
    border-top: 2px solid red;
    width: 32px;
    transform: rotate(45deg);
    transform-origin: left;
    top: -2px;
    left: -1px;
  }
`

const ColorPickerWrapper = styled.div`
  position: relative;

  .react-colorful {
    height: 208px;
    width: 200px;
  }
  .react-colorful__saturation,
  .react-colorful__hue {
    border-radius: 0;
  }
  .react-colorful__saturation {
    margin-bottom: 8px;
  }
  .react-colorful__hue {
    width: calc(100% - 48px);
    margin: 0 8px;
  }
`

const SaveWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 8px;
`

export const ColorPicker = ({
  onChangeColor,
  removeColor,
  hide,
  backgroundColor = '#46b7c3',
}: {
  onChangeColor: (color: string) => void
  removeColor: () => void
  hide?: () => void
  backgroundColor?: string
}) => {
  const [color, setColor] = useState(backgroundColor)
  const ref = useRef(null)
  useClickAway(ref, () => {
    hide?.()
  })

  const expand = (color: string) =>
    `${color[0]}${color[0]}${color[1]}${color[1]}${color[2]}${color[2]}`

  const handleSave = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault()
    if (color.startsWith('#')) {
      if (color.length === 7) {
        onChangeColor(color)
      } else if (color.length === 4) {
        const expanded = expand(color.substr(1, 3))
        onChangeColor('#' + expanded)
      }
    } else if (color.length === 6) {
      onChangeColor('#' + color)
    } else if (color.length === 3) {
      const expanded = expand(color)
      onChangeColor('#' + expanded)
    }
    hide?.()
  }

  const onChange = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault()
    const { color: newColor } = (e.target as HTMLButtonElement).dataset
    onChangeColor(newColor ?? '')
    hide?.()
  }

  const handleColorInput = (newColor: string) => {
    setColor(newColor)
  }

  const remove = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault()
    removeColor()
    hide?.()
  }

  return (
    <Box display="flex" flexDirection="column" ref={ref}>
      <Box marginBottom="8px">
        <ColorPickerWrapper>
          <HexColorPicker color={color} onChange={handleColorInput} />
          <SaveWrapper>
            <ColorButton
              as="button"
              onClick={handleSave}
              background={color}
              data-color={color}
              aria-label={color}
            />
          </SaveWrapper>
        </ColorPickerWrapper>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        margin="0 8px 8px 8px"
      >
        <ColorButton
          as="button"
          onClick={onChange}
          background="#f8e71c"
          data-color="#f8e71c"
          aria-label="#f8e71c"
        />
        <ColorButton
          as="button"
          onClick={onChange}
          background="#e010cd"
          data-color="#e010cd"
          aria-label="#e010cd"
        />
        <ColorButton
          as="button"
          onClick={onChange}
          background="#4299ff"
          data-color="#4299ff"
          aria-label="#4299ff"
        />
        <ColorButton
          as="button"
          onClick={onChange}
          background="#36dd1d"
          data-color="#36dd1d"
          aria-label="#36dd1d"
        />
        <ClearButton
          as="button"
          onClick={remove}
          background="#ffffff"
          data-color="#ffffff"
          aria-label="#ffffff"
        />
      </Box>
    </Box>
  )
}
