import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const ArrowReply = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M5.41421356,10 L11,10 C18.8689424,10 22,13.2562999 22,21 L20,21 C20,14.3437001 17.7464422,12 11,12 L5.41421356,12 L9.70710678,16.2928932 L8.29289322,17.7071068 L1.58578644,11 L8.29289322,4.29289322 L9.70710678,5.70710678 L5.41421356,10 Z"
    />
  </BaseIcon>
)
