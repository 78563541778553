import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Logo = ({
  height = 231,
  width = 231,
  title = 'Aurelius Logo',
  viewBox = '0 0 231 231',
  ...rest
}: SVGProps) => (
  <BaseIcon
    title={title}
    width={width}
    height={height}
    viewBox={viewBox}
    {...rest}
  >
    <g
      id="aurelius-logo"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="logo">
        <polygon
          id="Fill-1_2_"
          fill="#F7952C"
          points="64.5406105 51.3117888 134.092144 230.50217 229.499992 179.190398 160.148918 0"
        ></polygon>
        <polygon
          id="Fill-2_2_"
          fill="#70C6D2"
          points="64.5406105 51.3117888 0 229.90089 100.418776 177.586908 160.148918 0"
        ></polygon>
        <polygon
          id="Fill-3_2_"
          fill="#000000"
          fillRule="nonzero"
          points="64.5406105 51.3117888 64.5406105 51.3117888 106.632313 159.547606 160.148918 0"
        ></polygon>
      </g>
    </g>
  </BaseIcon>
)
