import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Eject = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M4,17 C3.20130462,17 2.72491322,16.1098545 3.16794971,15.4452998 L11.1679497,3.4452998 C11.5637718,2.85156673 12.4362282,2.85156673 12.8320503,3.4452998 L20.8320503,15.4452998 C21.2750868,16.1098545 20.7986954,17 20,17 L4,17 Z M5.86851709,15 L18.1314829,15 L12,5.80277564 L5.86851709,15 Z M3,21 L3,19 L21,19 L21,21 L3,21 Z"
    />
  </BaseIcon>
)
