import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const CreditcardScan = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M18,10 L18,8 L6,8 L6,10 L18,10 Z M18,12 L6,12 L6,16 L18,16 L18,12 Z M5.81818182,6 L18.1818182,6 C19.1859723,6 20,6.8954305 20,8 L20,16 C20,17.1045695 19.1859723,18 18.1818182,18 L5.81818182,18 C4.81402773,18 4,17.1045695 4,16 L4,8 C4,6.8954305 4.81402773,6 5.81818182,6 Z M5,3 L5,5 L3,5 L3,7 L1,7 L1,5 C1,3.8954305 1.81402773,3 2.81818182,3 L5,3 Z M19,21 L19,19 L21,19 L21,17 L23,17 L23,19 C23,20.1045695 22.1859723,21 21.1818182,21 L19,21 Z M19,3 L21.1818182,3 C22.1859723,3 23,3.8954305 23,5 L23,7 L21,7 L21,5 L19,5 L19,3 Z M5,21 L2.81818182,21 C1.81402773,21 1,20.1045695 1,19 L1,17 L3,17 L3,19 L5,19 L5,21 Z"
    />
  </BaseIcon>
)
