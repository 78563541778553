import { useCallback, useState } from 'react'
import { PlayerPause, PlayerPlay } from '@aurelius/icons'

import {
  PlayerContainer,
  PlayerTrackContainer,
  PlayerTrackBackground,
  PlayerTrackElapsed,
  PlayerTrackPlayButton,
  PlayerTimer,
} from './Player.styles'

export interface MediaProgressBarProps {
  media: any
  loading?: boolean
  overrideDuration?: number
}

export const formatTime = (time: number): string => {
  const minutes = Math.floor(time / 60)
  const seconds = Math.floor(time % 60)

  return `${minutes.toString().padStart(2, '0')}:${seconds
    .toString()
    .padStart(2, '0')}`
}

export const MediaProgressBar = ({
  media,
  loading = false,
  overrideDuration,
}: MediaProgressBarProps) => {
  const [trackPercent, setTrackPercent] = useState(0)
  const [currentTime, setCurrentTime] = useState(0)
  const [playing, setPlaying] = useState(false)
  const [duration, setDuration] = useState(overrideDuration ?? 0)
  const [loadingMedia, setLoadingMedia] = useState(loading)

  const play = (): void => {
    if (media) {
      media.play()
    }
  }

  const pause = (): void => {
    if (media) {
      media.pause()
    }
  }

  const toggle = (): void => {
    playing ? pause() : play()
    setPlaying(!playing)
  }

  const mediaCanPlay = useCallback(() => {
    setLoadingMedia(false)
    setDuration(
      isNaN(Math.floor(media.duration)) ? 0 : Math.floor(media.duration),
    )
  }, [media])

  const mediaTimeUpdate = useCallback((): void => {
    if (media) {
      const percent =
        (Math.floor(media.currentTime) * 100) / Math.floor(media.duration)
      setTrackPercent(percent)
      setCurrentTime(
        isNaN(Math.floor(media.currentTime))
          ? 0
          : Math.floor(media.currentTime),
      )
      if (percent === 100) {
        setPlaying(false)
      }
    }
  }, [media])

  if (media) {
    media.oncanplay = mediaCanPlay
    media.ontimeupdate = mediaTimeUpdate
  }

  return (
    <PlayerContainer>
      <PlayerTrackContainer>
        <PlayerTrackBackground />
        <PlayerTrackElapsed style={{ width: `${trackPercent}%` }} />
        <PlayerTrackPlayButton onClick={toggle} style={{ cursor: 'pointer' }}>
          {playing ? (
            <PlayerPause
              fill="var(--colors-inputs-text-color)"
              height={24}
              width={24}
            />
          ) : (
            <PlayerPlay
              fill="var(--colors-inputs-text-color)"
              height={24}
              width={24}
            />
          )}
        </PlayerTrackPlayButton>
      </PlayerTrackContainer>
      <PlayerTimer>
        {duration > 0 &&
          `${formatTime(currentTime)} / ${formatTime(duration)}${' '}`}
        {loadingMedia && 'Buffering...'}
      </PlayerTimer>
    </PlayerContainer>
  )
}
