import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const LocationNo = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M17.6176858,19.0318994 C16.3229182,20.0676219 14.735853,20.7531824 13,20.9450712 L13,23 L11,23 L11,20.9450712 C6.82838339,20.4839224 3.5160776,17.1716166 3.05492878,13 L1,13 L1,11 L3.05492878,11 C3.2468176,9.26414698 3.93237814,7.67708178 4.96810063,6.38231419 L1.29289322,2.70710678 L2.70710678,1.29289322 L22.7071068,21.2928932 L21.2928932,22.7071068 L17.6176858,19.0318994 Z M6.39361534,7.80782891 C5.51840355,8.97640944 5,10.4276649 5,12 C5,15.8659932 8.13400675,19 12,19 C13.5723351,19 15.0235906,18.4815965 16.1921711,17.6063847 L14.0319673,15.4461809 C13.4364541,15.7980706 12.7418086,16 12,16 C9.790861,16 8,14.209139 8,12 C8,11.2581914 8.20192939,10.5635459 8.55381909,9.96803265 L6.39361534,7.80782891 Z M10.0677432,11.4819568 C10.0235573,11.6471834 10,11.8208407 10,12 C10,13.1045695 10.8954305,14 12,14 C12.1791593,14 12.3528166,13.9764427 12.5180432,13.9322568 L10.0677432,11.4819568 Z M20.1136672,15.8994537 L18.587447,14.3732335 C18.8544705,13.6321696 19,12.8330799 19,12 C19,8.13400675 15.8659932,5 12,5 C11.1669201,5 10.3678304,5.14552951 9.62676652,5.41255301 L8.10054631,3.8863328 C8.99486582,3.45573566 9.97130626,3.1686451 11,3.05492878 L11,1 L13,1 L13,3.05492878 C17.1716166,3.5160776 20.4839224,6.82838339 20.9450712,11 L23,11 L23,13 L20.9450712,13 C20.8313549,14.0286937 20.5442643,15.0051342 20.1136672,15.8994537 Z"
    />
  </BaseIcon>
)
