import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const ArrowLeftCurved = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M5.41421356,10 L16,10 C19.3137085,10 22,12.6862915 22,16 C22,19.3137085 19.3137085,22 16,22 L13.5,22 L13.5,20 L16,20 C18.209139,20 20,18.209139 20,16 C20,13.790861 18.209139,12 16,12 L5.41421356,12 L9.70710678,16.2928932 L8.29289322,17.7071068 L1.58578644,11 L8.29289322,4.29289322 L9.70710678,5.70710678 L5.41421356,10 Z"
    />
  </BaseIcon>
)
