import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const FileCode = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M15,3.41421356 L15,7 L18.5857864,7 L15,3.41421356 Z M19,9 L15,9 C13.8954305,9 13,8.1045695 13,7 L13,3 L5,3 L5,21 L19,21 L19,9 Z M5,1 L15.4142136,1 L21,6.58578644 L21,21 C21,22.1045695 20.1045695,23 19,23 L5,23 C3.8954305,23 3,22.1045695 3,21 L3,3 C3,1.8954305 3.8954305,1 5,1 Z M11.7071068,15.2928932 L10.2928932,16.7071068 L6.58578644,13 L10.2928932,9.29289322 L11.7071068,10.7071068 L9.41421356,13 L11.7071068,15.2928932 Z M12.2928932,12.7071068 L13.7071068,11.2928932 L17.4142136,15 L13.7071068,18.7071068 L12.2928932,17.2928932 L14.5857864,15 L12.2928932,12.7071068 Z"
    />
  </BaseIcon>
)
