import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const CircleArrowLeftCurved = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M12,23 C5.92486775,23 1,18.0751322 1,12 C1,5.92486775 5.92486775,1 12,1 C18.0751322,1 23,5.92486775 23,12 C23,18.0751322 18.0751322,23 12,23 Z M12,21 C16.9705627,21 21,16.9705627 21,12 C21,7.02943725 16.9705627,3 12,3 C7.02943725,3 3,7.02943725 3,12 C3,16.9705627 7.02943725,21 12,21 Z M9.41421356,11 L11.7071068,13.2928932 L10.2928932,14.7071068 L5.58578644,10 L10.2928932,5.29289322 L11.7071068,6.70710678 L9.41421356,9 L14,9 C16.209139,9 18,10.790861 18,13 C18,15.209139 16.209139,17 14,17 L14,15 C15.1045695,15 16,14.1045695 16,13 C16,11.8954305 15.1045695,11 14,11 L9.41421356,11 Z"
    />
  </BaseIcon>
)
