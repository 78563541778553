import { useState } from 'react'
import styled from '@emotion/styled'
import { P } from './Typography'
import { Box, BoxProps } from '@chakra-ui/react'
import { Details, Summary } from './Toggle'
import { Card, Checkbox } from '.'

const SelectAll = styled.span`
  font-size: 14px;
  color: var(--colors-typography-titles);
  display: flex;
  margin-left: auto;
  line-height: 1.5;
`
const WrapperOverride = styled.div`
  border-radius: 0 0px 18.5px 18.5px;
  background-color: var(--colors-layout-content);
  overflow: hidden;

  & section {
    border: none;
  }
`

export const TagsGroup = ({
  id,
  name,
  children,
  readingOnly,
  onOpen,
  selectAllMethod,
  defaultOpen,
  ...props
}: React.PropsWithChildren<
  {
    id: string
    name: string
    onCloseMenu?: VoidFunction
    onOpen?: VoidFunction
    readingOnly?: boolean
    selectAllMethod?: (checked: boolean, id: string) => void
    defaultOpen?: boolean
  } & BoxProps
>) => {
  const [open, toggleOpen] = useState<boolean>(defaultOpen || false)
  const [checked, toggleCheck] = useState(false)

  return (
    <Card
      background="var(--colors-dropdowns-open-background) "
      marginTop="20px"
      marginBottom="20px"
    >
      <Details display="block" open={open} {...props}>
        <Summary
          open={open}
          marginLeft="0px !important"
          padding="12px 37px 12px 30px !important"
          onClick={(e): void => {
            e.preventDefault()
            toggleOpen(!open)
            if (!open) {
              onOpen?.()
            }
          }}
        >
          <Box display="flex" alignItems="center">
            <P
              fontSize="16px"
              fontWeight="bold"
              fontStyle="normal"
              lineHeight="normal"
              letterSpacing="0.29px"
              margin="0"
              display="inline-block"
            >
              {name}
            </P>
          </Box>
          {!readingOnly && (
            <SelectAll>
              Select All
              <Checkbox
                marginLeft="5px"
                checked={checked}
                onClick={() => {
                  toggleCheck(!checked)
                  if (selectAllMethod) {
                    selectAllMethod(!checked, id)
                  }
                }}
              />
            </SelectAll>
          )}
        </Summary>
        <WrapperOverride>{children}</WrapperOverride>
      </Details>
    </Card>
  )
}
