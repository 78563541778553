import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const MusicLibrary = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M20,19 C20,20.1045695 19.1045695,21 18,21 L6,21 C4.8954305,21 4,20.1045695 4,19 L3,19 C1.8954305,19 1,18.1045695 1,17 L1,7 C1,5.8954305 1.8954305,5 3,5 L4,5 C4,3.8954305 4.8954305,3 6,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L21,5 C22.1045695,5 23,5.8954305 23,7 L23,17 C23,18.1045695 22.1045695,19 21,19 L20,19 Z M18,19 L18,5 L6,5 L6,19 L18,19 Z M12,12.1404647 L12,7.13148291 L16.5547002,10.1679497 L15.4452998,11.8320503 L14,10.8685171 L14,14.5 C14,15.9534271 12.6045695,17 11,17 C9.3954305,17 8,15.9534271 8,14.5 C8,13.0465729 9.3954305,12 11,12 C11.3471248,12 11.6844618,12.0489806 12,12.1404647 Z M4,7 L3,7 L3,17 L4,17 L4,7 Z M20,7 L20,17 L21,17 L21,7 L20,7 Z M11,15 C11.6045695,15 12,14.7034271 12,14.5 C12,14.2965729 11.6045695,14 11,14 C10.3954305,14 10,14.2965729 10,14.5 C10,14.7034271 10.3954305,15 11,15 Z"
    />
  </BaseIcon>
)
