import { BaseIcon, SVGProps } from '@aurelius/icons'

export const StarIcon = ({ ...rest }: SVGProps) => (
  <BaseIcon
    title="Create Theme"
    width={18}
    height={17}
    viewBox="0 0 18 17"
    {...rest}
  >
    <path
      d="m8.66 0 2.675 5.422 5.984.87-4.33 4.22 1.022 5.96-5.351-2.814-5.352 2.813 1.022-5.959L0 6.292l5.984-.87L8.66 0zm0 3.356L6.97 6.78l-3.778.549 2.734 2.664-.646 3.763 3.38-1.776 3.379 1.776-.646-3.763 2.734-2.664-3.778-.55-1.69-3.423z"
      fill="#00678D"
      fillRule="evenodd"
    />
  </BaseIcon>
)
