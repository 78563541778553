import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Mic = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M13,19.9381062 L13,21 L16,21 L16,23 L8,23 L8,21 L11,21 L11,19.9381062 C7.05368842,19.4460082 4,16.0796177 4,12 L4,10 L6,10 L6,12 C6,15.3137085 8.6862915,18 12,18 C15.3137085,18 18,15.3137085 18,12 L18,10 L20,10 L20,12 C20,16.0796177 16.9463116,19.4460082 13,19.9381062 Z M12,1 C14.209139,1 16,2.790861 16,5 L16,12 C16,14.209139 14.209139,16 12,16 C9.790861,16 8,14.209139 8,12 L8,5 C8,2.790861 9.790861,1 12,1 Z M12,3 C10.8954305,3 10,3.8954305 10,5 L10,12 C10,13.1045695 10.8954305,14 12,14 C13.1045695,14 14,13.1045695 14,12 L14,5 C14,3.8954305 13.1045695,3 12,3 Z"
    />
  </BaseIcon>
)
