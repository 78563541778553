import styled from '@emotion/styled'

export const TogglerWrapper = styled.div`
  display: flex;
  align-items: center;

  .toggler-switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

  .toggler-switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .toggler-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    border-radius: 34px;
  }

  .toggler-slider:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.2s;
    border-radius: 50%;
  }

  input:checked + .toggler-slider {
    background-color: #2196f3;
    transition: 0.2s;
  }

  input:checked + .toggler-slider:before {
    transform: translateX(26px);
  }

  .toggler-label {
    font-size: 14px;
    margin: 7px;
  }
`
