import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Folder = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M3,11 L3,19 L21,19 L21,11 L3,11 Z M3,9 L21,9 L21,7 L11.994646,6.99998567 C11.2764915,6.99614058 10.8086916,6.65990923 10.3058322,6.03654146 C10.2364281,5.95050497 10.0158737,5.66440398 9.98159778,5.62115916 C9.60702158,5.14856811 9.38424442,5 9,5 L3,5 L3,9 Z M21,5 C22.1045695,5 23,5.8954305 23,7 L23,19 C23,20.1045695 22.1045695,21 21,21 L3,21 C1.8954305,21 1,20.1045695 1,19 L1,5 C1,3.8954305 1.8954305,3 3,3 L9,3 C10.1200023,3 10.832939,3.47545118 11.5489764,4.37885309 C11.5967547,4.43913352 11.8100999,4.71588275 11.8624831,4.78081945 C12.019726,4.97574495 12.0517795,4.99972956 12.0017863,5 L21,5 Z"
    />
  </BaseIcon>
)
