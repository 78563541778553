import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const BrakePage = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M21,2 L21,7 C21,8.1045695 20.1045695,9 19,9 L5,9 C3.8954305,9 3,8.1045695 3,7 L3,2 L5,2 L5,7 L19,7 L19,2 L21,2 Z M21,22 L19,22 L19,17 L5,17 L5,22 L3,22 L3,17 C3,15.8954305 3.8954305,15 5,15 L19,15 C20.1045695,15 21,15.8954305 21,17 L21,22 Z M4,11 L4,13 L2,13 L2,11 L4,11 Z M7,11 L7,13 L5,13 L5,11 L7,11 Z M10,11 L10,13 L8,13 L8,11 L10,11 Z M13,11 L13,13 L11,13 L11,11 L13,11 Z M16,11 L16,13 L14,13 L14,11 L16,11 Z M19,11 L19,13 L17,13 L17,11 L19,11 Z M22,11 L22,13 L20,13 L20,11 L22,11 Z"
    />
  </BaseIcon>
)
