import * as yup from 'yup'

export const sorting = yup.object().shape({
  sortBy: yup
    .string()
    .default('createdAt')
    .oneOf(['createdAt', 'updatedAt', 'name', 'owner', 'relevance']),
  direction: yup.number().default(-1).integer().oneOf([-1, 1]),
  cursor: yup.number().default(0).moreThan(-1),
  search: yup.string().default('').trim(),
})
export type sortingType = yup.InferType<typeof sorting>
