import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const SendToReport = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M22,2 L22,4 L21,4 L21,14 C21,15.1045695 20.1045695,16 19,16 L13,16 L13,17.4338096 L17.5144958,20.1425071 L16.4855042,21.8574929 L13,19.7661904 L13,22 L11,22 L11,19.7661904 L7.51449576,21.8574929 L6.48550424,20.1425071 L11,17.4338096 L11,16 L5,16 C3.8954305,16 3,15.1045695 3,14 L3,4 L2,4 L2,2 L22,2 Z M19,4 L5,4 L5,14 L19,14 L19,4 Z M10,8 L10,12 L8,12 L8,8 L10,8 Z M13,6 L13,12 L11,12 L11,6 L13,6 Z M16,9 L16,12 L14,12 L14,9 L16,9 Z"
    />
  </BaseIcon>
)
