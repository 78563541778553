import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const ArrowBottomLeft = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <polygon
      fillRule="evenodd"
      points="14 19 14 21 3 21 3 10 5 10 5 17.586 18.293 4.293 19.707 5.707 6.414 19"
    />
  </BaseIcon>
)
