import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const CircleStar = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M12,23 C5.92486775,23 1,18.0751322 1,12 C1,5.92486775 5.92486775,1 12,1 C18.0751322,1 23,5.92486775 23,12 C23,18.0751322 18.0751322,23 12,23 Z M12,21 C16.9705627,21 21,16.9705627 21,12 C21,7.02943725 16.9705627,3 12,3 C7.02943725,3 3,7.02943725 3,12 C3,16.9705627 7.02943725,21 12,21 Z M7.58169903,17.9385868 L8.42551943,13.0187364 L4.85103886,9.53447894 L9.79084952,8.81668244 L12,4.34045464 L14.2091505,8.81668244 L19.1489611,9.53447894 L15.5744806,13.0187364 L16.418301,17.9385868 L12,15.6157485 L7.58169903,17.9385868 Z M12,13.3561971 L13.7620389,14.2825557 L13.4255194,12.3204966 L14.8510389,10.9309585 L12.8810195,10.644698 L12,8.85955757 L11.1189805,10.644698 L9.14896114,10.9309585 L10.5744806,12.3204966 L10.2379611,14.2825557 L12,13.3561971 Z"
    />
  </BaseIcon>
)
