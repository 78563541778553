import { useEditor, useNode } from '@craftjs/core'
import { ROOT_NODE } from '@craftjs/utils'

interface ContainerProps {
  padding: number
  width?: number
  marginTop?: number
  marginBottom?: number
  marginRight?: number
  marginLeft?: number
  style?: object
}

const Container = ({
  padding = 0,
  width: initialWidth = 0,
  marginTop: initialMarginTop = 0,
  marginBottom: initialMarginBottom = 0,
  marginLeft: initialMarginLeft = 0,
  marginRight: initialMarginRight = 0,
  style = {},
  children,
}: React.PropsWithChildren<ContainerProps>) => {
  const {
    id,
    connectors: { connect, drag },
    marginTop = initialMarginTop,
    marginRight = initialMarginRight,
    marginBottom = initialMarginBottom,
    marginLeft = initialMarginLeft,
    paddingTop = padding,
    paddingRight = padding,
    paddingBottom = padding,
    paddingLeft = padding,
    width = initialWidth,
    height,
    color,
    backgroundColor,
    borderWidth = '0px',
    borderColor = '#024660',
    borderRadius = 0,
    isSelected,
    isHovered,
  } = useNode((state) => ({
    isSelected: state.events.selected,
    isHovered: state.events.hovered,
    marginTop: state.data.props.marginTop,
    marginRight: state.data.props.marginRight,
    marginBottom: state.data.props.marginBottom,
    marginLeft: state.data.props.marginLeft,
    paddingTop: state.data.props.paddingTop,
    paddingRight: state.data.props.paddingRight,
    paddingBottom: state.data.props.paddingBottom,
    paddingLeft: state.data.props.paddingLeft,
    height: state.data.props.height,
    width: state.data.props.width,
    color: state.data.props.color,
    borderWidth: state.data.props.borderWidth,
    borderRadius: state.data.props.borderRadius,
    borderColor: state.data.props.borderColor,
    backgroundColor: state.data.props.backgroundColor,
  }))

  const defaultBorder = borderWidth > 0 ? borderWidth : 1

  const { enabled } = useEditor((state) => ({ enabled: state.options.enabled }))

  return (
    <div
      ref={(ref): any => (ref && enabled ? connect(drag(ref)) : ref)}
      style={{
        display: 'inline-block',
        marginTop,
        marginRight,
        marginBottom,
        marginLeft,
        paddingTop,
        paddingRight,
        paddingBottom,
        paddingLeft,
        height: `${height}%`,
        width: `${width || 100}%`,
        color,
        borderRadius: `${borderRadius}px`,
        border: isSelected
          ? `${defaultBorder}px solid #024660`
          : isHovered && id !== ROOT_NODE
          ? `${defaultBorder}px dashed #024660`
          : borderWidth > 0
          ? `${borderWidth}px solid ${borderColor}`
          : '',
        backgroundColor: backgroundColor || '#ffffff',
        ...style,
      }}
    >
      {children}
    </div>
  )
}

Container.displayName = 'Container'

Container.craft = {
  displayName: 'Container',
}
export default Container
