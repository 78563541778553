import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Star = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M12,19.1297757 L4.79401645,22.9181855 L6.17023685,14.8942049 L0.340473695,9.21159025 L8.39700823,8.04090726 L12,0.740448538 L15.6029918,8.04090726 L23.6595263,9.21159025 L17.8297632,14.8942049 L19.2059835,22.9181855 L12,19.1297757 Z M12,16.8702243 L16.5497215,19.2621544 L15.680802,14.1959651 L19.361604,10.6080699 L14.2748607,9.86892279 L12,5.25955146 L9.72513925,9.86892279 L4.63839598,10.6080699 L8.31919799,14.1959651 L7.4502785,19.2621544 L12,16.8702243 Z"
    />
  </BaseIcon>
)
