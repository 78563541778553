import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Paypass = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M16.7143479,2.63941429 C18.1459774,5.12972035 19,8.42023439 19,11.9994139 C19,15.5785935 18.1459774,18.8691075 16.7143479,21.3594136 L14.9171213,20.4635391 C16.1877133,18.3045936 16.9940114,15.3240089 16.9940114,11.9994139 C16.9940114,8.67481895 16.1877133,5.69423428 14.9171213,3.53528881 L16.7143479,2.63941429 Z M13.0109863,4.48545151 C14.2529519,6.54954392 14.9880227,9.16845607 14.9880227,11.9994139 C14.9880227,14.8303718 14.2529519,17.449284 13.0109863,19.5133764 L11.2113746,18.616313 C12.3071272,16.8612823 12.9820341,14.5515264 12.9820341,11.9994139 C12.9820341,9.44730152 12.3071272,7.13754557 11.2113746,5.38251494 L13.0109863,4.48545151 Z M9.36872979,6.30102936 C10.3832551,7.92066307 10.9760455,9.88643985 10.9760455,11.9994139 C10.9760455,14.112388 10.3832551,16.0781648 9.36872979,17.6977985 L7.56525946,16.7988117 C8.44595754,15.4649981 8.97005684,13.8053766 8.97005684,11.9994139 C8.97005684,10.1934513 8.44595754,8.53382977 7.56525946,7.20001621 L9.36872979,6.30102936 Z M5.80860389,8.07566704 C6.54371504,9.23193393 6.96406821,10.5691709 6.96406821,11.9994139 C6.96406821,13.429657 6.54371504,14.766894 5.80860389,15.9231609 L4,15.0216151 C4.60973001,14.1309144 4.95807957,13.0966678 4.95807957,11.9994139 C4.95807957,10.9021601 4.60973001,9.86791351 4,8.97721285 L5.80860389,8.07566704 Z"
    />
  </BaseIcon>
)
