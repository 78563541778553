import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Map = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M14,5.29460825 L10,7.58032254 L10,19.2768203 L14,16.991106 L14,5.29460825 Z M16,4.15175111 L16,15.922967 L20,17.522967 L20,5.67703296 L16.0769157,4.10779926 L16,4.15175111 Z M8,19.6125741 L8,7.72075922 L4,6.38742589 L4,18.2792408 L8,19.6125741 Z M9.1079384,22.090072 L2,19.7207592 L2,3.61257411 L8.8920616,5.90992798 L15.9230843,1.89220074 L22,4.32296704 L22,20.477033 L16.0769157,18.1077993 L9.1079384,22.090072 Z"
    />
  </BaseIcon>
)
