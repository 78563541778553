import { BaseIcon, SVGProps } from '@aurelius/icons'

export const Video = ({ ...rest }: SVGProps) => (
  <BaseIcon title="Video" viewBox="0 0 31 22" {...rest}>
    <g
      id="Highlights-+-Clips-V5"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Documents-Page–Clips–More-clips–2"
        transform="translate(-165.000000, -244.000000)"
      >
        <g id="Group-9" transform="translate(165.000000, 244.000000)">
          <g id="Group-29-Copy" fill="#ED7D00">
            <rect
              id="Rectangle"
              x="0"
              y="0"
              width="31"
              height="22"
              rx="2"
            ></rect>
          </g>
          <polygon
            id="Triangle-Copy-2"
            stroke="#FFFFFF"
            strokeWidth="2"
            fill="#FFFFFF"
            strokeLinejoin="round"
            transform="translate(16.000000, 11.000000) rotate(-270.000000) translate(-16.000000, -11.000000) "
            points="16 8 20 14 12 14"
          ></polygon>
        </g>
      </g>
    </g>
  </BaseIcon>
)
