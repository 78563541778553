import { BaseIcon, SVGProps } from '@aurelius/icons'

export const MusicalNotes = ({ ...rest }: SVGProps) => (
  <BaseIcon title="Musical Notes" viewBox="0 0 31 22" {...rest}>
    <g
      id="Highlights-+-Clips-V5"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Documents-Page–Add-to-Highlight-Reel"
        transform="translate(-115.000000, -699.000000)"
      >
        <g id="Group-5" transform="translate(95.000000, 672.000000)">
          <g id="Group-4" transform="translate(20.000000, 27.000000)">
            <rect
              id="Rectangle"
              fill="#9560AE"
              x="0"
              y="0"
              width="31"
              height="22"
              rx="2"
            ></rect>
            <g
              id="Group"
              transform="translate(6.000000, 5.000000)"
              fill="#FFFFFF"
              fillRule="nonzero"
            >
              <path
                d="M1.08,2.18351477 C0.48,2.18351477 0,2.66874028 0,3.27527216 L0,9.72472784 C0,10.3312597 0.48,10.8164852 1.08,10.8164852 C1.68,10.8164852 2.16,10.3312597 2.16,9.72472784 L2.16,3.25505443 C2.16,2.66874028 1.68,2.18351477 1.08,2.18351477 Z"
                id="Path"
              ></path>
              <path
                d="M5.28,0 C4.68,0 4.2,0.485225505 4.2,1.09175739 L4.2,11.9082426 C4.2,12.5147745 4.68,13 5.28,13 C5.88,13 6.36,12.5147745 6.36,11.9082426 L6.36,1.09175739 C6.36,0.485225505 5.88,0 5.28,0 Z"
                id="Path"
              ></path>
              <path
                d="M9.5,2.18351477 C8.9,2.18351477 8.42,2.66874028 8.42,3.27527216 L8.42,9.72472784 C8.42,10.3312597 8.9,10.8164852 9.5,10.8164852 C10.1,10.8164852 10.58,10.3312597 10.58,9.72472784 L10.58,3.25505443 C10.58,2.66874028 10.1,2.18351477 9.5,2.18351477 Z"
                id="Path"
              ></path>
              <path
                d="M13.72,0 C13.12,0 12.64,0.485225505 12.64,1.09175739 L12.64,11.9082426 C12.64,12.5147745 13.12,13 13.72,13 C14.32,13 14.8,12.5147745 14.8,11.9082426 L14.8,1.09175739 C14.78,0.485225505 14.3,0 13.72,0 Z"
                id="Path"
              ></path>
              <path
                d="M17.92,2.18351477 C17.32,2.18351477 16.84,2.66874028 16.84,3.27527216 L16.84,9.72472784 C16.84,10.3312597 17.32,10.8164852 17.92,10.8164852 C18.52,10.8164852 19,10.3312597 19,9.72472784 L19,3.25505443 C19,2.66874028 18.52,2.18351477 17.92,2.18351477 Z"
                id="Path"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </BaseIcon>
)
