import { Note, Company, Hashtag } from '@aurelius/models'
import { SectionBox, TitleBox } from './Global.styles'
import { Tag } from './Tag'
import { Box } from '@chakra-ui/react'
import { Checkbox } from './Checkbox'
import { Smile } from './svg/Smile'
import { Frown } from './svg/Frown'
import { Card } from './Card'
import styled from '@emotion/styled'

const NoteTitleBox = styled.div`
  font-size: 12px;
  color: #127194;
  margin-bottom: 8px;
`

export const NoteCard = ({
  note,
  company,
  checked = false,
  check,
  hashtags = [],
  readOnly = false,
}: {
  note: Note
  company?: Company
  checked?: boolean
  check?: (
    note: {
      id: string
    } | null,
  ) => void
  hashtags?: Hashtag[]
  readOnly?: boolean
}) => {
  const showCheck = !readOnly && check

  return (
    <Card padding="24px" marginBottom="8px" position="relative">
      <SectionBox width="100%">
        <TitleBox flexGrow={1} marginRight="100px">
          <Box display="flex" marginBottom="12px">
            {showCheck && check && (
              <Checkbox
                checked={checked}
                onChange={(): void => {
                  if (checked) {
                    check(null)
                  } else {
                    check({ id: note.id })
                  }
                }}
                marginTop="3px"
                marginRight="16px"
              />
            )}
            <Box display="flex" flexDirection="column">
              <NoteTitleBox>{note.project?.name}</NoteTitleBox>
              <div
                dangerouslySetInnerHTML={{
                  __html: (note.name ?? 'Untitled') as string,
                }}
              />
            </Box>
          </Box>
          <Box
            display="flex"
            flexWrap="wrap"
            maxHeight="80px"
            overflow="auto"
            marginLeft={showCheck ? '32px' : ''}
          >
            {note.attitude === true && (
              <Smile
                width={24}
                height={24}
                fill="green"
                style={{
                  alignSelf: 'center',
                  marginRight: '8px',
                }}
              />
            )}
            {note.attitude === false && (
              <Frown
                fill="red"
                style={{
                  alignSelf: 'center',
                  marginRight: '8px',
                }}
                width={24}
                height={24}
              />
            )}
            {(company
              ? (note.hashtags as string[]).map((tag) =>
                  (company.hashtags as Hashtag[])?.find(
                    (hashtag) => hashtag._id === tag,
                  ),
                )
              : hashtags
            ).map(
              (tag) =>
                tag && (
                  <Tag
                    key={tag._id}
                    backgroundColor={tag?.display?.backgroundColor}
                    foregroundColor={tag?.display?.foregroundColor}
                    marginRight="4px"
                    marginLeft="4px"
                    marginBottom="8px"
                  >
                    #{tag.name}
                  </Tag>
                ),
            )}
          </Box>
        </TitleBox>
      </SectionBox>
    </Card>
  )
}
