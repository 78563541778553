import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const HeartNo = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M17.1801535,18.8872603 C15.7133809,20.0273683 14.0985449,21.0687615 12.8682739,21.6838971 L12,22.118034 L11.1317261,21.6838971 C9.56674931,20.9014086 7.37949522,19.4291785 5.66630483,17.9414079 C2.39888739,15.1039139 0.643286537,12.1671303 0.643286537,9.11987614 C0.643286537,7.15580739 1.31027009,5.41480526 2.43895721,4.14606399 L1,2.70710678 L2.41421356,1.29289322 L22.4142136,21.2928932 L21,22.7071068 L17.1801535,18.8872603 Z M3.8566261,5.56373288 C3.09252649,6.46444109 2.64328654,7.69907931 2.64328654,9.11987614 C2.64328654,11.4905211 4.10619359,13.9376841 6.97767814,16.4313417 C8.55588399,17.8018889 10.5880162,19.171604 12,19.8819258 C13.0545929,19.3513953 14.45513,18.4530284 15.754104,17.4612108 L3.8566261,5.56373288 Z M20.1287285,16.2074082 L18.7131223,14.791802 C20.4669259,12.8641138 21.3567135,10.9687246 21.3567135,9.11987614 C21.3567135,6.19577447 19.3706407,4.01064899 16.7113557,4.00000753 C15.4984526,4.00000017 14.8246627,4.12023913 14.0246017,4.49836057 C13.5332011,4.73060422 13.0980105,5.04357385 12.7193478,5.44030506 L12.0020961,6.19178168 L11.2784748,5.44643666 C10.9080948,5.06493754 10.479228,4.75895182 9.99325291,4.52686678 C9.33393125,4.21199734 8.74230331,4.0636595 7.93829128,4.01697098 L6.03590894,2.11458864 C6.43991941,2.03922162 6.85959546,2 7.29240297,2 C8.72838449,2 9.73450926,2.18693414 10.8551428,2.72211033 C11.2591887,2.91506884 11.6382026,3.14534408 11.9899397,3.41236773 C12.3552052,3.12992505 12.7493174,2.88896278 13.1700073,2.69013814 C14.2690408,2.17071757 15.2257088,1.99999852 16.7153604,2.00001552 C20.5229336,2.01524059 23.3567135,5.13303401 23.3567135,9.11987614 C23.3567135,11.5507972 22.239464,13.9114158 20.1287285,16.2074082 Z"
    />
  </BaseIcon>
)
