import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const BatteryCharching = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M21,9 C22.1045695,9 23,9.8954305 23,11 L23,13 C23,14.1045695 22.1045695,15 21,15 L21,16 C21,17.1045695 20.1045695,18 19,18 L13,18 L13,16 L19,16 L19,8 L14,8 L14,6 L19,6 C20.1045695,6 21,6.8954305 21,8 L21,9 Z M8,16 L8,18 L3,18 C1.8954305,18 1,17.1045695 1,16 L1,8 C1,6.8954305 1.8954305,6 3,6 L9,6 L9,8 L3,8 L3,16 L8,16 Z M15,11 L10,18 L11,13 L7,13 L12,6 L11,11 L15,11 Z"
    />
  </BaseIcon>
)
