import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const RotateRight = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M3.04477723,14 L11.5300586,5.51471863 L20.01534,14 L11.5300586,22.4852814 L3.04477723,14 Z M17.1869129,14 L11.5300586,8.34314575 L5.87320435,14 L11.5300586,19.6568542 L17.1869129,14 Z M19.3733306,4.28593933 L19.5323333,1.9325893 L21.5277839,2.0674107 L21.1269521,8 L15.5300586,8 L15.5300586,6 L18.2396307,6 C16.5615928,4.12319378 14.144019,3 11.5300586,3 C8.27920479,3 5.33209587,4.73720691 3.73348642,7.50072596 L2.00227469,6.49927404 C3.954821,3.12390371 7.5579471,1 11.5300586,1 C14.5433276,1 17.3442516,2.22227176 19.3733306,4.28593933 Z"
    />
  </BaseIcon>
)
