import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Video = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M17,8.38196601 L23,5.38196601 L23,18.618034 L17,15.618034 L17,17 C17,18.1045695 16.1045695,19 15,19 L3,19 C1.8954305,19 1,18.1045695 1,17 L1,7 C1,5.8954305 1.8954305,5 3,5 L15,5 C16.1045695,5 17,5.8954305 17,7 L17,8.38196601 Z M17,10.618034 L17,13.381966 L21,15.381966 L21,8.61803399 L17,10.618034 Z M3,7 L3,17 L15,17 L15,7 L3,7 Z"
    />
  </BaseIcon>
)
