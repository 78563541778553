import * as yup from 'yup'

export const saveReport = yup.object().shape({
  project: yup.string().required(),
  reportData: yup.string(),
  removedKeyInsights: yup.array().of(yup.string().required()).required(),
  removedHighlightReels: yup.array().of(yup.string().required()).required(),
  removedRecommendations: yup.array().of(yup.string().required()).required(),
  removedClips: yup.array().of(yup.string().required()).required(),
  insertedKeyInsights: yup.mixed(),
  insertedRecommendations: yup.mixed(),
  insertedHighlightReels: yup.mixed(),
  insertedClips: yup.mixed(),
})
export type saveReportType = yup.InferType<typeof saveReport>

export const sendReportEmail = yup.object().shape({
  emails: yup
    .array()
    .required()
    .of(yup.string().email().lowercase().required()),
})
export type sendReportEmailType = yup.InferType<typeof sendReportEmail>
