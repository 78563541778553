import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Shocked = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M12,23 C5.92486775,23 1,18.0751322 1,12 C1,5.92486775 5.92486775,1 12,1 C18.0751322,1 23,5.92486775 23,12 C23,18.0751322 18.0751322,23 12,23 Z M12,21 C16.9705627,21 21,16.9705627 21,12 C21,7.02943725 16.9705627,3 12,3 C7.02943725,3 3,7.02943725 3,12 C3,16.9705627 7.02943725,21 12,21 Z M12,10 C14.209139,10 16,11.790861 16,14 L16,15 C16,17.209139 14.209139,19 12,19 C9.790861,19 8,17.209139 8,15 L8,14 C8,11.790861 9.790861,10 12,10 Z M12,12 C10.8954305,12 10,12.8954305 10,14 L10,15 C10,16.1045695 10.8954305,17 12,17 C13.1045695,17 14,16.1045695 14,15 L14,14 C14,12.8954305 13.1045695,12 12,12 Z M15,7 L17,7 L17,9 L15,9 L15,7 Z M7,7 L9,7 L9,9 L7,9 L7,7 Z"
    />
  </BaseIcon>
)
