import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Cut = ({
  transform,
  ...rest
}: SVGProps & { transform?: string }) => (
  <BaseIcon {...rest}>
    <path
      transform={transform}
      fillRule="evenodd"
      d="M12,13.6473973 L10.0701764,16.5138634 C10.6506366,17.2081331 11,18.1022674 11,19.078 C11,21.287139 9.209139,23.078 7,23.078 C4.790861,23.078 3,21.287139 3,19.078 C3,16.868861 4.790861,15.078 7,15.078 C7.50945931,15.078 7.99667334,15.1732434 8.44480768,15.3468957 L10.7944902,11.8567916 L4.53447545,2.55847025 L6.19352455,1.44152975 L12,10.066186 L17.8064755,1.44152975 L19.4655245,2.55847025 L13.2055098,11.8567916 L15.5551923,15.3468957 C16.0033267,15.1732434 16.4905407,15.078 17,15.078 C19.209139,15.078 21,16.868861 21,19.078 C21,21.287139 19.209139,23.078 17,23.078 C14.790861,23.078 13,21.287139 13,19.078 C13,18.1022674 13.3493634,17.2081331 13.9298236,16.5138634 L12,13.6473973 Z M7,21.078 C8.1045695,21.078 9,20.1825695 9,19.078 C9,17.9734305 8.1045695,17.078 7,17.078 C5.8954305,17.078 5,17.9734305 5,19.078 C5,20.1825695 5.8954305,21.078 7,21.078 Z M17,21.078 C18.1045695,21.078 19,20.1825695 19,19.078 C19,17.9734305 18.1045695,17.078 17,17.078 C15.8954305,17.078 15,17.9734305 15,19.078 C15,20.1825695 15.8954305,21.078 17,21.078 Z"
    />
  </BaseIcon>
)
