import styled from '@emotion/styled'
import { File } from '@aurelius/icons'
import { Box } from '@chakra-ui/react'

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #545f61;
  border-radius: 2px;
  padding: 8px;
`

const Name = styled.p`
  padding-top: 8px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background: transparent;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.19px;
  color: #545f61;
`

export interface FilePreviewProps {
  name: string
  onPreviewClick?: VoidFunction
}

export const FilePreview = ({
  name,
  onPreviewClick,
  ...rest
}: FilePreviewProps) => {
  return (
    <Wrapper {...rest}>
      <File onClick={onPreviewClick} />
      <Name>{name}</Name>
    </Wrapper>
  )
}
