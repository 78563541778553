import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const BlockAlignVertically = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M8,11 L8,4 C8,2.92670537 8.79431889,2 9.85714286,2 L14.1428571,2 C15.2056811,2 16,2.92670537 16,4 L16,11 L22,11 L22,13 L16,13 L16,20 C16,21.0732946 15.2056811,22 14.1428571,22 L9.85714286,22 C8.79431889,22 8,21.0732946 8,20 L8,13 L2,13 L2,11 L8,11 Z M14,4 L10,4 L10,20 L14,20 L14,4 Z"
    />
  </BaseIcon>
)
