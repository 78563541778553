import styled from '@emotion/styled'
import { Box, BoxProps } from '@chakra-ui/react'

const Wrapper = styled(Box)`
  border: 1px solid var(--colors-placeholders-fill);
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholder-pulse;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(
    to right,
    var(--colors-placeholders-pulse) 8%,
    var(--colors-placeholders-fill) 18%,
    var(--colors-placeholders-pulse) 33%
  );
  background-size: 66% 100%;
  height: 20px;
  position: relative;
  width: ${(props) => props.width || '100%'};

  @keyframes placeholder-pulse {
    0% {
      background-position: -100% 0;
    }
    100% {
      background-position: 100% 0;
    }
  }
`

export const Placeholder = ({
  children,
  ...props
}: React.PropsWithChildren<BoxProps>) => (
  <Wrapper {...props}>{children}</Wrapper>
)

export const PlaceholderGroup = ({ ...props }: BoxProps) => (
  <Box width="100%" {...props}>
    <Placeholder marginBottom="8px" />
    <Placeholder marginBottom="8px" width="50%" />
    <Placeholder marginBottom="8px" width="75%" />
    <Placeholder marginBottom="8px" width="50%" />
    <Placeholder width="25%" />
  </Box>
)

Placeholder.displayName = 'Placeholder'
PlaceholderGroup.displayName = 'PlaceholderGroup'
