import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Share = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M9.91207766,11.1619952 C9.9696849,11.4322343 10,11.7125769 10,12 C10,12.2874231 9.9696849,12.5677657 9.91207766,12.8380048 L14.9830579,15.3734949 C15.716367,14.5318864 16.7960692,14 18,14 C20.209139,14 22,15.790861 22,18 C22,20.209139 20.209139,22 18,22 C15.790861,22 14,20.209139 14,18 C14,17.7125769 14.0303151,17.4322343 14.0879223,17.1619952 L9.01694214,14.6265051 C8.28363296,15.4681136 7.20393084,16 6,16 C3.790861,16 2,14.209139 2,12 C2,9.790861 3.790861,8 6,8 C7.20393084,8 8.28363296,8.53188639 9.01694214,9.37349494 L14.0879223,6.83800484 C14.0303151,6.56776568 14,6.28742308 14,6 C14,3.790861 15.790861,2 18,2 C20.209139,2 22,3.790861 22,6 C22,8.209139 20.209139,10 18,10 C16.7960692,10 15.716367,9.46811361 14.9830579,8.62650506 L9.91207766,11.1619952 Z M6,14 C7.1045695,14 8,13.1045695 8,12 C8,10.8954305 7.1045695,10 6,10 C4.8954305,10 4,10.8954305 4,12 C4,13.1045695 4.8954305,14 6,14 Z M18,8 C19.1045695,8 20,7.1045695 20,6 C20,4.8954305 19.1045695,4 18,4 C16.8954305,4 16,4.8954305 16,6 C16,7.1045695 16.8954305,8 18,8 Z M18,20 C19.1045695,20 20,19.1045695 20,18 C20,16.8954305 19.1045695,16 18,16 C16.8954305,16 16,16.8954305 16,18 C16,19.1045695 16.8954305,20 18,20 Z"
    />
  </BaseIcon>
)
