import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const IPad = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M4,1 L20,1 C21.1045695,1 22,1.8954305 22,3 L22,21 C22,22.1045695 21.1045695,23 20,23 L4,23 C2.8954305,23 2,22.1045695 2,21 L2,3 C2,1.8954305 2.8954305,1 4,1 Z M4,3 L4,21 L20,21 L20,3 L4,3 Z M12,20 C11.4477153,20 11,19.5522847 11,19 C11,18.4477153 11.4477153,18 12,18 C12.5522847,18 13,18.4477153 13,19 C13,19.5522847 12.5522847,20 12,20 Z"
    />
  </BaseIcon>
)
