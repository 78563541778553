import styled from '@emotion/styled'
import { ReactNode, forwardRef, useEffect, useState } from 'react'
import { P, PProps } from './Typography'

const ErrorText = styled(P)`
  line-height: 1;
  font-size: 14px;
  min-height: 14px;
  color: var(--colors-typography-errors);
  transition: opacity 0.5s;
  opacity: 0;

  &.fadeIn {
    opacity: 1;
  }

  a {
    color: var(--colors-typography-link);
    font-size: 14px;
    min-height: 14px;
    font-weight: 400;
    padding: 0;
    transition: var(--transitions-buttons);
    text-decoration: none;

    &:hover {
      color: var(--colors-typography-dark);
      text-decoration: none;
    }

    &:disabled,
    &[disabled] {
      cursor: not-allowed;
    }
  }
`

export const ErrorMessage = forwardRef(
  ({ children, ...props }: PProps, ref) => {
    const [fade, setFade] = useState<ReactNode>(false)
    useEffect(() => {
      if (children != null) {
        setFade(true)
      }
    }, [children])

    return (
      <ErrorText ref={ref as any} className={fade ? 'fadeIn' : ''} {...props}>
        {children}
      </ErrorText>
    )
  },
)
ErrorMessage.displayName = 'Error Message'
