import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Bread = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M21,9.46487122 L21,20 C21,21.1045695 20.1045695,22 19,22 L5,22 C3.8954305,22 3,21.1045695 3,20 L3,9.46487122 C1.80439726,8.77325238 1,7.48056471 1,6 C1,3.790861 2.790861,2 5,2 L19,2 C21.209139,2 23,3.790861 23,6 C23,7.48056471 22.1956027,8.77325238 21,9.46487122 Z M5,20 L19,20 L19,8.31128836 L19.9985481,7.73365949 C20.6144747,7.37736519 21,6.72333537 21,6 C21,4.8954305 20.1045695,4 19,4 L5,4 C3.8954305,4 3,4.8954305 3,6 C3,6.72333537 3.38552532,7.37736519 4.00145191,7.73365949 L5,8.31128836 L5,20 Z"
    />
  </BaseIcon>
)
