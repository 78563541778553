import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Bone = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M5,18 C2.790861,18 1,16.209139 1,14 C1,13.2857412 1.18859035,12.5994674 1.53521022,12 C1.18859035,11.4005326 1,10.7142588 1,10 C1,7.790861 2.790861,6 5,6 C6.45671341,6 7.7639237,6.78584495 8.4650385,8 L15.5349615,8 C16.2360763,6.78584495 17.5432866,6 19,6 C21.209139,6 23,7.790861 23,10 C23,10.7142588 22.8114096,11.4005326 22.4647898,12 C22.8114096,12.5994674 23,13.2857412 23,14 C23,16.209139 21.209139,18 19,18 C17.5432866,18 16.2360763,17.214155 15.5349615,16 L8.4650385,16 C7.7639237,17.214155 6.45671341,18 5,18 Z M6.88645441,14.6667539 L7.1221181,14 L16.8778819,14 L17.1135456,14.6667539 C17.393601,15.4591036 18.1454758,16 19,16 C20.1045695,16 21,15.1045695 21,14 C21,13.5002312 20.8173095,13.0315273 20.4909716,12.6669138 L19.8940676,12 L20.4909716,11.3330862 C20.8173095,10.9684727 21,10.4997688 21,10 C21,8.8954305 20.1045695,8 19,8 C18.1454758,8 17.393601,8.54089645 17.1135456,9.33324608 L16.8778819,10 L7.1221181,10 L6.88645441,9.33324608 C6.606399,8.54089645 5.85452417,8 5,8 C3.8954305,8 3,8.8954305 3,10 C3,10.4997688 3.18269047,10.9684727 3.50902838,11.3330862 L4.10593238,12 L3.50902838,12.6669138 C3.18269047,13.0315273 3,13.5002312 3,14 C3,15.1045695 3.8954305,16 5,16 C5.85452417,16 6.606399,15.4591036 6.88645441,14.6667539 Z"
    />
  </BaseIcon>
)
