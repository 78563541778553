import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Paperclip = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M13.3431459,6.54359691 L14.7681505,7.94693643 L8.44995122,14.362673 C8.13291462,14.6862631 8.13291462,15.2070402 8.44995122,15.5306303 C8.75079611,15.8376941 9.25336598,15.8362945 9.55100179,15.5289435 L16.5023994,8.46733202 C17.5172108,7.43170369 17.5172108,5.76848743 16.5023994,4.7328591 C15.5341634,3.74476165 13.820975,3.76674125 12.8720473,4.73474171 L5.2847529,12.4369015 C3.57174903,14.1843246 3.57174903,16.9902835 5.2847529,18.7377066 C6.94816131,20.4345378 9.80565005,20.4069016 11.4430826,18.7361401 L20.2920826,9.74890528 L21.7172138,11.1521162 L12.8698434,20.1376921 C10.4559333,22.6007416 6.31029227,22.6408362 3.85654347,20.1377844 C1.38115218,17.6126553 1.38115218,13.5619528 3.85824902,11.0350881 L11.4455397,3.33293215 C13.1689626,1.57486193 16.170293,1.53635593 17.930897,3.33307535 C19.7077116,5.14633788 19.7077116,8.05385323 17.9292946,9.86874731 L10.9820731,16.9260714 C9.90740151,18.0359992 8.10955314,18.041006 7.02134515,16.9303034 C5.94244998,15.8291061 5.94244998,14.0641972 7.02314587,12.9611667 L13.3431459,6.54359691 Z"
    />
  </BaseIcon>
)
