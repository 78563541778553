import styled from '@emotion/styled'

const Image = styled.img<{ showPointer: boolean }>`
  max-width: 100%;
  max-height: 350px;

  cursor: ${(props): string => (props.showPointer ? 'pointer' : 'default')};
`

const FullImage = styled.img<{ showPointer: boolean }>`
  max-width: 100vw;
  max-height: 600px;

  cursor: ${(props): string => (props.showPointer ? 'pointer' : 'default')};
`

export interface ImagePreviewProps {
  file: any
  name: string
  thumbnail?: string
  onPreviewClick?: VoidFunction
  preview: boolean
}

export const ImagePreview = ({
  file,
  name,
  thumbnail,
  onPreviewClick,
  preview = true,
}: ImagePreviewProps) => {
  const handleClick = (): void => {
    onPreviewClick?.()
  }

  return (
    <>
      {preview ? (
        <Image
          src={thumbnail}
          alt={name}
          onClick={handleClick}
          showPointer={!!onPreviewClick}
          loading="lazy"
        />
      ) : (
        <FullImage
          src={file}
          alt={name}
          onClick={handleClick}
          showPointer={!!onPreviewClick}
          loading="lazy"
        />
      )}
    </>
  )
  // }
}
