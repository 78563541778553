import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Lightbulb = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M14,17.662434 C13.3675324,17.8869131 12.6993441,18 12,18 C11.3006559,18 10.6324676,17.8869131 10,17.662434 L10,18 C10,19.1045695 10.8954305,20 12,20 C13.1045695,20 14,19.1045695 14,18 L14,17.662434 Z M9.96119734,15.4422586 C10.5843526,15.8162985 11.257995,16 12,16 C12.7439455,16 13.4191703,15.8153364 14.0436906,15.4393201 C14.0947002,15.1627057 14.1895453,14.8913302 14.323842,14.6190958 C14.4816975,14.2991048 14.6824274,13.9942592 14.9771627,13.6021505 C15.0406642,13.5176695 15.5469738,12.8634907 15.6944183,12.6630153 C16.5830391,11.4547873 17,10.4279051 17,9 C17,6.23857625 14.7614237,4 12,4 C9.23857625,4 7,6.23857625 7,9 C7,10.3785497 7.40666099,11.3566146 8.30369772,12.5682122 C8.43271675,12.7424739 8.87702522,13.3197125 8.91347694,13.3679357 C9.1647433,13.7003444 9.34250335,13.9566032 9.4956726,14.2195713 C9.73421839,14.6291177 9.89259264,15.0283115 9.96119734,15.4422586 Z M16,15.915 L16,18 C16,20.209139 14.209139,22 12,22 C9.790861,22 8,20.209139 8,18 L8,15.915 C8,15.7197328 7.93082686,15.5066658 7.76746076,15.2261913 C7.66249697,15.0459846 7.52517799,14.8480256 7.31800344,14.5739474 C7.29171438,14.5391688 6.83869892,13.9506182 6.69630228,13.7582878 C5.56644227,12.2322235 5,10.8698669 5,9 C5,5.13400675 8.13400675,2 12,2 C15.8659932,2 19,5.13400675 19,9 C19,10.9107615 18.4258248,12.3248276 17.3055817,13.8479847 C17.1403863,14.0725954 16.6252317,14.7382025 16.5758825,14.8038555 C16.3495301,15.1049893 16.20892,15.3185318 16.1174685,15.5039143 C16.034707,15.6716812 16,15.8013203 16,15.915 Z"
    />
  </BaseIcon>
)
