import * as yup from 'yup'

export const newRecommendation = yup.object().shape({
  name: yup.string().required('Name is required.'),
  description: yup.string().notRequired(),
  type: yup.string().required('Type is required.'),
  status: yup
    .string()
    .required('Status is required.')
    .oneOf(['not_started', 'in_progress', 'completed', 'none']),
  tags: yup.array().of(yup.string().required()).required(),
  newTags: yup.array().of(yup.string().required()).required(),
  insights: yup.array().of(yup.string().required()).required(),
})

export type newRecommendationType = yup.InferType<typeof newRecommendation>

export const updateRecommendation = yup.object().shape({
  name: yup.string().required('Name is required.'),
  description: yup.string().notRequired(),
  type: yup.string().required('Type is required.'),
  status: yup.string().required('Status is required.'),
})

export type updateRecommendationType = yup.InferType<
  typeof updateRecommendation
>

export const updateRecommendationsTypeField = yup.object().shape({
  ids: yup
    .array()
    .of(yup.string().required())
    .min(1, 'At least one id is required.')
    .required(),
  type: yup.string().required('Type is required.'),
})

export type updateRecommendationsTypeFieldType = yup.InferType<
  typeof updateRecommendationsTypeField
>

export const updateRecommendationsStatusField = yup.object().shape({
  ids: yup
    .array()
    .of(yup.string().required())
    .min(1, 'At least one id is required.')
    .required('At least one id is required.'),
  status: yup
    .string()
    .required('Type is required.')
    .oneOf(['not_started', 'in_progress', 'completed', 'none']),
})

export type updateRecommendationsStatusType = yup.InferType<
  typeof updateRecommendationsStatusField
>

export const arrayOfRecommendations = yup.object().shape({
  recommendations: yup.array().of(yup.string().required()).required(),
})
export type arrayOfRecommendationsType = yup.InferType<
  typeof arrayOfRecommendations
>

export const updateRecommendationClips = yup.object().shape({
  clips: yup.array().of(yup.string().required()).required(),
})

export type updateRecommendationClipsType = yup.InferType<
  typeof updateRecommendationClips
>

export const updateRecommendationHighlightReels = yup.object().shape({
  highlightreels: yup.array().of(yup.string().required()).required(),
})

export type updateRecommendationHighlightReelsType = yup.InferType<
  typeof updateRecommendationHighlightReels
>
