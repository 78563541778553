import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const PlayerFastForward = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M2,19 L2,5 C2,4.14084119 3.01192171,3.68166327 3.65850461,4.24742331 L11.6585046,11.2474233 C12.1138318,11.6458346 12.1138318,12.3541654 11.6585046,12.7525767 L3.65850461,19.7525767 C3.01192171,20.3183367 2,19.8591588 2,19 Z M4,16.7962318 L9.48140774,12 L4,7.20376823 L4,16.7962318 Z M12,19 L12,5 C12,4.14084119 13.0119217,3.68166327 13.6585046,4.24742331 L21.6585046,11.2474233 C22.1138318,11.6458346 22.1138318,12.3541654 21.6585046,12.7525767 L13.6585046,19.7525767 C13.0119217,20.3183367 12,19.8591588 12,19 Z M19.4814077,12 L14,7.20376823 L14,16.7962318 L19.4814077,12 Z"
    />
  </BaseIcon>
)
