import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const MemoryCard = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M8.58578644,1 L19,1 C20.1045695,1 21,1.8954305 21,3 L21,21 C21,22.1045695 20.1045695,23 19,23 L5,23 C3.8954305,23 3,22.1045695 3,21 L3,6.58578644 L8.58578644,1 Z M5,7.41421356 L5,21 L19,21 L19,3 L9.41421356,3 L5,7.41421356 Z M16,4 L18,4 L18,10 L16,10 L16,4 Z M13,4 L15,4 L15,10 L13,10 L13,4 Z M10,4 L12,4 L12,10 L10,10 L10,4 Z M7,7 L9,7 L9,11 L7,11 L7,7 Z"
    />
  </BaseIcon>
)
