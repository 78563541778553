import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Kanban = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M11,4 L11,20 C11,21.1045695 10.1045695,22 9,22 L4,22 C2.8954305,22 2,21.1045695 2,20 L2,4 C2,2.8954305 2.8954305,2 4,2 L9,2 C10.1045695,2 11,2.8954305 11,4 Z M9,4 L4,4 L4,20 L9,20 L9,4 Z M22,4 L22,16 C22,17.1045695 21.1045695,18 20,18 L15,18 C13.8954305,18 13,17.1045695 13,16 L13,4 C13,2.8954305 13.8954305,2 15,2 L20,2 C21.1045695,2 22,2.8954305 22,4 Z M20,4 L15,4 L15,16 L20,16 L20,4 Z"
    />
  </BaseIcon>
)
