import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const XOctagon = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M23,7.44365081 L23,16.5563492 L16.5563492,23 L7.44365081,23 L1,16.5563492 L1,7.44365081 L7.44365081,1 L16.5563492,1 L23,7.44365081 Z M15.7279221,3 L8.27207794,3 L3,8.27207794 L3,15.7279221 L8.27207794,21 L15.7279221,21 L21,15.7279221 L21,8.27207794 L15.7279221,3 Z M12,13.4142136 L8.70710678,16.7071068 L7.29289322,15.2928932 L10.5857864,12 L7.29289322,8.70710678 L8.70710678,7.29289322 L12,10.5857864 L15.2928932,7.29289322 L16.7071068,8.70710678 L13.4142136,12 L16.7071068,15.2928932 L15.2928932,16.7071068 L12,13.4142136 Z"
    />
  </BaseIcon>
)
