import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Code = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M12,23 C5.92486775,23 1,18.0751322 1,12 C1,5.92486775 5.92486775,1 12,1 C18.0751322,1 23,5.92486775 23,12 C23,18.0751322 18.0751322,23 12,23 Z M12,21 C16.9705627,21 21,16.9705627 21,12 C21,7.02943725 16.9705627,3 12,3 C7.02943725,3 3,7.02943725 3,12 C3,16.9705627 7.02943725,21 12,21 Z M9.70710678,15.2928932 L8.29289322,16.7071068 L3.58578644,12 L8.29289322,7.29289322 L9.70710678,8.70710678 L6.41421356,12 L9.70710678,15.2928932 Z M14.2928932,8.70710678 L15.7071068,7.29289322 L20.4142136,12 L15.7071068,16.7071068 L14.2928932,15.2928932 L17.5857864,12 L14.2928932,8.70710678 Z M11.9863939,18.164399 L10.0136061,17.835601 L12.0136061,5.83560101 L13.9863939,6.16439899 L11.9863939,18.164399 Z"
    />
  </BaseIcon>
)
