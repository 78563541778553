import { useRef, useState, useEffect, useCallback } from 'react'
import styled from '@emotion/styled'
import { Box } from '@chakra-ui/react'
import { ControlsClipEditor } from './ControlsClipEditor'
import { Clip, ClipModes } from '@aurelius/models'
import { clipSecondsToFormatted, clipTimeToSeconds } from '@aurelius/utils'
import { ControlProps } from 'react-select'
import ReactPlayer from 'react-player/lazy'
import { Card, CardTitle } from '../Card'
import { Progress } from 'react-sweet-progress'
import { clamp } from 'lodash-es'

const Wrapper = styled(Box)`
  width: 100%;
  position: relative;
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
`

const PlayerWrapper = styled.div`
  position: relative;

  .react-sweet-progress-symbol {
    display: none;
  }
`

const menuItems = [
  {
    display: '0.5x',
    value: 0.5,
  },
  {
    display: '1x',
    value: 1,
  },
  {
    display: '1.5x',
    value: 1.5,
  },
  {
    display: '2x',
    value: 2,
  },
]

export const zeroTime = '00:00:00'

export interface ClipEditorProps {
  file: any
  onSaveClip: (clip: any) => void
  clip?: Clip
  startTime?: number
  endTime?: number
  mode: ClipModes
  isDocument?: boolean
  isReel?: boolean
  onDownload?: () => void
  onCreateClip: () => void
  onNextClip: () => void
  downloading?: boolean
  couldEdit: boolean
  setSelectedStartTime: (time: string) => void
}

export const ClipEditor = ({
  file,
  onSaveClip,
  clip,
  startTime,
  endTime,
  mode,
  isDocument = false,
  isReel = false,
  onDownload,
  onCreateClip,
  onNextClip,
  downloading,
  couldEdit,
  setSelectedStartTime,
}: ClipEditorProps) => {
  const [
    {
      playing,
      seeking,
      played,
      percent,
      sliderVal,
      playbackRate,
      volume,
      loading,
    },
    setState,
  ] = useState({
    playing: false,
    seeking: false,
    played: 0,
    percent: 0,
    sliderVal: 0,
    playbackRate: 1,
    volume: 1,
    loading: true,
  })

  const [totalDuration, setTotalDuration] = useState<string>()
  const [elapsedTime, setElapsedTime] = useState(zeroTime)
  const [selectedEndTime, setSelectedEndTime] = useState<string>()

  const playerRef = useRef<any>(null)
  const controlsRef = useRef<ControlProps>(null)

  // This effect set's the playback start to the beginning after it's loaded
  useEffect(() => {
    if (!loading && playerRef?.current != null) {
      const duration = playerRef?.current?.getDuration()
      const currentTime = playerRef?.current?.getCurrentTime()

      if (duration) {
        if (startTime) {
          const newPlayedValue = startTime / (duration * 1000)
          playerRef?.current?.seekTo(newPlayedValue)
          setState((state) => ({ ...state, played: newPlayedValue }))
        }

        if (mode !== ClipModes.EditMode && clip && currentTime) {
          const elapsedTimeInSecs =
            currentTime - clipTimeToSeconds(clip.startTime)
          if (elapsedTimeInSecs >= (clip?.seconds ?? 0)) {
            setState((state) => ({ ...state, playing: false }))
            setElapsedTime(clipSecondsToFormatted(clip?.seconds ?? 0))
          } else {
            setElapsedTime(clipSecondsToFormatted(elapsedTimeInSecs))
          }
        } else if (currentTime) {
          setElapsedTime(clipSecondsToFormatted(currentTime))
        }

        setTotalDuration(clipSecondsToFormatted(duration))
      }
    }
  }, [clip, loading, mode, startTime])

  useEffect(() => {
    if (clip?.startValue != null) {
      playerRef?.current?.seekTo(clip.startValue / 10000)
    }
  }, [clip])

  // This effect pauses the video at the selected "end" for the clip while in edit mode
  useEffect(() => {
    const player = playerRef?.current?.getInternalPlayer?.() as HTMLVideoElement
    const seconds = clipTimeToSeconds(selectedEndTime ?? zeroTime)
    const timeCop = () => {
      if (seconds) {
        if (selectedEndTime && player?.currentTime > seconds) {
          player?.pause()
        }
      }
    }
    if (selectedEndTime && selectedEndTime != zeroTime) {
      if (!loading) {
        const currentTime = playerRef?.current?.getCurrentTime() ?? 0

        if (
          playing &&
          currentTime &&
          currentTime <= seconds &&
          mode === ClipModes.EditMode &&
          player
        ) {
          player.removeEventListener('timeupdate', timeCop)
          player.addEventListener('timeupdate', timeCop)
        }
      }
    }

    return () => {
      player?.removeEventListener('timeupdate', timeCop)
    }
  }, [mode, playing, selectedEndTime, loading])

  const handleProgress = useCallback(
    (changeState: any) => {
      const currentTime = playerRef?.current?.getCurrentTime()
      const playedState = played
      let playingState = playing
      if (currentTime) {
        if (mode !== ClipModes.EditMode && clip) {
          const elapsedTimeInSecs =
            currentTime - clipTimeToSeconds(clip.startTime)
          if (
            elapsedTimeInSecs >= (clip?.seconds ?? 0) ||
            sliderVal === elapsedTimeInSecs
          ) {
            playingState = false
            setState((state) => ({
              ...state,
              ...{
                playing: playingState,
              },
            }))
            setElapsedTime(clipSecondsToFormatted(clip?.seconds ?? 0))
            onNextClip()
          } else {
            setElapsedTime(clipSecondsToFormatted(elapsedTimeInSecs))
          }
        } else {
          setElapsedTime(clipSecondsToFormatted(currentTime))
        }
      }
      if (!seeking) {
        let newSliderValue = 0
        if (
          playedState > 0 &&
          changeState.played === 0 &&
          (clip?.startValue ?? 1) > 0
        ) {
          if (mode !== ClipModes.EditMode) {
            newSliderValue =
              ((playedState * 10000 - (clip ? clip.startValue : 0)) /
                ((clip ? clip.endValue : 10000) -
                  (clip ? clip.startValue : 0))) *
              10000
          }
          playerRef?.current?.seekTo(playedState)
          setState((state) => ({
            ...state,
            ...{
              ...changeState,
              playing: playingState,
              played: playedState,
              sliderVal: newSliderValue,
            },
          }))
        } else {
          if (mode !== ClipModes.EditMode) {
            newSliderValue =
              ((playedState * 10000 - (clip ? clip.startValue : 0)) /
                ((clip ? clip.endValue : 10000) -
                  (clip ? clip.startValue : 0))) *
              10000
          }
          setState((state) => ({
            ...state,
            ...{
              ...changeState,
              playing: playingState,
              sliderVal: newSliderValue,
            },
          }))
        }
      }
    },
    [clip, mode, onNextClip, played, playing, seeking, sliderVal],
  )

  const handleSeekChange = useCallback(
    (newValue: number) => {
      if (mode !== ClipModes.EditMode) {
        if (clip) {
          const newPlayedValue =
            (clip.startValue +
              newValue * ((clip.endValue - clip.startValue) / 10000)) /
            10000
          setState((state) => ({
            ...state,
            played: newPlayedValue,
            sliderVal: newValue,
          }))
        } else {
          const newPlayedValue = newValue / 10000
          setState((state) => ({
            ...state,
            played: newPlayedValue,
            sliderVal: newValue,
          }))
        }
      } else {
        setState((state) => ({ ...state, played: newValue / 10000 }))
      }
    },
    [clip, mode],
  )

  const handlePlayPause = useCallback(() => {
    if (mode !== ClipModes.EditMode && clip) {
      const currentTime = playerRef?.current?.getCurrentTime()
      if (currentTime) {
        const elapsedTimeInSecs =
          currentTime - clipTimeToSeconds(clip.startTime)
        if (elapsedTimeInSecs < (clip?.seconds ?? 0)) {
          setState((state) => ({ ...state, playing: !playing }))
        }
      }
    } else {
      setState((state) => ({ ...state, playing: !playing }))
    }
  }, [clip, mode, playing])

  const handleSeekMouseDown = useCallback(() => {
    setState((state) => ({ ...state, seeking: true }))
  }, [])

  const handleSeekMouseUp = useCallback(
    (ev: any) => {
      const newValue = ev.target.value
      if (mode !== ClipModes.EditMode && clip) {
        const newPlayedValue =
          (clip.startValue +
            newValue * ((clip.endValue - clip.startValue) / 10000)) /
          10000
        playerRef?.current?.seekTo(newPlayedValue)
        setState((state) => ({ ...state, seeking: false }))
      } else {
        playerRef?.current?.seekTo(newValue / 10000.01)
        setState((state) => ({ ...state, seeking: false }))
      }
    },
    [mode, clip],
  )

  const handleReady = useCallback(() => {
    if (loading) {
      const played = clip?.startValue ? (clip?.startValue ?? 0) / 10000 : 0

      playerRef?.current?.seekTo(played)

      setState((state) => ({
        ...state,
        played,
        percent: 100,
        sliderVal: 0,
        loading: false,
        playing: playing,
      }))
    }
  }, [clip?.startValue, loading, playing])

  const handlePlaybackRateChange = useCallback((rate: number) => {
    setState((state) => ({ ...state, playbackRate: rate }))
  }, [])

  const handleOnSaveClip = useCallback(
    (clip: any) => {
      onSaveClip(clip)
    },
    [onSaveClip],
  )

  const getAmount = useCallback((n: number) => {
    let amount = 0
    if (n >= 0 && n < 0.2) {
      amount = 0.05
    } else if (n >= 0.2 && n < 0.5) {
      amount = 0.005
    } else if (n >= 0.5 && n < 0.8) {
      amount = 0.0025
    } else if (n >= 0.8 && n < 0.99) {
      amount = 0.0001
    } else {
      amount = 0
    }
    amount = clamp(n + amount, 0, 0.994)
    return amount
  }, [])

  useEffect(() => {
    const timer = setInterval(() => {
      if (loading) {
        setState((state) => ({ ...state, percent: getAmount(state.percent) }))
      } else {
        setState((state) => ({ ...state, percent: 100 }))
        clearInterval(timer)
      }
    }, 1000)

    return () => {
      timer && clearInterval(timer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box width="100%" maxHeight="420px">
      <Wrapper>
        <PlayerWrapper>
          {loading && (
            <Box
              as="section"
              position="absolute"
              maxHeight="420px"
              width="100%"
              height="100%"
            >
              <Card
                display="flex"
                flexDirection="column"
                width="fit-content"
                padding="40px"
                margin="40px auto 0 auto"
              >
                <CardTitle marginBottom="40px">
                  🎥 Clip Editor is loading, hang tight! 📽
                </CardTitle>
                <Progress
                  percent={(percent * 100).toFixed(2) as any}
                  status="active"
                />
              </Card>
            </Box>
          )}
          <ReactPlayer
            ref={playerRef}
            url={file}
            playing={playing}
            volume={volume}
            onProgress={handleProgress}
            config={{
              file: {
                forceVideo: true,
              },
            }}
            onReady={handleReady}
            onPause={() => {
              setState((state) => ({ ...state, playing: false }))
            }}
            playbackRate={playbackRate}
            width="100%"
            height="100%"
            style={{ maxHeight: '420px' }}
          />
        </PlayerWrapper>
        {!loading && (
          <ControlsClipEditor
            ref={controlsRef}
            durationTime={
              mode !== ClipModes.EditMode && clip
                ? clip.duration ?? ''
                : totalDuration
            }
            elapsedTime={elapsedTime}
            played={played}
            playing={playing}
            playbackRate={playbackRate}
            menuItems={menuItems}
            onSeek={handleSeekChange}
            onSeekMouseDown={handleSeekMouseDown}
            onSeekMouseUp={handleSeekMouseUp}
            onPlayPause={handlePlayPause}
            onPlaybackRateChange={handlePlaybackRateChange}
            onSaveClip={handleOnSaveClip}
            clip={clip}
            startTimeStamp={startTime}
            endTimeStamp={endTime}
            mode={mode}
            sliderVal={sliderVal}
            isDocument={isDocument}
            isReel={isReel}
            onDownload={onDownload}
            downloading={downloading}
            onClipCreate={onCreateClip}
            couldEdit={couldEdit}
            setSelectedStartTime={setSelectedStartTime}
            setSelectedEndTime={setSelectedEndTime}
          />
        )}
      </Wrapper>
    </Box>
  )
}

ClipEditor.displayName = 'ClipEditor'
