import styled from '@emotion/styled'

const Input = styled.label`
  border: none;
  width: 24px;
  height: 24px;
  border: solid 1px #c9cacc;
`

export interface ColorPickerProps {
  color: string
  onChange: (e: any) => void
}

export const InputColor = ({ color, onChange }: ColorPickerProps) => {
  return (
    <Input style={{ background: color }}>
      <input
        type="color"
        value={color}
        style={{ visibility: 'hidden' }}
        onChange={onChange}
      />
    </Input>
  )
}
