import * as yup from 'yup'

export const zapierWebhook = yup.object().shape({
  'x-api-key': yup.string(),
})
export type zapierWebhookType = yup.InferType<typeof zapierWebhook>

export const zapierNotes = yup.object().shape({
  notes: yup.array().of(yup.string().required()).required(),
  category: yup.string().required(),
})
export type zapierNotesType = yup.InferType<typeof zapierNotes>

export const zapierWebhookEvent = yup.object().shape({
  hookUrl: yup.string().url().required(),
  eventType: yup
    .string()
    .oneOf([
      'recommendation',
      'insight',
      'global-insight',
      'global-recommendation',
    ])
    .required(),
  hookType: yup.string().oneOf(['create', 'update', 'delete']).required(),
})
export type zapierWebhookEventType = yup.InferType<typeof zapierWebhookEvent>

export const zapierWebhookDelete = yup.object().shape({
  eventType: yup.string(),
  id: yup.string(),
})
export type zapierWebhookDeleteType = yup.InferType<typeof zapierWebhookDelete>
