import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const TextColor = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M6,18 L18,18 C19.1045695,18 20,18.8954305 20,20 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,20 C4,18.8954305 4.8954305,18 6,18 Z M6,20 L6,21 L18,21 L18,20 L6,20 Z M15.4118086,12 L8.58819137,12 L7,16 L5,16 L11.0029544,1 L13,1 L19,16 L17,16 L15.4118086,12 Z M14.6177129,10 L12,3.40705914 L9.38228705,10 L14.6177129,10 Z"
    />
  </BaseIcon>
)
