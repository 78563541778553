import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Google = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M13,13 L13,11 L22,11 L22,12 C22,17.5228475 17.5228475,22 12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 C15.0799102,2 17.9312703,3.40249752 19.8167429,5.76259117 L18.2541652,7.01093042 C16.7442641,5.12094914 14.4653084,4 12,4 C7.581722,4 4,7.581722 4,12 C4,16.418278 7.581722,20 12,20 C16.0796177,20 19.4460082,16.9463116 19.9381062,13 L13,13 Z"
    />
  </BaseIcon>
)
