import { Hashtag } from './Hashtag'
import { Base } from './Base'

export enum RequiredForEnum {
  insights = 'insights',
  // recommendations = 'recommendations',
}

export type RequiredFor = {
  [K in keyof typeof RequiredForEnum]: boolean
}

export const RequiredForDisplay: { [K in RequiredForEnum]: string } = {
  [RequiredForEnum.insights]: 'Key Insights',
  // [RequiredForEnum.recommendations]: 'Recommendations',
}
export interface HashtagGroup extends Base {
  name: string
  description: string
  hashtags: Hashtag[]
  requiredFor?: RequiredFor
}

export interface HashtagAggregate {
  groups: HashtagGroup[]
  hashtags: Hashtag[]
}
