import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Radio = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M9.1925824,8 L20,8 C21.1045695,8 22,8.8954305 22,10 L22,20 C22,21.1045695 21.1045695,22 20,22 L4,22 C2.8954305,22 2,21.1045695 2,20 L2,10 C2,8.9709596 2.77716036,8.12343473 3.77656571,8.01234076 L18.6286093,2.07152331 L19.3713907,3.92847669 L9.1925824,8 Z M4,10 L4,20 L20,20 L20,10 L4,10 Z M14,17 L14,15 L19,15 L19,17 L14,17 Z M14,14 L14,12 L19,12 L19,14 L14,14 Z M9,19 C6.790861,19 5,17.209139 5,15 C5,12.790861 6.790861,11 9,11 C11.209139,11 13,12.790861 13,15 C13,17.209139 11.209139,19 9,19 Z M9,17 C10.1045695,17 11,16.1045695 11,15 C11,13.8954305 10.1045695,13 9,13 C7.8954305,13 7,13.8954305 7,15 C7,16.1045695 7.8954305,17 9,17 Z M9,16 C8.44771525,16 8,15.5522847 8,15 C8,14.4477153 8.44771525,14 9,14 C9.55228475,14 10,14.4477153 10,15 C10,15.5522847 9.55228475,16 9,16 Z"
    />
  </BaseIcon>
)
