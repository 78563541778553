import { useState, useEffect } from 'react'
import styled from '@emotion/styled'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
`

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 50px;

  input[type='range'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    outline: none;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    background-color: transparent;
    pointer-events: none;
  }

  input[type='range']::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    height: 5px;
  }

  input[type='range']::-moz-range-track {
    -moz-appearance: none;
    height: 5px;
  }

  input[type='range']::-ms-track {
    appearance: none;
    height: 5px;
  }

  #main-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 15px;
    width: 3px;
    background-color: #3af6c2;
    cursor: pointer;
    pointer-events: auto;
    margin-top: -5px;
  }

  #main-slider::-moz-range-thumb {
    -moz-appearance: none;
    height: 15px;
    width: 3px;
    background-color: #3af6c2;
    cursor: pointer;
    pointer-events: auto;
    margin-top: -12px;
  }

  #main-slider::-ms-thumb {
    appearance: none;
    height: 15px;
    width: 3px;
    background-color: #3af6c2;
    cursor: pointer;
    pointer-events: auto;
    margin-top: -12px;
  }

  #first-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 12px;
    width: 12px;
    border-radius: 100%;
    background-color: #148306;
    cursor: pointer;
    pointer-events: auto;
    margin-top: -20px;
  }

  #first-slider::-moz-range-thumb {
    -moz-appearance: none;
    height: 12px;
    width: 12px;
    border-radius: 100%;
    background-color: #148306;
    cursor: pointer;
    pointer-events: auto;
    margin-top: -20px;
  }

  #first-slider::-ms-thumb {
    appearance: none;
    height: 12px;
    width: 12px;
    border-radius: 100%;
    background-color: #148306;
    cursor: pointer;
    pointer-events: auto;
    margin-top: -20px;
  }

  #first-bottom-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 20px;
    width: 4px;
    background-color: #148306;
    cursor: pointer;
    pointer-events: auto;
    margin-top: -10px;
  }

  #first-bottom-slider::-moz-range-thumb {
    -moz-appearance: none;
    height: 20px;
    width: 4px;
    background-color: #148306;
    cursor: pointer;
    pointer-events: auto;
    margin-top: -10px;
  }

  #first-bottom-slider::-ms-thumb {
    appearance: none;
    height: 20px;
    width: 4px;
    background-color: #148306;
    cursor: pointer;
    pointer-events: auto;
    margin-top: -10px;
  }

  #last-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 12px;
    width: 12px;
    border-radius: 100%;
    background-color: #9b0000;
    cursor: pointer;
    pointer-events: auto;
    margin-top: -20px;
  }

  #last-slider::-moz-range-thumb {
    -moz-appearance: none;
    height: 12px;
    width: 12px;
    border-radius: 100%;
    background-color: #9b0000;
    cursor: pointer;
    pointer-events: auto;
    margin-top: -20px;
  }

  #last-slider::-ms-thumb {
    appearance: none;
    height: 12px;
    width: 12px;
    border-radius: 100%;
    background-color: #9b0000;
    cursor: pointer;
    pointer-events: auto;
    margin-top: -20px;
  }

  #last-bottom-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 20px;
    width: 4px;
    background-color: #9b0000;
    cursor: pointer;
    pointer-events: auto;
    margin-top: -10px;
  }

  #last-bottom-slider::-moz-range-thumb {
    -moz-appearance: none;
    height: 20px;
    width: 4px;
    background-color: #9b0000;
    cursor: pointer;
    pointer-events: auto;
    margin-top: -10px;
  }

  #last-bottom-slider::-ms-thumb {
    appearance: none;
    height: 20px;
    width: 4px;
    background-color: #9b0000;
    cursor: pointer;
    pointer-events: auto;
    margin-top: -10px;
  }
`

const SliderTrack = styled.div`
  width: 100%;
  height: 15px;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  border-radius: 5px;
`

export interface DualSliderProps {
  startValue: number
  endValue: number
  mainValue: number
  minGap?: number
  onHandleFirstValue?: (val: number) => void
  onHandleLastValue?: (val: number) => void
  onChangeMainValue?: (val: number) => void
  onSeekMouseDown?: () => void
  onSeekMouseUp?: (ev: any) => void
  readOnly?: boolean
}

export const DualSlider = ({
  startValue,
  endValue,
  mainValue,
  minGap = 0.1,
  onHandleFirstValue,
  onHandleLastValue,
  onChangeMainValue,
  onSeekMouseDown,
  onSeekMouseUp,
  readOnly = false,
}: DualSliderProps) => {
  const [firstValue, setFirstValue] = useState(startValue)
  const [lastValue, setLastValue] = useState(endValue)

  useEffect(() => {
    setFirstValue(startValue)
    setLastValue(endValue)
  }, [])

  useEffect(() => {
    setFirstValue(startValue)
  }, [startValue])

  useEffect(() => {
    setLastValue(endValue)
  }, [endValue])

  const handleFirstSlider = (ev: any) => {
    const value = Number(ev.target.value)
    if (lastValue - value <= minGap) {
      setFirstValue(lastValue - minGap)
      if (onHandleFirstValue) {
        onHandleFirstValue(lastValue - minGap)
      }
    } else {
      setFirstValue(value)
      if (onHandleFirstValue) {
        onHandleFirstValue(value)
      }
    }
  }

  const handleLastSlider = (ev: any) => {
    const value = Number(ev.target.value)
    if (value - firstValue <= minGap) {
      setLastValue(firstValue + minGap)
      if (onHandleLastValue) {
        onHandleLastValue(firstValue + minGap)
      }
    } else {
      setLastValue(value)
      if (onHandleLastValue) {
        onHandleLastValue(value)
      }
    }
  }

  const handleMainValue = (ev: any) => {
    if (onChangeMainValue) {
      onChangeMainValue(Number(ev.target.value))
    }
  }

  const calcMarginLeft = (sliderValue: number) => {
    if (sliderValue <= 5000) {
      const value = (4 * sliderValue) / 5000
      return 4 - value + 'px'
    } else {
      const value = (4 * (sliderValue - 5000)) / 5000
      return 0 - value + 'px'
    }
  }

  return (
    <Wrapper>
      <Container>
        <SliderTrack
          style={{
            background: `linear-gradient( to right, #575656 ${
              firstValue / 100
            }%, rgba(93, 186, 202, 0.4) ${
              firstValue / 100
            }%, rgba(93, 186, 202, 0.4) ${lastValue / 100}%, #575656 ${
              lastValue / 100
            }% )`,
          }}
        />
        <input
          disabled={readOnly}
          type="range"
          min="0"
          max="10000"
          value={firstValue}
          onChange={handleFirstSlider}
          id="first-bottom-slider"
          style={{
            marginLeft: calcMarginLeft(firstValue),
          }}
        />
        <input
          disabled={readOnly}
          type="range"
          min="0"
          max="10000"
          value={firstValue}
          onChange={handleFirstSlider}
          id="first-slider"
        />
        <input
          disabled={readOnly}
          type="range"
          min="0"
          max="10000"
          value={lastValue}
          onChange={handleLastSlider}
          id="last-bottom-slider"
          style={{
            marginLeft: calcMarginLeft(lastValue),
          }}
        />
        <input
          disabled={readOnly}
          type="range"
          min="0"
          max="10000"
          value={lastValue}
          onChange={handleLastSlider}
          id="last-slider"
        />
        <input
          type="range"
          min="0"
          max="10000"
          value={mainValue}
          onChange={handleMainValue}
          onMouseDown={onSeekMouseDown}
          onMouseUp={onSeekMouseUp}
          id="main-slider"
        />
      </Container>
    </Wrapper>
  )
}
