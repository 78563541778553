import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Note = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M20,15 L20,4 L4,4 L4,20 L15,20 L15,17 C15,15.8954305 15.8954305,15 17,15 L20,15 Z M19.5857864,17 L17,17 L17,19.5857864 L19.5857864,17 Z M4,22 C2.8954305,22 2,21.1045695 2,20 L2,4 C2,2.8954305 2.8954305,2 4,2 L20,2 C21.1045695,2 22,2.8954305 22,4 L22,17.4142136 L17.4142136,22 L4,22 Z"
    />
  </BaseIcon>
)
