import { BaseIcon, SVGProps } from '@aurelius/icons'

export const LayoutV = ({ ...rest }: SVGProps) => (
  <BaseIcon title="Vertical" viewBox="0 0 17 17" {...rest}>
    <g id="Page-1" stroke="none" fill="none">
      <g
        id="Analysis---themes---vertical-layout"
        transform="translate(-755.000000, -175.000000)"
        fill={rest.fill}
      >
        <g id="Group-5" transform="translate(664.000000, 171.000000)">
          <g
            id="Group-26-Copy"
            transform="translate(99.500000, 12.500000) rotate(-270.000000) translate(-99.500000, -12.500000) translate(87.000000, 0.000000)"
          >
            <path
              d="M21,19.3 C21,20.2388841 20.2388841,21 19.3,21 L5.7,21 C4.76111593,21 4,20.2388841 4,19.3 L4,5.7 C4,4.76111593 4.76111593,4 5.7,4 L19.3,4 C20.2388841,4 21,4.76111593 21,5.7 L21,19.3 Z M19.3,13.35 L5.7,13.35 L5.7,19.3 L19.3,19.3 L19.3,13.35 Z M19.3,5.7 L5.7,5.7 L5.7,11.649 L19.3,11.649 L19.3,5.7 Z"
              id="icon"
            />
          </g>
        </g>
      </g>
    </g>
  </BaseIcon>
)
