import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const ArrowsHv = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <polygon
      fillRule="evenodd"
      points="19.586 13 13 13 13 19.586 15.293 17.293 16.707 18.707 12 23.414 7.293 18.707 8.707 17.293 11 19.586 11 13 4.414 13 6.707 15.293 5.293 16.707 .586 12 5.293 7.293 6.707 8.707 4.414 11 11 11 11 4.414 8.707 6.707 7.293 5.293 12 .586 16.707 5.293 15.293 6.707 13 4.414 13 11 19.586 11 17.293 8.707 18.707 7.293 23.414 12 18.707 16.707 17.293 15.293"
    />
  </BaseIcon>
)
