import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const CC0 = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M12.8221414,8.11964931 C12.571438,8.03762988 12.2964982,8 12,8 C10.5309801,8 9.5,9.12470553 9.5,12 C9.5,12.8966894 9.60026969,13.6231223 9.78282493,14.1982822 L12.8221414,8.11964931 Z M14.2352182,9.7656316 L11.1861235,15.863821 C11.4373238,15.9562098 11.7101344,16 12,16 C13.5412741,16 14.5,14.9831695 14.5,12 C14.5,11.0759518 14.4080127,10.3405689 14.2352182,9.7656316 Z M12,1 C18.0751322,1 23,5.92486775 23,12 C23,18.0751322 18.0751322,23 12,23 C5.92486775,23 1,18.0751322 1,12 C1,5.92486775 5.92486775,1 12,1 Z M12,3 C7.02943725,3 3,7.02943725 3,12 C3,16.9705627 7.02943725,21 12,21 C16.9705627,21 21,16.9705627 21,12 C21,7.02943725 16.9705627,3 12,3 Z M12,6 C14.6730116,6 16.5,7.93771499 16.5,12 C16.5,16.062285 14.6730116,18 12,18 C9.3856866,18 7.5,15.9428873 7.5,12 C7.5,8.05711266 9.3856866,6 12,6 Z"
    />
  </BaseIcon>
)
