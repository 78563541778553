import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Airplay = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M19.2857143,19 L17.8571429,17 L21,17 L21,5 L3,5 L3,17 L6.14285714,17 L4.71428571,19 L3,19 C1.8954305,19 1,18.1045695 1,17 L1,5 C1,3.8954305 1.8954305,3 3,3 L21,3 C22.1045695,3 23,3.8954305 23,5 L23,17 C23,18.1045695 22.1045695,19 21,19 L19.2857143,19 Z M12,11.2795349 L18.9431893,21 L5.05681068,21 L12,11.2795349 Z M8.94318932,19 L15.0568107,19 L12,14.7204651 L8.94318932,19 Z"
    />
  </BaseIcon>
)
