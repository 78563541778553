import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const TimeHistory = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M5.11409306,6 L8,6 L8,8 L2,8 L2,1.99121094 L4,1.99121094 L4,4.25644966 C6.23707736,1.91055844 8.78662625,1 12,1 C18.0751322,1 23,5.92486775 23,12 C23,18.0751322 18.0751322,23 12,23 C5.92486775,23 1,18.0751322 1,12 L3,12 C3,16.9705627 7.02943725,21 12,21 C16.9705627,21 21,16.9705627 21,12 C21,7.02943725 16.9705627,3 12,3 C9.1592152,3 7.04465569,3.7913744 5.11409306,6 Z M13,11 L17,11 L17,13 L11,13 L11,6 L13,6 L13,11 Z"
    />
  </BaseIcon>
)
