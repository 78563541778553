import * as yup from 'yup'
import { removeURLs } from './purchase'

export const profileUpdate = yup.object().shape({
  email: yup.string().email().lowercase().required(),
  currentPassword: yup.string().required().nonNullable(),
})
export type profileUpdateType = yup.InferType<typeof profileUpdate>

export const profileUpdatePassword = yup.object().shape({
  password: yup.string().required(),
  currentPassword: yup.string().required().nonNullable(),
})
export type profileUpdatePasswordType = yup.InferType<
  typeof profileUpdatePassword
>

export const profileUpdateName = yup.object().shape({
  firstname: yup
    .string()
    .trim()
    .required('First Name is required.')
    .transform(removeURLs),
  lastname: yup
    .string()
    .trim()
    .required('Last Name is required.')
    .transform(removeURLs),
})
export type profileUpdateNameType = yup.InferType<typeof profileUpdateName>

export const userTips = yup.object().shape({
  projectList: yup.boolean(),
  projectOverview: yup.boolean(),
  projectNotes: yup.boolean(),
  projectTags: yup.boolean(),
  projectKeyInsights: yup.boolean(),
  projectRecommendations: yup.boolean(),
  projectReports: yup.boolean(),
  allTags: yup.boolean(),
  collections: yup.boolean(),
  search: yup.boolean(),
  ZoomMeetingsCard: yup.boolean(),
  createGlobalInsight: yup.boolean(),
  themeBoard: yup.boolean(),
})

export type userTipsType = yup.InferType<typeof userTips>
