import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const ArrowBoldTop = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M8.1801961,12 L2.79623177,12 L12,1.48140774 L21.2037682,12 L15.8198039,12 L13.8198039,22 L10.1801961,22 L8.1801961,12 Z M16.7962318,10 L12,4.51859226 L7.20376823,10 L9.8198039,10 L11.8198039,20 L12.1801961,20 L14.1801961,10 L16.7962318,10 Z"
    />
  </BaseIcon>
)
