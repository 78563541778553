import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Volume = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M6.76393202,7 L14,3.38196601 L14,20.618034 L6.76393202,17 L3,17 C1.8954305,17 1,16.1045695 1,15 L1,9 C1,7.8954305 1.8954305,7 3,7 L6.76393202,7 Z M8,8.61803399 L8,15.381966 L12,17.381966 L12,6.61803399 L8,8.61803399 Z M6,9 L3,9 L3,15 L6,15 L6,9 Z"
    />
  </BaseIcon>
)
