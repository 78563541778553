import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const CircleBlock = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M4.96810063,6.38231419 C3.73647287,7.92198502 3,9.87498629 3,12 C3,16.9705627 7.02943725,21 12,21 C14.1250137,21 16.078015,20.2635271 17.6176858,19.0318994 L4.96810063,6.38231419 Z M6.38231419,4.96810063 L19.0318994,17.6176858 C20.2635271,16.078015 21,14.1250137 21,12 C21,7.02943725 16.9705627,3 12,3 C9.87498629,3 7.92198502,3.73647287 6.38231419,4.96810063 Z M12,23 C5.92486775,23 1,18.0751322 1,12 C1,5.92486775 5.92486775,1 12,1 C18.0751322,1 23,5.92486775 23,12 C23,18.0751322 18.0751322,23 12,23 Z"
    />
  </BaseIcon>
)
