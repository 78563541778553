import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const FlashCard = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M8,17 L1,17 L1,7 L8,7 L8,5 L18,5 C20.7614237,5 23,7.23857625 23,10 L23,14 C23,16.7614237 20.7614237,19 18,19 L8,19 L8,17 Z M10,17 L18,17 C19.6568542,17 21,15.6568542 21,14 L21,10 C21,8.34314575 19.6568542,7 18,7 L10,7 L10,17 Z M3,9 L3,15 L8,15 L8,9 L3,9 Z M4,11 L7,11 L7,13 L4,13 L4,11 Z"
    />
  </BaseIcon>
)
