import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const HighlightReel = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-892.000000, -495.000000)">
        <g transform="translate(876.000000, 280.000000)">
          <g transform="translate(15.000000, 11.000000)">
            <g transform="translate(2.000000, 205.000000)">
              <g stroke="#00678D" strokeWidth="1.5">
                <path
                  d="M15,6 L15,19 C15,20.1045695 14.1045695,21 13,21 L0,21 L0,21"
                  transform="translate(7.500000, 13.500000) rotate(-270.000000) translate(-7.500000, -13.500000) "
                ></path>
                <path
                  d="M18,3 L18,16 C18,17.1045695 17.1045695,18 16,18 L3,18 L3,18"
                  transform="translate(10.500000, 10.500000) rotate(-270.000000) translate(-10.500000, -10.500000) "
                ></path>
                <path
                  d="M21,2 L21,13 C21,14.1045695 20.1045695,15 19,15 L8,15 C6.8954305,15 6,14.1045695 6,13 L6,2 C6,0.8954305 6.8954305,2.02906125e-16 8,0 L19,0 C20.1045695,-2.02906125e-16 21,0.8954305 21,2 Z"
                  transform="translate(13.500000, 7.500000) rotate(-270.000000) translate(-13.500000, -7.500000) "
                ></path>
              </g>
              <path
                d="M12.7906977,4.65625 L12.7906977,6.625 L10.6976744,6.625 C10.3116279,6.625 10,6.918125 10,7.28125 C10,7.644375 10.3116279,7.9375 10.6976744,7.9375 L12.7906977,7.9375 L12.7906977,9.90625 C12.7906977,10.269375 13.1023256,10.5625 13.4883721,10.5625 C13.8744186,10.5625 14.1860465,10.269375 14.1860465,9.90625 L14.1860465,7.9375 L16.2790698,7.9375 C16.6651163,7.9375 16.9767442,7.644375 16.9767442,7.28125 C16.9767442,6.918125 16.6651163,6.625 16.2790698,6.625 L14.1860465,6.625 L14.1860465,4.65625 C14.1860465,4.293125 13.8744186,4 13.4883721,4 C13.1023256,4 12.7906977,4.293125 12.7906977,4.65625 Z"
                fill="#00678D"
                fillRule="nonzero"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </BaseIcon>
)
