import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Tuner = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M8,12 L8,22 L6,22 L6,12 L5,12 C3.8954305,12 3,11.1045695 3,10 L3,9 C3,7.8954305 3.8954305,7 5,7 L9,7 C10.1045695,7 11,7.8954305 11,9 L11,10 C11,11.1045695 10.1045695,12 9,12 L8,12 Z M18,17 L18,22 L16,22 L16,17 L15,17 C13.8954305,17 13,16.1045695 13,15 L13,14 C13,12.8954305 13.8954305,12 15,12 L19,12 C20.1045695,12 21,12.8954305 21,14 L21,15 C21,16.1045695 20.1045695,17 19,17 L18,17 Z M8,6 L6,6 L6,2 L8,2 L8,6 Z M18,11 L16,11 L16,2 L18,2 L18,11 Z M5,9 L5,10 L9,10 L9,9 L5,9 Z M15,14 L15,15 L19,15 L19,14 L15,14 Z"
    />
  </BaseIcon>
)
