import { BaseIcon, SVGProps } from '@aurelius/icons'

export const PauseBlueIcon = ({ ...rest }: SVGProps) => (
  <BaseIcon title="Pause Icon" viewBox="0 0 20 20" {...rest}>
    <g
      id="Highlights-+-Clips-V5"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Documents-Page–Clips–Playing"
        transform="translate(-139.000000, -637.000000)"
        fill="#00678D"
      >
        <g id="Clip-Creator-–-Play" transform="translate(80.000000, 47.000000)">
          <g id="Group-18-Copy" transform="translate(24.000000, 580.000000)">
            <g id="Group-5" transform="translate(35.000000, 10.000000)">
              <rect id="Rectangle" x="0" y="0" width="7" height="20"></rect>
              <rect
                id="Rectangle-Copy-23"
                x="13"
                y="0"
                width="7"
                height="20"
              ></rect>
            </g>
          </g>
        </g>
      </g>
    </g>
  </BaseIcon>
)
