import * as yup from 'yup'

export const newInsight = yup.object().shape({
  name: yup.string().required('Name is required.'),
  description: yup.string().nonNullable(),
  type: yup.string().required('Type is required.'),
  insType: yup.string(),
  priority: yup.string().required('Priority is required.'),
  tags: yup.array().of(yup.string().required()).default([]),
  newTags: yup.array().of(yup.string().required()).default([]),
  recommendations: yup.array().of(yup.object().shape({ id: yup.string() })),
})

export const updateInsight = yup.object().shape({
  name: yup.string().required('Name is required.'),
  description: yup.string(),
  type: yup.string().required('Type is required.'),
  insType: yup.string(),
  priority: yup.string().required('Priority is required.'),
  tags: yup.array().of(yup.string().required()).default([]),
  newTags: yup.array().of(yup.string().required()).default([]),
  recommendations: yup.array().of(yup.object().shape({ id: yup.string() })),
})

export const arrayOfInsights = yup.object().shape({
  insights: yup.array().of(yup.string().required()).required(),
})

export const updateInsightDocuments = yup.object().shape({
  documents: yup.array().of(yup.string().required()).required(),
})

export const updateInsightClips = yup.object().shape({
  clips: yup.array().of(yup.string().required()).required(),
})

export type updateInsightDocumentsType = yup.InferType<
  typeof updateInsightDocuments
>

export type updateInsightClipsType = yup.InferType<typeof updateInsightClips>

export type newInsightType = yup.InferType<typeof newInsight>
export type arrayOfInsightsType = yup.InferType<typeof arrayOfInsights>
export type updateInsightType = yup.InferType<typeof updateInsight>
