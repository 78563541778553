import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Bookmark = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M12,19.118034 L5,22.618034 L5,4 C5,2.8954305 5.8954305,2 7,2 L17,2 C18.1045695,2 19,2.8954305 19,4 L19,22.618034 L12,19.118034 Z M7,19.381966 L12,16.881966 L17,19.381966 L17,4 L7,4 L7,19.381966 Z"
    />
  </BaseIcon>
)
