import * as yup from 'yup'
import { nameAndDescription } from './name'

export const newProject = nameAndDescription.shape({
  customDate: yup.date().notRequired(),
  owner: yup.string().notRequired(),
  isPrivate: yup.boolean().required(),
  users: yup.array().of(yup.string().required()).notRequired(),
})
export type newProjectType = yup.InferType<typeof newProject>

export const existingProject = yup.object().shape({
  id: yup.string().required(),
  name: yup.string().trim().required('Name is required.'),
  description: yup.string().trim().notRequired().nonNullable(),
  customDate: yup.date().notRequired(),
  owner: yup.string().notRequired(),
  isPrivate: yup.boolean().required(),
  users: yup.array().of(yup.string().required()).notRequired(),
})
export type existingProjectType = yup.InferType<typeof existingProject>

export const searchTags = yup.object().shape({
  search: yup.string().notRequired().trim().nonNullable(),
  sort: yup.string().notRequired().trim().nonNullable(),
})
export type searchTagsType = yup.InferType<typeof searchTags>

export const projectPermissions = yup.object().shape({
  ownerId: yup.string().required(),
  users: yup.array().of(yup.string().required()).required(),
  isPrivate: yup.boolean().required(),
})
export type projectPermissionsType = yup.InferType<typeof projectPermissions>
