import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const BlockAlignRight = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M19,9.85714286 L19,14.1428571 C19,15.2056811 18.0732946,16 17,16 L4,16 C2.92670537,16 2,15.2056811 2,14.1428571 L2,9.85714286 C2,8.79431889 2.92670537,8 4,8 L17,8 C18.0732946,8 19,8.79431889 19,9.85714286 Z M4,10 L4,14 L17,14 L17,10 L4,10 Z M20,2 L22,2 L22,22 L20,22 L20,2 Z"
    />
  </BaseIcon>
)
