import { DetailedHTMLProps, AnchorHTMLAttributes } from 'react'
import { Box, BoxProps } from '@chakra-ui/react'
import styled from '@emotion/styled'
import Link from 'next/link'

const CustomNavWrapper = styled.div`
  a {
    display: inline-block;
    font-size: 16px;
    font-weight: normal;

    font-style: normal;
    line-height: normal;
    letter-spacing: 0.17px;
    color: var(--colors-inputs-text-color);
    border-radius: 8px;
    margin: 0;
    padding: 12px 20px;
    text-decoration: none;
    border: 2px solid transparent;
    text-align: center;
    &:hover {
      border: 2px solid var(--colors-inputs-text-color);
    }
    &.active {
      font-weight: bold;
      background-color: var(--colors-horizontal-bar-background);
    }
  }
`

const Navigation = styled(Box)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  background-color: transparent;
  margin-top: auto;
  margin-bottom: auto;
`

export interface SubNavProps {
  links: ({ name: string; to: string; isActive?: boolean } & DetailedHTMLProps<
    AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  >)[]
  path: string
}

/**
 * By default, classname="active" will check if `path === link.href`
 */
export const SubNav = ({ path, links, ...rest }: SubNavProps & BoxProps) => {
  return (
    <Navigation {...rest}>
      {links.map((link) => (
        <Box key={link.name}>
          <CustomNavWrapper>
            <Link
              className={path === link.href || link.isActive ? 'active' : ''}
              href={link.to}
            >
              {link.name}
            </Link>
          </CustomNavWrapper>
        </Box>
      ))}
    </Navigation>
  )
}

SubNav.displayName = 'SubNav'
