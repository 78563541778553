import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Tag = ({ fill = 'var(--colors-icons)', ...rest }: SVGProps) => (
  <BaseIcon fill={fill} {...rest}>
    <path
      fillRule="evenodd"
      d="M6.53518376,5 L21,5 C22.1045695,5 23,5.8954305 23,7 L23,17 C23,18.1045695 22.1045695,19 21,19 L6.53518376,19 C5.86647738,19 5.24201473,18.6657977 4.87108317,18.1094004 L1.16794971,12.5547002 C0.944016765,12.2188008 0.944016765,11.7811992 1.16794971,11.4452998 L4.87108317,5.89059961 C5.24201473,5.33420227 5.86647738,5 6.53518376,5 Z M3.20185043,12 L6.53518376,17 L21,17 L21,7 L6.53518376,7 L3.20185043,12 Z M7,13 C6.44771525,13 6,12.5522847 6,12 C6,11.4477153 6.44771525,11 7,11 C7.55228475,11 8,11.4477153 8,12 C8,12.5522847 7.55228475,13 7,13 Z"
    />
  </BaseIcon>
)
