import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Peace = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M13,3.05492878 L13,11.5857864 L19.0318994,17.6176858 C20.2635271,16.078015 21,14.1250137 21,12 C21,7.36744635 17.4999505,3.55237307 13,3.05492878 Z M17.6176858,19.0318994 L13,14.4142136 L13,20.9450712 C14.735853,20.7531824 16.3229182,20.0676219 17.6176858,19.0318994 Z M11,20.9450712 L11,14.4142136 L6.38231419,19.0318994 C7.67708178,20.0676219 9.26414698,20.7531824 11,20.9450712 Z M4.96810063,17.6176858 L11,11.5857864 L11,3.05492878 C6.50004954,3.55237307 3,7.36744635 3,12 C3,14.1250137 3.73647287,16.078015 4.96810063,17.6176858 Z M12,1 C18.0751322,1 23,5.92486775 23,12 C23,18.0751322 18.0751322,23 12,23 C5.92486775,23 1,18.0751322 1,12 C1,5.92486775 5.92486775,1 12,1 Z"
    />
  </BaseIcon>
)
