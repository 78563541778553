import { useEditor, useNode } from '@craftjs/core'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorFallback } from '../ErrorFallback'

export const Line = () => {
  const {
    isSelected,
    isHovered,
    connectors: { connect, drag },
    marginTop = 24,
    marginRight = 0,
    marginBottom = 24,
    marginLeft = 0,
    paddingTop = 0,
    paddingRight = 0,
    paddingBottom = 0,
    paddingLeft = 0,
    width,
    color,
    backgroundColor = '#d7dddf',
  } = useNode((state) => ({
    isSelected: state.events.selected,
    isHovered: state.events.hovered,
    dragged: state.events.dragged,
    isActive: state.events.selected,
    marginTop: state.data.props.marginTop,
    marginRight: state.data.props.marginRight,
    marginBottom: state.data.props.marginBottom,
    marginLeft: state.data.props.marginLeft,
    paddingTop: state.data.props.paddingTop,
    paddingRight: state.data.props.paddingRight,
    paddingBottom: state.data.props.paddingBottom,
    paddingLeft: state.data.props.paddingLeft,
    width: state.data.props.width,
    color: state.data.props.color,
    backgroundColor: state.data.props.backgroundColor,
  }))
  const { enabled } = useEditor((state) => ({ enabled: state.options.enabled }))

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <div ref={(ref): any => (ref && enabled ? connect(drag(ref)) : ref)}>
        <div
          style={{
            marginTop,
            marginRight,
            marginBottom,
            marginLeft,
            paddingTop,
            paddingRight,
            paddingBottom,
            paddingLeft,
            width: `${width}%`,
            height: '2px',
            color,
            backgroundColor,
            border: isSelected
              ? '1px solid #024660'
              : isHovered
              ? '1px dashed #024660'
              : '',
          }}
        />
      </div>
    </ErrorBoundary>
  )
}

Line.displayName = 'Line'
//@ts-ignore
Line.craft = {
  displayName: 'Line',
}
