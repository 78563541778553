import * as yup from 'yup'
import { removeURLs } from './purchase'

export const signup = yup.object().shape({
  company: yup
    .string()
    .trim()
    .required('Company is required.')
    .transform(removeURLs),
  firstname: yup
    .string()
    .trim()
    .required('First Name is required.')
    .transform(removeURLs),
  lastname: yup
    .string()
    .trim()
    .required('Last Name is required.')
    .transform(removeURLs),
  email: yup
    .string()
    .email()
    .lowercase()
    .trim()
    .required('A valid email address required.'),
  password: yup
    .string()
    .trim()
    .min(8, 'Password must be at least 8 characters.')
    .required('Password is required.'),
  acceptedCustomerTOS: yup
    .boolean()
    .required()
    .oneOf([true], 'You must accept the Terms of Service.'),
  au_password: yup.string().notRequired(),
  recaptcha: yup.string().trim().required('reCaptcha token is required.'),
})

export type signupType = yup.InferType<typeof signup>
