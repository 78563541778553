import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Moon = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M15.2398038,3.86764935 C13.8204659,5.18476989 13,7.02542894 13,9 C13,12.8659932 16.1340068,16 20,16 C20.1451744,16 20.2897556,15.9955942 20.4336241,15.986821 L22.4431545,15.8642776 L21.3266144,17.5395589 C19.4842735,20.3038478 16.3853769,22 13,22 C7.4771525,22 3,17.5228475 3,12 C3,6.4771525 7.4771525,2 13,2 C13.5846805,2 14.1634362,2.05028518 14.7316831,2.14956084 L16.7173912,2.49647429 L15.2398038,3.86764935 Z M5,12 C5,16.418278 8.581722,20 13,20 C15.0609678,20 16.9878304,19.215338 18.4414082,17.8655328 C14.2137018,17.1274203 11,13.4390103 11,9 C11,7.19183498 11.5366814,5.46848045 12.5051382,4.01505578 C8.31734829,4.27062453 5,7.74790816 5,12 Z"
    />
  </BaseIcon>
)
