import { P } from './Typography'
import styled from '@emotion/styled'
import {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  useEffect,
  useMemo,
  ReactPortal,
  PropsWithChildren,
} from 'react'
import { createPortal } from 'react-dom'
import { Plus } from '@aurelius/icons'
import { Box } from '@chakra-ui/react'

/**
 * Used to create the blocking
 * semi-transparent overlay
 */
const ModalBlocker = styled.div`
  display: flex;
  z-index: 50;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: hsla(0, 0%, 7%, 0.4);
`
/**
 * Opt-in Modal wrapper component
 * used to render a white box in the
 * center of the UI.
 * Usage:
 * ```
 * <Modal>
 *  <ModalWrapper>
 *    ...
 *  </ModalWrapper>
 * </Modal>
 * ```
 */
export const ModalWrapper = styled(Box)`
  background-color: var(--colors--modal-background);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: ${(props) => (props.width ? props.width : '100%')};
  position: absolute;
  padding: ${(props) => (props.padding ? props.padding : '0px')};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '40px'};
  box-shadow: var(--dropshadow);
  border-top: 4px solid #3c4c4f;
  border: solid 1px #d7dddf;
  min-width: 360px;
  max-height: 90%;
  overflow-y: auto;

  @media screen and (min-width: 768px) {
    width: 90%;
  }
  & form {
    margin-bottom: 0;
  }
`

const Button = styled.button<{
  right?: string
  top?: string
}>`
  background-color: transparent;
  border: none;
  width: 24px;
  height: 24px;
  cursor: pointer;
  position: absolute;
  right: ${(props) => (props.right ? props.right : '40px')};
  top: ${(props) => (props.top ? props.top : '40px')};

  & > svg {
    transform: rotate(45deg);
  }
`

type ButtonProps = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  right?: string
  top?: string
}

/**
 * Opt-in close button
 * Usage:
 * ```
 * <Modal>
 *  <ModalWrapper>
 *    <CloseModal onClick={close} />
 *    ...
 *  </ModalWrapper>
 * </Modal>
 * ```
 */
export const CloseModal = ({ color, ...props }: ButtonProps) => (
  <Button type="button" aria-label="Close Modal" {...props}>
    <Plus height={24} width={24} fill={color} />
  </Button>
)

/**
 * Opt-in Modal subtitle
 * Usage:
 * ```
 * <Modal>
 *  <ModalWrapper>
 *    <ModalSubtitle>Some text here</ModalSubtitle>
 *    ...
 *  </ModalWrapper>
 * </Modal>
 * ```
 */
export const ModalSubtitle = styled(P)`
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.14px;
  color: var(--colors-typography-meta);
  margin-bottom: 24px;
`

export const Modal = ({ children }: PropsWithChildren): ReactPortal | null => {
  const el = useMemo<HTMLDivElement | undefined>(() => {
    if (typeof document != 'undefined') {
      return document?.createElement('div')
    } else {
      return undefined
    }
  }, [])

  useEffect(() => {
    if (typeof document != 'undefined' && el != null) {
      document.body.appendChild(el)
      return (): void => {
        el.parentNode?.removeChild(el)
      }
    } else {
      return undefined
    }
  }, [el])

  //@ts-ignore
  return el ? createPortal(<ModalBlocker>{children}</ModalBlocker>, el) : null
}
Modal.displayName = 'Modal'
