import { BoxProps } from '@chakra-ui/react'

export const boxProps = (props: BoxProps): string => {
  return `${props.alignContent ? `align-content: ${props.alignContent};` : ''}
  ${props.alignItems ? `align-items: ${props.alignItems};` : ''}
  ${props.alignSelf ? `align-self: ${props.alignSelf};` : ''}
  ${props.background ? `background: ${props.background};` : ''}
  ${props.backgroundColor ? `background-color: ${props.backgroundColor};` : ''}
  ${props.backgroundImage ? `background-image: ${props.backgroundImage};` : ''}
  ${
    props.backgroundPosition
      ? `background-position: ${props.backgroundPosition};`
      : ''
  }
  ${
    props.backgroundRepeat
      ? `background-repeat: ${props.backgroundRepeat};`
      : ''
  }
  ${props.backgroundSize ? `background-size: ${props.backgroundSize};` : ''}
  ${props.border ? `border: ${props.border};` : ''}
  ${props.borderBottom ? `border-bottom: ${props.borderBottom};` : ''}
  ${
    props.borderBottomColor
      ? `border-bottom-color: ${props.borderBottomColor};`
      : ''
  }
  ${
    props.borderBottomLeftRadius
      ? `border-bottom-left-radius: ${props.borderBottomLeftRadius};`
      : ''
  }
  ${
    props.borderBottomRightRadius
      ? `border-bottom-right-radius: ${props.borderBottomRightRadius};`
      : ''
  }
  ${
    props.borderBottomStyle
      ? `border-bottom-style: ${props.borderBottomStyle};`
      : ''
  }
  ${
    props.borderBottomWidth
      ? `border-bottom-width: ${props.borderBottomWidth};`
      : ''
  }
  ${props.borderColor ? `border-color: ${props.borderColor};` : ''}
  ${props.borderLeft ? `border-left: ${props.borderLeft};` : ''}
  ${props.borderLeftColor ? `border-left-color: ${props.borderLeftColor};` : ''}
  ${props.borderLeftStyle ? `border-left-style: ${props.borderLeftStyle};` : ''}
  ${props.borderLeftWidth ? `border-left-width: ${props.borderLeftWidth};` : ''}
  ${props.borderRadius ? `border-radius: ${props.borderRadius};` : ''}
  ${props.borderRight ? `border-right: ${props.borderRight};` : ''}
  ${
    props.borderRightColor
      ? `border-right-color: ${props.borderRightColor};`
      : ''
  }
  ${
    props.borderRightStyle
      ? `border-right-style: ${props.borderRightStyle};`
      : ''
  }
  ${
    props.borderRightWidth
      ? `border-right-width: ${props.borderRightWidth};`
      : ''
  }
  ${props.borderStyle ? `border-style: ${props.borderStyle};` : ''}
  ${props.borderTop ? `border-top: ${props.borderTop};` : ''}
  ${props.borderTopColor ? `border-top-color: ${props.borderTopColor};` : ''}
  ${
    props.borderTopLeftRadius
      ? `border-top-left-radius: ${props.borderTopLeftRadius};`
      : ''
  }
  ${
    props.borderTopRightRadius
      ? `border-top-right-radius: ${props.borderTopRightRadius};`
      : ''
  }
  ${props.borderTopStyle ? `border-top-style: ${props.borderTopStyle};` : ''}
  ${props.borderTopWidth ? `border-top-width: ${props.borderTopWidth};` : ''}
  ${props.borderWidth ? `border-width: ${props.borderWidth};` : ''}
  ${props.bottom ? `bottom: ${props.bottom};` : ''}
  ${props.boxShadow ? `box-shadow: ${props.boxShadow};` : ''}
  ${props.color ? `color: ${props.color};` : ''}
  ${props.display ? `display: ${props.display};` : ''}
  ${props.flex ? `flex: ${props.flex};` : ''}
  ${props.flexBasis ? `flex-basis: ${props.flexBasis};` : ''}
  ${props.flexDirection ? `flex-direction: ${props.flexDirection};` : ''}
  ${props.flexGrow ? `flex-grow: ${props.flexGrow};` : ''}
  ${props.flexShrink ? `flex-shrink: ${props.flexShrink};` : ''}
  ${props.flexWrap ? `flex-wrap: ${props.flexWrap};` : ''}
  ${props.fontFamily ? `font-family: ${props.fontFamily};` : ''}
  ${props.fontSize ? `font-size: ${props.fontSize};` : ''}
  ${props.fontStyle ? `font-style: ${props.fontStyle};` : ''}
  ${props.fontWeight ? `font-weight: ${props.fontWeight};` : ''}
  ${props.gridArea ? `grid-area: ${props.gridArea};` : ''}
  ${props.gridAutoColumns ? `grid-auto-columns: ${props.gridAutoColumns};` : ''}
  ${props.gridAutoFlow ? `grid-auto-flow: ${props.gridAutoFlow};` : ''}
  ${props.gridAutoRows ? `grid-auto-rows: ${props.gridAutoRows};` : ''}
  ${props.gridColumnGap ? `grid-column-gap: ${props.gridColumnGap};` : ''}
  ${props.gridColumn ? `grid-column: ${props.gridColumn};` : ''}
  ${props.gridRow ? `grid-row: ${props.gridRow};` : ''}
  ${props.gridRowGap ? `grid-row-gap: ${props.gridRowGap};` : ''}
  ${
    props.gridTemplateAreas
      ? `grid-template-areas: ${props.gridTemplateAreas};`
      : ''
  }
  ${
    props.gridTemplateColumns
      ? `grid-template-columns: ${props.gridTemplateColumns};`
      : ''
  }
  ${
    props.gridTemplateRows
      ? `grid-template-rows: ${props.gridTemplateRows};`
      : ''
  }
  ${props.height ? `height: ${props.height};` : ''}
  ${props.justifyContent ? `justify-content: ${props.justifyContent};` : ''}
  ${props.justifyItems ? `justify-items: ${props.justifyItems};` : ''}
  ${props.justifySelf ? `justify-self: ${props.justifySelf};` : ''}
  ${props.left ? `left: ${props.left};` : ''}
  ${props.letterSpacing ? `letter-spacing: ${props.letterSpacing};` : ''}
  ${props.lineHeight ? `line-height: ${props.lineHeight};` : ''}
  ${props.margin ? `margin: ${props.margin};` : ''}
  ${props.marginBottom ? `margin-bottom: ${props.marginBottom};` : ''}
  ${props.marginLeft ? `margin-left: ${props.marginLeft};` : ''}
  ${props.marginRight ? `margin-right: ${props.marginRight};` : ''}
  ${props.marginTop ? `margin-top: ${props.marginTop};` : ''}
  ${props.maxHeight ? `max-height: ${props.maxHeight};` : ''}
  ${props.maxWidth ? `max-width: ${props.maxWidth};` : ''}
  ${props.minHeight ? `min-height: ${props.minHeight};` : ''}
  ${props.minWidth ? `min-width: ${props.minWidth};` : ''}
  ${props.opacity ? `opacity: ${props.opacity};` : ''}
  ${props.overflow ? `overflow: ${props.overflow};` : ''}
  ${props.overflowY ? `overflow-y: ${props.overflowY};` : ''}
  ${props.overflowX ? `overflow-x: ${props.overflowX};` : ''}
  ${props.padding ? `padding: ${props.padding};` : ''}
  ${props.paddingBottom ? `padding-bottom: ${props.paddingBottom};` : ''}
  ${props.paddingLeft ? `padding-left: ${props.paddingLeft};` : ''}
  ${props.paddingRight ? `padding-right: ${props.paddingRight};` : ''}
  ${props.paddingTop ? `padding-top: ${props.paddingTop};` : ''}
  ${props.position ? `position: ${props.position};` : ''}
  ${props.right ? `right: ${props.right};` : ''}
  ${props.textAlign ? `text-align: ${props.textAlign};` : ''}
  ${props.textTransform ? `text-transform: ${props.textTransform};` : ''}
  ${props.textDecoration ? `text-decoration: ${props.textDecoration};` : ''}
  ${props.top ? `top: ${props.top};` : ''}
  ${props.transition ? `transition: ${props.transition};` : ''}
  ${props.verticalAlign ? `vertical-align: ${props.verticalAlign};` : ''}
  ${props.translate ? `translate: ${props.translate};` : ''}
  ${props.width ? `width: ${props.width};` : ''}
  ${props.zIndex ? `z-index: ${props.zIndex};` : ''}
`
}
