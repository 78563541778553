import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const BlockAlignHorizontally = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M11,16 L4,16 C2.92670537,16 2,15.2056811 2,14.1428571 L2,9.85714286 C2,8.79431889 2.92670537,8 4,8 L11,8 L11,2 L13,2 L13,8 L20,8 C21.0732946,8 22,8.79431889 22,9.85714286 L22,14.1428571 C22,15.2056811 21.0732946,16 20,16 L13,16 L13,22 L11,22 L11,16 Z M4,10 L4,14 L20,14 L20,10 L4,10 Z"
    />
  </BaseIcon>
)
