import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const VolumeHigh = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M6.76393202,7 L14,3.38196601 L14,20.618034 L6.76393202,17 L3,17 C1.8954305,17 1,16.1045695 1,15 L1,9 C1,7.8954305 1.8954305,7 3,7 L6.76393202,7 Z M8,8.61803399 L8,15.381966 L12,17.381966 L12,6.61803399 L8,8.61803399 Z M6,9 L3,9 L3,15 L6,15 L6,9 Z M19.6790988,3.45672099 C21.7136304,5.48074529 23,8.56810385 23,12 C23,15.4318962 21.7136304,18.5192547 19.6790988,20.543279 L18.1031499,19.2825199 C19.8487564,17.6536398 21,15.012173 21,12 C21,8.98782698 19.8487564,6.34636022 18.1031499,4.71748008 L19.6790988,3.45672099 Z M16.5747536,5.94019713 C18.0648988,7.41238271 19,9.58933501 19,12 C19,14.410665 18.0648988,16.5876173 16.5747536,18.0598029 L14.9972703,16.7978163 C16.2071847,15.7082904 17,13.97433 17,12 C17,10.02567 16.2071847,8.29170963 14.9972703,7.20218374 L16.5747536,5.94019713 Z"
    />
  </BaseIcon>
)
