import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Terminal = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M3,3 L21,3 C22.1045695,3 23,3.8954305 23,5 L23,19 C23,20.1045695 22.1045695,21 21,21 L3,21 C1.8954305,21 1,20.1045695 1,19 L1,5 C1,3.8954305 1.8954305,3 3,3 Z M3,5 L3,19 L21,19 L21,5 L3,5 Z M8.33333333,12 L5.4,9.8 L6.6,8.2 L11.6666667,12 L6.6,15.8 L5.4,14.2 L8.33333333,12 Z M12,16 L12,14 L17,14 L17,16 L12,16 Z"
    />
  </BaseIcon>
)
