import { Hashtag } from '@aurelius/models'
import { Fragment, useCallback } from 'react'
import { Tag } from '../Tag'
import { Box } from '@chakra-ui/react'
import { Grid } from '../Grid'
import { BarRounded } from '@visx/shape'
import { Group } from '@visx/group'
import { ParentSizeModern as ParentSize } from '@visx/responsive'
import { useTagsChart, Show } from '../hooks/useTagsChart'
import { getColorFromName } from '@aurelius/utils'
import { localPoint } from '@visx/event'
import { useTooltip, useTooltipInPortal } from '@visx/tooltip'

const RenderSVG = ({
  width,
  getCount,
  show,
  max,
  tag,
}: {
  width: number
  getCount: (id: string) => number
  show: Show
  max: number
  tag: Hashtag
}) => {
  const getColor = useCallback((name: string) => getColorFromName(name), [])

  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip()

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    // use TooltipWithBounds
    detectBounds: true,
    // when tooltip containers are scrolled, this will correctly update the Tooltip position
    scroll: true,
  })

  const handleMouseOver = (event: any, datum: Hashtag) => {
    const coords = localPoint(event.target.ownerSVGElement, event)
    showTooltip({
      tooltipLeft: coords?.x ?? 0,
      tooltipTop: coords?.y ?? 0,
      tooltipData: datum,
    })
  }
  return (
    <>
      <svg height="24px" width={`${width}px`} ref={containerRef}>
        <Group>
          <BarRounded
            radius={6}
            all={true}
            x={0}
            y={0}
            width={(getCount(tag.id) / max) * width}
            height={24}
            fill={getColor(tag.name)}
            onMouseOver={(e) => handleMouseOver(e, tag)}
            onMouseOut={hideTooltip}
          />
        </Group>
      </svg>

      {tooltipOpen && (
        <TooltipInPortal
          // set this to random so it correctly updates with parent bounds
          key={Math.random()}
          top={tooltipTop}
          left={tooltipLeft}
        >
          <>
            {show.notes ? (
              <Box margin="8px">
                Notes:{' '}
                <strong>{(tooltipData as Hashtag)?.notes?.length ?? 0}</strong>
              </Box>
            ) : null}
            {show.documents ? (
              <Box margin="8px">
                Documents:{' '}
                <strong>
                  {(tooltipData as Hashtag)?.documents?.length ?? 0}
                </strong>
              </Box>
            ) : null}
            {show.insights ? (
              <Box margin="8px">
                Insights:{' '}
                <strong>
                  {(tooltipData as Hashtag)?.insights?.length ?? 0}
                </strong>
              </Box>
            ) : null}
            {show.recommendations ? (
              <Box margin="8px">
                Recommendations:{' '}
                <strong>
                  {(tooltipData as Hashtag)?.recommendations?.length ?? 0}
                </strong>
              </Box>
            ) : null}
          </>
        </TooltipInPortal>
      )}
    </>
  )
}

export const TagsBarChart = ({
  tags,
  show,
}: {
  tags?: Hashtag[]
  show: Show
}) => {
  const { max, getCount } = useTagsChart({ tags, show })

  return (
    <Grid
      gridTemplateColumns="max-content auto"
      gridAutoRows="minmax(min-content, max-content)"
      gridColumnGap="8px"
      gridRowGap="0px"
    >
      {tags?.map(
        (tag, i) =>
          tag && (
            <Fragment key={tag.id || i}>
              <Box
                display="flex"
                justifyContent="end"
                borderRight="1px solid var(--colors-hr)"
                paddingRight="8px"
                paddingBottom="8px"
              >
                <Tag key={tag.id}>
                  #{tag.name} ({getCount(tag.id)})
                </Tag>
              </Box>
              <ParentSize
                ignoreDimensions={['height', 'top', 'left']}
                parentSizeStyles={{
                  // center vertically
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  // this allows it to resize on window resize
                  overflowX: 'hidden',
                  // styles
                  paddingBottom: '8px',
                }}
              >
                {({ width }) => (
                  <RenderSVG
                    width={width}
                    max={max}
                    getCount={getCount}
                    tag={tag}
                    show={show}
                  />
                )}
              </ParentSize>
            </Fragment>
          ),
      )}
    </Grid>
  )
}
