import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const BlockAlignTop = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M14.1428571,22 L9.85714286,22 C8.79431889,22 8,21.0732946 8,20 L8,7 C8,5.92670537 8.79431889,5 9.85714286,5 L14.1428571,5 C15.2056811,5 16,5.92670537 16,7 L16,20 C16,21.0732946 15.2056811,22 14.1428571,22 Z M14,20 L14,7 L10,7 L10,20 L14,20 Z M2,4 L2,2 L22,2 L22,4 L2,4 Z"
    />
  </BaseIcon>
)
