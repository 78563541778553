import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Plus = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <polygon
      fillRule="evenodd"
      points="13 11 22 11 22 13 13 13 13 22 11 22 11 13 2 13 2 11 11 11 11 2 13 2"
    />
  </BaseIcon>
)
