import styled from '@emotion/styled'
import { Box, BoxProps } from '@chakra-ui/react'
import { MemoFDE } from './MemoFDE'

type Types = {
  backgroundColor?: string
  foregroundColor?: string
  styles?: { fontSize: string; padding: string; cursor: string }
  size?: 'sm' | 'md' | 'lg'
  onClick?: any
  selected?: boolean
}

const TagWrapper = styled(Box)<Types & BoxProps>`
  display: inline-block;
  border-radius: 8px;
  border: 1px solid
    ${(props) =>
      props?.borderColor
        ? props.borderColor
        : props?.backgroundColor
        ? props.backgroundColor
        : 'var(--colors-tags-border)'};
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : 'transparent'};
  font-size: ${(props) =>
    props?.styles?.fontSize ? props.styles.fontSize : '16px'};
  font-weight: ${(props) =>
    props?.selected && props?.size === 'md' ? 'bold' : 'normal'};
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.19px;
  color: ${(props) =>
    props.textColor ? props.textColor : 'var(--colors-tags-color)'};
  padding: ${(props) =>
    props?.styles?.padding ? props.styles.padding : '8px 12px'};
  cursor: ${(props) => (props?.styles?.cursor ? props.styles.cursor : 'unset')};
  word-wrap: anywhere;
  align-self: center;

  > a {
    color: ${(props) =>
      props.textColor ? props.textColor : 'var(--colors-tags-color)'};
  }
`

export const Tag = MemoFDE(
  ({ size, onClick, children, foregroundColor, ...rest }: Types & BoxProps) => {
    let styles = {
      fontSize: '14px',
      padding: '5px 10px',
      cursor: 'unset',
    }

    if (size === 'md') {
      styles = {
        fontSize: '14px',
        padding: '5px 10px',
        cursor: 'pointer',
      }
    } else if (size === 'lg') {
      styles = {
        fontSize: '14px',
        padding: '8px 12px',
        cursor: 'pointer',
      }
    } else if (size === 'sm') {
      styles = {
        fontSize: '12px',
        padding: '3px 10px',
        cursor: 'unset',
      }
    }

    return (
      <TagWrapper
        styles={styles}
        size={size}
        onClick={onClick}
        width={rest.width}
        textColor={foregroundColor}
        {...rest}
      >
        {children}
      </TagWrapper>
    )
  },
)
