import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const ArrowBoldBottom = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M15.8198039,12 L21.2037682,12 L12,22.5185923 L2.79623177,12 L8.1801961,12 L10.1801961,2 L13.8198039,2 L15.8198039,12 Z M12,19.4814077 L16.7962318,14 L14.1801961,14 L12.1801961,4 L11.8198039,4 L9.8198039,14 L7.20376823,14 L12,19.4814077 Z"
    />
  </BaseIcon>
)
