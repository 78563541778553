import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const ArrowReplyAll = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M10.4142136,12 L14.7071068,16.2928932 L13.2928932,17.7071068 L6.58578644,11 L13.2928932,4.29289322 L14.7071068,5.70710678 L10.4142136,10 L11,10 C18.8689424,10 22,13.2562999 22,21 L20,21 C20,14.3437001 17.7464422,12 11,12 L10.4142136,12 Z M9.70710678,16.2928932 L8.29289322,17.7071068 L1.58578644,11 L8.29289322,4.29289322 L9.70710678,5.70710678 L4.41421356,11 L9.70710678,16.2928932 Z"
    />
  </BaseIcon>
)
