import { useCallback } from 'react'
import { Tag } from './Tag'
import styled from '@emotion/styled'
import { Checkbox } from './Checkbox'

const Wrapper = styled.div`
  input,
  input ~ div {
    margin-top: 9px;
  }
`

export const TagButton = ({
  id,
  select,
  selected = false,
  children,
  tagColors = {
    backgroundColor: false,
    foregroundColor: '',
    borderColor: 'var(--colors-tags-border)',
  },
  hideCheckmark,
  size,
}: React.PropsWithChildren<{
  id: string
  select?: (id: string) => void
  selected?: boolean
  tagColors?: any
  hideCheckmark?: boolean
  size?: 'sm' | 'md' | 'lg'
}>) => {
  const handleSelect = useCallback(
    (e: any): void => {
      e.preventDefault()
      if (select) {
        select(id)
      }
    },
    [id, select],
  )
  return (
    <Wrapper>
      {hideCheckmark ? (
        <Tag
          onClick={handleSelect}
          backgroundColor={tagColors.backgroundColor}
          foregroundColor={tagColors.foregroundColor}
          borderColor={tagColors.borderColor}
          size={size}
          selected={selected}
        >
          #{children}
        </Tag>
      ) : (
        <Checkbox checked={selected} onClick={handleSelect}>
          <Tag
            backgroundColor={tagColors.backgroundColor}
            foregroundColor={tagColors.foregroundColor}
            borderColor={tagColors.borderColor}
            size={size}
            selected={selected}
          >
            #{children}
          </Tag>
        </Checkbox>
      )}
    </Wrapper>
  )
}
TagButton.displayName = 'TagButton'
