import * as yup from 'yup'

export const hashtagCard = yup.object().shape({
  notes: yup.array().of(yup.string().required()),
  documents: yup.array().of(yup.string().required()),
  highlightreels: yup.array().of(yup.string().required()),
  clips: yup.array().of(yup.string().required()),
  project: yup.string(),
  hashtagBoard: yup.string(),
  hashtagId: yup.string().required(),
})

export type hashtagCardType = yup.InferType<typeof hashtagCard>

export const arrayOfHashtagCards = yup.object().shape({
  hashtagCards: yup.array().of(yup.string().required()).required(),
})

export type arrayOfHashtagCardsType = yup.InferType<typeof arrayOfHashtagCards>
