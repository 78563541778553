import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Devices = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M21,7 C22.1045695,7 23,7.8954305 23,9 L23,18 C23,19.1045695 22.1045695,20 21,20 L16,20 L3,20 C1.8954305,20 1,19.1045695 1,18 L1,15 L3,15 L3,6 C3,4.8954305 3.8954305,4 5,4 L19,4 C20.1045695,4 21,4.8954305 21,6 L21,7 Z M19,7 L19,6 L5,6 L5,15 L14,15 L14,9 C14,7.8954305 14.8954305,7 16,7 L19,7 Z M14,18 L14,17 L3,17 L3,18 L14,18 Z M16,9 L16,18 L21,18 L21,9 L16,9 Z"
    />
  </BaseIcon>
)
