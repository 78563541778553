import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Printer = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M6,19 L4,19 C2.8954305,19 2,18.1045695 2,17 L2,9 C2,7.8954305 2.8954305,7 4,7 L6,7 L6,2 L18,2 L18,7 L20,7 C21.1045695,7 22,7.8954305 22,9 L22,17 C22,18.1045695 21.1045695,19 20,19 L18,19 L18,22 L6,22 L6,19 Z M6,17 L6,15 L18,15 L18,17 L20,17 L20,9 L4,9 L4,17 L6,17 Z M8,7 L16,7 L16,4 L8,4 L8,7 Z M16,17 L8,17 L8,20 L16,20 L16,17 Z M18,10 C18.5522847,10 19,10.4477153 19,11 C19,11.5522847 18.5522847,12 18,12 C17.4477153,12 17,11.5522847 17,11 C17,10.4477153 17.4477153,10 18,10 Z"
    />
  </BaseIcon>
)
