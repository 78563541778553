import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Sad = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M12,23 C5.92486775,23 1,18.0751322 1,12 C1,5.92486775 5.92486775,1 12,1 C18.0751322,1 23,5.92486775 23,12 C23,18.0751322 18.0751322,23 12,23 Z M12,21 C16.9705627,21 21,16.9705627 21,12 C21,7.02943725 16.9705627,3 12,3 C7.02943725,3 3,7.02943725 3,12 C3,16.9705627 7.02943725,21 12,21 Z M15.2746538,18 C14.5292972,16.9402308 13.3195004,16.2978292 12,16.2978292 C10.6804996,16.2978292 9.4707028,16.9402308 8.72534624,18 L7.08943782,16.8494333 C8.20545254,15.2626519 10.0224456,14.2978292 12,14.2978292 C13.9775544,14.2978292 15.7945475,15.2626519 16.9105622,16.8494333 L15.2746538,18 Z M14,12 L14,10 L16,10 L16,12 L14,12 Z M8,12 L8,10 L10,10 L10,12 L8,12 Z"
    />
  </BaseIcon>
)
