import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const CreditcardNo = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M18.5857864,19 L2.81818182,19 C1.81402773,19 1,18.1045695 1,17 L1,5 C1,3.97663942 1.69873174,3.13279614 2.60003397,3.01424753 L1.29289322,1.70710678 L2.70710678,0.292893219 L22.7071068,20.2928932 L21.2928932,21.7071068 L18.5857864,19 Z M4.58578644,5 L3,5 L3,8 L7.58578644,8 L4.58578644,5 Z M9.58578644,10 L3,10 L3,17 L16.5857864,17 L9.58578644,10 Z M10.2142135,5 L8.21421351,3 L21.1818182,3 C22.1859723,3 23,3.8954305 23,5 L23,17 C23,17.2381818 22.9621496,17.4666391 22.8927044,17.6784909 L21,15.7857865 L21,10 L15.2142135,10 L13.2142135,8 L21,8 L21,5 L10.2142135,5 Z"
    />
  </BaseIcon>
)
