import { Language, LanguageModel } from '../interfaces/Language'

export const gcsSpeechToText: Language[] = [
  {
    name: 'Afrikaans (South Africa)',
    BCP47: 'af-ZA',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: true,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Albanian (Albania)',
    BCP47: 'sq-AL',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: false,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Amharic (Ethiopia)',
    BCP47: 'am-ET',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: false,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Arabic (Algeria)',
    BCP47: 'ar-DZ',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: false,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Arabic (Bahrain)',
    BCP47: 'ar-BH',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: true,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Arabic (Egypt)',
    BCP47: 'ar-EG',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: true,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Arabic (Iraq)',
    BCP47: 'ar-IQ',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: true,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Arabic (Israel)',
    BCP47: 'ar-IL',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: true,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Arabic (Jordan)',
    BCP47: 'ar-JO',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: true,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Arabic (Kuwait)',
    BCP47: 'ar-KW',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: true,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Arabic (Lebanon)',
    BCP47: 'ar-LB',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: true,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Arabic (Morocco)',
    BCP47: 'ar-MA',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: false,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Arabic (Oman)',
    BCP47: 'ar-OM',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: true,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Arabic (Qatar)',
    BCP47: 'ar-QA',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: true,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Arabic (Saudi Arabia)',
    BCP47: 'ar-SA',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: true,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Arabic (State of Palestine)',
    BCP47: 'ar-PS',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: true,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Arabic (Tunisia)',
    BCP47: 'ar-TN',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: false,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Arabic (United Arab Emirates)',
    BCP47: 'ar-AE',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: true,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Arabic (Yemen)',
    BCP47: 'ar-YE',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: false,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Armenian (Armenia)',
    BCP47: 'hy-AM',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: false,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Azerbaijani (Azerbaijan)',
    BCP47: 'az-AZ',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: false,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Basque (Spain)',
    BCP47: 'eu-ES',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: false,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Bengali (Bangladesh)',
    BCP47: 'bn-BD',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: true,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Bengali (India)',
    BCP47: 'bn-IN',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: false,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Bosnian (Bosnia and Herzegovina)',
    BCP47: 'bs-BA',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: false,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Bulgarian (Bulgaria)',
    BCP47: 'bg-BG',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: false,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Burmese (Myanmar)',
    BCP47: 'my-MM',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: false,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Catalan (Spain)',
    BCP47: 'ca-ES',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: false,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Chinese, Cantonese (Traditional Hong Kong)',
    BCP47: 'yue-Hant-HK',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: true,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Chinese, Mandarin (Simplified, China)',
    BCP47: 'zh (cmn-Hans-CN)',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: true,
        diarization: true,
        boost: true,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Chinese, Mandarin (Traditional, Taiwan)',
    BCP47: 'zh-TW (cmn-Hant-TW)',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: true,
        diarization: false,
        boost: true,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Croatian (Croatia)',
    BCP47: 'hr-HR',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: false,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Czech (Czech Republic)',
    BCP47: 'cs-CZ',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: true,
        diarization: false,
        boost: true,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: true,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Danish (Denmark)',
    BCP47: 'da-DK',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: true,
        diarization: false,
        boost: true,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: true,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Dutch (Belgium)',
    BCP47: 'nl-BE',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: false,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: true,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Dutch (Netherlands)',
    BCP47: 'nl-NL',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: true,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: true,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'English (Australia)',
    BCP47: 'en-AU',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: true,
        diarization: false,
        boost: true,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: true,
        spokenEmojis: true,
      },
      {
        model: 'phone_call',
        enhanced: true,
        automaticPunctuation: true,
        diarization: false,
        boost: false,
        wordConfidenceLevel: true,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'English (Canada)',
    BCP47: 'en-CA',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: false,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'English (Ghana)',
    BCP47: 'en-GH',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: true,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: true,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'English (Hong Kong)',
    BCP47: 'en-HK',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: false,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: true,
        spokenEmojis: true,
      },
    ],
  },
  {
    name: 'English (India)',
    BCP47: 'en-IN',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: true,
        diarization: true,
        boost: true,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: true,
        spokenEmojis: true,
      },
    ],
  },
  {
    name: 'English (Ireland)',
    BCP47: 'en-IE',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: false,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: true,
        spokenEmojis: true,
      },
    ],
  },
  {
    name: 'English (Kenya)',
    BCP47: 'en-KE',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: true,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: true,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'English (New Zealand)',
    BCP47: 'en-NZ',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: false,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: true,
        spokenEmojis: true,
      },
    ],
  },
  {
    name: 'English (Nigeria)',
    BCP47: 'en-NG',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: true,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: true,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'English (Pakistan)',
    BCP47: 'en-PK',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: false,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: true,
        spokenEmojis: true,
      },
    ],
  },
  {
    name: 'English (Philippines)',
    BCP47: 'en-PH',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: true,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'English (Singapore)',
    BCP47: 'en-SG',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: true,
        diarization: true,
        boost: true,
        wordConfidenceLevel: true,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'English (South Africa)',
    BCP47: 'en-ZA',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: true,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: true,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'English (Tanzania)',
    BCP47: 'en-TZ',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: true,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: true,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'English (United Kingdom)',
    BCP47: 'en-GB',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: true,
        diarization: true,
        boost: true,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: true,
        spokenEmojis: true,
      },
      {
        model: 'phone_call',
        enhanced: true,
        automaticPunctuation: true,
        diarization: true,
        boost: true,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: true,
        spokenEmojis: true,
      },
      {
        model: 'phone_call',
        enhanced: false,
        automaticPunctuation: true,
        diarization: true,
        boost: true,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: true,
        spokenEmojis: true,
      },
    ],
  },
  {
    name: 'English (United States)',
    BCP47: 'en-US',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: true,
        diarization: true,
        boost: true,
        wordConfidenceLevel: true,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
      {
        model: 'phone_call',
        enhanced: true,
        automaticPunctuation: true,
        diarization: true,
        boost: true,
        wordConfidenceLevel: true,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
      {
        model: 'video',
        enhanced: true,
        automaticPunctuation: true,
        diarization: true,
        boost: true,
        wordConfidenceLevel: true,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
      {
        model: 'phone_call',
        enhanced: false,
        automaticPunctuation: true,
        diarization: true,
        boost: true,
        wordConfidenceLevel: true,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Estonian (Estonia)',
    BCP47: 'et-EE',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: false,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Filipino (Philippines)',
    BCP47: 'fil-PH',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: true,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Finnish (Finland)',
    BCP47: 'fi-FI',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: true,
        diarization: false,
        boost: true,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'French (Belgium)',
    BCP47: 'fr-BE',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: false,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: true,
        spokenEmojis: true,
      },
    ],
  },
  {
    name: 'French (Canada)',
    BCP47: 'fr-CA',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: true,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: true,
        spokenEmojis: false,
      },
      {
        model: 'phone_call',
        enhanced: true,
        automaticPunctuation: false,
        diarization: false,
        boost: true,
        wordConfidenceLevel: true,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'French (France)',
    BCP47: 'fr-FR',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: true,
        diarization: true,
        boost: true,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: true,
        spokenEmojis: true,
      },
      {
        model: 'phone_call',
        enhanced: true,
        automaticPunctuation: true,
        diarization: true,
        boost: true,
        wordConfidenceLevel: true,
        profanityFilter: true,
        spokenPunctuation: true,
        spokenEmojis: true,
      },
      {
        model: 'phone_call',
        enhanced: false,
        automaticPunctuation: true,
        diarization: true,
        boost: true,
        wordConfidenceLevel: true,
        profanityFilter: true,
        spokenPunctuation: true,
        spokenEmojis: true,
      },
    ],
  },
  {
    name: 'French (Switzerland)',
    BCP47: 'fr-CH',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: false,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: true,
        spokenEmojis: true,
      },
    ],
  },
  {
    name: 'Galician (Spain)',
    BCP47: 'gl-ES',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: false,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Georgian (Georgia)',
    BCP47: 'ka-GE',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: false,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'German (Austria)',
    BCP47: 'de-AT',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: false,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: true,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'German (Germany)',
    BCP47: 'de-DE',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: true,
        diarization: true,
        boost: true,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: true,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'German (Switzerland)',
    BCP47: 'de-CH',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: false,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: true,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Greek (Greece)',
    BCP47: 'el-GR',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: false,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Gujarati (India)',
    BCP47: 'gu-IN',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: true,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Hebrew (Israel)',
    BCP47: 'iw-IL',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: true,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: true,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Hindi (India)',
    BCP47: 'hi-IN',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: true,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: true,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Hungarian (Hungary)',
    BCP47: 'hu-HU',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: false,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Icelandic (Iceland)',
    BCP47: 'is-IS',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: false,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Indonesian (Indonesia)',
    BCP47: 'id-ID',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: true,
        diarization: false,
        boost: true,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: true,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Italian (Italy)',
    BCP47: 'it-IT',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: true,
        diarization: true,
        boost: true,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: true,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Italian (Switzerland)',
    BCP47: 'it-CH',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: false,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: true,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Japanese (Japan)',
    BCP47: 'ja-JP',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: true,
        diarization: true,
        boost: true,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: true,
        spokenEmojis: false,
      },
      {
        model: 'phone_call',
        enhanced: true,
        automaticPunctuation: true,
        diarization: true,
        boost: true,
        wordConfidenceLevel: true,
        profanityFilter: true,
        spokenPunctuation: true,
        spokenEmojis: false,
      },
      {
        model: 'phone_call',
        enhanced: false,
        automaticPunctuation: true,
        diarization: true,
        boost: true,
        wordConfidenceLevel: true,
        profanityFilter: true,
        spokenPunctuation: true,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Javanese (Indonesia)',
    BCP47: 'jv-ID',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: false,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Kannada (India)',
    BCP47: 'kn-IN',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: true,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Kazakh (Kazakhstan)',
    BCP47: 'kk-KZ',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: false,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Khmer (Cambodia)',
    BCP47: 'km-KH',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: false,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Korean (South Korea)',
    BCP47: 'ko-KR',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: true,
        diarization: false,
        boost: true,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: true,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Lao (Laos)',
    BCP47: 'lo-LA',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: false,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Latvian (Latvia)',
    BCP47: 'lv-LV',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: false,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Lithuanian (Lithuania)',
    BCP47: 'lt-LT',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: false,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Macedonian (North Macedonia)',
    BCP47: 'mk-MK',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: false,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Malay (Malaysia)',
    BCP47: 'ms-MY',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: true,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Malayalam (India)',
    BCP47: 'ml-IN',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: true,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: true,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Marathi (India)',
    BCP47: 'mr-IN',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: true,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Mongolian (Mongolia)',
    BCP47: 'mn-MN',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: false,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Nepali (Nepal)',
    BCP47: 'ne-NP',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: false,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Norwegian Bokmål (Norway)',
    BCP47: 'no-NO',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: true,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: true,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Persian (Iran)',
    BCP47: 'fa-IR',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: true,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Polish (Poland)',
    BCP47: 'pl-PL',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: true,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: true,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Portuguese (Brazil)',
    BCP47: 'pt-BR',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: true,
        boost: true,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: true,
        spokenEmojis: false,
      },
      {
        model: 'phone_call',
        enhanced: true,
        automaticPunctuation: true,
        diarization: true,
        boost: true,
        wordConfidenceLevel: true,
        profanityFilter: true,
        spokenPunctuation: true,
        spokenEmojis: false,
      },
      {
        model: 'phone_call',
        enhanced: false,
        automaticPunctuation: true,
        diarization: true,
        boost: true,
        wordConfidenceLevel: true,
        profanityFilter: true,
        spokenPunctuation: true,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Portuguese (Portugal)',
    BCP47: 'pt-PT',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: true,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: true,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Punjabi (Gurmukhi India)',
    BCP47: 'pa-Guru-IN',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: false,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Romanian (Romania)',
    BCP47: 'ro-RO',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: false,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Russian (Russia)',
    BCP47: 'ru-RU',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: true,
        boost: true,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: true,
        spokenEmojis: false,
      },
      {
        model: 'phone_call',
        enhanced: true,
        automaticPunctuation: false,
        diarization: true,
        boost: true,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: true,
        spokenEmojis: false,
      },
      {
        model: 'phone_call',
        enhanced: false,
        automaticPunctuation: false,
        diarization: true,
        boost: true,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: true,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Serbian (Serbia)',
    BCP47: 'sr-RS',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: true,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Sinhala (Sri Lanka)',
    BCP47: 'si-LK',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: false,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Slovak (Slovakia)',
    BCP47: 'sk-SK',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: false,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Slovenian (Slovenia)',
    BCP47: 'sl-SI',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: false,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Spanish (Argentina)',
    BCP47: 'es-AR',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: false,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: true,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Spanish (Bolivia)',
    BCP47: 'es-BO',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: false,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: true,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Spanish (Chile)',
    BCP47: 'es-CL',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: false,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: true,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Spanish (Colombia)',
    BCP47: 'es-CO',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: false,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: true,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Spanish (Costa Rica)',
    BCP47: 'es-CR',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: false,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: true,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Spanish (Dominican Republic)',
    BCP47: 'es-DO',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: false,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: true,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Spanish (Ecuador)',
    BCP47: 'es-EC',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: false,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: true,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Spanish (El Salvador)',
    BCP47: 'es-SV',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: false,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: true,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Spanish (Guatemala)',
    BCP47: 'es-GT',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: false,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: true,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Spanish (Honduras)',
    BCP47: 'es-HN',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: false,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: true,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Spanish (Mexico)',
    BCP47: 'es-MX',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: false,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: true,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Spanish (Nicaragua)',
    BCP47: 'es-NI',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: false,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: true,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Spanish (Panama)',
    BCP47: 'es-PA',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: false,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: true,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Spanish (Paraguay)',
    BCP47: 'es-PY',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: false,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: true,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Spanish (Peru)',
    BCP47: 'es-PE',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: false,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: true,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Spanish (Puerto Rico)',
    BCP47: 'es-PR',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: false,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: true,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Spanish (Spain)',
    BCP47: 'es-ES',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: true,
        boost: true,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: true,
        spokenEmojis: false,
      },
      {
        model: 'phone_call',
        enhanced: true,
        automaticPunctuation: false,
        diarization: true,
        boost: false,
        wordConfidenceLevel: true,
        profanityFilter: true,
        spokenPunctuation: true,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Spanish (United States)',
    BCP47: 'es-US',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: true,
        diarization: false,
        boost: true,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: true,
        spokenEmojis: false,
      },
      {
        model: 'phone_call',
        enhanced: true,
        automaticPunctuation: true,
        diarization: false,
        boost: true,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: true,
        spokenEmojis: false,
      },
      {
        model: 'phone_call',
        enhanced: false,
        automaticPunctuation: true,
        diarization: false,
        boost: true,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: true,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Spanish (Uruguay)',
    BCP47: 'es-UY',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: false,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: true,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Spanish (Venezuela)',
    BCP47: 'es-VE',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: false,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: true,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Sundanese (Indonesia)',
    BCP47: 'su-ID',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: false,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Swahili (Kenya)',
    BCP47: 'sw-KE',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: false,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Swahili (Tanzania)',
    BCP47: 'sw-TZ',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: false,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Swedish (Sweden)',
    BCP47: 'sv-SE',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: true,
        diarization: false,
        boost: true,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: true,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Tamil (India)',
    BCP47: 'ta-IN',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: true,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Tamil (Malaysia)',
    BCP47: 'ta-MY',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: false,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Tamil (Singapore)',
    BCP47: 'ta-SG',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: false,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Tamil (Sri Lanka)',
    BCP47: 'ta-LK',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: false,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Telugu (India)',
    BCP47: 'te-IN',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: true,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Thai (Thailand)',
    BCP47: 'th-TH',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: true,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Turkish (Turkey)',
    BCP47: 'tr-TR',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: true,
        diarization: false,
        boost: true,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: true,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Ukrainian (Ukraine)',
    BCP47: 'uk-UA',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: true,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Urdu (India)',
    BCP47: 'ur-IN',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: false,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Urdu (Pakistan)',
    BCP47: 'ur-PK',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: true,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Uzbek (Uzbekistan)',
    BCP47: 'uz-UZ',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: false,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Vietnamese (Vietnam)',
    BCP47: 'vi-VN',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: true,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
  {
    name: 'Zulu (South Africa)',
    BCP47: 'zu-ZA',
    models: [
      {
        model: 'default',
        enhanced: false,
        automaticPunctuation: false,
        diarization: false,
        boost: true,
        wordConfidenceLevel: false,
        profanityFilter: true,
        spokenPunctuation: false,
        spokenEmojis: false,
      },
    ],
  },
]

const aAiSpeechToText: Language[] = [
  {
    name: 'English',
    BCP47: 'en',
    global: true,
    models: [
      {
        model: 'default',
        enhanced: true,
        diarization: true,
        automaticPunctuation: true,
        profanityFilter: true,
      },
    ],
  },
  {
    name: 'English Australian',
    BCP47: 'en-au',
    global: false,
    models: [
      {
        model: 'default',
        enhanced: true,
        diarization: true,
        automaticPunctuation: true,
        profanityFilter: true,
      },
    ],
  },
  {
    name: 'English British',
    BCP47: 'en-uk',
    BCP47_ALT: 'en-gb',
    global: false,
    models: [
      {
        model: 'default',
        enhanced: true,
        diarization: true,
        automaticPunctuation: true,
        profanityFilter: true,
      },
    ],
  },
  {
    name: 'English US',
    BCP47: 'en-us',
    global: false,
    models: [
      {
        model: 'default',
        enhanced: true,
        diarization: true,
        automaticPunctuation: true,
        profanityFilter: true,
      },
    ],
  },
  {
    name: 'Spanish',
    BCP47: 'es',
    global: true,
    models: [
      {
        model: 'default',
        enhanced: true,
        diarization: true,
        automaticPunctuation: true,
        profanityFilter: true,
      },
    ],
  },
  {
    name: 'French',
    BCP47: 'fr',
    global: true,
    models: [
      {
        model: 'default',
        enhanced: true,
        diarization: true,
        automaticPunctuation: true,
        profanityFilter: true,
      },
    ],
  },
  {
    name: 'German',
    BCP47: 'de',
    global: true,
    models: [
      {
        model: 'default',
        enhanced: true,
        diarization: true,
        automaticPunctuation: true,
        profanityFilter: true,
      },
    ],
  },
  {
    name: 'Italian',
    BCP47: 'it',
    global: true,
    models: [
      {
        model: 'default',
        enhanced: true,
        diarization: true,
        automaticPunctuation: true,
        profanityFilter: true,
      },
    ],
  },
  {
    name: 'Portuguese',
    BCP47: 'pt',
    global: true,
    models: [
      {
        model: 'default',
        enhanced: true,
        diarization: true,
        automaticPunctuation: true,
        profanityFilter: true,
      },
    ],
  },
  {
    name: 'Dutch',
    BCP47: 'nl',
    global: true,
    models: [
      {
        model: 'default',
        enhanced: true,
        diarization: true,
        automaticPunctuation: true,
        profanityFilter: false,
      },
    ],
  },
  {
    name: 'Hindi',
    BCP47: 'hi',
    global: true,
    models: [
      {
        model: 'default',
        enhanced: true,
        diarization: false,
        automaticPunctuation: true,
        profanityFilter: true,
      },
    ],
  },
  {
    name: 'Japanese',
    BCP47: 'ja',
    global: true,
    models: [
      {
        model: 'default',
        enhanced: true,
        diarization: false,
        automaticPunctuation: true,
        profanityFilter: true,
      },
    ],
  },
  {
    name: 'Chinese',
    BCP47: 'zh',
    global: true,
    models: [
      {
        model: 'default',
        enhanced: true,
        diarization: false,
        automaticPunctuation: true,
        profanityFilter: false,
      },
    ],
  },
  {
    name: 'Finnish',
    BCP47: 'fi',
    global: true,
    models: [
      {
        model: 'default',
        enhanced: true,
        diarization: true,
        automaticPunctuation: true,
        profanityFilter: false,
      },
    ],
  },
  {
    name: 'Korean',
    BCP47: 'ko',
    global: true,
    models: [
      {
        model: 'default',
        enhanced: true,
        diarization: false,
        automaticPunctuation: true,
        profanityFilter: false,
      },
    ],
  },
  {
    name: 'Polish',
    BCP47: 'pl',
    global: true,
    models: [
      {
        model: 'default',
        enhanced: true,
        diarization: true,
        automaticPunctuation: true,
        profanityFilter: false,
      },
    ],
  },
  {
    name: 'Russian',
    BCP47: 'ru',
    global: true,
    models: [
      {
        model: 'default',
        enhanced: true,
        diarization: true,
        automaticPunctuation: true,
        profanityFilter: false,
      },
    ],
  },
  {
    name: 'Turkish',
    BCP47: 'tr',
    global: true,
    models: [
      {
        model: 'default',
        enhanced: true,
        diarization: true,
        automaticPunctuation: true,
        profanityFilter: false,
      },
    ],
  },
  {
    name: 'Ukrainian',
    BCP47: 'uk',
    global: true,
    models: [
      {
        model: 'default',
        enhanced: true,
        diarization: false,
        automaticPunctuation: true,
        profanityFilter: false,
      },
    ],
  },
  {
    name: 'Vietnamese',
    BCP47: 'vi',
    global: true,
    models: [
      {
        model: 'default',
        enhanced: true,
        diarization: false,
        automaticPunctuation: true,
        profanityFilter: false,
      },
    ],
  },
]

/**
 * Get the transcription features available from GCP/AAI Speech-to-text.
 * @param bcp47 This BCP47 (i.e. en-US)
 * @returns a model, and if diarization/enhanced models are allowed
 */
export const getTranscriptionModel = (
  bcp47: string,
): {
  BCP47: string
  model: 'phone_call' | 'video' | 'default'
  diarization: boolean
  useEnhanced: boolean
  useAAI: boolean
} => {
  let useAAI = false
  let languageModel: LanguageModel
  let BCP47: string

  // choose either matching option from AAI, or a global AAI option, and finally fallback to GCP
  const aAiOption =
    aAiSpeechToText
      .filter((x) => !x.global)
      .find(
        (x) =>
          x.BCP47 === bcp47.toLowerCase() ||
          x.BCP47_ALT === bcp47.toLowerCase(),
      ) ||
    aAiSpeechToText
      .filter((x) => x.global)
      .find((x) => bcp47.startsWith(x.BCP47))

  if (aAiOption) {
    useAAI = true
    BCP47 = aAiOption.BCP47
    languageModel = aAiOption.models[0]
  } else {
    useAAI = false
    // For GCP we prefer video, then phone call, then fallback to default. Preferring enhanced models.
    const gcpOption = gcsSpeechToText.find((x) => x.BCP47 === bcp47)
    const gcpLanguageModel =
      gcpOption?.models.find((m) => m.model === 'video' && m.enhanced) ??
      gcpOption?.models.find((m) => m.model === 'phone_call' && m.enhanced) ??
      gcpOption?.models.find((m) => m.model === 'default' && m.enhanced) ??
      gcpOption?.models.find((m) => m.model === 'video') ??
      gcpOption?.models.find((m) => m.model === 'phone_call') ??
      gcpOption?.models.find((m) => m.model === 'default')

    if (!gcpLanguageModel) {
      throw new Error(`Language model ${bcp47} not found.`)
    } else {
      BCP47 = bcp47
      languageModel = gcpLanguageModel
    }
  }
  return {
    BCP47,
    model: languageModel?.model ?? 'default',
    diarization: languageModel?.diarization ?? false,
    useEnhanced: languageModel?.enhanced ?? false,
    useAAI,
  }
}
