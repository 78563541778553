import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const CircleArrowRightCurved = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M12,23 C5.92486775,23 1,18.0751322 1,12 C1,5.92486775 5.92486775,1 12,1 C18.0751322,1 23,5.92486775 23,12 C23,18.0751322 18.0751322,23 12,23 Z M12,21 C16.9705627,21 21,16.9705627 21,12 C21,7.02943725 16.9705627,3 12,3 C7.02943725,3 3,7.02943725 3,12 C3,16.9705627 7.02943725,21 12,21 Z M14.5857864,9 L12.2928932,6.70710678 L13.7071068,5.29289322 L18.4142136,10 L13.7071068,14.7071068 L12.2928932,13.2928932 L14.5857864,11 L10,11 C8.8954305,11 8,11.8954305 8,13 C8,14.1045695 8.8954305,15 10,15 L10,17 C7.790861,17 6,15.209139 6,13 C6,10.790861 7.790861,9 10,9 L14.5857864,9 Z"
    />
  </BaseIcon>
)
