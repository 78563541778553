import styled from '@emotion/styled'
import {
  AnchorHTMLAttributes,
  CSSProperties,
  DetailedHTMLProps,
  ElementType,
  HTMLAttributes,
  LabelHTMLAttributes,
  PropsWithChildren,
  forwardRef,
} from 'react'
import isPropValid from '@emotion/is-prop-valid'
import shouldForwardProp from '@styled-system/should-forward-prop'
import { MemoFDE } from './MemoFDE'

export type TextProps = Pick<
  CSSProperties,
  | 'alignContent'
  | 'alignItems'
  | 'alignSelf'
  | 'bottom'
  | 'boxShadow'
  | 'color'
  | 'display'
  | 'flex'
  | 'flexBasis'
  | 'flexDirection'
  | 'flexGrow'
  | 'flexShrink'
  | 'flexWrap'
  | 'fontFamily'
  | 'fontSize'
  | 'fontStyle'
  | 'fontWeight'
  | 'height'
  | 'justifyContent'
  | 'justifyItems'
  | 'justifySelf'
  | 'left'
  | 'letterSpacing'
  | 'lineHeight'
  | 'margin'
  | 'marginBottom'
  | 'marginLeft'
  | 'marginRight'
  | 'marginTop'
  | 'maxHeight'
  | 'maxWidth'
  | 'minHeight'
  | 'minWidth'
  | 'opacity'
  | 'overflow'
  | 'overflowY'
  | 'overflowX'
  | 'padding'
  | 'paddingBottom'
  | 'paddingLeft'
  | 'paddingRight'
  | 'paddingTop'
  | 'position'
  | 'right'
  | 'textAlign'
  | 'textDecoration'
  | 'top'
  | 'verticalAlign'
  | 'translate'
  | 'width'
  | 'whiteSpace'
  | 'zIndex'
>
type Props = PropsWithChildren<
  TextProps & {
    style?: CSSProperties
    as?: ElementType
  } & HTMLAttributes<HTMLElement>
>

const StyledTypography = MemoFDE(
  styled('p', {
    shouldForwardProp: (prop) => isPropValid(prop) && shouldForwardProp(prop),
  })((props: Props) => ({
    alignContent: props.alignContent,
    alignItems: props.alignItems,
    alignSelf: props.alignSelf,
    bottom: props.bottom,
    boxShadow: props.boxShadow,
    color: props.color,
    display: props.display,
    flex: props.flex,
    flexBasis: props.flexBasis,
    flexDirection: props.flexDirection,
    flexGrow: props.flexGrow,
    flexShrink: props.flexShrink,
    flexWrap: props.flexWrap,
    fontFamily: props.fontFamily,
    fontSize: props.fontSize,
    fontStyle: props.fontStyle,
    fontWeight: props.fontWeight,
    height: props.height,
    justifyContent: props.justifyContent,
    justifyItems: props.justifyItems,
    justifySelf: props.justifySelf,
    left: props.left,
    letterSpacing: props.letterSpacing,
    lineHeight: props.lineHeight,
    margin: props.margin,
    marginBottom: props.marginBottom,
    marginLeft: props.marginLeft,
    marginRight: props.marginRight,
    marginTop: props.marginTop,
    maxHeight: props.maxHeight,
    maxWidth: props.maxWidth,
    minHeight: props.minHeight,
    minWidth: props.minWidth,
    opacity: props.opacity,
    overflow: props.overflow,
    overflowY: props.overflowY,
    overflowX: props.overflowX,
    padding: props.padding,
    paddingBottom: props.paddingBottom,
    paddingLeft: props.paddingLeft,
    paddingRight: props.paddingRight,
    paddingTop: props.paddingTop,
    position: props.position,
    right: props.right,
    textAlign: props.textAlign,
    textDecoration: props.textDecoration,
    top: props.top,
    verticalAlign: props.verticalAlign,
    translate: props.translate,
    width: props.width,
    whiteSpace: props.whiteSpace,
    zIndex: props.zIndex,
  })),
)

/**
 * HOC for quickly choosing the appropriate component with typography props
 */
export const Typography = forwardRef<HTMLElement, Props>(
  ({ as, children, style, ...rest }: Props & { as?: ElementType }, ref) => {
    return (
      <StyledTypography as={as} style={style} ref={ref as any} {...rest}>
        {children}
      </StyledTypography>
    )
  },
)

Typography.displayName = 'Typography'

type StyledHProps<T> = PropsWithChildren<
  DetailedHTMLProps<HTMLAttributes<HTMLHeadingElement> & T, HTMLHeadingElement>
>
export type HProps = StyledHProps<Props>

export const H1 = forwardRef(
  (
    { children, color, lineHeight = '1', fontSize = '32px', ...rest }: HProps,
    ref,
  ) => (
    <Typography
      color={color}
      lineHeight={lineHeight}
      fontSize={fontSize}
      ref={ref as any}
      {...rest}
      as="h1"
    >
      {children}
    </Typography>
  ),
)

H1.displayName = 'H1'

export const H2 = forwardRef(
  (
    { children, color, lineHeight = '1', fontSize = '28px', ...rest }: HProps,
    ref,
  ) => (
    <Typography
      color={color}
      lineHeight={lineHeight}
      fontSize={fontSize}
      ref={ref as any}
      {...rest}
      as="h2"
    >
      {children}
    </Typography>
  ),
)
H2.displayName = 'H2'

export const H3 = forwardRef(
  (
    { children, color, lineHeight = '1', fontSize = '24px', ...rest }: HProps,
    ref,
  ) => (
    <Typography
      color={color}
      lineHeight={lineHeight}
      fontSize={fontSize}
      ref={ref as any}
      {...rest}
      as="h3"
    >
      {children}
    </Typography>
  ),
)
H3.displayName = 'H3'

export const H4 = forwardRef(
  (
    { children, color, lineHeight = '1', fontSize = '20px', ...rest }: HProps,
    ref,
  ) => (
    <Typography
      color={color}
      lineHeight={lineHeight}
      fontSize={fontSize}
      ref={ref as any}
      {...rest}
      as="h4"
    >
      {children}
    </Typography>
  ),
)

H4.displayName = 'H4'

export const H5 = forwardRef(
  (
    { children, color, lineHeight = '1', fontSize = '16px', ...rest }: HProps,
    ref,
  ) => (
    <Typography
      color={color}
      lineHeight={lineHeight}
      fontSize={fontSize}
      ref={ref as any}
      {...rest}
      as="h5"
    >
      {children}
    </Typography>
  ),
)
H5.displayName = 'H5'

export const H6 = forwardRef(
  (
    { children, color, lineHeight = '1', fontSize = '14px', ...rest }: HProps,
    ref,
  ) => (
    <Typography
      color={color}
      lineHeight={lineHeight}
      fontSize={fontSize}
      ref={ref as any}
      {...rest}
      as="h6"
    >
      {children}
    </Typography>
  ),
)

H6.displayName = 'H6'

type StyledTextProps<T = {}> = DetailedHTMLProps<
  HTMLAttributes<HTMLSpanElement> & T,
  HTMLSpanElement
>
export type PProps = StyledTextProps<TextProps>

export const P = forwardRef(
  (
    { children, color, lineHeight = '1', fontSize = '14px', ...rest }: PProps,
    ref,
  ) => (
    <Typography
      color={color}
      lineHeight={lineHeight}
      fontSize={fontSize}
      ref={ref as any}
      {...rest}
      as="p"
    >
      {children}
    </Typography>
  ),
)

P.displayName = 'P'

export const Span = forwardRef(
  (
    {
      children,
      color,
      lineHeight = '1',
      fontSize = '14px',
      display = 'inline-block',
      ...rest
    }: PProps,
    ref,
  ) => (
    <Typography
      color={color}
      lineHeight={lineHeight}
      fontSize={fontSize}
      display={display}
      ref={ref as any}
      {...rest}
      as="span"
    >
      {children}
    </Typography>
  ),
)
Span.displayName = 'Span'

export const Small = forwardRef(
  (
    { children, color, lineHeight = '1', fontSize = '12px', ...rest }: PProps,
    ref,
  ) => (
    <Typography
      color={color}
      lineHeight={lineHeight}
      fontSize={fontSize}
      ref={ref as any}
      {...rest}
      as="small"
    >
      {children}
    </Typography>
  ),
)

Small.displayName = 'Small'

export const Strong = forwardRef(
  (
    { children, color, lineHeight = '1', fontSize = '14px', ...rest }: PProps,
    ref,
  ) => (
    <Typography
      color={color}
      lineHeight={lineHeight}
      fontSize={fontSize}
      ref={ref as any}
      {...rest}
      as="strong"
    >
      {children}
    </Typography>
  ),
)
Strong.displayName = 'Strong'

type StyledLabelProps<T = {}> = DetailedHTMLProps<
  HTMLAttributes<HTMLLabelElement> & T,
  HTMLLabelElement
> &
  LabelHTMLAttributes<T>

export type LabelProps = StyledLabelProps<TextProps>

export const SubLabel = forwardRef(
  (
    {
      children,
      color = 'var(--colors-typography-labels-sub)',
      fontSize = '12px',
      letterSpacing = '0.14px',
      ...rest
    }: LabelProps,
    ref,
  ) => (
    <Typography
      color={color}
      letterSpacing={letterSpacing}
      fontSize={fontSize}
      ref={ref as any}
      {...rest}
      as="label"
    >
      {children}
    </Typography>
  ),
)
SubLabel.displayName = 'SubLabel'

export const Title = forwardRef(
  (
    {
      children,
      fontSize = '24px',
      fontWeight = 'bold',
      fontStyle = 'normal',
      lineHeight = '1.33',
      letterSpacing = '0.29px',
      color = 'var(--colors-typography-notes)',
      ...rest
    }: PProps,
    ref,
  ) => (
    <Typography
      color={color}
      lineHeight={lineHeight}
      fontSize={fontSize}
      fontWeight={fontWeight}
      fontStyle={fontStyle}
      letterSpacing={letterSpacing}
      ref={ref as any}
      {...rest}
      as="p"
    >
      {children}
    </Typography>
  ),
)
Title.displayName = 'Title'

export const SectionTitle = forwardRef(
  (
    {
      children,
      fontSize = '16px',
      fontWeight = 'bold',
      fontStyle = 'normal',
      lineHeight = '1.33',
      letterSpacing = '0.29px',
      color = 'var(--colors-typography-notes)',
      ...rest
    }: PProps,
    ref,
  ) => (
    <Typography
      color={color}
      lineHeight={lineHeight}
      fontSize={fontSize}
      fontWeight={fontWeight}
      fontStyle={fontStyle}
      letterSpacing={letterSpacing}
      ref={ref as any}
      {...rest}
      as="p"
    >
      {children}
    </Typography>
  ),
)
SectionTitle.displayName = 'SectionTitle'

export const A = forwardRef(
  (
    { children, ...rest }: PProps & AnchorHTMLAttributes<HTMLAnchorElement>,
    ref,
  ) => (
    <Typography ref={ref as any} {...rest} as="a">
      {children}
    </Typography>
  ),
)

A.displayName = 'A'
