import styled from '@emotion/styled'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
`

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 50px;

  input[type='range'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    outline: none;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    background-color: transparent;
    pointer-events: none;
  }

  input[type='range']::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    height: 5px;
  }

  input[type='range']::-moz-range-track {
    -moz-appearance: none;
    height: 5px;
  }

  input[type='range']::-ms-track {
    appearance: none;
    height: 5px;
  }

  #main-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 15px;
    width: 3px;
    background-color: #3af6c2;
    cursor: pointer;
    pointer-events: auto;
    margin-top: -5px;
  }

  #main-slider::-moz-range-thumb {
    -moz-appearance: none;
    height: 15px;
    width: 3px;
    background-color: #3af6c2;
    cursor: pointer;
    pointer-events: auto;
    margin-top: -12px;
  }

  #main-slider::-ms-thumb {
    appearance: none;
    height: 15px;
    width: 3px;
    background-color: #3af6c2;
    cursor: pointer;
    pointer-events: auto;
    margin-top: -12px;
  }

  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 23px;
    width: 5px;
    background-color: #5dbaca;
    cursor: pointer;
    pointer-events: auto;
    margin-top: -9px;
  }

  input[type='range']::-moz-range-thumb {
    -moz-appearance: none;
    height: 23px;
    width: 5px;
    background-color: #5dbaca;
    cursor: pointer;
    pointer-events: auto;
    margin-top: -9px;
  }

  input[type='range']::-ms-thumb {
    appearance: none;
    height: 23px;
    width: 5px;
    background-color: #5dbaca;
    cursor: pointer;
    pointer-events: auto;
    margin-top: -9px;
  }

  input[type='range']:active::-webkit-slider-thumb {
    background-color: #5dbaca;
    border: 3px solid #6dcada;
  }
`

const SliderTrack = styled.div`
  width: 100%;
  height: 15px;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  border-radius: 5px;
`

export interface SliderProps {
  mainValue: number
  onChangeMainValue?: (val: number) => void
  onSeekMouseDown?: () => void
  onSeekMouseUp?: (ev: any) => void
}

export const Slider = ({
  mainValue,
  onChangeMainValue,
  onSeekMouseDown,
  onSeekMouseUp,
}: SliderProps) => {
  const handleMainValue = (ev: any) => {
    if (onChangeMainValue) {
      onChangeMainValue(Number(ev.target.value))
    }
  }

  return (
    <Wrapper>
      <Container>
        <SliderTrack
          style={{
            background: '#575656',
          }}
        />
        <input
          type="range"
          min="0"
          max="10000"
          value={mainValue}
          onChange={handleMainValue}
          onMouseDown={onSeekMouseDown}
          onMouseUp={onSeekMouseUp}
          id="main-slider"
        />
      </Container>
    </Wrapper>
  )
}
