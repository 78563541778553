import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const CreditcardIncome = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M3,10 L3,12 L1,12 L1,5 C1,3.8954305 1.81402773,3 2.81818182,3 L21.1818182,3 C22.1859723,3 23,3.8954305 23,5 L23,17 C23,18.1045695 22.1859723,19 21.1818182,19 L11,19 L11,17 L21,17 L21,10 L3,10 Z M3,8 L21,8 L21,5 L3,5 L3,8 Z M5.58578644,17 L1,17 L1,15 L5.58578644,15 L4.29289322,13.7071068 L5.70710678,12.2928932 L9.41421356,16 L5.70710678,19.7071068 L4.29289322,18.2928932 L5.58578644,17 Z"
    />
  </BaseIcon>
)
