import { BaseIcon, SVGProps } from '@aurelius/icons'

export const PlayBlueIcon = ({ ...rest }: SVGProps) => (
  <BaseIcon title="Play Icon" viewBox="0 0 17 22" {...rest}>
    <g
      id="Highlights-+-Clips-V5"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Documents-Page–Clips–Editor"
        transform="translate(-142.000000, -636.000000)"
        fill="#00678D"
      >
        <g id="Clip-Creator-–-Open" transform="translate(80.000000, 47.000000)">
          <g id="Group-24" transform="translate(24.000000, 580.000000)">
            <polygon
              id="Triangle"
              transform="translate(46.571429, 20.000000) rotate(-270.000000) translate(-46.571429, -20.000000) "
              points="46.5714286 12 56.8571429 28 36.2857143 28"
            ></polygon>
          </g>
        </g>
      </g>
    </g>
  </BaseIcon>
)
