export enum ClipModes {
  PlayMode = 1,
  EditMode = 2,
  PlayOnlyOneFileMode = 3,
}

export const getModeFromClipMode = (type: ClipModes): number => {
  switch (type) {
    case ClipModes.PlayMode:
      return 1
    case ClipModes.EditMode:
      return 2
    case ClipModes.PlayOnlyOneFileMode:
      return 3
    default:
      return 2
  }
}

export const getClipModeFromMode = (mode?: number): ClipModes => {
  if (!mode) {
    return ClipModes.EditMode
  }
  if (mode === 1) {
    return ClipModes.PlayMode
  } else if (mode === 2) {
    return ClipModes.EditMode
  } else if (mode === 3) {
    return ClipModes.PlayOnlyOneFileMode
  }
  return ClipModes.EditMode
}
