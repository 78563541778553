import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Drop = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M12.6577283,1.24674475 C18.1886921,6.07627821 21,10.29324 21,14 C21,19.2493364 16.9029475,23 12,23 C7.09705254,23 3,19.2493364 3,14 C3,10.29324 5.81130786,6.07627821 11.3422717,1.24674475 L12,0.672428777 L12.6577283,1.24674475 Z M5,14 C5,18.1033978 8.16411512,21 12,21 C15.8358849,21 19,18.1033978 19,14 C19,11.1507676 16.693388,7.56645344 12,3.33659609 C7.30661202,7.56645344 5,11.1507676 5,14 Z M12,20 L12,18 C14.209139,18 16,16.209139 16,14 L18,14 C18,17.3137085 15.3137085,20 12,20 Z"
    />
  </BaseIcon>
)
