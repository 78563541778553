import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Artist = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M12.5449583,5.98310324 C12.1985417,6.57525241 12,7.26444026 12,8 C12,10.209139 13.790861,12 16,12 C16.7422712,12 17.4373203,11.7978187 18.0330812,11.4455225 C16.8338903,10.8860487 15.5806753,9.99488884 14.7928932,9.20710678 C13.9948774,8.40909096 13.1016472,7.16707402 12.5449583,5.98310324 Z M14.0852562,4.48719414 C14.3877487,5.43112584 15.4281841,7.01397051 16.2071068,7.79289322 C16.9733039,8.55909037 18.5287892,9.57630173 19.5217245,9.89832493 C19.8268158,9.33351727 20,8.6869833 20,8 C20,5.790861 18.209139,4 16,4 C15.3063505,4 14.6539391,4.17656148 14.0852562,4.48719414 Z M13.8704322,13.6110971 C12.2697863,13.0032732 10.9967268,11.7302137 10.3889029,10.1295678 L4.29859663,18.8843831 L5.11561694,19.7014034 L13.8704322,13.6110971 Z M16.9127854,13.9310124 L4.88438306,22.2985966 L1.70140337,19.1156169 L10.0689876,7.08721464 C10.5086439,4.2067098 12.9966196,2 16,2 C19.3137085,2 22,4.6862915 22,8 C22,11.0033804 19.7932902,13.4913561 16.9127854,13.9310124 Z"
    />
  </BaseIcon>
)
