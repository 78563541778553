import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const LockNo = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M19.5844957,21.2916025 C19.2125855,21.7299682 18.6300661,22 18,22 L6,22 C4.93164867,22 4,21.2236261 4,20.1666667 L4,11.8333333 C4,10.7763739 4.93164867,10 6,10 L7,10 L7,8.70710678 L1,2.70710678 L2.41421356,1.29289322 L22.4142136,21.2928932 L21,22.7071068 L19.5844957,21.2916025 Z M10.2928932,12 L6,12 L6,20 L18,20 L18,19.7071068 L10.2928932,12 Z M20,16.0786797 L18,14.0786797 L18,12 L15.9213203,12 L13.9213203,10 L15,10 L15,7 C15,5.34314575 13.6568542,4 12,4 C10.894797,4 9.92918,4.59763858 9.4086925,5.48737221 L7.96626499,4.0449447 C8.87618952,2.80496976 10.3440894,2 12,2 C14.7614237,2 17,4.23857625 17,7 L17,10 L18,10 C19.0683513,10 20,10.7763739 20,11.8333333 L20,16.0786797 Z M12,17 C11.4477153,17 11,16.5522847 11,16 C11,15.4477153 11.4477153,15 12,15 C12.5522847,15 13,15.4477153 13,16 C13,16.5522847 12.5522847,17 12,17 Z"
    />
  </BaseIcon>
)
