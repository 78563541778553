import { SVGProps } from './SVGProps'
import styled from '@emotion/styled'
import isPropValid from '@emotion/is-prop-valid'

const StyledBaseIcon = styled('svg', {
  shouldForwardProp: (prop) => isPropValid(prop),
})((props: SVGProps) => ({
  margin: props.margin,
  marginBottom: props.marginBottom,
  marginLeft: props.marginLeft,
  marginRight: props.marginRight,
  marginTop: props.marginTop,
  padding: props.padding,
  paddingBottom: props.paddingBottom,
  paddingLeft: props.paddingLeft,
  paddingRight: props.paddingRight,
  paddingTop: props.paddingTop,
}))

export const BaseIcon = ({
  fill = 'var(--colors-icons)',
  viewBox = '0 0 24 24',
  title,
  children,
  height = 24,
  width = 24,
  style,
  ...rest
}: SVGProps) => {
  return (
    <StyledBaseIcon
      fill={fill}
      width={width}
      height={height}
      viewBox={viewBox}
      style={style}
      {...rest}
    >
      {title ? <title>{title}</title> : null}
      {children}
    </StyledBaseIcon>
  )
}

BaseIcon.displayName = 'BaseIcon'
export default BaseIcon
