import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Brush = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M14,3 L14,6 L12,6 L12,3 L10,3 L10,7 L8,7 L8,3 L6,3 L6,9 L18,9 L18,3 L14,3 Z M4,9 L4,1 L20,1 L20,9 L20.0000198,10.0000198 C20,11.5000033 20,11.5000033 19.9999835,12.9942495 C20.0087372,14.516482 19.1594249,14.9983131 16.7913579,15.7079171 C15.4033862,16.1238306 15,16.3523073 15,16.5 C15,17.2676064 15.0702864,17.7830399 15.2201425,18.3824644 C15.1911605,18.2665365 15.361966,18.9269843 15.3996774,19.1029709 C15.4660007,19.4124795 15.5,19.6941881 15.5,20 C15.5,21.6608656 13.9376765,23 12,23 C10.0623234,23 8.5,21.6608658 8.5,20 C8.5,19.6941881 8.53399931,19.4124795 8.60032259,19.1029709 C8.638034,18.9269843 8.80883947,18.2665365 8.7798575,18.3824644 C8.92971361,17.7830399 9,17.2676064 9,16.5 C9,16.3513074 8.5977122,16.1233443 7.21265211,15.7078263 C4.8477122,14.9983443 4,14.5179741 4,13 L4,9 Z M6,11 L6,13 C6,13.1486926 6.4022878,13.3766557 7.78734789,13.7921737 C10.1522878,14.5016557 11,14.9820259 11,16.5 C11,17.4407269 10.9077864,18.1169601 10.7201425,18.8675356 C10.7380355,18.7959635 10.5833202,19.3941962 10.5559274,19.5220291 C10.5173987,19.7018297 10.5,19.8459905 10.5,20 C10.5,20.4819917 11.1043431,21 12,21 C12.8956568,21 13.5,20.4819915 13.5,20 C13.5,19.8459905 13.4826013,19.7018297 13.4440726,19.5220291 C13.4166798,19.3941962 13.2619645,18.7959635 13.2798575,18.8675356 C13.0922136,18.1169601 13,17.4407269 13,16.5 C13,14.9826981 13.8492981,14.5016584 16.2129647,13.7933724 L16.2172679,13.7920829 C17.6004668,13.3775999 18.0008487,13.1504556 18,13 C18,11.9351632 18,11.626243 18.0000071,11 L6,11 Z"
    />
  </BaseIcon>
)
