import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Icecream = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M13,23 L11,23 L11,19 L6.81818182,19 C5.81402773,19 5,18.1045695 5,17 L5,8 C5,4.13400675 8.13400675,1 12,1 C15.8659932,1 19,4.13400675 19,8 L19,17 C19,18.1045695 18.1859723,19 17.1818182,19 L13,19 L13,23 Z M16.6510385,13.3368307 C16.5984736,13.3712191 16.3373811,13.5537558 16.2548806,13.6070813 C15.8571895,13.8641348 15.4791609,14 15,14 C14.3920612,14 14.0072297,13.8536629 13.0821434,13.396505 C12.7642885,13.2394279 12.6096177,13.1677272 12.4455838,13.1054422 C12.2536187,13.0325515 12.1084167,13 12,13 C11.8145358,13 11.5692487,13.0855649 11.1037392,13.3175588 C11.0298025,13.3545394 11.0298025,13.3545394 10.9532129,13.3931165 C10.0661102,13.839913 9.63902263,14 9,14 C8.36170265,14 7.92805756,13.8383733 7.0416047,13.3939397 C7.02751478,13.3868756 7.01364845,13.3799364 7,13.3731198 L7,17 L17,17 L17,13.1514381 C16.8778139,13.2005637 16.7652276,13.2621272 16.6510385,13.3368307 Z M17,8 C17,5.23857625 14.7614237,3 12,3 C9.23857625,3 7,5.23857625 7,8 L7,11.1810919 C7.25277533,11.271132 7.53332494,11.4031822 7.93797904,11.6060603 C8.53759359,11.906684 8.78796064,12 9,12 C9.21055623,12 9.45484487,11.9084323 10.053562,11.6068835 C10.1312933,11.5677295 10.1312933,11.5677295 10.2116533,11.5275357 C10.9413791,11.1638654 11.411129,11 12,11 C12.3821693,11 12.7548508,11.0835481 13.1555424,11.2356939 C13.3986119,11.3279894 13.5939097,11.4185235 13.9682107,11.603495 C14.5888085,11.9101812 14.8250104,12 15,12 C15.0469054,12 15.0694044,11.9919138 15.1691998,11.9274095 C15.2124468,11.8994562 15.4674932,11.7211464 15.5561148,11.6631693 C15.9921972,11.3778805 16.4469063,11.1837866 17,11.0825395 L17,8 Z"
    />
  </BaseIcon>
)
