import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const BlockDistributeHorizontally = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M14.1428571,20 L9.85714286,20 C8.79431889,20 8,19.0732946 8,18 L8,6 C8,4.92670537 8.79431889,4 9.85714286,4 L14.1428571,4 C15.2056811,4 16,4.92670537 16,6 L16,18 C16,19.0732946 15.2056811,20 14.1428571,20 Z M14,6 L10,6 L10,18 L14,18 L14,6 Z M5,2 L7,2 L7,22 L5,22 L5,2 Z M17,2 L19,2 L19,22 L17,22 L17,2 Z"
    />
  </BaseIcon>
)
