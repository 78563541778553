import { ErrorMessage } from './ErrorMessage'
import styled from '@emotion/styled'
import { Button } from './Button'
import { H1, P } from './Typography'
import { FallbackProps } from 'react-error-boundary'

const ErrorWrapper = styled.div`
  background-color: #ffcccc;
  border-radius: 3px;
`

const isPROD =
  process.env.NODE_ENV === 'production' &&
  (process.env.HOST_ADDRESS?.indexOf?.('https://app.qa.') ?? -1) === -1

export const ErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
  return (
    <ErrorWrapper>
      <section>
        <H1 marginBottom="40px">Something went wrong:</H1>
        <P>Error Message:</P>
        <ErrorMessage marginBottom="16px">
          {error?.message ?? 'Error'}
        </ErrorMessage>
        {!isPROD && <pre>{error.stack}</pre>}
        <Button type="button" onClick={resetErrorBoundary}>
          Try again
        </Button>
      </section>
    </ErrorWrapper>
  )
}
