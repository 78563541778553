import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const PlayerPlayPause = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M2,21 L2,3 C2,2.17595468 2.94076375,1.70557281 3.6,2.2 L15.6,11.2 C16.1333333,11.6 16.1333333,12.4 15.6,12.8 L3.6,21.8 C2.94076375,22.2944272 2,21.8240453 2,21 Z M4,19 L13.3333333,12 L4,5 L4,19 Z M22,22 L20,22 L20,2 L22,2 L22,22 Z M19,22 L17,22 L17,2 L19,2 L19,22 Z"
    />
  </BaseIcon>
)
