import { GlobalRecommendation } from '@aurelius/models'
import { CardProps } from './Card'
import { RecommendationCard } from './RecommendationCard'

export const GlobalRecommendationCard = ({
  ...rest
}: React.PropsWithChildren<
  {
    recommendation: GlobalRecommendation
    checked?: boolean
    check?: (v: { id: string } | null) => void
    readOnly?: boolean
  } & CardProps
>) => <RecommendationCard {...rest} />

GlobalRecommendationCard.displayName = 'GlobalRecommendationCard'
