import * as yup from 'yup'

export const accessLevel = yup.object().shape({
  type: yup
    .string()
    .required('Type of access level is required.')
    .oneOf(['everything', 'specific']),
  ids: yup.array().of(yup.string().required()).required(),
})

export type accessLevelType = yup.InferType<typeof accessLevel>
