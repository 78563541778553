import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const WiFiNo = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M8.24538479,9.65959835 L6.76964468,8.18385824 C5.58381805,8.7545097 4.47238296,9.53146317 3.4891275,10.5147186 C3.4640374,10.5398087 3.43908163,10.5649823 3.4142602,10.5902384 L2,9.17597822 C2.02485621,9.15074942 2.04982752,9.12559148 2.07491394,9.10050506 C3.04944561,8.12597339 4.13190461,7.32514812 5.28381569,6.69802925 L1.29289322,2.70710678 L2.70710678,1.29289322 L22.7071068,21.2928932 L21.2928932,22.7071068 L14.7049041,16.1191177 L14.6524489,16.1715729 C13.0903517,14.6094757 10.5576918,14.6094757 8.99559466,16.1715729 L7.58138109,14.7573593 C8.69350358,13.6452368 10.1335865,13.0609588 11.5903116,13.0045251 L9.83555761,11.2497712 C8.49147032,11.5932681 7.21925369,12.2910596 6.16716753,13.3431458 L4.75295397,11.9289322 C5.77895221,10.902934 6.97242959,10.1464893 8.24538479,9.65959835 Z M13.327168,9.11295451 C15.3650115,9.42110719 17.3259238,10.3597664 18.8950896,11.9289322 L17.5191176,13.3049041 L13.327168,9.11295451 Z M9.44356434,5.22935082 C13.7826585,4.43586976 18.4242914,5.70128319 21.7984307,9.02559113 L20.3841705,10.4398513 C17.8618373,7.96093102 14.5159602,6.82183153 11.2367664,7.02255286 L9.44356434,5.22935082 Z M10.4098082,17.5857864 C11.1908568,16.8047379 12.4571868,16.8047379 13.2382353,17.5857864 L11.8240218,19 L10.4098082,17.5857864 Z"
    />
  </BaseIcon>
)
