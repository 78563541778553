import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const LinkNo = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M5.60143966,7.01565323 L1.29289322,2.70710678 L2.70710678,1.29289322 L22.7071068,21.2928932 L21.2928932,22.7071068 L11.5857864,13 L7,13 L7,11 L9.58578644,11 L7.58578644,9 L6,9 C4.34314575,9 3,10.3431458 3,12 C3,13.6568542 4.34314575,15 6,15 L10,15 L10,17 L6,17 C3.23857625,17 1,14.7614237 1,12 C1,9.37272925 3.02635457,7.21873898 5.60143966,7.01565323 Z M20.5286199,16.3144064 L19.0319937,14.8177802 C20.1804435,14.3970466 21,13.2942322 21,12 C21,10.3431458 19.6568542,9 18,9 L14,9 L14,7 L18,7 C20.7614237,7 23,9.23857625 23,12 C23,13.8388673 22.0073262,15.4458832 20.5286199,16.3144064 Z"
    />
  </BaseIcon>
)
