import * as yup from 'yup'

export const highlightReelForm = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required('Please enter a name for this highlight reel.'),
  description: yup.string().trim(),
})
export type highlightReelFormType = yup.InferType<typeof highlightReelForm>

export const highlightreel = yup.object().shape({
  name: yup.string().trim().required('Name is required.'),
  thumbnail: yup.string(),
  description: yup.string().trim(),
  duration: yup.number(),
  mimetype: yup.string().trim(),
  tags: yup.array().of(yup.string().required()),
  newTags: yup.array().of(yup.string().required()),
  recommendations: yup.array().of(yup.string().required()),
  insights: yup.array().of(yup.string().required()),
  collections: yup.array().of(yup.string().required()),
})

export type highlightreelType = yup.InferType<typeof highlightreel>

export const updateHighlightReelClips = yup.object().shape({
  clips: yup.array().of(yup.string().required()).required(),
})

export type updateHighlightReelClipsType = yup.InferType<
  typeof updateHighlightReelClips
>

export const arrayOfHighlightReels = yup.object().shape({
  highlightreels: yup.array().of(yup.string().required()).required(),
})
export type arrayOfHighlightReelsType = yup.InferType<
  typeof arrayOfHighlightReels
>

export const reelQuery = yup.object().shape({
  email: yup.string().lowercase().required().email(),
  reelId: yup.string(),
  insightId: yup.string(),
  recommendationId: yup.string(),
})
export type reelQueryType = yup.InferType<typeof reelQuery>
