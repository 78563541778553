import ok from './images/icons/icons8-ok.svg'
import cancel from './images/icons/icons8-cancel.svg'
import styled from '@emotion/styled'

export const AcceptButton = styled.button`
  height: 50px;
  width: 50px;
  border: none;
  background-color: #ccc;
  background-image: url(${ok});
  background-size: 24px 24px;
  background-repeat: no-repeat;
  background-position: center;
  filter: invert(100%);
`
export const CancelButton = styled(AcceptButton)`
  background-image: url(${cancel});
`
