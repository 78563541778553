import styled from '@emotion/styled'
import { Box } from '@chakra-ui/react'

export const Spinner = styled(Box)`
  content: ' ';
  display: inline-block;
  height: ${(p) => (p.height ? p.height : '24px')};
  width: ${(p) => (p.width ? p.width : '24px')};
  border-radius: 50%;
  border: 2px solid var(--colors-typography-primary);
  border-top-color: transparent;
  animation: spin 0.75s linear infinite;

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`
