import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Watch = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M19.9381062,13 C19.6824798,15.0499603 18.651251,16.8590542 17.1473414,18.1243601 L15.9615239,22.2747211 C15.8388664,22.7040227 15.4464804,23 15,23 L9,23 C8.55351964,23 8.16113364,22.7040227 8.03847605,22.2747211 L6.85265862,18.1243601 C5.10841647,16.6568518 4,14.4578682 4,12 C4,9.54213182 5.10841647,7.34314819 6.85265862,5.87563988 L8.03847605,1.72527887 C8.16113364,1.29597731 8.55351964,1 9,1 L15,1 C15.4464804,1 15.8388664,1.29597731 15.9615239,1.72527887 L17.1473414,5.87563988 C18.651251,7.14094578 19.6824798,8.95003966 19.9381062,11 L21,11 L21,13 L19.9381062,13 Z M14.6608919,4.45317664 L14.2456986,3 L9.75430141,3 L9.33910809,4.45317664 C10.1715528,4.15967183 11.0671297,4 12,4 C12.9328703,4 13.8284472,4.15967183 14.6608919,4.45317664 Z M9.33910809,19.5468234 L9.75430141,21 L14.2456986,21 L14.6608919,19.5468234 C13.8284472,19.8403282 12.9328703,20 12,20 C11.0671297,20 10.1715528,19.8403282 9.33910809,19.5468234 Z M12,18 C15.3137085,18 18,15.3137085 18,12 C18,8.6862915 15.3137085,6 12,6 C8.6862915,6 6,8.6862915 6,12 C6,15.3137085 8.6862915,18 12,18 Z M13,11 L15,11 L15,13 L11,13 L11,8 L13,8 L13,11 Z"
    />
  </BaseIcon>
)
