import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const CloudNo = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M17.5857864,19 L7,19 C3.6862915,19 1,16.3137085 1,13 C1,10.1320517 3.01605065,7.72290805 5.72212244,7.136336 L1.29289322,2.70710678 L2.70710678,1.29289322 L22.7071068,21.2928932 L21.2928932,22.7071068 L17.5857864,19 Z M7.45532281,8.86953637 L7.39066076,8.98151234 L6.83965518,9.0031404 C4.69934052,9.08715198 3,10.8504451 3,13 C3,15.209139 4.790861,17 7,17 L15.5857864,17 L7.45532281,8.86953637 Z M21.641028,17.4268145 L20.2257382,16.0115247 C20.7069401,15.4794096 21,14.7739287 21,14 C21,12.3431458 19.6568542,11 18,11 C17.9686786,11.0001061 17.9686786,11.0001061 17.9374883,11.0006341 L17.0737589,11.0181765 L16.9309417,10.1661557 C16.5303438,7.77626335 14.4511274,6 12,6 C11.4659176,6 10.9466324,6.08436874 10.4571233,6.24290982 L8.92460896,4.71039545 C9.86234872,4.25169049 10.9093398,4 12,4 C15.1586186,4 17.8750012,6.1056212 18.7254431,9.0522437 C21.1430685,9.40362782 23,11.4849591 23,14 C23,15.3262254 22.4836544,16.5318518 21.641028,17.4268145 Z"
    />
  </BaseIcon>
)
