import * as yup from 'yup'

export const email = yup.object().shape({
  email: yup.string().lowercase().required().email(),
})
export type emailType = yup.InferType<typeof email>
export const emails = yup.object().shape({
  emails: yup
    .array()
    .required()
    .of(yup.string().lowercase().email().required()),
})
export type emailsType = yup.InferType<typeof emails>
