import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const BookmarkNo = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M5,6.41421356 L1.29289322,2.70710678 L2.70710678,1.29289322 L22.7071068,21.2928932 L21.2928932,22.7071068 L19,20.4142136 L19,22.618034 L12,19.118034 L5,22.618034 L5,6.41421356 Z M17,18.4142136 L7,8.41421356 L7,19.381966 L12,16.881966 L17,19.381966 L17,18.4142136 Z M6.329409,2.11519549 C6.53903629,2.04060779 6.76477592,2 7,2 L17,2 C18.1045695,2 19,2.8954305 19,4 L19,14.7857865 L17,12.7857865 L17,4 L8.21421351,4 L6.329409,2.11519549 Z"
    />
  </BaseIcon>
)
