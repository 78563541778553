import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const LightningNo = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M8.21555795,9.62977151 L1.29289322,2.70710678 L2.70710678,1.29289322 L22.7071068,21.2928932 L21.2928932,22.7071068 L14.5273633,15.9415769 L9,22.8507811 L9,15 L3.91937515,15 L8.21555795,9.62977151 Z M13.1044468,14.5186604 L9.63847444,11.052688 L8.08062485,13 L11,13 L11,17.1492189 L13.1044468,14.5186604 Z M10.7139225,6.50681576 L15,1.14921894 L15,9 L20.0806248,9 L17.0257279,12.8186211 L15.6028114,11.3957047 L15.9193752,11 L15.2071068,11 L13,8.79289322 L13,6.85078106 L12.136839,7.92973226 L10.7139225,6.50681576 Z"
    />
  </BaseIcon>
)
