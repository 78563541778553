import * as yup from 'yup'
import { InsightType } from '../interfaces/BaseInsight'

export const newGlobalInsight = yup.object().shape({
  name: yup.string().required('Name is required.'),
  description: yup.string(),
  type: yup
    .string()
    .required('Type is required.')
    .oneOf(Object.keys(InsightType)),
  priority: yup.string().required('Priority is required.'),
  tags: yup.array().of(yup.string().required()),
  newTags: yup.array().of(yup.string().required()),
})

export const updateGlobalInsight = yup.object().shape({
  name: yup.string().required('Name is required.'),
  description: yup.string(),
  type: yup
    .string()
    .required('Type is required.')
    .oneOf(Object.keys(InsightType))
    .required(),
  priority: yup.string().required('Priority is required.'),
})

export const arrayOfGlobalInsights = yup.object().shape({
  globalInsights: yup.array().of(yup.string().required()).required(),
})

export type newGlobalInsightType = yup.InferType<typeof newGlobalInsight>
export type arrayOfGlobalInsightsType = yup.InferType<
  typeof arrayOfGlobalInsights
>
export type updateGlobalInsightType = yup.InferType<typeof updateGlobalInsight>
