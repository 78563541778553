import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Ethereum = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M7.46040481,11.6121684 L12,13.881966 L16.5395952,11.6121684 L12,4.80277564 L7.46040481,11.6121684 Z M15.0791904,14.5784388 L12,16.118034 L8.92080963,14.5784388 L12,19.1972244 L15.0791904,14.5784388 Z M12,1.19722436 L19.2018504,12 L12,22.8027756 L4.79814957,12 L12,1.19722436 Z"
    />
  </BaseIcon>
)
