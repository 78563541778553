import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const ArrowTopRight = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <polygon
      fillRule="evenodd"
      points="19 6.414 5.707 19.707 4.293 18.293 17.586 5 10 5 10 3 21 3 21 14 19 14"
    />
  </BaseIcon>
)
