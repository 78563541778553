import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const PinRound = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M5.68555219,14.803407 C6.09614052,15.3655519 6.55351566,15.9373299 7.05747528,16.518967 C5.19525101,16.9384148 4,17.5336753 4,18 C4,18.8069531 7.5791408,20 12,20 C16.4208592,20 20,18.8069531 20,18 C20,17.5336753 18.804749,16.9384148 16.9425247,16.518967 C17.4464843,15.9373299 17.9038595,15.3655519 18.3144478,14.803407 C20.5632616,15.4857957 22,16.5803715 22,18 C22,20.5067554 17.5202663,22 12,22 C6.4797337,22 2,20.5067554 2,18 C2,16.5803715 3.43673842,15.4857957 5.68555219,14.803407 Z M13.0962138,18.2554254 L12,19.2126187 L10.9037862,18.2554254 C6.98842138,14.8366026 5,11.8112113 5,9 C5,5.02141358 8.10349435,2 12,2 C15.8965056,2 19,5.02141358 19,9 C19,11.8112113 17.0115786,14.8366026 13.0962138,18.2554254 Z M17,9 C17,6.13815834 14.8037557,4 12,4 C9.19624429,4 7,6.13815834 7,9 C7,11.0469642 8.62732685,13.5658963 12,16.5560236 C15.3726731,13.5658963 17,11.0469642 17,9 Z M12,6 C13.6568542,6 15,7.34314575 15,9 C15,10.6568542 13.6568542,12 12,12 C10.3431458,12 9,10.6568542 9,9 C9,7.34314575 10.3431458,6 12,6 Z M12,8 C11.4477153,8 11,8.44771525 11,9 C11,9.55228475 11.4477153,10 12,10 C12.5522847,10 13,9.55228475 13,9 C13,8.44771525 12.5522847,8 12,8 Z"
    />
  </BaseIcon>
)
