import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const FolderCloud = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M21,5 C22.1045695,5 23,5.8954305 23,7 L23,19 C23,20.1045695 22.1045695,21 21,21 L3,21 C1.8954305,21 1,20.1045695 1,19 L1,5 C1,3.8954305 1.8954305,3 3,3 L9,3 C10.1200023,3 10.832939,3.47545118 11.5489764,4.37885309 C11.5967547,4.43913352 11.8100999,4.71588275 11.8624831,4.78081945 C12.019726,4.97574495 12.0517795,4.99972956 12.0017863,5 L21,5 Z M21,19 L21,7 L11.994646,6.99998567 C11.2764915,6.99614058 10.8086916,6.65990923 10.3058322,6.03654146 C10.2364281,5.95050497 10.0158737,5.66440398 9.98159778,5.62115916 C9.60702158,5.14856811 9.38424442,5 9,5 L3,5 L3,19 L21,19 Z M12,8 C13.8381418,8 15.3959754,9.14530656 16.0585781,10.7601274 C17.2069739,11.2589154 18,12.4191703 18,13.75 C18,15.5270723 16.5859894,17 14.8,17 L9.6,17 C7.59309673,17 6,15.3405242 6,13.3333333 C6,11.7353318 7.00976238,10.3577246 8.43419222,9.86245429 C9.24009802,8.72349382 10.5473134,8 12,8 Z M9.6,15 L14.8,15 C15.4627417,15 16,14.4403559 16,13.75 C16,13.0596441 15.4627417,12.5 14.8,12.5 C14.6593862,12.5 14.5244212,12.525193 14.3990378,12.5714821 C14.3996778,12.5477361 14.4,12.5239072 14.4,12.5 C14.4,11.1192881 13.3254834,10 12,10 C10.9529404,10 10.0624876,10.6984512 9.7345996,11.6724807 C9.69022279,11.6686304 9.64533155,11.6666667 9.6,11.6666667 C8.7163444,11.6666667 8,12.4128588 8,13.3333333 C8,14.2538079 8.7163444,15 9.6,15 Z"
    />
  </BaseIcon>
)
