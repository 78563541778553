import { HighlightReel } from '@aurelius/icons'
import { DocumentButton } from './DocumentButton'
import { BoxProps } from '@chakra-ui/react'
import { pick } from '@styled-system/props'

export const CreateHighlightReelButton = ({
  onClick,
  ...rest
}: {
  onClick: VoidFunction
} & BoxProps) => {
  return (
    <DocumentButton
      onClick={onClick}
      text="Add Highlight Reel"
      {...pick(rest)}
      icon={<HighlightReel height={20} width={20} marginRight="8px" />}
    />
  )
}
