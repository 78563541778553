import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Unlock = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M7,11 L7,6 C7,3.23857625 9.23857625,1 12,1 C14.7614237,1 17,3.23857625 17,6 L17,8 L15,8 L15,6 C15,4.34314575 13.6568542,3 12,3 C10.3431458,3 9,4.34314575 9,6 L9,11 L18,11 C19.1045695,11 20,11.8954305 20,13 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,13 C4,11.8954305 4.8954305,11 6,11 L7,11 Z M6,13 L6,21 L18,21 L18,13 L6,13 Z M12,18 C11.4477153,18 11,17.5522847 11,17 C11,16.4477153 11.4477153,16 12,16 C12.5522847,16 13,16.4477153 13,17 C13,17.5522847 12.5522847,18 12,18 Z"
    />
  </BaseIcon>
)
