import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const BookmarkAdd = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M17,19.381966 L17,17 L19,17 L19,22.618034 L12,19.118034 L5,22.618034 L5,4 C5,2.8954305 5.8954305,2 7,2 L17,2 C18.1045695,2 19,2.8954305 19,4 L19,5 L17,5 L17,4 L7,4 L7,19.381966 L12,16.881966 L17,19.381966 Z M18,10 L18,8 L20,8 L20,10 L22,10 L22,12 L20,12 L20,14 L18,14 L18,12 L16,12 L16,10 L18,10 Z"
    />
  </BaseIcon>
)
