import * as yup from 'yup'
import isIp from 'is-ip'
import IpCIDR from 'ip-cidr'

export const ipWhitelist = yup.object().shape({
  ipWhitelist: yup
    .array()
    .of(
      yup
        .string()
        .required()
        .test(
          'is-ip',
          'Invalid IP Address or CIDR range.',
          (value) => isIp.v4(value) || new IpCIDR(value).isValid(),
        )
        .trim(),
    )
    .required(),
})
export type ipWhitelistType = yup.InferType<typeof ipWhitelist>
