import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const CutCoupon = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M20,13 L20,11 L22,11 L22,13 L20,13 Z M20,16 L20,14 L22,14 L22,16 L20,16 Z M20,22 L20,20 L22,20 L22,22 L20,22 Z M20,19 L20,17 L22,17 L22,19 L20,19 Z M17,22 L17,20 L19,20 L19,22 L17,22 Z M14,22 L14,20 L16,20 L16,22 L14,22 Z M8,22 L8,20 L10,20 L10,22 L8,22 Z M11,22 L11,20 L13,20 L13,22 L11,22 Z M5,22 L5,20 L7,20 L7,22 L5,22 Z M5,19 L5,17 L7,17 L7,19 L5,19 Z M17,10 L17,8 L19,8 L19,10 L17,10 Z M14,10 L14,8 L16,8 L16,10 L14,10 Z M20,10 L20,8 L22,8 L22,10 L20,10 Z M6.66301234,7.49725925 C6.18703889,7.81485878 5.61514047,8 5,8 C3.34314575,8 2,6.65685425 2,5 C2,3.34314575 3.34314575,2 5,2 C6.65685425,2 8,3.34314575 8,5 C8,5.31266674 7.95216807,5.61416173 7.86342239,5.8975668 L10.3333333,7.75 L16.4,3.2 L17.6,4.8 L12,9 L17.6,13.2 L16.4,14.8 L10.3333333,10.25 L7.86342239,12.1024332 C7.95216807,12.3858383 8,12.6873333 8,13 C8,14.6568542 6.65685425,16 5,16 C3.34314575,16 2,14.6568542 2,13 C2,11.3431458 3.34314575,10 5,10 C5.61514047,10 6.18703889,10.1851412 6.66301234,10.5027407 L8.66666667,9 L6.66301234,7.49725925 Z M5,14 C5.55228475,14 6,13.5522847 6,13 C6,12.4477153 5.55228475,12 5,12 C4.44771525,12 4,12.4477153 4,13 C4,13.5522847 4.44771525,14 5,14 Z M5,6 C5.55228475,6 6,5.55228475 6,5 C6,4.44771525 5.55228475,4 5,4 C4.44771525,4 4,4.44771525 4,5 C4,5.55228475 4.44771525,6 5,6 Z"
    />
  </BaseIcon>
)
