import { TogglerWrapper } from './Toggler.styles'

export interface TogglerProps {
  isToggled: boolean
  onToggle: () => void
  label?: string
}

export const Toggler = ({ isToggled, onToggle, label = '' }: TogglerProps) => {
  return (
    <TogglerWrapper>
      <label className="toggler-switch">
        <input type="checkbox" checked={isToggled} onChange={onToggle} />
        <span className="toggler-slider" />
      </label>
      {label && <span className="toggler-label">{label}</span>}
    </TogglerWrapper>
  )
}
