import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const CommentPlus = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M12.0867962,18 L6,21.8042476 L6,18 L4,18 C2.8954305,18 2,17.1045695 2,16 L2,4 C2,2.8954305 2.8954305,2 4,2 L20,2 C21.1045695,2 22,2.8954305 22,4 L22,16 C22,17.1045695 21.1045695,18 20,18 L12.0867962,18 Z M8,18.1957524 L11.5132038,16 L20,16 L20,4 L4,4 L4,16 L8,16 L8,18.1957524 Z M11,9 L11,6 L13,6 L13,9 L16,9 L16,11 L13,11 L13,14 L11,14 L11,11 L8,11 L8,9 L11,9 Z"
    />
  </BaseIcon>
)
