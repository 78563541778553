import * as yup from 'yup'

export const login = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required('Email is required.')
    .email('Email is invalid.'),
  password: yup.string().required('Password is required.'),
})
export type loginType = yup.InferType<typeof login>

export const reset = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required('Please enter a valid email.')
    .email('Please enter a valid email.'),
  confirm: yup
    .string()
    .trim()
    .required('Confirm your email address.')
    .email(' Please enter a valid email.')
    .test({
      name: 'testConfirmationEmail',
      test(this, value) {
        return this.parent.email === value
      },
      message: 'Emails do not match.',
      exclusive: false,
    }),
})
export type resetType = yup.InferType<typeof reset>

export const ResetPasswordForm = yup.object().shape({
  password: yup
    .string()
    .trim()
    .required()
    .min(8, 'Password must be at least 8 characters.'),
  confirmPassword: yup
    .string()
    .trim()
    .required()
    .min(8, 'Password must be at least 8 characters.')
    .test({
      name: 'testConfirmationPassword',
      test(this, value) {
        return this.parent.password === value
      },
      message: 'Passwords do not match.',
      exclusive: false,
    }),
})
export type ResetPasswordFormType = yup.InferType<typeof ResetPasswordForm>

export const ResetPassword = yup.object().shape({
  password: yup
    .string()
    .trim()
    .required()
    .min(8, 'Password must be at least 8 characters.'),
})
export type ResetPasswordType = yup.InferType<typeof ResetPassword>

export const ResetToken = yup.object().shape({
  token: yup.string().trim().required('Reset token required.'),
})
export type ResetTokenType = yup.InferType<typeof ResetToken>
