import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const ArrowTopLeft = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <polygon
      fillRule="evenodd"
      points="6.414 5 19.707 18.293 18.293 19.707 5 6.414 5 14 3 14 3 3 14 3 14 5"
    />
  </BaseIcon>
)
