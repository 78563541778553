import { SyntheticEvent, useRef } from 'react'
import { FileUpload } from '@aurelius/icons'
import { IconButton } from './DocumentButton'

export interface UploadButtonProps {
  handleFile: Function
  text: string
  type?: string
  disabled?: boolean
}

export const UploadButton = ({
  handleFile,
  text,
  type,
  disabled = false,
}: UploadButtonProps) => {
  const hiddenFileInput = useRef<HTMLInputElement>(null)

  const handleClick = (): void => {
    hiddenFileInput?.current?.click()
  }
  const handleChange = (event: SyntheticEvent<HTMLInputElement>): void => {
    if (event.currentTarget?.files && event.currentTarget?.files.length > 0) {
      handleFile(event.currentTarget?.files[0])
    }
  }

  return (
    <>
      <IconButton onClick={handleClick} disabled={disabled}>
        <FileUpload
          fill="var(--colors-inputs-text-color)"
          height={20}
          width={20}
          marginRight="8px"
        />
        {text}
      </IconButton>
      <input
        type="file"
        ref={hiddenFileInput}
        accept={type || '*'}
        onChange={handleChange}
        style={{ display: 'none' }}
      />
    </>
  )
}
