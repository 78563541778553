import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Rocket = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M11,22 L9,22 C7.8954305,22 7,21.1045695 7,20 L3,20 L3,14.5 L7,11.5 L7,8 C7,4.84392364 8.9135584,1 12,1 C15.0864416,1 17,4.84392364 17,8 L17,11.5 L21,14.5 L21,20 L17,20 C17,21.1045695 16.1045695,22 15,22 L13,22 L13,23 L11,23 L11,22 Z M13,20 L15,20 L15,10 L15,8 C15,5.80723576 13.6025192,3 12,3 C10.3974808,3 9,5.80723576 9,8 L9,10 L9,20 L11,20 L11,14 L13,14 L13,20 Z M7,14 L5,15.5 L5,18 L7,18 L7,14 Z M17,14 L17,18 L19,18 L19,15.5 L17,14 Z M12,11 C10.8954305,11 10,10.1045695 10,9 C10,7.8954305 10.8954305,7 12,7 C13.1045695,7 14,7.8954305 14,9 C14,10.1045695 13.1045695,11 12,11 Z"
    />
  </BaseIcon>
)
