import { ButtonHTMLAttributes, forwardRef } from 'react'
import styled from '@emotion/styled'
import { Box, BoxProps } from '@chakra-ui/react'

export interface AnchorProps {
  kind?: 'action' | 'cancel'
  action?: () => void
}

const BaseBox = ({ ...props }) => (
  <Box
    background="transparent"
    border="none"
    color="var(--colors-typography-anchor)"
    minHeight="18px"
    padding="0"
    transition="var(--transitions-buttons)"
    fontStyle="normal"
    fontWeight="400"
    fontSize="16px"
    lineHeight="19px"
    {...props}
  />
)

const AnchorStyled = styled(BaseBox)`
  &:hover {
    text-decoration: underline;
  }

  &:disabled,
  &[disabled] {
    cursor: not-allowed;
  }
`

export const Action = forwardRef(
  (
    {
      action,
      kind: kind = 'action',
      children,
      ...props
    }: AnchorProps & BoxProps & ButtonHTMLAttributes<unknown>,
    ref,
  ) => (
    <AnchorStyled
      as="button"
      type={kind === 'cancel' ? 'reset' : 'button'}
      onClick={action}
      ref={ref as any}
      {...props}
    >
      {children}
    </AnchorStyled>
  ),
)

Action.displayName = 'Action'
