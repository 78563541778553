import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const BellNo = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M18.5918201,20.0060337 L15.8743256,20.0060337 C15.6241439,20.9903926 15.0074832,21.8525384 14.0878016,22.4135273 C12.8058555,23.1954909 11.1941445,23.1954909 9.91219841,22.4135273 C8.87009269,21.777862 8.29383594,21.067621 8.08797645,20.0060337 L2,20.0060337 L2,16.597356 L2.2879647,16.3054582 C2.39205094,16.2006412 3.24384208,15.4366433 3.48595854,15.1733251 C4.46898326,14.1042185 5,12.628966 5,9.99618774 C5,8.86162002 5.17378564,7.82494058 5.48730755,6.90152112 L1.29289322,2.70710678 L2.70710678,1.29289322 L22.7071068,21.2928932 L21.2928932,22.7071068 L18.5918201,20.0060337 Z M16.5926673,18.0068809 L7.125136,8.53934957 C7.04340246,8.99464198 7,9.48079158 7,9.99618774 C7,13.1126682 6.29422173,15.0734373 4.95848591,16.5261413 C4.74843403,16.7545871 4.29607181,17.1742479 4,17.445401 L4,18.0068809 L16.5926673,18.0068809 Z M10.1786171,20.0060337 C10.3199018,20.2687456 10.5607105,20.4671167 10.9540156,20.7070255 C11.596268,21.0987877 12.403732,21.0987877 13.0459844,20.7070255 C13.3433933,20.5256116 13.5757865,20.2855657 13.7367218,20.0060337 L10.1786171,20.0060337 Z M19,9.99618774 L19,14.779947 L17.303195,13.0838608 C17.1024999,12.1972654 17,11.1798521 17,9.99618774 C17,6.22907352 14.6597092,4.01110567 12,4.01110567 C10.9706966,4.01110567 9.99120269,4.33971881 9.17636285,4.96047103 L7.75448353,3.539194 C8.46070283,2.95462856 9.26830273,2.52704144 10.1353075,2.27538271 C10.2402118,2.00482729 10.405725,1.75331598 10.6305147,1.54220031 C11.4002733,0.819266563 12.5997267,0.819266563 13.3694853,1.54220031 C13.5947695,1.75378036 13.7605161,2.00593396 13.8653839,2.27716837 C16.7616088,3.12259832 19,5.93873195 19,9.99618774 Z"
    />
  </BaseIcon>
)
