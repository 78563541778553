import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Select = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M8,4 L8,2 L11,2 L11,4 L8,4 Z M13,4 L13,2 L16,2 L16,4 L13,4 Z M8,22 L8,20 L11,20 L11,22 L8,22 Z M13,22 L13,20 L16,20 L16,22 L13,22 Z M2,16 L2,13 L4,13 L4,16 L2,16 Z M2,11 L2,8 L4,8 L4,11 L2,11 Z M20,16 L20,13 L22,13 L22,16 L20,16 Z M20,11 L20,8 L22,8 L22,11 L20,11 Z M2,6 L2,2 L6,2 L6,4 L4,4 L4,6 L2,6 Z M22,6 L20,6 L20,4 L18,4 L18,2 L22,2 L22,6 Z M2,18 L4,18 L4,20 L6,20 L6,22 L2,22 L2,18 Z M22,18 L22,22 L18,22 L18,20 L20,20 L20,18 L22,18 Z"
    />
  </BaseIcon>
)
