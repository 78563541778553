import * as yup from 'yup'

export const comment = yup.object().shape({
  comment: yup.string().min(1).max(500).required(),
  user: yup.string(),
  url: yup.string().required(),
  source: yup.string().required(),
  emails: yup.array().of(yup.string().email().lowercase().required()),
})

export type commentType = yup.InferType<typeof comment>

export const commentUpdate = yup.object().shape({
  comment: yup.string().min(1).max(500).required(),
  url: yup.string().required(),
  source: yup.string().required(),
  emails: yup.array().of(yup.string().email().lowercase().required()),
})

export type commentUpdateType = yup.InferType<typeof commentUpdate>
