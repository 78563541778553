import * as yup from 'yup'
import { nameAndDescription } from './name'

export const hashtagGroup = nameAndDescription.shape({
  tags: yup.array().of(yup.string().required()).required(),
  newTags: yup.array().of(yup.string().required()).required(),
})
export type hashtagGroupType = yup.InferType<typeof hashtagGroup>

export const groupIds = yup.object().shape({
  groups: yup.array().of(yup.string().required()).required(),
})
export type groupIdsType = yup.InferType<typeof groupIds>
