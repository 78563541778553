import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const CircleArrowBottomRight = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M12,23 C5.92486775,23 1,18.0751322 1,12 C1,5.92486775 5.92486775,1 12,1 C18.0751322,1 23,5.92486775 23,12 C23,18.0751322 18.0751322,23 12,23 Z M12,21 C16.9705627,21 21,16.9705627 21,12 C21,7.02943725 16.9705627,3 12,3 C7.02943725,3 3,7.02943725 3,12 C3,16.9705627 7.02943725,21 12,21 Z M14,12.5857864 L14,9 L16,9 L16,16 L9,16 L9,14 L12.5857864,14 L7.29289322,8.70710678 L8.70710678,7.29289322 L14,12.5857864 Z"
    />
  </BaseIcon>
)
