import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Broadcasting = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M9.38742589,20 L8.72075922,22 L6.61257411,22 L9.89881747,12.1412699 C9.34410225,11.5968755 9,10.8386225 9,10 C9,8.34314575 10.3431458,7 12,7 C13.6568542,7 15,8.34314575 15,10 C15,10.8386225 14.6558977,11.5968755 14.1011825,12.1412699 L17.3874259,22 L15.2792408,22 L14.6125741,20 L9.38742589,20 Z M10.7207592,16 L10.0540926,18 L13.9459074,18 L13.2792408,16 L10.7207592,16 Z M11.3874259,14 L12.6125741,14 L12.2750928,12.987556 C12.1844984,12.9957918 12.0927406,13 12,13 C11.9072594,13 11.8155016,12.9957918 11.7249072,12.987556 L11.3874259,14 Z M12,11 C12.5522847,11 13,10.5522847 13,10 C13,9.44771525 12.5522847,9 12,9 C11.4477153,9 11,9.44771525 11,10 C11,10.5522847 11.4477153,11 12,11 Z M20.108743,2.56698557 C21.9041909,4.52460368 23,7.13433188 23,10 C23,12.8656681 21.9041909,15.4753963 20.108743,17.4330144 L18.6344261,16.0815573 C20.103429,14.4798697 21,12.3446376 21,10 C21,7.65536245 20.103429,5.52013028 18.6344261,3.91844274 L20.108743,2.56698557 Z M17.1601092,5.26989991 C18.302667,6.51565689 19,8.17639301 19,10 C19,11.823607 18.302667,13.4843431 17.1601092,14.7301001 L15.6857923,13.3786429 C16.501905,12.4888165 17,11.3025764 17,10 C17,8.69742358 16.501905,7.51118349 15.6857923,6.62135708 L17.1601092,5.26989991 Z M3.89125699,2.56665655 L5.3655739,3.91811372 C3.89657104,5.51980126 3,7.65503343 3,9.99967098 C3,12.3443085 3.89657104,14.4795407 5.3655739,16.0812282 L3.89125699,17.4326854 C2.09580905,15.4750673 1,12.8653391 1,9.99967098 C1,7.13400286 2.09580905,4.52427466 3.89125699,2.56665655 Z M6.83989081,5.26957089 L8.31420772,6.62102806 C7.49809502,7.51085447 7,8.69709456 7,9.99967098 C7,11.3022474 7.49809502,12.4884875 8.31420772,13.3783139 L6.83989081,14.7297711 C5.69733303,13.4840141 5,11.823278 5,9.99967098 C5,8.17606399 5.69733303,6.51532787 6.83989081,5.26957089 Z"
    />
  </BaseIcon>
)
