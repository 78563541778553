import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Pocket = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M5,8 L5,16 C5,17.3453523 7.13536157,18.8072282 12.0010062,19.9485587 C16.8661967,18.8122979 19,17.3512826 19,16 C19,15.7712103 19.0005765,15.3491003 19.0018384,14.6071066 C19.0036743,13.5577252 19.0049852,12.8133507 19.0058766,12.2835936 C19.0072397,11.4734722 19.008335,10.7624033 19.0092314,10.0858044 C19.010198,9.35625981 19.0108125,8.65221075 19.0111518,8 L5,8 Z M5,6 L19.01135,6 C19.0109291,4.89761715 19.0095956,4.19630355 19.008182,4.18072328 L19,4 L5,4 L5,6 Z M12,22 C6,20.6604092 3,18.6604092 3,16 L3,4 C3,3 4,2 5,2 L19,2 C20,2 21,3 21,4 C21.0264318,4.29131477 21,14.5 21,16 C21,18.6666667 18,20.6666667 12,22 Z M12,11 C11.4477153,11 11,10.5522847 11,10 C11,9.44771525 11.4477153,9 12,9 C12.5522847,9 13,9.44771525 13,10 C13,10.5522847 12.5522847,11 12,11 Z"
    />
  </BaseIcon>
)
