import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Maximize = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M20,5.41421356 L15.7071068,9.70710678 L14.2928932,8.29289322 L18.5857864,4 L15,4 L15,2 L22,2 L22,9 L20,9 L20,5.41421356 Z M20,20 L20,12 L22,12 L22,22 L12,22 L12,20 L20,20 Z M4,4 L4,12 L2,12 L2,2 L12,2 L12,4 L4,4 Z M9,20 L9,22 L2,22 L2,15 L4,15 L4,18.5857864 L8.29289322,14.2928932 L9.70710678,15.7071068 L5.41421356,20 L9,20 Z"
    />
  </BaseIcon>
)
