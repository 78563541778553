import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Laptop = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M21,15 L23,15 L23,18 C23,19.1045695 22.1045695,20 21,20 L3,20 C1.8954305,20 1,19.1045695 1,18 L1,15 L3,15 L3,6 C3,4.8954305 3.8954305,4 5,4 L19,4 C20.1045695,4 21,4.8954305 21,6 L21,15 Z M19,15 L19,6 L5,6 L5,15 L19,15 Z M3,17 L3,18 L21,18 L21,17 L3,17 Z"
    />
  </BaseIcon>
)
