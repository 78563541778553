import * as Yup from 'yup'

export const paginationParamsSchema = Yup.object().shape({
  search: Yup.string().optional(),
  pageSize: Yup.number().positive().integer().optional(),
  pageNumber: Yup.number().positive().integer().optional(),
  select: Yup.array().of(Yup.string()).optional(),
  populate: Yup.array()
    .of(
      Yup.object().shape({
        path: Yup.string().optional(),
        select: Yup.array().of(Yup.string()).optional(),
        limit: Yup.number().positive().integer().max(1000).optional(),
      }),
    )
    .optional(),
  sort: Yup.lazy((value) =>
    Yup.object()
      .shape(
        Object.keys(value).reduce(
          (acc: Record<string, Yup.NumberSchema>, key) => {
            acc[key] = Yup.number().oneOf([1, -1]).optional()
            return acc
          },
          {},
        ),
      )
      .noUnknown(true, 'sort object can only have keys with values of 1 or -1'),
  ).optional(),
})

export type paginationParamsType = Yup.InferType<typeof paginationParamsSchema>
