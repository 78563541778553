import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const ShieldLock = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M9,9 L9,7 C9,5.34314575 10.3431458,4 12,4 C13.6568542,4 15,5.34314575 15,7 L15,9 C16.1045695,9 17,9.8954305 17,11 L17,15 C17,16.1045695 16.1045695,17 15,17 L9,17 C7.8954305,17 7,16.1045695 7,15 L7,11 C7,9.8954305 7.8954305,9 9,9 Z M11,9 L13,9 L13,7 C13,6.44771525 12.5522847,6 12,6 C11.4477153,6 11,6.44771525 11,7 L11,9 Z M12,23 C6,20.3270758 3,17.6604092 3,15 L3,5 C3,4 3.5,4 5,3 C5.16179337,2.89213775 8.56320917,1 12,1 C14.9952495,1 17.5,2 19,3 C20.5,4 21,4 21,5 C21.0264318,5.29131477 21,13.5 21,15 C21,17.6666667 18,20.3333333 12,23 Z M19,15 C19,14.7696854 19.0005911,14.3838015 19.0018395,13.7546873 C19.002442,13.4510385 19.0036756,12.8625961 19.0058778,11.801424 C19.0071585,11.1695166 19.0081782,10.6272502 19.0090335,10.1097828 C19.0127226,7.87763814 19.0117352,5.96181826 19.0095779,5.36928408 C18.6788452,5.1638246 18.1627209,4.84551481 17.8905996,4.66410059 C16.4143231,3.67991623 14.2601784,3 12,3 C9.8380869,3 7.02863525,4.05127735 6.10940039,4.66410059 C5.83989194,4.84377289 5.33104387,5.15772448 5,5.3633347 L5,15 C5,16.5084521 7.22911083,18.568744 12.000926,20.8019938 C16.7721586,18.5737351 19,16.5142377 19,15 Z M9,11 L9,15 L15,15 L15,11 L9,11 Z M12,14 C11.4477153,14 11,13.5522847 11,13 C11,12.4477153 11.4477153,12 12,12 C12.5522847,12 13,12.4477153 13,13 C13,13.5522847 12.5522847,14 12,14 Z"
    />
  </BaseIcon>
)
