import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const ThumbsUp = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M7.65336736,21.1256786 C7.2933164,21.6534897 6.68712411,22 6,22 L4,22 C2.8954305,22 2,21.1045695 2,20 L2,11 C2,9.8954305 2.8954305,9 4,9 L6,9 C6.198171,9 6.38961023,9.02882212 6.57035446,9.08250314 C7.6444817,7.51807482 11.2554748,3.57449216 12.2202699,2.37298955 L12.5204954,1.99910538 L13,1.99910538 C13.6147967,1.99901314 14.1422801,2.04513312 14.748281,2.22159137 C16.091867,2.61282321 17,3.52709845 17,4.99910538 C17,5.51921677 16.9079007,6.07373891 16.7382242,6.68923415 C16.6259612,7.09646424 16.4872151,7.50766133 16.2970775,8.01801005 C16.2391583,8.17347129 16.007193,8.78168345 15.9547911,8.92337824 C15.9451954,8.94932503 15.9359266,8.9745589 15.9269768,8.99910538 L19,8.99910538 C20.8695981,8.99910538 22,10.316625 22,11.9991054 C22,17.9426745 20.7551411,21.9991054 18,21.9991054 L11.0344006,21.9985135 C10.3408482,22.0111947 9.50544052,21.9186578 8.66312366,21.6172857 C8.29521907,21.4856533 7.9567386,21.3218904 7.65336736,21.1256786 Z M8,17.9991054 C8,18.9013355 8.45196659,19.4175764 9.33687634,19.7341879 C9.91062406,19.9394688 10.5253746,20.0075638 10.963066,19.9997143 C10.9703499,19.9995545 10.9703499,19.9995545 11,19.9991054 L18,19.9991054 C19.015127,19.9991054 20,16.7898506 20,11.9991054 C20,11.3589955 19.6912225,10.9991054 19,10.9991054 L15,10.9991054 C13.8054549,10.9991054 13.5555377,10.0754586 13.8046536,9.07541689 C13.8632547,8.84017106 13.9489218,8.5812741 14.0789589,8.22965366 C14.1358033,8.07594642 14.3689242,7.46470447 14.4229225,7.31976744 C14.5944107,6.85947528 14.7161494,6.4986824 14.8101464,6.1577119 C14.9359344,5.70142019 15,5.31568707 15,4.99910538 C15,4.54813166 14.7621788,4.30870189 14.1891338,4.1418399 C13.9483736,4.07173418 13.7113931,4.0330943 13.4599624,4.01423587 C11.914268,5.86195611 8.40663864,9.70054105 8.14171093,10.3190878 C8.04443154,10.5462133 8,10.7589101 8,10.9991054 L8,17.9991054 Z M4,11 L4,20 L6,20 L6,11 L4,11 Z"
    />
  </BaseIcon>
)
