import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Box = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M20,9 L20,19 C20,20.1045695 19.1045695,21 18,21 L6,21 C4.8954305,21 4,20.1045695 4,19 L4,9 C2.8954305,9 2,8.1045695 2,7 L2,5 C2,3.8954305 2.8954305,3 4,3 L20,3 C21.1045695,3 22,3.8954305 22,5 L22,7 C22,8.1045695 21.1045695,9 20,9 Z M6,9 L6,19 L18,19 L18,9 L6,9 Z M9,12 L9,10 L15,10 L15,12 L9,12 Z M4,5 L4,7 L20,7 L20,5 L4,5 Z"
    />
  </BaseIcon>
)
