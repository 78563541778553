import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const ChevronsBottom = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M17.2928932,10.2928932 L18.7071068,11.7071068 L12,18.4142136 L5.29289322,11.7071068 L6.70710678,10.2928932 L12,15.5857864 L17.2928932,10.2928932 Z M12,10.5857864 L17.2928932,5.29289322 L18.7071068,6.70710678 L12,13.4142136 L5.29289322,6.70710678 L6.70710678,5.29289322 L12,10.5857864 Z"
    />
  </BaseIcon>
)
