import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Ruller = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M23,8 L23,16 C23,17.1045695 22.1045695,18 21,18 L3,18 C1.8954305,18 1,17.1045695 1,16 L1,8 C1,6.8954305 1.8954305,6 3,6 L21,6 C22.1045695,6 23,6.8954305 23,8 Z M21,8 L3,8 L3,16 L21,16 L21,8 Z M7,11 L5,11 L5,8 L7,8 L7,11 Z M11,13 L9,13 L9,8 L11,8 L11,13 Z M15,11 L13,11 L13,8 L15,8 L15,11 Z M19,13 L17,13 L17,8 L19,8 L19,13 Z"
    />
  </BaseIcon>
)
