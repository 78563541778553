import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const KeyNo = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M8.01284518,9.42705874 L1.29289322,2.70710678 L2.70710678,1.29289322 L22.7071068,21.2928932 L21.2928932,22.7071068 L14.5857864,16 L13,16 L13,18 L11,18 L11,20 L9,20 L9,22 L2,22 L2,16.5857864 L8.14800517,10.4377813 C8.07871184,10.10612 8.03344059,9.76854167 8.01284518,9.42705874 Z M12.5857864,14 L10,11.4142136 L4,17.4142136 L4,20 L7,20 L7,18 L9,18 L9,16 L11,16 L11,14 L12.5857864,14 Z M9.23810082,5.0238873 C10.5012135,3.19694219 12.6108264,2 15,2 C18.8659932,2 22,5.13400675 22,9 C22,11.3891736 20.8030578,13.4987865 18.9761127,14.7618992 L17.5286199,13.3144064 C19.0073262,12.4458832 20,10.8388673 20,9 C20,6.23857625 17.7614237,4 15,4 C13.1611327,4 11.5541168,4.99267382 10.6855936,6.47138009 L9.23810082,5.0238873 Z M13.0102705,8.79605695 C13.1124387,7.78728318 13.9642795,7 15,7 C16.1045695,7 17,7.8954305 17,9 C17,10.0357205 16.2127168,10.8875613 15.203943,10.9897295 L13.0102705,8.79605695 Z"
    />
  </BaseIcon>
)
