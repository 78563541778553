import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Scan = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M2,13 L2,11 L22,11 L22,13 L2,13 Z M16,22 L16,20 L20,20 L20,16 L22,16 L22,20 C22,21.1045695 21.1859723,22 20.1818182,22 L16,22 Z M8,22 L3.81818182,22 C2.81402773,22 2,21.1045695 2,20 L2,16 L4,16 L4,20 L8,20 L8,22 Z M16,2 L20.1818182,2 C21.1859723,2 22,2.8954305 22,4 L22,8 L20,8 L20,4 L16,4 L16,2 Z M8,2 L8,4 L4,4 L4,8 L2,8 L2,4 C2,2.8954305 2.81402773,2 3.81818182,2 L8,2 Z"
    />
  </BaseIcon>
)
