import styled from '@emotion/styled'
import { Box } from '@chakra-ui/react'

export const RectangleBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  height: 40px;
  width: 180px;
  border-radius: 2px;
  border: solid 1px var(--colors-buttons-primary-background);
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  border: 2px solid var(--colors-inputs-text-border);
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.19px;
  color: var(--colors-inputs-text-color);
`
