import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Avocado = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M12,23 C6.76072886,23 3,18.8974702 3,14.0002441 C3,11.9853361 3.63235816,9.87359263 5.08579282,6.59475287 C6.59000624,3.20136005 8.7711852,1 11.5,1 L12.5,1 C15.2022739,1 16.9335757,2.76511187 18.8880434,6.54024028 C20.2593613,9.18899298 21,11.8001915 21,14.0002441 C21,18.8974702 17.2392711,23 12,23 Z M12,21 C16.0939876,21 19,17.8298691 19,14.0002441 C19,12.1493807 18.3458278,9.84302866 17.1119566,7.45975972 C15.4749916,4.29789953 14.2019528,3 12.5,3 L11.5,3 C9.77465256,3 8.13211769,4.65773222 6.91420718,7.40524713 C5.56563561,10.4475236 5,12.3364489 5,14.0002441 C5,17.8298691 7.90601241,21 12,21 Z M12,17.75 C9.790861,17.75 8,15.959139 8,13.75 C8,11.4512712 9.82200281,9 12,9 C14.1779972,9 16,11.4512712 16,13.75 C16,15.959139 14.209139,17.75 12,17.75 Z M12,15.75 C13.1045695,15.75 14,14.8545695 14,13.75 C14,12.448905 12.9230449,11 12,11 C11.0769551,11 10,12.448905 10,13.75 C10,14.8545695 10.8954305,15.75 12,15.75 Z"
    />
  </BaseIcon>
)
