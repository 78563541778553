import {
  forwardRef,
  useCallback,
  ChangeEvent,
  ReactNode,
  useEffect,
  useRef,
  ClassAttributes,
  TextareaHTMLAttributes,
  DetailedHTMLProps,
} from 'react'
import styled from '@emotion/styled'
import { ErrorMessage } from './ErrorMessage'
import useComposedRef from 'use-composed-ref'
import { Box, BoxProps } from '@chakra-ui/react'

export interface TextareaProps {
  label?: string
  onChange?: (e: any) => void
  onInput?: (e: any) => void
  newlines?: boolean
  error?: ReactNode
  showError?: boolean
  hideLabel?: boolean
}

const TextareaStyled = styled(Box)`
  min-height: 20px;
  resize: none;
  appearance: none;
  color: var(--colors-inputs-text-color);
  border-radius: 2px;
  border: solid 2px #acbcc4;
  background-color: rgba(221, 233, 233, 0.5);
  font-size: 16px;
  font-weight: 400;
  padding: 0 16px;
`

const LabelStyled = styled.label`
  font-size: 16px;
  letter-spacing: 0.19px;
  color: var(--colors-typography-labels);
  margin-bottom: 8px;

  &.visuallyHidden {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
`

export const getHeight = (
  el:
    | (EventTarget &
        ClassAttributes<HTMLTextAreaElement> &
        TextareaHTMLAttributes<HTMLTextAreaElement>)
    | HTMLTextAreaElement,
): number => {
  if (typeof window != 'undefined') {
    // Get the computed styles for the element
    const computed = window.getComputedStyle(el as Element)
    const scrollHeight =
      (el as unknown as { scrollHeight?: number })?.scrollHeight ?? 0

    // Calculate the height
    return (
      parseInt(computed.getPropertyValue('border-top-width'), 10) +
      parseInt(computed.getPropertyValue('padding-top'), 10) +
      scrollHeight +
      parseInt(computed.getPropertyValue('padding-bottom'), 10) +
      parseInt(computed.getPropertyValue('border-bottom-width'), 10)
    )
  } else {
    return 0
  }
}

export const resize = (
  el:
    | (EventTarget &
        ClassAttributes<HTMLTextAreaElement> &
        TextareaHTMLAttributes<HTMLTextAreaElement>)
    | HTMLTextAreaElement,
): void => {
  if (el?.style != null) {
    // reset the height
    el.style.height = 'inherit'

    // set the new height
    const size = getHeight(el)
    el.style.height = `${size}px`
  }
}

export type TextareaType = DetailedHTMLProps<
  TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
>
export const TextArea = forwardRef<
  TextareaType,
  TextareaType & TextareaProps & BoxProps
>(
  (
    {
      label,
      id,
      newlines,
      error,
      showError = true,
      hideLabel = false,
      ...props
    },
    userRef,
  ) => {
    const { onChange } = props
    const { onInput, ...rest } = props
    const myRef = useRef<HTMLTextAreaElement | null>(null)
    const ref = useComposedRef(myRef, userRef as any)

    const handleInput = useCallback(
      (e: ChangeEvent<TextareaType>) => {
        if (
          newlines === false &&
          e != null &&
          e.target != null &&
          e.target?.value != null
        ) {
          e.target.value = e.target?.value?.toString()?.replace('\n', '')
        }
        if (onInput != null && typeof onInput === 'function') {
          onInput(e)
        }
        resize(e.target)
      },
      [newlines, onInput],
    )
    const handleChange = useCallback(
      (e: ChangeEvent<TextareaType>) => {
        if (
          newlines === false &&
          e != null &&
          e.target != null &&
          e.target?.value != null
        ) {
          e.target.value = e.target?.value?.toString()?.replace('\n', '')
        }
        if (onChange != null && typeof onChange === 'function') {
          onChange(e)
        }
        resize(e.target)
      },
      [newlines, onChange],
    )
    useEffect(() => {
      if (myRef.current?.scrollHeight) {
        resize(myRef.current)
      }
    }, [myRef.current?.scrollHeight])
    return (
      <>
        {label && (
          <LabelStyled
            className={hideLabel ? 'visuallyHidden' : ''}
            htmlFor={id}
          >
            {label}:
          </LabelStyled>
        )}
        <TextareaStyled
          as="textarea"
          ref={ref as any}
          id={id}
          onInput={handleInput as any}
          onChange={handleChange as any}
          {...rest}
        />
        {showError && <ErrorMessage>{error}</ErrorMessage>}
      </>
    )
  },
)

TextArea.displayName = 'TextArea'
