import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const CreditcardOutcome = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M3,10 L3,17 L12,17 L12,19 L2.81818182,19 C1.81402773,19 1,18.1045695 1,17 L1,5 C1,3.8954305 1.81402773,3 2.81818182,3 L21.1818182,3 C22.1859723,3 23,3.8954305 23,5 L23,12 L21,12 L21,10 L3,10 Z M3,8 L21,8 L21,5 L3,5 L3,8 Z M18.5857864,17 L14,17 L14,15 L18.5857864,15 L17.2928932,13.7071068 L18.7071068,12.2928932 L22.4142136,16 L18.7071068,19.7071068 L17.2928932,18.2928932 L18.5857864,17 Z"
    />
  </BaseIcon>
)
