import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const DownloadBold = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M7,11 L7,4 C7,2.8954305 7.8954305,2 9,2 L15,2 C16.1045695,2 17,2.8954305 17,4 L17,11 L22,11 L12,19.5846559 L2,11 L7,11 Z M15,11 L15,4 L9,4 L9,11 L9,13 L7.70325741,13 L12,16.9505003 L16.2967426,13 L15,13 L15,11 Z M21,20 C21,21.1045695 20.1045695,22 19,22 L5,22 C3.8954305,22 3,21.1045695 3,20 L21,20 Z"
    />
  </BaseIcon>
)
