import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const ArrowForward = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <path
      fillRule="evenodd"
      d="M18.5857864,10 L14.2928932,5.70710678 L15.7071068,4.29289322 L22.4142136,11 L15.7071068,17.7071068 L14.2928932,16.2928932 L18.5857864,12 L13,12 C6.25355783,12 4,14.3437001 4,21 L2,21 C2,13.2562999 5.13105756,10 13,10 L18.5857864,10 Z"
    />
  </BaseIcon>
)
